import axios, { AxiosInstance } from "axios";
import { getAuthCookies } from "../utils/auth";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:8080";

interface BaseAPIProps {
  baseURL?: string;
  cache?: boolean;
  generalPath: string;
}

export default class BaseAPI {
  protected axios: AxiosInstance;
  protected baseURL: string;
  constructor({ baseURL = REACT_APP_SERVER_URL, cache = false, generalPath }: BaseAPIProps) {
    this.baseURL = baseURL;
    this.axios = axios.create({
      baseURL: `${this.baseURL}${generalPath}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.axios.interceptors.request.use((config) => {
      config.headers.Authorization = getAuthCookies();
      return config;
    });
  }
}
