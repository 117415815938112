import React from "react";
import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { adminAuthApi } from "../../apis/AdminAuthApi";
import { adminApi } from "../../apis/AdminApi";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";

interface EditEmailFormProps {
  currentEmail: string;
  onBack: () => void;
  onSaveNewEmail: () => void;
}

export default function EditEmailForm({ currentEmail, onBack, onSaveNewEmail }: EditEmailFormProps) {
  const [newEmail, setNewEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const adminId = useSelector((state: any) => state.admin.adminInfo._id);

  const dispatch = useDispatch();

  async function handleSendOtp() {
    try {
      const res = await adminAuthApi.generateOtpAndSendEmail(newEmail);
      if (!res) throw new Error("Error in sending OTP");
      setIsOtpSent(true);
      dispatch(openSnackbar({ message: "OTP sent successfully", severity: "success" }));
    } catch (err) {
      console.error(err);
      setIsOtpSent(false);
      dispatch(openSnackbar({ message: "Error in sending OTP", severity: "error" }));
    }
  }

  async function handleVerifyOtp() {
    try {
      const res = await adminAuthApi.verifyOtp(newEmail, otp);
      if (!res) throw new Error("Invalid OTP");
      const updatedResult = await adminApi.updateAdmin({ id: adminId, email: newEmail });
      if (!updatedResult) throw new Error("Error in updating email");
      onSaveNewEmail();
      onBack();
      dispatch(openSnackbar({ message: "Email updated successfully", severity: "success" }));
    } catch (err: any) {
      console.error(err);
      dispatch(openSnackbar({ message: "Failed to verify OTP!", severity: "error" }));
    }
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    if (isOtpSent) {
      handleVerifyOtp();
    } else {
      handleSendOtp();
    }
  }

  return (
    <React.Fragment>
      <Typography variant="subtitle1" sx={{ float: "right" }}>
        Current Email: {currentEmail} {newEmail && `and new email: ${newEmail}`}
      </Typography>
      <Box>
        {!isOtpSent && (
          <TextField
            margin="normal"
            required
            fullWidth
            label="New Email"
            value={newEmail}
            helperText="abc@example.com"
            onChange={(e) => setNewEmail(e.target.value)}
          />
        )}
        {isOtpSent && (
          <TextField
            margin="normal"
            required
            fullWidth
            label="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        )}
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{ display: "flex", mt: 3, justifyContent: "flex-end", width: { xs: "100%", sm: "auto" } }}
        >
          {isOtpSent ? "Verify OTP and Save" : "Send OTP"}
        </Button>
      </Box>
    </React.Fragment>
  );
}
