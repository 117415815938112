import { Avatar, Box, Chip, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import defaultUserPic from "../../assets/defaultUserPic.png";
import { formatDate, formatTime } from "../../utils/time";
import { getImageUrlFromProfileImageField } from "../../utils/users";

interface UserDetailRowProps {
  user: any;
  tab: string;
}

export default function UserDetailRow({ user, tab }: UserDetailRowProps) {
  return (
    <TableRow
      key={user?._id}
      hover
      sx={{
        "&:hover": { backgroundColor: "secondary.light", cursor: "pointer", color: "white" },
      }}
    >
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            alt={user?.UserName}
            src={getImageUrlFromProfileImageField(user?.profileImage)}
            sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
          />
          <Box>
            <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
              {user?.userName || "No Username"}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
            >
              {user.emailAddress || "No Email"}
              {user.verifiedEmail && (
                <VerifiedIcon color="success" sx={{ ml: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" } }} />
              )}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
        {user?.interests?.length ? (
          user?.interests.map((interest: string, index: number) => <Chip key={index} label={interest} sx={{ mr: 1 }} />)
        ) : (
          <Chip key={"none"} label={"No Interests"} sx={{ mr: 1 }} />
        )}
      </TableCell>

      <TableCell sx={{ display: { xs: tab === "lastActiveAt" ? "table-cell" : "none", sm: "table-cell" } }}>
        <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
          {formatDate(user?.lastActiveAt)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
        >
          {formatTime(user.lastActiveAt)}
        </Typography>
      </TableCell>

      <TableCell
        sx={{
          display: { xs: tab === "accountCreatedAt" || tab === "all" ? "table-cell" : "none", sm: "table-cell" },
        }}
      >
        <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
          {formatDate(user?.accountCreatedAt)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
        >
          {formatTime(user.accountCreatedAt)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
