import BaseAPI from "./BaseApi";

class StatsApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/stats", cache: false });
  }

  async getRecentStats(timeRangeInHours: number = 24): Promise<{
    subscriptions: {
      total: number;
      uniqueUsers: number;
    };
    scratchCards: {
      total: number;
      uniqueUsers: number;
    };
    timeRangeInHours: number;
  }> {
    try {
      const result = await this.axios.get("/", {
        params: { timeRangeInHours },
      });

      if (!result.data || !result.data?.success || !result.data?.stats) {
        throw new Error("Invalid response from server");
      }

      return result.data.stats;
    } catch (error: any) {
      throw new Error("Error in StatsApi.getRecentStats: " + error.message);
    }
  }
}

export const statsApi = new StatsApi();
