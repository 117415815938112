import BaseAPI from "./BaseApi";

class PackTypeApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/pack-type", cache: false });
  }

  async getPackTypes({ ids, name, imageUrl }: any) {
    try {
      const response = await this.axios.get(`/`, {
        params: { ids, name, imageUrl },
      });
      if (!response.data) throw new Error("Invalid response from server");
      return response.data;
    } catch (error: any) {
      throw new Error("Error in PackTypeApi.getPackTypes: " + error.message);
    }
  }

  async UpsertPackType(formData: FormData) {
    try {
      const response = await this.axios.post("/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!response.data) throw new Error("Invalid response from server");
      return response.data;
    } catch (error: any) {
      throw new Error("Error in PackTypeApi.createPackType: " + error.message);
    }
  }

  async updatePackType(id: string, formData: FormData) {
    try {
      const response = await this.axios.put(`/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { id },
      });
      if (!response.data) throw new Error("Invalid response from server");
      return response.data;
    } catch (error: any) {
      throw new Error("Error in PackTypeApi.updatePackType: " + error.message);
    }
  }
}

export const packTypeApi = new PackTypeApi();
