import React from "react";
import { Box, Link, Tooltip, useTheme, alpha, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatCount } from "../../utils/numberUtils";

interface CategoryPathProps {
  path: any[];
  handleCategoryClick: (id: string) => void;
  variant?: "default" | "form" | "no-background";
  highlightTitle?: boolean;
}

export const CategoryPath: React.FC<CategoryPathProps> = ({
  highlightTitle,
  path,
  handleCategoryClick,
  variant = "default",
}) => {
  const theme = useTheme();

  const isForm = variant === "form";
  const isNoBackground = variant === "no-background";

  return (
    <Box
      sx={{
        mb: isForm || isNoBackground ? 0 : "0.33rem",
        py: isForm || isNoBackground ? 0 : "0.5rem",
        px: isForm || isNoBackground ? 0 : "1rem",
        bgcolor: isForm || isNoBackground ? "transparent" : "background.paper",
        borderRadius: "1rem",
        boxShadow: isForm || isNoBackground ? "none" : theme.shadows[1],
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "0.5rem" }}>
        {!isForm ? (
          <Tooltip title="Go to Root" arrow>
            <Link
              component="button"
              onClick={() => handleCategoryClick("")}
              sx={{
                display: "flex",
                alignItems: "center",
                color: isNoBackground ? "text.primary" : "text.secondary",
                textDecoration: "none",
                "&:hover": {
                  color: isNoBackground ? "text.primary" : "primary.main",
                  transform: "translateY(-1px)",
                },
                transition: "all 0.2s ease",
                border: "none",
                background: "none",
                cursor: "pointer",
                p: 1,
                borderRadius: 1,
              }}
            >
              <HomeIcon sx={{ fontSize: "1.5rem" }} />
              <Typography variant="body2" sx={{ ml: "0.5rem", fontSize: "1rem" }}>
                Categories
              </Typography>
            </Link>
          </Tooltip>
        ) : (
          <HomeIcon sx={{ fontSize: "1.5rem", mr: "0.5rem" }} />
        )}

        {path.map((category: any, index: number) => (
          <React.Fragment key={index}>
            <NavigateNextIcon
              sx={{ color: "text.disabled", fontSize: isForm || isNoBackground ? "1.5rem" : "1.6rem" }}
            />
            <Link
              component="button"
              onClick={() => !isForm && handleCategoryClick(typeof category === "string" ? category : category?._id)}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                color: index === path.length - 1 && !isNoBackground ? "primary.main" : "text.primary",
                textDecoration: "none",
                "&:hover": {
                  color: isForm || isNoBackground ? "text.primary" : "primary.main",
                  bgcolor: isForm || isNoBackground ? "transparent" : alpha(theme.palette.primary.main, 0.08),
                  cursor: isForm ? "default" : "pointer",
                },
                transition: "all 0.2s ease",
                border: "none",
                background: "none",
                backgroundColor: highlightTitle && index === path.length - 1 ? "yellow" : "transparent",
                cursor: isForm ? "default" : "pointer",
                px: isForm || isNoBackground ? 0.5 : 1.5,
                py: isForm || isNoBackground ? 0 : 0.5,
                borderRadius: 1,
                fontSize: isForm || isNoBackground ? "1rem" : "1.1rem",
                fontWeight: index === path.length - 1 ? 600 : 400,
              }}
            >
              {typeof category === "string" ? category : category?.title}
              <Tooltip
                title={`Subcategories: ${category.countOfSubCategories}, Items: ${category.countOfItems}, Items Quantity: ${category.sumOfItemsQuantity}`}
              >
                <Typography sx={{ ml: 1 }}>
                  {"(" +
                    formatCount(category.countOfSubCategories || 0) +
                    ", " +
                    formatCount(category.countOfItems || 0) +
                    ", " +
                    formatCount(category.sumOfItemsQuantity || 0) +
                    ")"}
                </Typography>
              </Tooltip>
            </Link>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
