import BaseAPI from "./BaseApi";

class BoxItemApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/boxItem", cache: false });
  }
  async getPaginatedAllBoxItems({
    boxIds,
    project,
    limit,
    page,
    sortKey,
    sortDirection,
    total = false,
  }: {
    boxIds?: string[];
    project?: any;
    limit?: number;
    page?: number;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      let boxIdsString = boxIds?.join(",");

      const result = await this.axios.get("/", {
        params: { boxIds: boxIdsString, project, limit, page, sortKey, sortDirection, total },
      });

      if (!result.data || !result.data?.success || !result.data?.boxItems)
        throw new Error("Invalid response from server");

      return result.data;
    } catch (error: any) {
      throw new Error("Error in packItemApi.getPaginatedAllPackItems: " + error.message);
    }
  }
}

export const boxItemApi = new BoxItemApi();
