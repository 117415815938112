import React from "react";
import {
  Paper,
  Box,
  Typography,
  useTheme,
  alpha,
  useMediaQuery,
  Stack,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Category, CategoryTag } from "../../types/common";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatCount } from "../../utils/numberUtils";

interface CategoryCardProps {
  category: Category;
  onClick: (id: string) => void;
  onEdit?: (category: any) => void;
  onDelete?: (category: any) => void;
}

export const CategoryCard: React.FC<CategoryCardProps> = ({ category, onClick, onEdit, onDelete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      onClick={() => onClick(category._id)}
      sx={{
        minWidth: { xs: 200, sm: 250, md: 300 },
        position: "relative",
        p: { xs: 1.5, sm: 2, md: 2.5 },
        cursor: "pointer",
        height: "100%",
        minHeight: { xs: 80, sm: 100, md: 120 },
        display: "flex",
        alignItems: "center",
        borderRadius: { xs: 1.5, sm: 2 },
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        bgcolor: alpha(theme.palette.primary.main, 0.04),
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        "&:hover": {
          transform: "translateY(-4px)",
          bgcolor: alpha(theme.palette.primary.main, 0.08),
          boxShadow: theme.shadows[4],
          "& .category-icon": {
            transform: "scale(1.1)",
          },
          "& .tag-icon": {
            transform: "rotate(15deg)",
          },
          "& .category-actions": {
            opacity: 1,
          },
        },
      }}
    >
      <Box
        className="category-actions"
        sx={{
          position: "absolute",
          top: { xs: 0, sm: 0 },
          right: { xs: 0, sm: 0 },
          display: "flex",
          gap: { xs: 0.5, sm: 1 },
          opacity: isMobile ? 1 : 0,
          transition: "opacity 0.2s ease",
          borderRadius: 1,
          padding: { xs: "2px", sm: "4px" },
          zIndex: 20000,
        }}
      >
        {onDelete && (
          <Tooltip title="Delete Item">
            <IconButton
              size={isMobile ? "small" : "medium"}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(category);
              }}
            >
              <DeleteIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Stack
        direction="row"
        className="category-icon"
        sx={{
          position: "relative",
          mr: { xs: 1.5, sm: 2 },
          transition: "transform 0.3s ease",
        }}
      >
        <CategoryIcon
          sx={{
            fontSize: { xs: 32, sm: 36, md: 40 },
            color: "primary.main",
          }}
        />
        <LocalOfferIcon
          className="tag-icon"
          sx={{
            position: "absolute",
            fontSize: { xs: 16, sm: 18, md: 20 },
            color: alpha(theme.palette.primary.main, 0.7),
            top: -4,
            right: -8,
            transform: "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          minWidth: 0, // Important for text truncation
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            variant={isMobile ? "subtitle1" : "h6"}
            sx={{
              mb: 0.5,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {category.title}
          </Typography>
          <Tooltip
            title={`Subcategories: ${category.countOfSubCategories}, Items: ${category.countOfItems}, Items Quantity: ${category.sumOfItemsQuantity}`}
          >
            <Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ mb: 0.5, ml: 1 }}>
              {"(" +
                formatCount(category.countOfSubCategories || 0) +
                ", " +
                formatCount(category.countOfItems || 0) +
                ", " +
                formatCount(category.sumOfItemsQuantity || 0) +
                ")"}
            </Typography>
          </Tooltip>
        </Stack>

        {category.description && !isMobile && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: isTablet ? 1 : 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: 1.4,
            }}
          >
            {category.description}
          </Typography>
        )}
        {category.tags && category.tags.length > 0 && (
          <Stack direction="row" spacing={1} sx={{ mb: 1, flexWrap: "wrap", maxWidth: { xs: "100%", sm: "100%" } }}>
            {category.tags.map((tag: CategoryTag, index: number) => (
              <Chip
                key={index}
                label={
                  <>
                    <Typography component="span" fontWeight="bold" sx={{ fontSize: "0.8rem" }}>
                      {tag?.tag}
                    </Typography>
                    <Typography component="span" fontWeight="bold" sx={{ fontSize: "0.8rem" }}>
                      :{" "}
                    </Typography>
                    <Typography component="span" fontWeight="normal" sx={{ fontSize: "0.8rem" }}>
                      {tag?.values?.map((value: string) => (
                        <>
                          <span style={{}}>{value}</span>{" "}
                        </>
                      ))}
                    </Typography>
                  </>
                }
                sx={{
                  height: "auto",
                  p: "0.2rem",
                  fontSize: "0.7rem",
                  bgcolor: "primary.main",
                  color: "white",
                }}
              />
            ))}
          </Stack>
        )}
      </Box>
      <ArrowForwardIcon
        sx={{
          ml: { xs: 1, sm: 1.5, md: "auto" },
          fontSize: { xs: 20, sm: 24 },
          color: "primary.main",
          opacity: 0.7,
        }}
      />
    </Paper>
  );
};
