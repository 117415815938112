import { Box, Button, Divider, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import CustomDialog from "../common/CustomDialog";
import { dropApi } from "../../apis/DropApi";
import { ripPackApi } from "../../apis/RipPackApi";

export default function AddPackItems({ open, onClose, onRefresh }: any) {
  const dispatch = useDispatch();
  const [drops, setDrops] = useState<any[]>([]);
  const [ripPacks, setRipPacks] = useState<any[]>([]);
  const [selectedDropId, setSelectedDropId] = useState<string>("");
  const [selectedRipPackId, setSelectedRipPackId] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("0");

  useEffect(() => {
    async function fetchDrops() {
      try {
        const response = await dropApi.getPaginatedAllDrops({});
        setDrops(response.drops); // assuming response structure
      } catch (error: any) {
        console.error("Failed to fetch drops:", error);
        dispatch(openSnackbar({ message: "Failed to fetch drops.", severity: "error" }));
      }
    }
    fetchDrops();
  }, [dispatch]);

  useEffect(() => {
    // Fetch rip packs whenever a drop is selected
    async function fetchPacks() {
      try {
        if (!selectedDropId) return; // Only fetch if a drop is selected
        const response = await ripPackApi.getRipPackDetails({ dropIds: [selectedDropId] });
        setRipPacks(response.ripPacks);
      } catch (error: any) {
        console.error("Failed to fetch packs:", error);
        dispatch(openSnackbar({ message: "Failed to fetch packs.", severity: "error" }));
      }
    }
    fetchPacks();
  }, [selectedDropId]);

  const handleAddPackItems = async () => {
    try {
      if (
        !selectedDropId ||
        !selectedRipPackId ||
        quantity === null ||
        Number.isNaN(parseInt(quantity)) ||
        parseInt(quantity) <= 0
      )
        return;
      // Use selectedDropId as needed for update
      await ripPackApi.addItemsToRipPack({ ripPackId: selectedRipPackId, quantity: parseInt(quantity) });
      dispatch(openSnackbar({ message: "Quantity updated successfully", severity: "success" }));
      onClose();
      setQuantity("0");
      onRefresh();
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to update quantity!", severity: "error" }));
      console.error(e);
    }
  };
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      text="Add Pack Items"
      subText="Select a drop and pack item to add items"
    >
      <TextField
        select
        label="Select Drop"
        value={selectedDropId}
        onChange={(e) => setSelectedDropId(e.target.value as string)}
        sx={{ mb: 2, width: "100%" }}
      >
        {drops.map((drop: any) => (
          <MenuItem key={drop._id} value={drop._id}>
            {drop.name}
          </MenuItem>
        ))}
      </TextField>

      {/* RipPack selection dropdown (shown only when ripPacks are loaded) */}
      {ripPacks.length > 0 && (
        <React.Fragment>
          <Divider sx={{ mb: 2 }} />
          <TextField
            select
            label="Select Pack Item"
            value={selectedRipPackId}
            onChange={(e) => {
              setSelectedRipPackId(e.target.value as string);
            }}
            fullWidth
          >
            {ripPacks.map((pack: any) => (
              <MenuItem key={pack.details._id} value={pack.details._id}>
                {pack.details.name}
              </MenuItem>
            ))}
          </TextField>
        </React.Fragment>
      )}

      {selectedRipPackId && (
        <React.Fragment>
          <Divider sx={{ mb: 2 }} />
          <TextField
            type="number"
            fullWidth
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          />
        </React.Fragment>
      )}

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleAddPackItems}
          disabled={Number.isNaN(parseInt(quantity)) || parseInt(quantity) <= 0}
        >
          Confirm
        </Button>
      </Box>
    </CustomDialog>
  );
}
