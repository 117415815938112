import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import RewardToolBar from "./reward-activity/RewardListToolBar";
import RewardDetailRow from "./reward-activity/RewardDetailRow";
import RewardDetailHeader from "./reward-activity/RewardDetailHeader";
import { rewardApi } from "../../apis/RewardApi";
import { getTimestampForString } from "../../utils/time";
import CreateReward from "../forms/CreateReward";
import SkeletonRow from "../common/SkeletonRow";

export default function RewardActivity() {
  const [rewards, setRewards] = useState<any[]>([]);
  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [createReward, setCreateReward] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  const fetchRewards = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        total: true,
        search: searchQuery,
        sortKey: sortColumn,
        sortDirection: sortDirection === "desc" ? "-1" : "1",
      };
      const response: any = await rewardApi.getPaginatedRewards(params);

      const { rewards, page, limit, total } = response;

      setRewards(rewards);
      setTotal(total);
      if (!searchQuery) dispatch(openSnackbar({ message: "Rewards fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching rewards:", error);
      dispatch(openSnackbar({ message: "Error fetching rewards", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [sortColumn, sortDirection, searchQuery, dispatch]);

  useEffect(() => {
    fetchRewards();
  }, [sortColumn, sortDirection, searchQuery, fetchRewards]);

  useEffect(() => {
    if (refresh) fetchRewards();
  }, [refresh, fetchRewards]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: "2rem", sm: "3rem" },
        minHeight: "100vh",
        height: "auto",
        backgroundColor: "white",
      }}
    >
      <Box sx={{ mb: 3, width: "100%" }}>
        <Typography variant="h3" gutterBottom>
          Prize Management
        </Typography>
        <Typography variant="h6" gutterBottom>
          Manage prizes
        </Typography>
      </Box>
      <RewardToolBar
        tab={tab}
        handleTabChange={handleTabChange}
        total={total}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRefresh={() => setRefresh(true)}
        setCreateReward={() => setCreateReward(true)}
      />
      <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
        <Table>
          <RewardDetailHeader
            tab={tab}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={setSortColumn}
            handleSortDirection={setSortDirection}
          />
          <TableBody>
            {loading ? (
              <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={4} />
            ) : rewards.length > 0 ? (
              rewards.map((reward: any) => <RewardDetailRow key={reward._id} reward={reward} />)
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No rewards found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateReward
        open={createReward}
        onClose={() => setCreateReward(false)}
        afterCreationCallback={() => setRefresh(true)}
      />
    </Box>
  );
}
