import React from "react";
import { TableRow, TableCell, Typography, Box, Chip, Tooltip, IconButton } from "@mui/material";
import { formatDate, formatTime } from "../../utils/time";
import { useState } from "react";
import { ripPackApi } from "../../apis/RipPackApi";
import PackDetailRow from "./pack-management/PackDetailRow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalMallIcon from "@mui/icons-material/LocalMall";

interface DropDetailRowProps {
  tab?: string;
  drop?: any;
  setSelectedDrop: (drop: any) => void;
  setSelectedPack: (pack: any) => void;
  setPackSelectedForStats?: any;
}

const MAX_VISIBLE_TAGS = 2;

export default function DropDetailRow({
  tab,
  drop,
  setSelectedDrop,
  setSelectedPack,
  setPackSelectedForStats,
}: DropDetailRowProps) {
  const [showPacks, setShowPacks] = useState(false);
  const [ripPacks, setRipPacks] = useState<any[]>([]);
  const [showAllTags, setShowAllTags] = useState(false);

  if (!drop) return null;

  const tags = drop?.tags || [];
  const visibleTags = showAllTags ? tags : tags.slice(0, MAX_VISIBLE_TAGS);
  const hiddenTagCount = tags.length - MAX_VISIBLE_TAGS;

  async function handleToggle() {
    try {
      if (showPacks) {
        setShowPacks(false);
        return;
      }
      const ripPacks = await ripPackApi.getRipPackDetails({
        dropIds: [drop?._id],
      });
      setRipPacks(ripPacks.ripPacks || []);
      setShowPacks(true);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <React.Fragment>
      <TableRow
        key={drop?._id}
        onClick={handleToggle}
        sx={{
          cursor: "pointer",
          position: "relative",
          transition: "all 0.2s ease-in-out",
          borderLeft: "3px solid",
          borderLeftColor: showPacks ? "primary.main" : "transparent",
          "&:hover": {
            bgcolor: "action.hover",
            "& .hover-chip": {
              opacity: 1,
              transform: "translateX(0)",
              visibility: "visible",
            },
          },
          ...(showPacks && {
            bgcolor: "primary.lighter",
            "&:hover": {
              bgcolor: "primary.lighter",
            },
          }),
        }}
      >
        <TableCell>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 28,
                  height: 28,
                  borderRadius: "8px",
                  bgcolor: showPacks ? "primary.main" : "action.selected",
                  color: showPacks ? "primary.contrastText" : "primary.main",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                {showPacks ? (
                  <KeyboardArrowUpIcon sx={{ fontSize: "1.2rem" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ fontSize: "1.2rem" }} />
                )}
              </Box>
              <Typography variant="body1" sx={{ fontWeight: showPacks ? 600 : 400 }}>
                {drop?.name || "No Drop Name"}
              </Typography>

              <Typography
                className="hover-chip"
                variant="caption"
                sx={{
                  ml: 2,
                  opacity: 0,
                  visibility: "hidden",
                  transform: "translateX(-10px)",
                  transition: "all 0.2s ease-in-out",
                  color: showPacks ? "primary.main" : "text.secondary",
                  fontWeight: 500,
                  fontStyle: "italic",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                Click to view packs
                <KeyboardArrowDownIcon sx={{ fontSize: "1rem" }} />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                flexWrap: "wrap",
                mt: 0.5,
                alignItems: "center",
                ml: "36px", // To align with the content after the icon
              }}
            >
              {visibleTags.map((tag: string, index: number) => (
                <Chip
                  key={index}
                  label={tag}
                  size="small"
                  sx={{
                    height: 20,
                    fontSize: "0.75rem",
                    bgcolor: "secondary.main",
                    color: "white",
                    "&:hover": {
                      bgcolor: "secondary.dark",
                    },
                  }}
                />
              ))}
              {!showAllTags && hiddenTagCount > 0 && (
                <Tooltip
                  title={tags.slice(MAX_VISIBLE_TAGS).join(", ")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllTags(true);
                  }}
                >
                  <Chip
                    icon={<MoreHorizIcon />}
                    label={`+${hiddenTagCount} more`}
                    size="small"
                    sx={{
                      height: 20,
                      fontSize: "0.75rem",
                      bgcolor: "action.hover",
                      cursor: "pointer",
                      "&:hover": {
                        bgcolor: "action.selected",
                      },
                    }}
                  />
                </Tooltip>
              )}
              {showAllTags && (
                <Chip
                  label="Show less"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllTags(false);
                  }}
                  sx={{
                    height: 20,
                    fontSize: "0.75rem",

                    bgcolor: "action.hover",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "action.selected",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
            {drop?.packType?.map((type: string, index: number) => (
              <Chip
                key={index}
                label={type}
                size="small"
                sx={{
                  height: 20,
                  fontSize: "0.75rem",
                  bgcolor: "primary.light",
                  color: "primary.dark",
                }}
              />
            ))}
          </Box>
        </TableCell>
        <TableCell>{drop?.isActive ? "Active" : "Inactive"}</TableCell>
        <TableCell>
          <Typography variant="body1">
            {formatDate(drop?.dropTime)}
            <br />
            {formatTime(drop?.dropTime)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {formatDate(drop?.createdAt)}
            <br />
            {formatTime(drop?.createdAt)}
          </Typography>
        </TableCell>
      </TableRow>
      <PackDetailRow
        open={showPacks}
        onClose={() => {
          setShowPacks(false);
        }}
        ripPacks={ripPacks || []}
        setSelectedPack={(pack: any) => {
          setSelectedPack(pack);
          setSelectedDrop(drop);
        }}
        setPackSelectedForStats={setPackSelectedForStats}
      />
    </React.Fragment>
  );
}
