import React, { useEffect, useState } from "react";
import { Category, Item, ItemTag } from "../../types/common";
import { ItemTagEnum } from "../../types/category-item-enum";
import { itemApi } from "../../apis/ItemApi";
import {
  Box,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  TextField,
  TableCell,
  Checkbox,
  TableBody,
  Button,
  Pagination,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { Stack, Typography } from "@mui/material";
import FileUploadButton from "../common/FileUploadButton";
import CustomDialog from "../common/CustomDialog";

interface EditGroupFormProps {
  open: boolean;
  path: Category[];
  onClose: () => void;
  refresh: () => void;
  categoryId: string;
  groupName: string;
}

export default function EditGroupForm({ open, onClose, path, refresh, categoryId, groupName }: EditGroupFormProps) {
  const [items, setItems] = useState<(Item & { selected: boolean })[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(false);

  const pathString = path.map((p: any) => p.title).join(" > ");

  function handleCheckboxChange(item: Item) {
    setItems((prev) => {
      return prev.map((i) => {
        if (i.numberId === item.numberId) {
          return { ...i, selected: !i.selected };
        }
        return i;
      });
    });
  }

  async function handleSave() {
    const formData = new FormData();

    if (selectedFile) {
      formData.append("itemImage", selectedFile, selectedFile.name);
    }

    const updateObject: any = {
      title,
      description,
    };

    const selectedItems = items.filter((item) => item.selected);
    const ids = selectedItems.map((item) => item._id);

    // it is a group update if:
    // condition 1: all selected items are not edited individually
    // condition 2: number of selected items is equal to not edited items
    const condition1 = selectedItems.every((item) => !item.editedIndividual);
    const condition2 = selectedItems.length === items.filter((item) => !item.editedIndividual).length;

    if (!(condition1 && condition2)) {
      updateObject.editedIndividual = true;
    }

    formData.append("updateObject", JSON.stringify(updateObject));
    formData.append("ids", JSON.stringify(ids));

    await itemApi.updateItemsBatch(formData);
    refresh();
    onClose();
  }

  function handleSelectAll() {
    setItems((prev) => prev.map((item) => ({ ...item, selected: true })));
  }

  function handleUnselectAll() {
    setItems((prev) => prev.map((item) => ({ ...item, selected: false })));
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setImageUrlPreview(URL.createObjectURL(file));
    }
  }

  useEffect(() => {
    if (!groupName) return;

    async function fetchGroupItems() {
      setIsLoading(true);

      const groupTag: ItemTag = {
        tag: ItemTagEnum.GROUP_NAME,
        value: groupName,
      };

      const data = await itemApi.getItems({
        categoryId,
        search: searchTerm,
        tags: [groupTag],
        limit: itemsPerPage,
        page,
      });
      setTotalItems(data.total);
      const items = data.items.sort((a: Item, b: Item) => a.numberId - b.numberId);

      setItems(items.map((item: Item) => ({ ...item, selected: !item.editedIndividual })));
      setTotalPages(Math.ceil(data.total / itemsPerPage));

      setIsLoading(false);
    }

    fetchGroupItems();
  }, [groupName, categoryId, page, itemsPerPage, searchTerm]);

  return (
    <CustomDialog open={open} onClose={onClose} text={`Edit Group ${groupName}`} subText="Update the group details">
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            backdropFilter: "blur(4px)",
            zIndex: 1,
          }}
        >
          <CircularProgress size={40} />
        </Box>
      )}
      <Stack sx={{ p: 2, gap: 3 }}>
        <Stack sx={{ gap: 1 }}>
          <TextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
          <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />

          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography>GroupName: </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {groupName} (x {totalItems})
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography>Category: </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{pathString}</Typography>
          </Box>
        </Stack>

        <FileUploadButton onFileChange={handleFileChange}>
          <Typography>Update Item Image</Typography>
        </FileUploadButton>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Pagination count={totalPages} page={page} onChange={(_, newPage) => setPage(newPage)} />
          <TextField
            sx={{ width: "8rem" }}
            size="small"
            label="Item Per Page"
            select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </TextField>
        </Box>

        <TextField
          label="Search by title or serial number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          size="small"
        />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    sx={{ p: 0 }}
                    checked={items.every((item) => item.selected)}
                    indeterminate={items.some((item) => item.selected) && !items.every((item) => item.selected)}
                    onChange={(e) => (e.target.checked ? handleSelectAll() : handleUnselectAll())}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Modified Individually</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox sx={{ p: 0 }} checked={item.selected} onChange={() => handleCheckboxChange(item)} />
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.numberId}</TableCell>
                  <TableCell>{item.editedIndividual ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </CustomDialog>
  );
}
