import BaseAPI from "./BaseApi";

class UserApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/user", cache: false });
  }

  async getPaginatedAllUsers({
    userId,
    limit,
    page,
    search,
    project,
    sortKey,
    sortDirection,
    total = false,
  }: {
    userId?: string;
    limit?: number;
    page?: number;
    search?: string;
    project?: any;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getAllUsers", {
        params: { userId, limit, page, search, project, sortKey, sortDirection, total },
      });
      if (!result.data || !result.data?.success || !result.data?.usersData)
        throw new Error("Invalid response from server");
      return result.data.usersData;
    } catch (error: any) {
      throw new Error("Error in userApi.getPaginatedAllUsers: " + error.message);
    }
  }

  async getPaginatedUsersByEvent({
    eventField,
    fromTimestamp,
    toTimestamp,
    limit,
    page,
    project,
    sortKey,
    sortDirection,
    total = false,
  }: {
    eventField: string;
    fromTimestamp?: string;
    toTimestamp?: string;
    limit?: number;
    page?: number;
    project?: any;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getUsersByEvent", {
        params: { eventField, fromTimestamp, toTimestamp, limit, page, project, sortKey, sortDirection, total },
      });
      if (!result.data || !result.data?.success || !result.data?.usersData)
        throw new Error("Invalid response from server");
      return result.data.usersData;
    } catch (error: any) {
      throw new Error("Error in UserApi.getPaginatedUsersByEvent: " + error.message);
    }
  }
}

export const userApi = new UserApi();
