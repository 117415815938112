import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import SkeletonRow from "../components/common/SkeletonRow";
import { useCallback, useEffect, useState } from "react";
import RoleManagementToolBar from "../components/role-management/RoleManagementToolBar";
import RoleManagementHeader from "../components/role-management/RoleManagementHeader";
import RoleManagementRow from "../components/role-management/RoleManagementRow";
import { adminApi } from "../apis/AdminApi";
import { openSnackbar } from "../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/common/Header";

export default function RoleManagement() {
  const [users, setUsers] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState("all");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  const [rolesMap, setRolesMap] = useState<any[]>([]);
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (adminInfo.roleName !== "superAdmin") {
      navigate("/");
    }
  }, [adminInfo, navigate]);

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  const fetchUsers = useCallback(async () => {
    if (Object.keys(roles).length === 0) return; // Ensure roles are fetched first

    try {
      setLoading(true);

      const usersData = tab === "all" ? await adminApi.getAllAdmins() : [];
      const total = usersData.length;

      // Map each user's role ID to role name
      const usersWithRoles = usersData.map((user: any) => ({
        ...user,
        roleName: rolesMap[user.role] || "AdminUser",
      }));
      setUsers(usersWithRoles);
      setTotal(total);
      dispatch(openSnackbar({ message: "Users fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching users:", error);
      dispatch(openSnackbar({ message: "Error fetching users", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [roles, searchQuery, tab, dispatch]);

  const fetchRoles = useCallback(async () => {
    try {
      setLoading(true);
      const rolesData = await adminApi.getAllRoles();
      if (!rolesData) throw new Error("Roles not defined");

      // Map role IDs to role names
      const rolesMap = rolesData.reduce((acc: Record<string, string>, role: any) => {
        acc[role._id] = role.roleName;
        return acc;
      }, {});
      setRoles(rolesData);
      setRolesMap(rolesMap);
      dispatch(openSnackbar({ message: "Roles fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching Roles:", error);
      dispatch(openSnackbar({ message: "Error fetching roles", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  // Fetch roles on initial load
  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  // Fetch users after roles are loaded
  useEffect(() => {
    if (Object.keys(roles).length > 0) {
      fetchUsers();
    }
  }, [roles, tab, fetchUsers]);

  useEffect(() => {
    if (refresh) fetchUsers();
  }, [refresh, fetchUsers]);
  const filteredUserCount = users.filter(
    (user) =>
      (!roleFilter || user.role === roleFilter) &&
      (user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()))
  ).length;

  const totalPages = Math.ceil(filteredUserCount / rowsPerPage);

  // Filter users based on search query and then paginate
  const filteredUsers = users
    .filter(
      (user) =>
        (!roleFilter || user.role === roleFilter) &&
        (user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, roleFilter]);
  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
          width: "100vw",
          minHeight: "100vh",
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h3" gutterBottom>
            Role Management
          </Typography>
          <Typography variant="h6" gutterBottom>
            Manage Roles
          </Typography>
        </Box>
        <RoleManagementToolBar
          tab={tab}
          total={total}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          roleFilter={roleFilter}
          setRoleFilter={setRoleFilter}
          handleTabChange={handleTabChange}
          onRefresh={() => setRefresh(true)}
          roles={roles}
        />
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
            <Table>
              <RoleManagementHeader tab={tab} />

              <TableBody>
                {loading ? (
                  <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={2} />
                ) : filteredUsers.length > 0 ? (
                  filteredUsers.map((user: any) => (
                    <RoleManagementRow key={user?._id} user={user} tab={tab} roles={roles} setRefresh={setRefresh} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No users found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(totalPages)}
              variant="outlined"
              color="primary"
              page={page + 1}
              onChange={(event, value) => setPage(value - 1)}
            />
          </Box>
        </Box>{" "}
      </Box>
    </>
  );
}
