export enum CategorySortEnum {
  AtoZ = "az",
  ZtoA = "za",
  Recent = "recent",
  QtyOfItems = "qtyOfItems",
}

export const CategorySortLabels: Record<CategorySortEnum, string> = {
  [CategorySortEnum.AtoZ]: "A to Z",
  [CategorySortEnum.ZtoA]: "Z to A",
  [CategorySortEnum.Recent]: "Recent Activity",
  [CategorySortEnum.QtyOfItems]: "Quantity of Items",
};
