import { useEffect } from "react";
import Header from "../components/common/Header";
import { adminAuthApi } from "../apis/AdminAuthApi";
import { useDispatch, useSelector } from "react-redux";
import { setAuthCookies } from "../utils/auth";
import { setAdminInfo } from "../redux/slices/adminUser";
import { openSnackbar } from "../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";

export default function DuoCallback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const duoCode = params.get("duo_code");
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);

  useEffect(() => {
    const adminId = adminInfo.adminId;
    if (!adminId || !duoCode) return;
    adminAuthApi
      .validateDuoCallback({ adminId: adminId, duoCode })
      .then((res: any) => {
        const token = res.token;
        const newAdminInfo = { ...res.admin, signedIn: true };
        setAuthCookies(token);
        dispatch(setAdminInfo(newAdminInfo));
      })
      .catch((error: any) => {
        console.error(error);
        dispatch(openSnackbar({ message: "Failed to validate user!", severity: "error" }));
      })
      .finally(() => {
        navigate("/");
      });
  }, []);
  return (
    <div>
      <Header />
    </div>
  );
}
