import { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  Tooltip,
  Dialog,
  DialogContent,
  Card,
  MenuItem,
  Box,
  Stack,
} from "@mui/material";
import { extractImageAttributes } from "../../utils/operate-html";
import { Close, FormatAlignCenter, FormatAlignLeft, FormatAlignRight, Search, TextFields } from "@mui/icons-material";
import { getS3ImageUrl } from "../../utils/s3Image";
import { openSnackbar } from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import CustomDialog from "../common/CustomDialog";

interface ImageEditorProps {
  htmlContent: string;
  blockName: string;
  handleBlockNameChange: (name: string) => void;
  handleHtmlContentChange: (htmlContent: string, blockIndex: any) => void;
  blockIndex: { index: number; subIndex: number };
  handleDeleteBlock: (blockIndex: any) => void;
}

export default function ImageEditor({
  htmlContent,
  blockIndex,
  blockName,
  handleBlockNameChange,
  handleHtmlContentChange,
  handleDeleteBlock,
}: ImageEditorProps) {
  const [url, setUrl] = useState("");
  const [onClickUrl, setOnClickUrl] = useState("");
  const [width, setWidth] = useState("98");
  const [height, setHeight] = useState("");
  const [assets, setAssets] = useState<any[]>([]);
  const [alignment, setAlignment] = useState("left");
  const [top, setTop] = useState("0");
  const [bottom, setBottom] = useState("0");
  const [showSearchImgDialog, setShowSearchImgDialog] = useState(false);
  const [redirectOption, setRedirectOption] = useState("no-link");
  const dispatch = useDispatch();

  // function searchAssets(input: string) {
  //   if (!input) setAssets([]);
  //   const storedAssets: any = Object.keys(assetFolderApi.assets).map((key) => assetFolderApi.assets[key]);
  //   const tempAssets = storedAssets.filter((asset: any) => {
  //     if (asset.status === "archived") return false;
  //     const lowerCaseInput = input.toLowerCase();
  //     const lowerCaseName = asset.name.toLowerCase();
  //     const nameMatch = lowerCaseName.includes(lowerCaseInput);
  //     const tagsMatch = asset.tag?.some((eachTag: string) => eachTag.toLowerCase().includes(lowerCaseInput));
  //     return nameMatch || tagsMatch;
  //   });
  //   setAssets(tempAssets);
  // }

  function handleInputChanges(type: string, value: string) {
    let tempWidth = width;
    let tempHeight = height;
    let tempUrl = url;
    let tempAlignment = alignment;
    let tempTop = top;
    let tempBottom = bottom;
    let tempOnClickUrl = onClickUrl;
    let tempRedirectOption = redirectOption;
    if (type === "width") {
      tempWidth = value;
      setWidth(value);
    } else if (type === "height") {
      tempHeight = value;
      setHeight(value);
    } else if (type === "url") {
      tempUrl = value;
      setUrl(value);
    } else if (type === "alignment") {
      tempAlignment = value;
      setAlignment(value);
    } else if (type === "top") {
      tempTop = value;
      setTop(value);
      if (value.slice(-1) === ".") return;
    } else if (type === "bottom") {
      tempBottom = value;
      setBottom(value);
      if (value.slice(-1) === ".") return;
    } else if (type === "onClickUrl") {
      tempOnClickUrl = value;
      setOnClickUrl(value);
    } else if (type === "redirectOption") {
      tempRedirectOption = value;
      setRedirectOption(value);
    }
    let updatedHtmlContent = `<div style="text-align: ${tempAlignment}; margin-top: ${tempTop}rem; margin-bottom: ${tempBottom}rem">
    <img src="${tempUrl}" style="width: ${tempWidth}%;${
      tempHeight ? ` height: ${tempHeight}px` : ""
    }" alt="${tempUrl}" /></div>`;
    if (tempOnClickUrl) {
      if (tempRedirectOption === "new-tab")
        updatedHtmlContent = `<a href="${tempOnClickUrl}" target="_blank">${updatedHtmlContent}</a>`;
      else updatedHtmlContent = `<a href="${tempOnClickUrl}">${updatedHtmlContent}</a>`;
    }
    handleHtmlContentChange(updatedHtmlContent, blockIndex);
  }

  useEffect(() => {
    if (!htmlContent) return;
    const { src, width, height, onClickUrl, divStyles, redirectOption }: any = extractImageAttributes(htmlContent);
    setUrl(src);
    setWidth(width);
    setHeight(height);
    setAlignment(divStyles?.textAlign || "left");
    setOnClickUrl(onClickUrl);
    setTop(divStyles?.marginTop?.replace("rem", "") || "");
    setBottom(divStyles?.marginBottom?.replace("rem", "") || "");
    setRedirectOption(redirectOption);
  }, [htmlContent]);

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1, ml: 2, mt: 1 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <TextFields sx={{ fontSize: "1.2rem", color: "primary.main" }} />
          <Box sx={{ fontWeight: 600, color: "text.primary" }}>Image Block</Box>
        </Box>
        <Tooltip title="Delete Block" placement="top">
          <IconButton
            onClick={() => handleDeleteBlock(blockIndex)}
            color="error"
            sx={{
              "&:hover": {
                transform: "scale(1.1)",
                transition: "transform 0.2s",
              },
            }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <Card
        elevation={0}
        sx={{
          p: 3,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 2,
          bgcolor: "background.paper",
          "&:hover": {
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            transition: "box-shadow 0.3s ease-in-out",
          },
          ml: 2,
          mb: 2,
        }}
      >
        <Stack spacing={3}>
          {/* Header with Block Name and Delete */}
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <TextField
              label="Block Name"
              value={blockName}
              onChange={(e) => handleBlockNameChange(e.target.value)}
              size="small"
              sx={{ width: "70%" }}
            />
          </Box>

          {/* Image Preview */}
          <Box
            sx={{
              width: "100%",
              minHeight: "200px",
              border: "2px dashed",
              borderColor: url ? "primary.main" : "divider",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {url ? (
              <img
                src={url}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Box
                sx={{
                  color: "text.secondary",
                  textAlign: "center",
                  p: 2,
                }}
              >
                <Search sx={{ fontSize: 40, mb: 1 }} />
                <div>Click search to add an image</div>
              </Box>
            )}
          </Box>

          {/* Image URL and Search */}
          <TextField
            label="Image URL"
            value={url}
            onChange={(e) => handleInputChanges("url", e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Search Image">
                    <IconButton onClick={() => setShowSearchImgDialog(true)} edge="end">
                      <Search />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          {/* Image Settings */}
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
            <TextField
              label="Width (%)"
              value={width}
              onChange={(e) => handleInputChanges("width", e.target.value)}
              size="small"
              type="number"
              inputProps={{ min: 0, max: 100 }}
            />
            <TextField
              label="Height (px)"
              value={height}
              onChange={(e) => handleInputChanges("height", e.target.value)}
              size="small"
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Top Margin (rem)"
              value={top}
              onChange={(e) => handleInputChanges("top", e.target.value)}
              size="small"
              type="number"
              inputProps={{ step: 0.5 }}
            />
            <TextField
              label="Bottom Margin (rem)"
              value={bottom}
              onChange={(e) => handleInputChanges("bottom", e.target.value)}
              size="small"
              type="number"
              inputProps={{ step: 0.5 }}
            />
          </Box>

          {/* Alignment Controls */}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Align Left">
              <IconButton
                onClick={() => handleInputChanges("alignment", "left")}
                color={alignment === "left" ? "primary" : "default"}
                sx={{ flex: 1, border: 1, borderColor: "divider" }}
              >
                <FormatAlignLeft />
              </IconButton>
            </Tooltip>
            <Tooltip title="Align Center">
              <IconButton
                onClick={() => handleInputChanges("alignment", "center")}
                color={alignment === "center" ? "primary" : "default"}
                sx={{ flex: 1, border: 1, borderColor: "divider" }}
              >
                <FormatAlignCenter />
              </IconButton>
            </Tooltip>
            <Tooltip title="Align Right">
              <IconButton
                onClick={() => handleInputChanges("alignment", "right")}
                color={alignment === "right" ? "primary" : "default"}
                sx={{ flex: 1, border: 1, borderColor: "divider" }}
              >
                <FormatAlignRight />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Redirect Settings */}
          <Box>
            <TextField
              select
              label="Redirect Option"
              value={redirectOption}
              onChange={(e) => handleInputChanges("redirectOption", e.target.value)}
              fullWidth
              size="small"
              sx={{ mb: 2 }}
            >
              <MenuItem value="no-link">No Link</MenuItem>
              <MenuItem value="current-tab">open in current page</MenuItem>
              <MenuItem value="new-tab">open in new tab</MenuItem>
            </TextField>

            {redirectOption !== "no-link" && (
              <TextField
                label="Click URL"
                value={onClickUrl}
                onChange={(e) => handleInputChanges("onClickUrl", e.target.value)}
                fullWidth
                size="small"
                helperText="Enter URL for image click action"
              />
            )}
          </Box>
        </Stack>

        {/* Search Dialog */}
        <CustomDialog open={showSearchImgDialog} onClose={() => setShowSearchImgDialog(false)}>
          <DialogContent>
            <div className="d-flex flex-wrap pt-2 gap-2">
              {assets.map((asset, index) => (
                <Card className="p-2" key={index} sx={{ width: "10rem", height: "10rem" }}>
                  <img
                    src={getS3ImageUrl(asset.url)}
                    className="w-100 h-100"
                    alt={asset.name}
                    onClick={() => {
                      const url = getS3ImageUrl(asset.url);
                      handleInputChanges("url", url);
                      navigator.clipboard
                        .writeText(url)
                        .then(() => {
                          dispatch(openSnackbar({ message: "Image URL copied!" }));
                        })
                        .catch((err) => {
                          dispatch(openSnackbar({ message: err.message, severity: "error" }));
                        });
                      setShowSearchImgDialog(false);
                    }}
                  />
                </Card>
              ))}
            </div>
          </DialogContent>
        </CustomDialog>
      </Card>
    </>
  );
}
