import { TableRow, TableCell, Box, Typography, Avatar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/slices/snackbar";
import { getS3ImageUrl } from "../../../utils/s3Image";
import { formatString } from "../../../utils/string";

interface RewardDetailRowProps {
  reward: any;
}

export default function RewardDetailRow({ reward }: RewardDetailRowProps) {
  const dispatch = useDispatch();
  const handleCopyToClipboard = async () => {
    if (reward?._id) {
      try {
        await navigator.clipboard.writeText(reward?._id);
        dispatch(openSnackbar({ message: "Copied to clipboard", severity: "success" }));
      } catch (error) {
        console.error("Failed to copy text:", error);
      }
    }
  };
  return (
    <TableRow key={reward?._id}>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            alt={reward?.name}
            src={
              getS3ImageUrl({
                imageUrl: reward?.imageUrl,
                type: "reward",
              }) || ""
            }
            sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
          />
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "0.9rem", sm: "1rem" },
                color: "text.primary",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {reward?.name || "No Prize Name"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
            >
              <ContentCopyIcon
                sx={{ mr: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" }, cursor: "pointer" }}
                onClick={handleCopyToClipboard}
              />
              {reward?._id || "No Prize Id"}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell width={"30%"}>
        {reward?.ripPackDetails ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Box sx={{ display: "flex", alignItems: "baseline", gap: 1, mb: 0.5 }}>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {reward?.ripPackDetails?.name || "No Pack Name"}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  color: "text.secondary",
                  fontSize: "0.75rem",
                  lineHeight: 1.2,
                  opacity: 0.75,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                ID: {reward?.ripPackDetails?._id || "N/A"}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Not Appicable
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell>{formatString(reward?.type)}</TableCell>
      <TableCell>{reward?.description}</TableCell>
    </TableRow>
  );
}
