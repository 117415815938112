import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Divider,
  SwipeableDrawer,
  Stack,
  alpha,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { packTypeApi } from "../../apis/PackTypeApi";
import FileUploadButton from "../common/FileUploadButton";
import { Edit, Close as CloseIcon } from "@mui/icons-material";
import { getS3ImageUrl } from "../../utils/s3Image";

interface CreatePackTypeProps {
  open: boolean;
  onClose: () => void;
  setRefresh: (refresh: boolean) => void;
}

export default function CreatePackType({ open, onClose, setRefresh }: CreatePackTypeProps) {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [packTypes, setPackTypes] = useState<any[]>([]);
  const [selectedPackType, setSelectedPackType] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [originalValues, setOriginalValues] = useState({
    name: "",
    description: "",
  });

  // Validation states
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    image: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      description: "",
      image: "",
    };

    // Name validation
    if (!name.trim()) {
      newErrors.name = "Pack type name is required";
      isValid = false;
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long";
      isValid = false;
    }

    // Description validation
    if (!description.trim()) {
      newErrors.description = "Description is required";
      isValid = false;
    } else if (description.length < 10) {
      newErrors.description = "Description must be at least 10 characters long";
      isValid = false;
    }

    // Image validation for new pack type
    if (!isEditing && !image) {
      newErrors.image = "Please upload an image";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    fetchPackTypes();
  }, [open]);

  const fetchPackTypes = async () => {
    try {
      const data = await packTypeApi.getPackTypes({});
      setPackTypes(data.packTypes);
    } catch (error: any) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      dispatch(openSnackbar({ message: "Please fix the errors before submitting", severity: "error" }));
      return;
    }

    try {
      const formData = new FormData();
      if (selectedPackType?._id) formData.append("id", selectedPackType._id);

      formData.append("name", name);
      formData.append("description", description);
      if (image) {
        formData.append("packTypeImage", image);
      }

      if (!(await packTypeApi.UpsertPackType(formData))) throw new Error("Pack Type could not be saved");
      dispatch(
        openSnackbar({
          message:
            isEditing && selectedPackType ? "Pack type updated successfully!" : "Pack type created successfully!",
          severity: "success",
        })
      );

      setRefresh(true);
      resetForm();
      fetchPackTypes();
    } catch (error) {
      dispatch(openSnackbar({ message: "Error saving pack type!", severity: "error" }));
    }
  };

  const handleEdit = (packType: any) => {
    setSelectedPackType(packType);
    setName(packType.name);
    setDescription(packType.description);
    setIsEditing(true);
    // Store original values
    setOriginalValues({
      name: packType.name,
      description: packType.description,
    });
    // Scroll to the form section
    setTimeout(() => {
      const formSection = document.querySelector("[data-form-section]");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setImage(null);
    setImagePreview("");
    setSelectedPackType(null);
    setIsEditing(false);
    setOriginalValues({
      name: "",
      description: "",
    });
    setErrors({
      name: "",
      description: "",
      image: "",
    });
  };

  const hasChanges = () => {
    if (!isEditing) return true; // Always enabled for create mode
    return name !== originalValues.name || description !== originalValues.description || image !== null; // If there's a new image, consider it as changed
  };

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  }

  return (
    <React.Fragment>
      {/* Main Pack Types List */}
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "35%", md: "30%" },
            borderRadius: { xs: 0, sm: 2 },
            height: { xs: "90vh", sm: "100%" },
          },
        }}
      >
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", p: 3 }}>
          {/* Header */}
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Pack Types {packTypes?.length > 0 && `(${packTypes?.length})`}
            </Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* Pack Types Grid */}
          <Box
            sx={{
              position: "relative",
              flex: 1,
              overflowY: "auto",
              mb: 1,
              px: 1,
              mx: -1,
              "&::-webkit-scrollbar": {
                width: "0.5rem",
              },
              "&::-webkit-scrollbar-track": {
                background: "theme.palette.primary.main",
              },
              "&::-webkit-scrollbar-thumb": {
                background: (theme) => alpha(theme.palette.secondary.main, 1),
                borderRadius: "20px",
                "&:hover": {
                  background: (theme) => alpha(theme.palette.secondary.main, 1),
                },
              },
            }}
          >
            {/* Content Container */}
            <Box sx={{ position: "relative", pb: 5 }}>
              <Grid container spacing={2}>
                {packTypes.map((packType: any) => (
                  <Grid size={{ xs: 12, sm: 6 }} key={packType._id}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: (theme) => `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
                        },
                        border: selectedPackType?._id === packType._id ? "2px solid #1976d2" : "1px solid #e0e0e0",
                        borderRadius: 2,
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="180"
                        image={getS3ImageUrl({ imageUrl: packType.imageUrl, _id: packType._id, type: "pack-type" })}
                        alt={packType.name}
                        sx={{
                          objectFit: "cover",
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(0.90)",
                          },
                        }}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h6" component="div">
                          {packType.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {packType.description}
                        </Typography>
                      </CardContent>
                      <Box sx={{ position: "absolute", top: 8, right: 8, display: "flex", gap: 1 }}>
                        <IconButton
                          size="small"
                          onClick={() => handleEdit(packType)}
                          sx={{
                            bgcolor: "rgba(255, 255, 255, 0.9)",
                            "&:hover": { bgcolor: "rgba(255, 255, 255, 1)" },
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/* Fade Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "2rem",
                  background: (theme) =>
                    `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0)}, ${
                      theme.palette.background.paper
                    })`,
                  pointerEvents: "none",
                }}
              />
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Form Section */}
          <Box
            sx={{
              flex: "none",
              bgcolor: "background.paper",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
              {isEditing ? "Edit Pack Type" : "Create New Pack Type"}
            </Typography>

            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Pack Type Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (errors.name) {
                    setErrors((prev) => ({ ...prev, name: "" }));
                  }
                }}
                error={!!errors.name}
                helperText={errors.name}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (errors.description) {
                    setErrors((prev) => ({ ...prev, description: "" }));
                  }
                }}
                error={!!errors.description}
                helperText={errors.description}
                multiline
                rows={4}
                sx={{ mb: 2 }}
              />
              <Box sx={{ mb: 2 }}>
                <FileUploadButton onFileChange={handleFileChange} error={!!errors.image} helperText={errors.image} />
                {(imagePreview || image || selectedPackType?.imageUrl) && (
                  <Box
                    component="img"
                    src={
                      imagePreview ||
                      (image ? URL.createObjectURL(image) : "") ||
                      (selectedPackType &&
                        getS3ImageUrl({
                          imageUrl: selectedPackType?.imageUrl,
                          _id: selectedPackType?._id,
                          type: "pack-type",
                        }))
                    }
                    alt="Selected Image"
                    sx={{
                      mt: 2,
                      width: "100%",
                      height: 200,
                      objectFit: "contain",
                      borderRadius: 1,
                    }}
                  />
                )}
              </Box>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                {isEditing && (
                  <Button variant="outlined" onClick={resetForm} sx={{ borderRadius: 2 }}>
                    Cancel Edit
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ borderRadius: 2 }}
                  disabled={isEditing && !hasChanges()}
                >
                  {isEditing ? "Update Pack Type" : "Create Pack Type"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
