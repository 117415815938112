import { useState, useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  Grid2 as Grid,
  Stack,
  Paper,
  Pagination,
  TextField,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import ItemSkeleton from "./item-section/ItemSkeleton";
import EmptyState from "./item-section/EmptyState";
import ItemCard from "./item-section/ItemCard";
import PackSelectionManager from "./item-section/PackSelectionManager";
import IconSwitch from "../common/IconSwitch";
import { Item } from "../../types/common";
import { getItemGroupName } from "../../utils/item";
import { useSearchParams } from "react-router-dom";
import { ripPackApi } from "../../apis/RipPackApi";

interface ItemSectionProps {
  items: Item[];
  isLoading: boolean;
  groupMax: Record<string, number>;
  setOpenUpdateItemForm: (itemId: string) => void;
  refetchItems: (page: number) => void;
  total?: number;
  totalQuantity?: number;
  page?: number;
  itemsPerPage: number;
  setItemsPerPage: (itemsPerPage: number) => void;
}

export default function ItemSection({
  items,
  isLoading,
  groupMax,
  setOpenUpdateItemForm,
  refetchItems,
  total = 0,
  totalQuantity = 0,
  page = 1,
  itemsPerPage,
  setItemsPerPage,
}: ItemSectionProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedRipPack, setSelectedRipPack] = useState<any>(null);
  const [remainingSlots, setRemainingSlots] = useState<number | null>(null);

  // Memoize the filtered visible items
  const visibleItems = useMemo(() => items.filter((item) => !item?.isHidden), [items]);

  function handleItemSelect({ itemId }: { itemId: string }) {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      if (remainingSlots !== null && selectedItems.length >= remainingSlots) return;

      setSelectedItems([...selectedItems, itemId]);
    }
  }

  useEffect(() => {
    async function validateRipPack() {
      try {
        await ripPackApi.getRipPackDetails({ ripPackIds: [searchParams.get("ripPackId") as string] });
      } catch (error) {
        setSearchParams({});
      }
    }
    const ripPackId = searchParams.get("ripPackId");
    if (ripPackId) {
      validateRipPack();
    }
  }, [searchParams, setSearchParams]);

  return (
    <Stack sx={{ width: "80%", p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h5" sx={{ color: "text.secondary" }}>
            Item Section {visibleItems.length > 0 && `(${totalQuantity})`}
          </Typography>
        </Box>
        {total > itemsPerPage && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
            <Pagination
              count={Math.ceil(total / itemsPerPage)}
              page={page}
              onChange={(_, newPage) => refetchItems(newPage)}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box>
            <TextField
              sx={{ width: "8rem" }}
              size="small"
              label="Item Per Page"
              select
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
              }}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </TextField>
          </Box>
          {/* {visibleItems.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <Button
                size="small"
                variant="outlined"
                disabled={page <= 1}
                onClick={() => !isLoading && refetchItems(page - 1)}
              >
                {isLoading ? "..." : "Prev"}
              </Button>
              <Typography variant="body2" sx={{ mx: 1, textAlign: "center" }}>
                {`Page ${page}`}
              </Typography>
              <Button
                size="small"
                variant="outlined"
                disabled={total <= page * itemsPerPage}
                onClick={() => !isLoading && refetchItems(page + 1)}
              >
                {isLoading ? "..." : "Next"}
              </Button>
            </Box>
          )} */}
        </Box>
      </Box>

      {/* Pack Selection Manager */}
      {searchParams.get("ripPackId") && (
        <PackSelectionManager
          visibleItems={visibleItems}
          selectedItems={selectedItems}
          onSelectedItemsChange={setSelectedItems}
          selectedRipPack={selectedRipPack}
          setSelectedRipPack={setSelectedRipPack}
          remainingSlots={remainingSlots}
          setRemainingSlots={setRemainingSlots}
          refetchItems={refetchItems}
        />
      )}

      {isLoading ? (
        <ItemSkeleton />
      ) : visibleItems.length === 0 ? (
        <Paper
          elevation={0}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.default",
            borderRadius: 2,
            minHeight: 600,
          }}
        >
          <SearchOffIcon sx={{ fontSize: 64, color: "text.secondary", mb: 2 }} />
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No Items Found
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Try adjusting your filters or search terms to find what you're looking for
          </Typography>
        </Paper>
      ) : (
        <>
          <Grid container spacing={3}>
            {visibleItems.map((item) => (
              <Grid key={item._id} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <ItemCard
                  item={item}
                  serialNumberMax={groupMax[getItemGroupName({ itemObj: item })]}
                  isSelected={selectedItems.includes(item._id)}
                  onSelect={() => {
                    handleItemSelect({ itemId: item._id });
                  }}
                  onEdit={() => {
                    setOpenUpdateItemForm(item._id);
                  }}
                  hoveredGroupName={null}
                  onGroupNameHover={() => {}}
                />
              </Grid>
            ))}
          </Grid>
          {total > itemsPerPage && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
              <Pagination
                count={Math.ceil(total / itemsPerPage)}
                page={page}
                onChange={(_, newPage) => refetchItems(newPage)}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </>
      )}
    </Stack>
  );
}
