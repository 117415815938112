import { TableCell, TableHead, TableRow } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImportExportIcon from "@mui/icons-material/ImportExport";

interface SubscriptionListHeaderProps {
  tab: string;
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}

export default function SubscriptionListHeader({
  tab,
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: SubscriptionListHeaderProps) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Subscription</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Description</TableCell>
        <TableCell sx={{ borderBottom: "none", display: { xs: "none", sm: "table-cell" } }}>
          Activation Time
          {sortColumn === "activationTime" ? (
            sortDirection === "1" ? (
              <ArrowUpwardIcon
                sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("activationTime");
                  handleSortDirection("-1");
                }}
              />
            ) : (
              <ArrowDownwardIcon
                sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("activationTime");
                  handleSortDirection("1");
                }}
              />
            )
          ) : (
            <ImportExportIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => {
                handleSort("activationTime");
                handleSortDirection("-1");
              }}
            />
          )}
        </TableCell>
        <TableCell sx={{ borderBottom: "none", display: { xs: "none", sm: "table-cell" } }}>
          Expiration Time
          {sortColumn === "expirationTime" ? (
            sortDirection === "1" ? (
              <ArrowUpwardIcon
                sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("expirationTime");
                  handleSortDirection("-1");
                }}
              />
            ) : (
              <ArrowDownwardIcon
                sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("expirationTime");
                  handleSortDirection("1");
                }}
              />
            )
          ) : (
            <ImportExportIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => {
                handleSort("expirationTime");
                handleSortDirection("-1");
              }}
            />
          )}
        </TableCell>
        <TableCell sx={{ borderBottom: "none", display: { xs: "none", sm: tab !== "all" ? "table-cell" : "none" } }}>
          Users Read Count
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
