export enum scratchCardStatusEnum {
  Created = "created",
  Active = "active",
}

export enum scratchCardUserStatusEnum {
  RewardAssigned = "rewardAssigned",
  Redeemed = "redeemed",
  Claimed = "claimed",
}

export enum scratchCardTypeEnum {
  PhysicalReferral = "physical-referral",
  Physical = "physical",
  PhysicalTest = "physical-test",
  PromoReferral = "promo-code-referral",
  PromoCode = "promo-code",
}

export const scratchCardStatusMap: {
  [key in scratchCardStatusEnum]: { color: string; contrastText: string; permissions: string[] };
} = {
  [scratchCardStatusEnum.Created]: {
    color: "#B3E5FC", // Soft sky blue for 'Created'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
  [scratchCardStatusEnum.Active]: {
    color: "#C8E6C9", // Light mint green for 'Active'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
};

export const scratchCardUserStatusMap: {
  [key in scratchCardUserStatusEnum]: { color: string; contrastText: string; permissions: string[] };
} = {
  [scratchCardUserStatusEnum.RewardAssigned]: {
    color: "#FFF9C4", // Pale yellow for 'RewardAssigned'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
  [scratchCardUserStatusEnum.Redeemed]: {
    color: "#D1C4E9", // Soft lavender for 'Redeemed'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
  [scratchCardUserStatusEnum.Claimed]: {
    color: "#A5D6A7", // Medium mint green for 'Claimed'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
};

export const scratchCardTypeMap: {
  [key in scratchCardTypeEnum]: { color: string; contrastText: string; };
} = {
  [scratchCardTypeEnum.PhysicalReferral]: {
    color: "#E57373", // Distinct red for 'PhysicalReferral'
    contrastText: "#FFFFFF", // White text for better contrast
  },
  [scratchCardTypeEnum.Physical]: {
    color: "#64B5F6", // Bright blue for 'Physical'
    contrastText: "#000000",
  },
  [scratchCardTypeEnum.PhysicalTest]: {
    color: "#81C784", // Fresh green for 'PhysicalTest'
    contrastText: "#000000",
  },
  [scratchCardTypeEnum.PromoReferral]: {
    color: "#FFB74D", // Orange for 'PromoReferral'
    contrastText: "#000000",
  },
  [scratchCardTypeEnum.PromoCode]: {
    color: "#9575CD", // Deep purple for 'PromoCode'
    contrastText: "#FFFFFF",
  },
};
