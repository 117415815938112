import {
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add } from "@mui/icons-material";

interface RoleManagementToolBarProps {
  tab: string;
  total: number;
  searchQuery: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
  roleFilter: string;
  setRoleFilter: (value: string) => void;
  roles: any[];
}

export default function RoleManagementToolBar({
  tab,
  total,
  searchQuery,
  handleTabChange,
  setSearchQuery,
  onRefresh,
  roleFilter,
  setRoleFilter,
  roles,
}: RoleManagementToolBarProps) {
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", my: 2 }}>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="user roles tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ "& .MuiTab-root": { textTransform: "none" } }} // Prevent text from being capitalized
        >
          <Tab label={tab === "all" ? `All Users (${total})` : `All Users`} value="all" />
        </Tabs>
      </Grid>

      <Grid size={{ xs: 12, sm: 8 }} container spacing={2} justifyContent="flex-end">
        <Grid size={{ xs: tab === "all" ? 6 : 12, sm: 4 }}>
          <TextField
            variant="outlined"
            placeholder={total ? "Search admin users..." : "No admin users to search"}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 4, sm: 3 }}>
          <Select value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)} displayEmpty fullWidth>
            <MenuItem value="">
              <em>All Roles</em>
            </MenuItem>
            {roles.map((role: any) => (
              <MenuItem key={role._id} value={role._id}>
                {role.roleName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid size={{ xs: 2, sm: 1 }} container justifyContent="flex-end">
          <Tooltip title="Refresh">
            <IconButton
              onClick={onRefresh}
              aria-label="refresh"
              sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
