import BaseAPI from "./BaseApi";

class RipPackApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/ripPack", cache: false });
  }

  async getRipPackDetails({
    ripPackIds,
    dropIds,
    fromTimestamp,
    toTimestamp,
  }: {
    ripPackIds?: string[];
    dropIds?: string[];
    fromTimestamp?: string;
    toTimestamp?: string;
  }): Promise<{
    ripPacks: Array<{ ripPackId: string; details: any; stats: { count: number; events: any } }>;
    total: number;
  }> {
    try {
      const result = await this.axios.get("", {
        params: {
          ripPackIds,
          dropIds,
          fromTimestamp,
          toTimestamp,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.ripPacksData)
        throw new Error("Invalid response from server");
      return result.data.ripPacksData;
    } catch (error: any) {
      throw new Error("Error in RipPackApi.getRipPackDetails: " + error.message);
    }
  }

  async createRipPack(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!result.data || !result.data?.success || !result.data?.ripPack)
        throw new Error("Invalid response from server");
      return result.data.ripPack;
    } catch (error: any) {
      throw new Error("Error in RipPackApi.createRipPack: " + error.message);
    }
  }

  async addItemsToRipPack({ ripPackId, quantity }: { ripPackId: string; quantity: number }): Promise<any> {
    try {
      const result = await this.axios.post("/addItemsToRipPack", {
        ripPackId,
        quantity,
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in RipPackApi.addItemsToRipPack: " + error.message);
    }
  }

  async getItemDistribution({ ripPackId }: { ripPackId: string }): Promise<any> {
    try {
      if (!ripPackId) {
        return {};
      }

      const result = await this.axios.get(`/${ripPackId}/itemDistribution`);
      if (!result.data || !result.data?.success || !result.data?.itemsInPack)
        throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in RipPackApi.getItemDistribution: " + error.message);
    }
  }
}

export const ripPackApi = new RipPackApi();
