import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { setAdminInfo } from "../redux/slices/adminUser";
import { adminAuthApi } from "../apis/AdminAuthApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { validateEmail } from "../utils/validation";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Header from "../components/common/Header";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit">Shockem Comics</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const isLoggedIn = useIsLoggedIn();
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (!email) {
      setEmailValidationError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailValidationError("Email is not valid");
    } else setEmailValidationError("");
  }, [email]);

  useEffect(() => {
    if (password.length === 0) setPasswordValidationError("Password is required");
    else setPasswordValidationError("");
  }, [password]);

  async function handleSubmit(event: any) {
    event.preventDefault();
    adminAuthApi
      .verifyCredentials(email, password)
      .then((res) => {
        const duoURL = res.duoURL;
        const adminId = res.adminId;
        dispatch(setAdminInfo({ adminId }));
        window.location.href = duoURL;
      })
      .catch((err) => {
        dispatch(openSnackbar({ message: "Invalid password or email!", severity: "error" }));
        console.error(err);
      });
  }
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Header />
      <Grid
        container
        component="main"
        mt={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[100] : t.palette.grey[900]),
        }}
      >
        <CssBaseline />
        <Grid size={{ xs: 12, sm: 8, md: 6, lg: 4 }} component={Paper} elevation={6}>
          <Box sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailValidationError !== ""}
                helperText={emailValidationError}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                error={passwordValidationError !== ""}
                helperText={passwordValidationError}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={emailValidationError !== "" || passwordValidationError !== ""}
              >
                Sign In
              </Button>
              <Grid container>
                {/* <Grid size={6}>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
                <Grid size={6}>
                  <Link onClick={() => navigate("/sign-up")} variant="body2" sx={{ cursor: "pointer" }}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
