import { Block } from "../types/common";

export function convertStringToHtml(string: string) {
  try {
    let htmlString = string;
    htmlString = htmlString.replace(/(?:\r\n|\r|\n)/g, "<br>");
    htmlString = htmlString.replace(/ /g, "&nbsp;");
    htmlString = "<div>" + htmlString + "</div>";
    return htmlString;
  } catch (error: any) {
    throw new Error("Error in util/operate-html.ts: convertStringToHtml: " + error.message);
  }
}

export function mergeHtmlBlocksIntoHtmlString(blocks: any[], backgroundImage: string, backgroundColor: string) {
  try {
    let htmlString = `<div style="background-image:url('${backgroundImage}') !important;background-size:cover !important;background-position:center !important;background-repeat:no-repeat !important;background-color:${backgroundColor} !important; padding: 10px " >`;
    if (!blocks) return htmlString + "</div>";
    blocks.forEach((block) => {
      htmlString += block.htmlContent;
    });
    htmlString += "</div>";
    return htmlString;
  } catch (error: any) {
    throw new Error("Error in util/operate-html.ts: mergeHtmlBlocksIntoHtmlString: " + error.message);
  }
}

export function mergeEditorsIntoHtmlString(blocks: any[], backgroundImage: string, backgroundColor: string) {
  try {
    let htmlString = `<div style="background-image:url('${backgroundImage}')!important;background-size:cover!important;background-position:center!important;background-repeat:no-repeat!important;background-color:${backgroundColor}; padding: 10px " >`;
    if (!blocks) return htmlString + "</div>";
    blocks.forEach((block) => {
      block.forEach((item: any) => {
        htmlString += item.htmlContent;
      });
    });
    htmlString += "</div>";
    return htmlString;
  } catch (error: any) {
    throw new Error("Error in util/operate-html.ts: mergeHtmlBlocksIntoHtmlString: " + error.message);
  }
}

export function extractImageAttributes(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const img = doc.querySelector("img");
  const div = doc.querySelector("div");
  const divStyles = div?.style;
  const a = doc.querySelector("a");
  const onClickUrl = a?.getAttribute("href");
  const redirectTarget = a?.getAttribute("target");
  const redirectOption = redirectTarget === "_blank" ? "new-tab" : "current-tab";
  if (img) {
    const src = img.getAttribute("src");
    const width = img.style.width;
    const height = img.style.height?.replace("px", "");
    const widthPercentage = width ? width.replace("%", "") : "";
    return { src, width: widthPercentage, height, onClickUrl, divStyles, redirectOption };
  }
  return null;
}

export function extractInputAttributes(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const divStyles = doc.querySelector("div")?.style;
  const outterDivElement = doc.querySelector("div.mp-input-editor-outter-div") as HTMLDivElement;
  const innerDivElement = doc.querySelector("div.mp-input-editor-inner-div") as HTMLDivElement;
  const textInput = doc.querySelector('input[type="text"]');
  const buttonInput = doc.querySelector('input[type="button"]') as HTMLInputElement;

  const outterDivStyles = outterDivElement?.style;
  const innerDivStyles = innerDivElement?.style;
  const placeholder = textInput?.getAttribute("placeholder")?.trim() || "";
  const buttonColor = buttonInput.style.backgroundColor.trim();
  const buttonText = buttonInput.getAttribute("value")?.trim() || "";
  const action = buttonInput.getAttribute("name")?.trim() || "";
  const width = innerDivStyles?.width || "0%";
  const alignment = outterDivStyles?.justifyContent || "left";

  return {
    placeholder,
    action,
    buttonText,
    buttonColor,
    divStyles,
    width,
    alignment,
  };
}
export function htmlToBlocks(htmlContent: string): Block[][] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const blocks: Block[][] = [];
  let currentBlock: Block[] = [];

  // Create a fragment to minimize reflow and repaint
  const fragment = document.createDocumentFragment();
  Array.from(doc.body.childNodes).forEach((node) => {
    fragment.appendChild(node);

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      const blockType = element.tagName.toLowerCase() === "img" ? "image" : "text";
      const block: Block = {
        type: blockType,
        htmlContent: element.outerHTML,
      };

      currentBlock.push(block);

      // When a div ends or two sub-blocks are added, push the block
      if (element.tagName.toLowerCase() === "div" || currentBlock.length === 2) {
        blocks.push(currentBlock);
        currentBlock = [];
      }
    }
  });

  // Add the remaining block if not empty
  if (currentBlock.length > 0) {
    blocks.push(currentBlock);
  }

  return blocks;
}

export function validateAndFormatColor(color: string): string {
  const hexColorRegex = /^#[0-9A-Fa-f]{6}$/;
  if (hexColorRegex.test(color)) {
    return color;
  }
  console.warn(`Invalid color format: ${color}. Falling back to default color #ffffff.`);
  return "#ffffff";
}
