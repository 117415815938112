import { TableCell, TableHead, TableRow } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface DropDetailHeaderProps {
  tab: string;
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}

export default function DropDetailHeader({
  tab,
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: DropDetailHeaderProps) {
  const handleSortColumn = (column: string) => {
    if (column === sortColumn) {
      handleSortDirection(sortDirection === "1" ? "-1" : "1");
    } else {
      handleSortDirection("1");
      handleSort(column);
    }
  };
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Drop Name</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Pack Types</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Status</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          Drop Time
          {sortColumn === "dropTime" && sortDirection === "1" ? (
            <ArrowUpwardIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => handleSortColumn("dropTime")}
            />
          ) : (
            <ArrowDownwardIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => handleSortColumn("dropTime")}
            />
          )}
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          Created Time
          {sortColumn === "createdAt" && sortDirection === "1" ? (
            <ArrowUpwardIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => handleSortColumn("createdAt")}
            />
          ) : (
            <ArrowDownwardIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => handleSortColumn("createdAt")}
            />
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
