import { Tabs, Tab, TextField, InputAdornment, IconButton, Tooltip, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add } from "@mui/icons-material";

interface DropListToolBarProps {
  tab: string;
  total: number;
  searchQuery: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
  setCreateDrop: () => void;
  setCreatePackType: () => void;
}

export default function DropListToolBar({
  tab,
  total,
  searchQuery,
  handleTabChange,
  setSearchQuery,
  onRefresh,
  setCreateDrop,
  setCreatePackType,
}: DropListToolBarProps) {
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", my: 2 }}>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="drop status tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ "& .MuiTab-root": { textTransform: "none" } }}
        >
          <Tab label={`All Drops (${total})`} value="all" />
        </Tabs>
      </Grid>

      <Grid size={{ xs: 12, sm: 8 }} container spacing={2} justifyContent="flex-end">
        <Grid size={{ xs: 6, sm: 4 }}>
          <TextField
            variant="outlined"
            placeholder="Search drops by name or type..."
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>

        <Grid size={{ xs: 6, sm: 2 }} container justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={setCreateDrop}
            startIcon={<Add />}
            color="secondary"
            sx={{ borderRadius: 2, fontSize: 12 }}
          >
            Add Drop
          </Button>
        </Grid>

        <Grid size={{ xs: 6, sm: 2 }} container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={setCreatePackType}
            color="secondary"
            startIcon={<Add />}
            sx={{ borderRadius: 2, fontSize: 12 }}
          >
            Add Pack Type
          </Button>
        </Grid>

        <Grid size={{ xs: 2, sm: 1 }} container justifyContent="flex-end">
          <Tooltip title="Refresh">
            <IconButton
              onClick={onRefresh}
              aria-label="refresh"
              sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
