import { Button, Divider, MenuItem, SelectChangeEvent, TextField, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { subscriptionApi } from "../../apis/SubscriptionApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import FileUploadButton from "../common/FileUploadButton";
import CustomDialog from "../common/CustomDialog";
import { builderApi } from "../../apis/BuilderPageApi";
import { getS3ImageUrl } from "../../utils/s3Image";

interface CreateSubscriptionProps {
  open: boolean;
  onClose: () => void;
  setRefresh: (value: boolean) => void;
  selectedSubscription?: any;
}

export default function CreateSubscription({
  open,
  onClose,
  setRefresh,
  selectedSubscription,
}: CreateSubscriptionProps) {
  const dispatch = useDispatch();

  const now = new Date();
  const tenYearsLater = new Date();
  tenYearsLater.setFullYear(now.getFullYear() + 10);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [scriptUrl, setScriptUrl] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<string>("");
  const [pageDetails, setPageDetails] = useState<any[]>([]);
  const [activationDate, setActivationDate] = useState<string>(now.toISOString().split("T")[0]);
  const [activationTime, setActivationTime] = useState<string>(now.toTimeString().slice(0, 5));
  const [expirationDate, setExpirationDate] = useState<string>(tenYearsLater.toISOString().split("T")[0]);
  const [expirationTime, setExpirationTime] = useState<string>(now.toTimeString().slice(0, 5));

  function resetForm() {
    setSelectedFile(null);
    setName("");
    setDescription("");
    setImageUrlPreview("");
    setUrl("");
    setScriptUrl("");
    setSelectedPage("");
    setActivationDate(new Date().toISOString().split("T")[0]);
    setActivationTime(new Date().toTimeString().slice(0, 5));
    setExpirationDate(new Date().toISOString().split("T")[0]);
    setExpirationTime(new Date().toTimeString().slice(0, 5));
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setImageUrlPreview(URL.createObjectURL(file));
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (
      !name ||
      !url ||
      !scriptUrl ||
      !selectedPage ||
      !activationDate ||
      !activationTime ||
      !expirationDate ||
      !expirationTime ||
      (!selectedFile && !selectedSubscription)
    ) {
      dispatch(openSnackbar({ message: "All fields are required", severity: "warning" }));
      return;
    }

    const activationDateTime = new Date(`${activationDate} ${activationTime}`).toISOString();
    const expirationDateTime = new Date(`${expirationDate} ${expirationTime}`).toISOString();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (selectedFile) {
      formData.append("subscriptionImage", selectedFile as Blob);
    }
    formData.append("url", url);
    formData.append("scriptUrl", scriptUrl);
    formData.append("pageLink", selectedPage);
    formData.append("activationTime", activationDateTime);
    formData.append("expirationTime", expirationDateTime);
    if (selectedSubscription) {
      formData.append("subscriptionId", selectedSubscription.details?._id);
    }

    try {
      if (selectedSubscription) {
        await subscriptionApi.updateSubscription(formData);
        dispatch(openSnackbar({ message: "Subscription updated successfully", severity: "success" }));
      } else {
        await subscriptionApi.createSubscription(formData);
        dispatch(openSnackbar({ message: "Subscription created successfully", severity: "success" }));
      }
      setRefresh(true);
      onClose();
      resetForm();
    } catch (error: any) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  }

  useEffect(() => {
    setPageDetails([]);
    builderApi
      .getAllPages()
      .then((res) => {
        if (!res) throw new Error("No data found");
        setPageDetails(res || []);
      })
      .catch((error) => {
        dispatch(openSnackbar({ message: error.message, severity: "error" }));
        setPageDetails([]);
        console.error(error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (selectedSubscription) {
      setName(selectedSubscription.details?.name || "");
      setDescription(selectedSubscription.details?.description || "");
      setUrl(selectedSubscription.details?.url || "");
      setScriptUrl(selectedSubscription.details?.scriptUrl || "");
      setSelectedPage(selectedSubscription.details?.pageLink || "");
      setActivationDate(
        selectedSubscription.details?.activationTime?.split("T")[0] || new Date().toISOString().split("T")[0]
      );
      setActivationTime(
        selectedSubscription.details?.activationTime?.split("T")[1].slice(0, 5) || new Date().toTimeString().slice(0, 5)
      );
      setExpirationDate(
        selectedSubscription.details?.expirationTime?.split("T")[0] || new Date().toISOString().split("T")[0]
      );
      setExpirationTime(
        selectedSubscription.details?.expirationTime?.split("T")[1].slice(0, 5) || new Date().toTimeString().slice(0, 5)
      );
    } else {
      resetForm();
    }
  }, [selectedSubscription]);

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={selectedSubscription ? "Update Subscription" : "Create Subscription"}
      subText={
        selectedSubscription ? "Edit the details for the subscription" : "Fill in the details for the new subscription"
      }
    >
      <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} sx={{ mb: 2 }} />
      <Divider sx={{ mb: 2 }} />

      <TextField
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Image
        </Typography>
        <FileUploadButton onFileChange={handleFileChange} />
        {(imageUrlPreview || selectedSubscription?.details?.imageUrl) && (
          <Box
            component="img"
            src={
              imageUrlPreview || getS3ImageUrl({ imageUrl: selectedSubscription?.details?._id, type: "subscription" })
            }
            alt="Uploaded"
          />
        )}
      </Box>
      <Divider sx={{ mb: 2 }} />

      <TextField fullWidth label="URL" value={url} onChange={(e) => setUrl(e.target.value)} sx={{ mb: 2 }} />
      <Divider sx={{ mb: 2 }} />

      <TextField
        fullWidth
        label="Script URL"
        value={scriptUrl}
        onChange={(e) => setScriptUrl(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />

      <TextField
        select
        fullWidth
        label="Select Buffer Page"
        value={selectedPage}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedPage(e.target.value)}
        disabled={pageDetails.length === 0}
        sx={{ mb: 2 }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {pageDetails.map((page, index) => (
          <MenuItem key={index} value={page.pageLink}>
            {page.pageLink}
          </MenuItem>
        ))}
      </TextField>
      <Divider sx={{ mb: 2 }} />

      <TextField
        fullWidth
        type="date"
        label="Activation Date"
        value={activationDate}
        onChange={(e) => setActivationDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />

      <TextField
        fullWidth
        type="time"
        label="Activation Time"
        value={activationTime}
        onChange={(e) => setActivationTime(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />

      <TextField
        fullWidth
        type="date"
        label="Expiration Date"
        value={expirationDate}
        onChange={(e) => setExpirationDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />

      <TextField
        fullWidth
        type="time"
        label="Expiration Time"
        value={expirationTime}
        onChange={(e) => setExpirationTime(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={handleSubmit}>
          {selectedSubscription ? "Update Subscription" : "Create Subscription"}
        </Button>
      </Box>
    </CustomDialog>
  );
}
