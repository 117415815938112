import { Box, Divider, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { CurrencyEnum } from "../../types/currency";
import { getCurrencyOptions, getCurrencyProps } from "../../utils/currency";

interface RipPackData {
  name: string;
  quantity: number;
  maxQuantity: number;
  price: number;
  currency: CurrencyEnum;
}

interface CreateRipPackProps {
  packTypeDetails: any;
  packData: RipPackData;
  onPackDataChange: (data: RipPackData) => void;
}

const defaultPackData: RipPackData = {
  name: "",
  quantity: 1,
  maxQuantity: 1,
  price: 0,
  currency: CurrencyEnum.USD,
};

export default function CreateRipPack({
  packTypeDetails,
  packData = defaultPackData,
  onPackDataChange,
}: CreateRipPackProps) {
  const currencyProps = getCurrencyProps(packData?.currency || CurrencyEnum.USD);
  const [errors, setErrors] = useState<{
    quantity?: string;
    maxQuantity?: string;
  }>({});

  const validateNumber = (value: number, field: "quantity" | "maxQuantity") => {
    if (value < 1) {
      setErrors((prev) => ({
        ...prev,
        [field]: `${field === "quantity" ? "Quantity" : "Max quantity"} must be at least 1`,
      }));
      return false;
    }
    if (!Number.isInteger(value)) {
      setErrors((prev) => ({
        ...prev,
        [field]: `${field === "quantity" ? "Quantity" : "Max quantity"} must be a whole number`,
      }));
      return false;
    }
    setErrors((prev) => ({
      ...prev,
      [field]: undefined,
    }));
    return true;
  };

  const handleChange = (field: string, value: any) => {
    if (field === "quantity" || field === "maxQuantity") {
      const numValue = Number(value);
      if (validateNumber(numValue, field)) {
        onPackDataChange({
          ...packData,
          [field]: numValue,
        });
      }
    } else {
      onPackDataChange({
        ...packData,
        [field]: value,
      });
    }
  };

  return (
    <Box sx={{ mb: 3, border: "1px solid #ccc", padding: 2 }}>
      <Box>
        <Typography variant="body1" gutterBottom>
          Name
        </Typography>
        <TextField
          fullWidth
          value={packData?.name || ""}
          onChange={(e) => handleChange("name", e.target.value)}
          placeholder="Enter pack name"
        />
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography variant="body1" gutterBottom>
          Quantity
        </Typography>
        <TextField
          fullWidth
          type="number"
          value={packData?.quantity ?? 1}
          onChange={(e) => handleChange("quantity", e.target.value)}
          placeholder="Enter quantity"
          error={!!errors.quantity}
          helperText={errors.quantity}
          inputProps={{ min: 1 }}
        />
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography variant="body1" gutterBottom>
          Max Quantity (per pack)
        </Typography>
        <TextField
          fullWidth
          type="number"
          value={packData?.maxQuantity ?? 1}
          onChange={(e) => handleChange("maxQuantity", e.target.value)}
          placeholder="Enter max quantity per pack"
          error={!!errors.maxQuantity}
          helperText={errors.maxQuantity}
          inputProps={{ min: 1 }}
        />
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" gutterBottom>
            Price
          </Typography>
          <TextField
            fullWidth
            type="number"
            value={packData?.price || 0}
            onChange={(e) => handleChange("price", Number(e.target.value))}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencyProps?.symbol}</InputAdornment>,
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" gutterBottom>
            Currency
          </Typography>
          <TextField
            fullWidth
            select
            value={packData?.currency || CurrencyEnum.USD}
            onChange={(e) => handleChange("currency", e.target.value)}
          >
            {getCurrencyOptions().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    </Box>
  );
}
