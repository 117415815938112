import React from "react";
import { TableRow, TableCell, Typography, Box, Avatar, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getS3ImageUrl } from "../../utils/s3Image";
import EditIcon from "@mui/icons-material/Edit";
import InsightsIcon from "@mui/icons-material/Insights";
interface PackItemDetailRowProps {
  tab?: string;
  packItem?: any; // Adjust the type based on your packItem structure
  setPackItemToEdit?: any;
}

export default function PackItemDetailRow({ tab, packItem, setPackItemToEdit }: PackItemDetailRowProps) {
  if (!packItem) return null;

  return (
    <React.Fragment>
      <TableRow key={packItem?._id} sx={{ cursor: "pointer" }}>
        <TableCell>
          {" "}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              alt={packItem?.ripPack?.name}
              src={getS3ImageUrl({
                imageUrl: packItem?.ripPack?.packTypeId,
                type: "pack-type",
              })}
              sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
            />
            <Box>
              <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                {packItem?.ripPack?.name || "No RipPack Name"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.75rem", sm: "0.875rem" },
                }}
              >
                <ContentCopyIcon sx={{ mr: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" } }} />
                {packItem?.sequenceNumber}
              </Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell>{packItem?.itemQuantity}</TableCell>
        <TableCell>
          <IconButton>
            <EditIcon
              onClick={() => {
                setPackItemToEdit && setPackItemToEdit(packItem);
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
