import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { adminApi } from "../apis/AdminApi";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { validateEmail } from "../utils/validation";
import Copyright from "../components/common/Copyright";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Header from "../components/common/Header";

export default function SignUp() {
  const isLoggedIn = useIsLoggedIn();
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [username, setUsername] = useState("");
  const [usernameValidationError, setUsernameValidationError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (!email) setEmailValidationError("Email is required");
    else if (!validateEmail(email)) setEmailValidationError("Email is not valid");
    else setEmailValidationError("");
  }, [email]);

  useEffect(() => {
    if (username.length < 5) setUsernameValidationError("Username should be atLeast 2 Characters");
    else setUsernameValidationError("");
  }, [username]);

  useEffect(() => {
    if (password.length < 8)
      setPasswordValidationError("Password should be atLeast 8 Characters Long with atleast 1 number");
    else setPasswordValidationError("");
  }, [password]);

  async function handleSubmit(event: any) {
    event.preventDefault();
    adminApi
      .registerAdmin({ email, password, username })
      .then((res) => {
        const adminDetails = res.admin;
        dispatch(openSnackbar({ message: "Admin created successfully", severity: "success" }));
        navigate("/login");
      })
      .catch((err) => {
        dispatch(openSnackbar({ message: "Failed to create Admin Account!", severity: "error" }));
        console.error(err);
      });
  }
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Header />
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[100] : t.palette.grey[900]),
        }}
      >
        <CssBaseline />
        <Grid size={{ xs: 12, sm: 8, md: 6, lg: 4 }} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailValidationError !== ""}
                helperText={emailValidationError}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                id="username"
                label="Username"
                required
                fullWidth
                autoFocus
                error={usernameValidationError !== ""}
                helperText={usernameValidationError}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                error={passwordValidationError !== ""}
                helperText={passwordValidationError}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  emailValidationError !== "" || usernameValidationError !== "" || passwordValidationError !== ""
                }
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid size={{}}>
                  <Link onClick={() => navigate("/login")} variant="body2" sx={{ cursor: "pointer" }}>
                    {"Already have an account? Log In"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
