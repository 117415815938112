import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import EventToolBar from "./event-activity/EventListToolBar";
import EventDetailRow from "./event-activity/EventDetailRow";
import EventDetailHeader from "./event-activity/EventDetailHeader";
import { getTimestampForString } from "../../utils/time";
import CreateEvent from "../forms/CreateEvent";
import { eventApi } from "../../apis/EventApi";
import SkeletonRow from "../common/SkeletonRow";

export default function EventActivity() {
  const [events, setEvents] = useState<any[]>([]);
  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("eventTime");
  const [sortDirection, setSortDirection] = useState("-1");
  const [createEvent, setCreateEvent] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        page,
        limit: rowsPerPage,
        total: true,
        sortKey: sortColumn,
        search: searchQuery,
        sortDirection: sortDirection === "-1" ? "-1" : "1",
      };
      const { events, total } = await eventApi.getEventDetails(params);

      setEvents(events);
      setTotal(total);
      if (!searchQuery) dispatch(openSnackbar({ message: "Events fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching events:", error);
      dispatch(openSnackbar({ message: "Error fetching events ", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [sortDirection, sortColumn, dispatch, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    fetchEvents();
  }, [sortColumn, sortDirection, fetchEvents]);

  useEffect(() => {
    if (refresh) fetchEvents();
  }, [refresh, fetchEvents]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: "2rem", sm: "3rem" },
        boxSizing: "border-box",
        minHeight: "100vh",
        height: "auto",
        backgroundColor: "white",
      }}
    >
      <Box sx={{ mb: 3, width: "100%" }}>
        <Typography variant="h3" gutterBottom>
          Event Activity
        </Typography>
        <Typography variant="h6" gutterBottom>
          Manage event activity
        </Typography>
      </Box>
      <EventToolBar
        tab={tab}
        handleTabChange={handleTabChange}
        total={total}
        searchQuery={searchQuery}
        setSearchQuery={(value: string) => {
          setSearchQuery(value);
          setPage(0);
        }}
        onRefresh={() => setRefresh(true)}
        setCreateEvent={() => setCreateEvent(true)}
      />
      <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
        <Table>
          <EventDetailHeader
            tab={tab}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={setSortColumn}
            handleSortDirection={setSortDirection}
          />
          <TableBody>
            {loading ? (
              <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={3} />
            ) : events.length > 0 ? (
              events.map((event: any) => <EventDetailRow key={event.eventId} event={event} tab={tab} />)
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No events found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateEvent open={createEvent} onClose={() => setCreateEvent(false)} setRefresh={setRefresh} />
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(total / rowsPerPage)}
          variant="outlined"
          color="primary"
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
        />
      </Box>
    </Box>
  );
}
