import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import SubscriptionToolBar from "../components/subscription-activity/SubscriptionListToolBar";
import SubscriptionDetailRow from "../components/subscription-activity/SubscriptionDetailRow";
import SubscriptionDetailHeader from "../components/subscription-activity/SubscriptionDetailHeader";
import { getUTCEndOfDay } from "../utils/time";
import CreateSubscription from "../components/forms/CreateSubscription";
import { subscriptionApi } from "../apis/SubscriptionApi";
import SkeletonRow from "../components/common/SkeletonRow";
import Header from "../components/common/Header";

export default function SubscriptionActivity() {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const oneDayAgo = useMemo(() => new Date(new Date().setDate(new Date().getDate() - 1)), []);
  const [fromTimestamp, setFromTimestamp] = useState(getUTCEndOfDay(oneDayAgo));
  const [toTimestamp, setToTimestamp] = useState(new Date());
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("expirationTime");
  const [sortDirection, setSortDirection] = useState("1");
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const [createSubscription, setCreateSubscription] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  function handleSearchQuery(searchValue: string) {
    setPage(0);
    setSearchQuery(searchValue);
  }

  const fetchSubscriptions = useCallback(async () => {
    try {
      setLoading(true);
      let params: any = {};
      if (tab !== "all") {
        params = {
          page,
          limit: rowsPerPage,
          total: true,
          sortKey: sortColumn,
          sortDirection: sortDirection === "-1" ? "-1" : "1",
          fromTimestamp: fromTimestamp,
          toTimestamp: toTimestamp,
        };
      } else {
        params = {
          page,
          limit: rowsPerPage,
          total: true,
          sortKey: sortColumn,
          sortDirection: sortDirection === "-1" ? "-1" : "1",
        };
      }
      if (searchQuery) params.search = searchQuery;
      const { subscriptions, total } = await subscriptionApi.getSubscriptionDetails(params);

      const filteredSubscriptions = subscriptions;
      // tab === "all"
      //   ? subscriptions
      //   : subscriptions?.filter((subscription: any) => subscription?.stats?.subscriptionId) || [];

      setSubscriptions(filteredSubscriptions);
      if (tab === "all") setTotal(total);
      else setTotal(filteredSubscriptions.length);
      if (!searchQuery) dispatch(openSnackbar({ message: "Subscriptions fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching subscriptions:", error);
      dispatch(openSnackbar({ message: "Error fetching subscriptions", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [tab, fromTimestamp, toTimestamp, dispatch, sortDirection, sortColumn, searchQuery, page, rowsPerPage]);

  useEffect(() => {
    fetchSubscriptions();
  }, [sortColumn, sortDirection, fromTimestamp, toTimestamp, fetchSubscriptions, searchQuery]);

  useEffect(() => {
    if (refresh) fetchSubscriptions();
  }, [refresh, fetchSubscriptions]);

  useEffect(() => {
    if (selectedSubscription) setCreateSubscription(true); //open createSubscription
  }, [selectedSubscription]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
          width: "100vw",
          minHeight: "100vh",
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h3" gutterBottom>
            Subscription Activity
          </Typography>
          <Typography variant="h6" gutterBottom>
            Manage subscription activity
          </Typography>
        </Box>
        <SubscriptionToolBar
          tab={tab}
          handleTabChange={handleTabChange}
          fromTimestamp={fromTimestamp}
          toTimestamp={toTimestamp}
          setFromTimestamp={setFromTimestamp}
          setToTimestamp={setToTimestamp}
          total={total}
          searchQuery={searchQuery}
          setSearchQuery={(searchValue: string) => handleSearchQuery(searchValue)}
          onRefresh={() => setRefresh(true)}
          setCreateSubscription={() => setCreateSubscription(true)}
        />
        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
          <Table>
            <SubscriptionDetailHeader
              tab={tab}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={setSortColumn}
              handleSortDirection={setSortDirection}
            />
            <TableBody>
              {loading ? (
                <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={5} />
              ) : subscriptions.length > 0 ? (
                subscriptions.map((subscription: any) => (
                  <SubscriptionDetailRow
                    key={subscription.subscriptionId}
                    subscription={subscription}
                    tab={tab}
                    setSelectedSubscription={setSelectedSubscription}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No subscriptions found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CreateSubscription
          open={createSubscription}
          onClose={() => {
            setCreateSubscription(false);
            setSelectedSubscription(null);
          }}
          setRefresh={setRefresh}
          selectedSubscription={selectedSubscription}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(total / rowsPerPage)}
            variant="outlined"
            color="primary"
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
        </Box>
      </Box>
    </>
  );
}
