import React, { useState, useEffect } from "react";
import { Box, useTheme, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AssetCard from "./AssetCard";
import assetApi from "../../apis/AssetApi";
import { getS3ImageUrlFromAsset } from "../../utils/asset";
import { AssetType } from "../../types/common";

interface AssetGalleryProps {
  onAssetSelect?: (imageUrl: string) => void;
  refresh?: boolean;
}

export default function AssetGallery({ onAssetSelect, refresh }: AssetGalleryProps) {
  const theme = useTheme();
  const [images, setImages] = useState<AssetType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = async () => {
    setIsLoading(true);
    try {
      const response = await assetApi.getAllAssets();
      setImages(response.assets || []);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [refresh]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }} key={image._id}>
            <AssetCard
              imageUrl={getS3ImageUrlFromAsset({ asset: image })}
              onSelect={onAssetSelect}
              onDelete={async () => {
                try {
                  await assetApi.deleteAsset(image._id);
                  fetchAssets();
                } catch (error) {
                  console.error("Error deleting image:", error);
                }
              }}
              asset={{
                originalName: image.originalName,
                size: image.size,
                mimeType: image.mimeType,
                createdAt: image.createdAt || "",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
