import React, { useState } from "react";
import { Box, Typography, useTheme, Stack } from "@mui/material";
import AssetGallery from "../components/image-management/AssetGallery";
import FileUploader from "../components/image-management/FileUploader";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import Header from "../components/common/Header";

export default function AssetsManagement() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const handleImageUploadSuccess = () => {
    dispatch(
      openSnackbar({
        message: "Assets uploaded successfully!",
        severity: "success",
      })
    );
    setRefresh((prev) => !prev);
  };

  return (
    <>
      <Header />
      <Box sx={{ p: { xs: 2, md: 4 } }}>
        {/* Header Section */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 600, color: "text.primary" }}>
              Assets Management
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              Upload and manage your assets (images / videos)
            </Typography>
          </Box>
        </Stack>

        {/* Upload Section */}
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: theme.shadows[1],
            mb: 4,
          }}
        >
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Upload Assets (Images / Videos)
            </Typography>
            <FileUploader onUploadSuccess={handleImageUploadSuccess} />
          </Box>
        </Box>

        {/* Gallery Section */}
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: theme.shadows[1],
          }}
        >
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Assets Gallery
            </Typography>
            <AssetGallery
              refresh={refresh}
              onAssetSelect={(url) => {
                navigator.clipboard.writeText(url);
                dispatch(
                  openSnackbar({
                    message: "Asset URL copied to clipboard!",
                    severity: "success",
                  })
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
