const previewContainerStyles = {
  position: "relative" as const,
  background:
    "linear-gradient(45deg, #f8fafc 25%, transparent 25%, transparent 75%, #f8fafc 75%, #f8fafc) 0 0, linear-gradient(45deg, #f8fafc 25%, transparent 25%, transparent 75%, #f8fafc 75%, #f8fafc) 1em 1em",
  backgroundColor: "#ffffff",
  backgroundSize: "2em 2em",
  padding: "2rem",
  borderRadius: "0.75rem",
  boxShadow: "0 0 1.25rem rgba(0, 0, 0, 0.05)",
  minHeight: "100%",
};

const previewHeaderStyles = {
  position: "absolute" as const,
  top: "-0.75rem",
  left: "50%",
  transform: "translateX(-50%)",
  background: "#fff",
  padding: "0.25rem 1rem",
  borderRadius: "1.25rem",
  boxShadow: "0 0.125rem 0.5rem rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  zIndex: 1,
};

const emailStyles = {
  margin: "0 auto",
  border: "0.0625rem solid #ccc",
  padding: "1.25rem",
  borderRadius: "0.5rem",
  backgroundColor: "#fff",
};

const websiteStyles = `
  /* General reset */
  body, table, td, a {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  table, td {
    mso-table-rspace: 0pt;
    mso-table-lspace: 0pt;
  }
  img {
    -ms-interpolation-mode: bicubic;
    max-width: 100%;
    height: auto;
    transition: transform 0.2s ease;
  }
  img:hover {
    transform: scale(1.01);
  }
  a[x-apple-data-detectors] {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
  }
  div[style*="margin: 16px 0;"] {
    margin: 0 !important;
  }
  
  /* Website-specific styles */
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.5;
  }
  p {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #333;
  }
  a {
    color: #1a0dab;
    text-decoration: underline;
    transition: color 0.2s ease;
  }
  a:hover {
    color: #2563eb;
  }
  img {
    border: 0;
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
    border-radius: 0.25rem;
  }
  table {
    border-collapse: collapse !important;
  }
  td {
    border-collapse: collapse !important;
  }

  /* Enhanced components */
  .button {
    display: inline-block;
    padding: 0.625rem 1.25rem;
    background-color: #2563eb;
    color: white !important;
    text-decoration: none;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  .button:hover {
    background-color: #1d4ed8;
    transform: translateY(-0.0625rem);
  }

  .card {
    background: white;
    border-radius: 0.5rem;
    padding: 1.25rem;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
  }
  .card:hover {
    box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  }

  /* Responsive design */
  @media screen and (max-width: 37.5rem) {
    .card {
      padding: 1rem;
    }
    img.responsive {
      height: auto !important;
      max-width: 100% !important;
      width: auto !important;
    }
  }
`;

interface PreviewAreaProps {
  blocks: [any[any]];
  type: string;
  divStyles: any;
}

export default function PreviewSite({ blocks, type, divStyles }: PreviewAreaProps) {
  if (!blocks) {
    return (
      <div style={previewContainerStyles}>
        <div style={previewHeaderStyles}>
          <span style={{ fontSize: "0.75rem", fontWeight: 500, color: "#64748b" }}>PREVIEW</span>
          <div style={{ width: "0.5rem", height: "0.5rem", borderRadius: "50%", backgroundColor: "#10b981" }} />
        </div>
        <div
          className="rounded-3"
          style={{
            ...emailStyles,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "12.5rem",
            color: "#666",
            fontSize: "0.875rem",
            fontStyle: "italic",
          }}
        >
          No content to preview
        </div>
      </div>
    );
  }

  const containerStyle =
    type === "pc"
      ? { ...emailStyles, width: "100%", minHeight: "100%" }
      : { ...emailStyles, width: "40%", minHeight: "100%" };

  return (
    <div style={previewContainerStyles}>
      <div style={previewHeaderStyles}>
        <span style={{ fontSize: "0.75rem", fontWeight: 500, color: "#64748b" }}>
          PREVIEW {type === "mobile" ? "(Mobile)" : "(Desktop)"}
        </span>
        <div style={{ width: "0.5rem", height: "0.5rem", borderRadius: "50%", backgroundColor: "#10b981" }} />
      </div>
      <div
        className="preview-frame"
        style={{
          position: "relative",
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: type === "mobile" ? "1rem" : "0",
        }}
      >
        <div
          className="rounded-3"
          style={{
            ...containerStyle,
            ...(type === "mobile"
              ? {
                  maxWidth: "23.4375rem",
                  border: "0.75rem solid #1a1a1a",
                  borderRadius: "2rem",
                  position: "relative",
                  overflow: "hidden",
                }
              : {}),
          }}
        >
          {type === "mobile" ? (
            <>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "9.375rem",
                  height: "1.5rem",
                  backgroundColor: "#1a1a1a",
                  borderBottomLeftRadius: "1rem",
                  borderBottomRightRadius: "1rem",
                  zIndex: 2,
                }}
              />
              <style>{websiteStyles}</style>
              <div style={{ ...divStyles, padding: "0.3125rem" }}>
                {blocks?.map((block) => {
                  return block?.map((item: any) => {
                    return (
                      <div style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: item.htmlContent }} />
                    );
                  });
                })}
              </div>
            </>
          ) : (
            <>
              <style>{websiteStyles}</style>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  ...divStyles,
                  padding: "0.3125rem",
                  gap: "1rem",
                }}
              >
                {blocks?.map((block, blockIndex) => (
                  <div
                    key={blockIndex}
                    style={{
                      display: "flex",
                      flexDirection: type === "mobile" ? "column" : "row",
                      width: "100%",
                      gap: "1rem",
                    }}
                  >
                    {block?.map((item: any, subIndex: number) => (
                      <div
                        key={subIndex}
                        style={{
                          width: `${100 / block.length}%`,
                          overflow: "hidden",
                          borderRadius: "0.25rem",
                          transition: "all 0.2s ease",
                        }}
                        dangerouslySetInnerHTML={{ __html: item.htmlContent }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
