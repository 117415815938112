import BaseAPI from "./BaseApi";

class PackItemApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/ripPackItem", cache: false });
  }
  async getPaginatedAllPackItems({
    ripPackId,
    project,
    limit,
    page,
    sortKey,
    sortDirection,
    total = false,
  }: {
    ripPackId?: string;
    project?: any;
    limit?: number;
    page?: number;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/", {
        params: { ripPackId, project, limit, page, sortKey, sortDirection, total },
      });

      if (!result.data || !result.data?.success || !result.data?.ripPackItems)
        throw new Error("Invalid response from server");

      return result.data;
    } catch (error: any) {
      throw new Error("Error in packItemApi.getPaginatedAllPackItems: " + error.message);
    }
  }

  async insertPackItem({ ripPackId, itemIds }: { ripPackId: string; itemIds: string[] }): Promise<any> {
    try {
      const result = await this.axios.post("/createRipPackItems", { ripPackId, itemIds });
      if (!result.data || !result.data?.success || !result.data?.ripPackItem)
        throw new Error("Invalid response from server");
      return result.data.ripPackItem;
    } catch (error: any) {
      throw new Error("Error in packItemApi.insertPackItem: " + error.message);
    }
  }

  async editPackItem({
    packItemId,
    updatedItemQuantity,
  }: {
    packItemId: string;
    updatedItemQuantity: number;
  }): Promise<any> {
    try {
      if (!updatedItemQuantity) throw new Error("updatedItemQuantity is required");

      const result = await this.axios.put(`/${packItemId}`, { updatedItemQuantity });
      if (!result.data || !result.data?.success || !result.data?.updatedRipPackItem)
        throw new Error("Invalid response from server");
      return result.data.updatedRipPackItem;
    } catch (error: any) {
      throw new Error("Error in packItemApi.editPackItem: " + error.message);
    }
  }

  async getRipPackItemStats({ ripPackId }: { ripPackId: string }): Promise<any> {
    try {
      const result = await this.axios.get(`/stats`, {
        params: { ripPackId },
      });
      if (!result.data || !result.data?.success || !result.data?.stats) throw new Error("Invalid response from server");
      return result.data.stats;
    } catch (error: any) {
      throw new Error("Error in packItemApi.getRipPackItemStats: " + error.message);
    }
  }

  async getRipPackItemScratchCardStats({ ripPackId }: { ripPackId: string }): Promise<any> {
    try {
      const result = await this.axios.get(`/${ripPackId}/scratchCardStats`);
      if (!result.data || !result.data?.success || !result.data?.stats) throw new Error("Invalid response from server");
      return result.data.stats;
    } catch (error: any) {
      throw new Error("Error in packItemApi.getRipPackItemScratchCardStats: " + error.message);
    }
  }
}

export const packItemApi = new PackItemApi();
