import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { scratchCardApi } from "../../apis/ScratchCardApi";
import ScratchCardDetailRow from "./scratch-card-activity/ScratchCardDetailRow";
import ScratchCardDetailHeader from "./scratch-card-activity/ScratchCardDetailHeader";
import ScratchCardListToolBar from "./scratch-card-activity/ScratchCardListToolBar";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { getUTCEndOfDay } from "../../utils/time";
import CreateScratchCard from "../forms/CreateScratchCard";
import SkeletonRow from "../common/SkeletonRow";
import ScratchCardFilters from "./scratch-card-activity/ScratchCardFilters";
import { rewardApi } from "../../apis/RewardApi";

export default function ScratchCardActivity() {
  const [searchQuery, setSearchQuery] = useState("");
  const [sectionOpen, setSectionOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [cards, setCards] = useState<any[]>([]);
  const [filteredCards, setFilteredCards] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState("all");
  const [filterRewardType, setFilterRewardType] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterType, setFilterType] = useState("all");
  const [filterRewardIds, setFilterRewardIds] = useState<string[]>([]);

  const oneDayAgo = useMemo(() => new Date(new Date().setDate(new Date().getDate() - 1)), []);
  const [fromTimestamp, setFromTimestamp] = useState(getUTCEndOfDay(oneDayAgo));
  const [toTimestamp, setToTimestamp] = useState(new Date());
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-1");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showCreateScratchCard, setShowCreateScratchCard] = useState(false);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
    setSortColumn(newValue === "Used/Claimed Cards" ? "rewardClaimTime" : "createdAt");
    setRowsPerPage(50);
    setPage(0);
  }

  const fetchScratchCards = useCallback(async () => {
    try {
      setLoading(true);
      const params: any = {
        page,
        limit: rowsPerPage,
        total: true,
        sortKey: sortColumn,
        sortDirection: sortDirection,
        fromTimestamp,
        toTimestamp,
        search: searchQuery,
        populate: true,
      };
      if (filterStatus && filterStatus !== "all") params.status = filterStatus;
      if (filterType && filterType !== "all") params.type = filterType;
      if (filterRewardIds && filterRewardIds.length > 0) params.rewardIds = filterRewardIds;
      const cardsData = await scratchCardApi.getPaginatedScratchCards(params);
      // : await scratchCardApi.getScratchCardByClaimTime(params);
      const { scratchCards, total } = cardsData;
      if (!scratchCards) throw new Error("No cards found");
      setCards(scratchCards);
      setTotal(total);
      if (!searchQuery) dispatch(openSnackbar({ message: "Scratch cards fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching scratch cards:", error);
      dispatch(openSnackbar({ message: "Error fetching scratch cards", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [
    page,
    rowsPerPage,
    sortColumn,
    sortDirection,
    filterStatus,
    filterType,
    filterRewardIds,
    fromTimestamp,
    toTimestamp,
    searchQuery,
    dispatch,
  ]);

  useEffect(() => {
    fetchScratchCards();
  }, [fetchScratchCards]);

  useEffect(() => {
    if (!filterRewardType || filterRewardType === "all") {
      setFilterRewardIds([]);
      return;
    }

    async function fetchRewardsByRewardType() {
      try {
        const rewardsData = await rewardApi.getPaginatedRewards({
          type: filterRewardType,
          limit: 0,
          page: 0,
        });
        setFilterRewardIds(rewardsData?.rewards?.map((reward: any) => reward._id));
      } catch (error) {
        console.error("Error fetching rewards:", error);
        dispatch(openSnackbar({ message: "Error fetching rewards", severity: "error" }));
        setFilterRewardIds([]);
      }
    }

    fetchRewardsByRewardType();
  }, [filterRewardType, dispatch]);

  useEffect(() => {
    setFilteredCards(cards);
  }, [cards]);

  function handleRefresh() {
    setRefresh(true);
    fetchScratchCards();
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: "2rem", sm: "3rem" },
        boxSizing: "border-box",
        minHeight: "100vh",
        height: "auto",
        backgroundColor: "white",
      }}
    >
      <Box sx={{ mb: 3, width: "100%" }}>
        <Typography variant="h3" gutterBottom>
          Scratch Card Activity
        </Typography>
        <Typography variant="h6" gutterBottom>
          Manage scratch card data
        </Typography>
      </Box>
      <ScratchCardListToolBar
        tab={tab}
        handleTabChange={handleTabChange}
        fromTimestamp={fromTimestamp}
        toTimestamp={toTimestamp}
        setFromTimestamp={setFromTimestamp}
        setToTimestamp={setToTimestamp}
        total={total}
        searchQuery={searchQuery}
        setSearchQuery={(value: string) => {
          setSearchQuery(value);
          setPage(0);
        }}
        onRefresh={handleRefresh}
        setShowCreateScratchCard={setShowCreateScratchCard}
      />

      <ScratchCardFilters
        sectionOpen={sectionOpen}
        setSectionOpen={() => setSectionOpen((prev) => !prev)}
        filterRewardType={filterRewardType}
        filterStatus={filterStatus}
        filterType={filterType}
        setFilterRewardType={(value: string) => {
          setFilterRewardType(value);
          setPage(0);
        }}
        setFilterStatus={(value: string) => {
          setFilterStatus(value);
          setPage(0);
        }}
        setFilterType={(value: string) => {
          setFilterType(value);
          setPage(0);
        }}
      />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
          <Table>
            <ScratchCardDetailHeader
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={setSortColumn}
              handleSortDirection={setSortDirection}
            />
            <TableBody>
              {loading ? (
                <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={7} />
              ) : filteredCards.length > 0 ? (
                filteredCards.map((card: any) => <ScratchCardDetailRow key={card?._id} card={card} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No scratch cards found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(total / rowsPerPage)}
            variant="outlined"
            color="primary"
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
        </Box>
      </Box>

      <CreateScratchCard open={showCreateScratchCard} onClose={() => setShowCreateScratchCard(false)} />
    </Box>
  );
}
