import { Avatar, Box, Chip, TableCell, TableRow, Typography } from "@mui/material";
import { formatDate, formatTime } from "../../../utils/time"; // Assuming you have a utility to format dates
import { getS3ImageUrl } from "../../../utils/s3Image";
import { formatString } from "../../../utils/string";
import { scratchCardStatusMap, scratchCardTypeEnum, scratchCardTypeMap } from "../../../types/scratch-card-enum";
import { itemStagesMap } from "../../../types/stage";

interface ScratchCardRowProps {
  card: any; // Replace with your specific type if available
}

export default function ScratchCardDetailRow({ card }: ScratchCardRowProps) {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body1">{card.fullCode}</Typography>
      </TableCell>
      <TableCell>
        {card?.type ? (
          <Chip
            label={formatString(card?.type)}
            sx={{
              bgcolor: scratchCardTypeMap[card.type as keyof typeof scratchCardTypeMap].color,
              color: scratchCardTypeMap[card.type as keyof typeof scratchCardTypeMap].contrastText,
              fontWeight: "bold",
            }}
            size="small"
          />
        ) : (
          "Unknown"
        )}
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {card?.eventDetails && (
            <Avatar
              alt={card?.eventDetails?.name}
              src={
                getS3ImageUrl({
                  imageUrl: card?.eventDetails?.imageUrl,
                  type: "event",
                }) || ""
              }
              sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
            />
          )}
          <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
            {card?.eventDetails?.name || "None"}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {card?.rewardDetails && (
            <Avatar
              alt={card?.rewardDetails?.name}
              src={
                getS3ImageUrl({
                  imageUrl: card?.rewardDetails?.imageUrl,
                  type: "reward",
                }) || ""
              }
              sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
            />
          )}

          <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
            {card?.rewardDetails?.name || "No Prize"}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{card?.ownerLimit || "Unlimited"}</Typography>
      </TableCell>
      <TableCell>
        {card?.status ? (
          <Chip
            label={formatString(card?.status)}
            sx={{
              bgcolor: scratchCardStatusMap[card.status as keyof typeof scratchCardStatusMap].color,
              color: scratchCardStatusMap[card.status as keyof typeof scratchCardStatusMap].contrastText,
              fontWeight: "bold",
            }}
            size="small"
          />
        ) : (
          "Unknown"
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
            {formatDate(card.redeemTime)}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
          >
            {formatTime(card.redeemTime)}
          </Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
}
