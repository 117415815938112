import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";
import {
  Add,
  TextFields,
  Image as ImageIcon,
  Input as InputIcon,
  ViewCarousel,
  Palette as PaletteIcon,
  DragIndicator as DragIndicatorIcon,
  NoteAdd as NoteAddIcon,
  AddAPhoto,
  Upload as UploadIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  Visibility,
  Settings,
  AccessTime,
  LocationOn,
  FileCopy,
} from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DesktopMac, PhoneIphone } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { htmlToBlocks, mergeEditorsIntoHtmlString } from "../utils/operate-html";
import { handleCopy, shortenString } from "../utils/string";
import { getS3ImageUrl } from "../utils/s3Image";
import { openSnackbar } from "../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { PageContent } from "../types/common";
import { builderApi } from "../apis/BuilderPageApi";
import SubBlock from "../components/page-editor/SubBlock";
import PreviewSite from "../components/page-editor/PreviewSite";
import { useNavigate, useParams } from "react-router-dom";
import FileUploader from "../components/image-management/FileUploader";
import CustomDialog from "../components/common/CustomDialog";
import Header from "../components/common/Header";

export default function PageEditor() {
  const [pageLink, setPageLink] = useState("/");
  const [selectedIndex, setSelectedIndex] = useState("new");
  const { "*": link } = useParams<{ "*": string }>();
  const navigate = useNavigate();
  const mainSiteURL = process.env.REACT_APP_MAINSITE_URL;

  const [curPage, setCurPage] = useState<any>({});
  const [combinedHtmlContent, setCombinedHtmlContent] = useState<string>("");
  const [previewType, setPreviewType] = useState<string>("pc");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageDetails, setPageDetails] = useState<PageContent[]>([]);
  const [originMetadata, setOriginMetadata] = useState<any>({});
  const [metadata, setMetadata] = useState<any>({});
  const [showMetadata, setShowMetadata] = useState(false);
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  const [draggedItemType, setDraggedItemType] = useState<string | null>(null);
  const [draggedSubIndex, setDraggedSubIndex] = useState<number | null>(null);
  const [showDeadlineDialog, setShowDeadlineDialog] = useState<boolean>(false);
  const [showUploadArea, setShowUploadArea] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [migrateFrom, setMigrateFrom] = useState<string>("");
  const [migrateTo, setMigrateTo] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch();

  function handleDeleteBlock(blockIndex: { index: number; subIndex: number }) {
    setCurPage((prev: any) => {
      const curPageCopy = { ...prev };
      curPageCopy.blocks[blockIndex.index].splice(blockIndex.subIndex, 1);
      if (curPageCopy.blocks[blockIndex.index].length === 0) {
        curPageCopy.blocks.splice(blockIndex.index, 1);
      }
      return curPageCopy;
    });
  }

  const handleBlockNameChange = useCallback((name: string, blockIndex: { index: number; subIndex: number }) => {
    setCurPage((prev: any) => {
      const curPageCopy = { ...prev };
      curPageCopy.blocks[blockIndex.index][blockIndex.subIndex].name = name;
      return curPageCopy;
    });
  }, []);

  const handleHtmlContentChange = useCallback(
    (htmlContent: string, blockIndex: { index: number; subIndex: number }) => {
      setCurPage((prev: any) => {
        const curPageCopy = { ...prev };
        curPageCopy.blocks[blockIndex.index][blockIndex.subIndex].htmlContent = htmlContent;
        return curPageCopy;
      });
    },
    []
  );

  const onDragStart = useCallback((index: number, type: string, subIndex?: number) => {
    setDraggedItemIndex(index);
    setDraggedItemType(type);
    setDraggedSubIndex(subIndex ?? null);
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  const resetDragState = useCallback(() => {
    setDraggedItemIndex(null);
    setDraggedItemType(null);
    setDraggedSubIndex(null);
  }, []);

  const onDrop = useCallback(
    (index: number, type: string, subIndex?: number) => {
      if (draggedItemIndex === null || draggedItemType !== type) return;

      const blocks = [...curPage.blocks];
      if (type === "block") {
        const [movedBlock] = blocks.splice(draggedItemIndex, 1);
        blocks.splice(index, 0, movedBlock);
        setCurPage({ ...curPage, blocks });
      } else if (type === "subblock" && draggedSubIndex !== null && subIndex !== undefined) {
        const block = [...blocks[index]];
        const [movedSubBlock] = block.splice(draggedSubIndex, 1);
        block.splice(subIndex, 0, movedSubBlock);
        blocks[index] = block;
        setCurPage({ ...curPage, blocks });
      }
      resetDragState();
    },
    [curPage, draggedItemIndex, draggedItemType, draggedSubIndex, resetDragState]
  );

  function createBlock(type: string, blockIndex?: { index: number; subIndex?: number }) {
    setCurPage((prevPage: any) => {
      const newBlocks = [...(prevPage.blocks || [])];
      if (blockIndex) {
        const { index, subIndex } = blockIndex;
        if (subIndex !== undefined) {
          if (!newBlocks[index]) newBlocks[index] = [];
          newBlocks[index] = [
            ...newBlocks[index].slice(0, subIndex),
            { type, htmlContent: "" },
            ...newBlocks[index].slice(subIndex),
          ];
        } else {
          newBlocks.splice(index, 0, [{ type }]);
        }
      } else {
        newBlocks.push([{ type }]);
      }
      return { ...prevPage, blocks: newBlocks };
    });
  }

  function handleViewPage() {
    if (!curPage.pageLink) return dispatch(openSnackbar({ message: "Please input an URL.", severity: "warning" }));
    const fullURL = mainSiteURL + curPage.pageLink;
    window.open(fullURL, "_blank");
  }

  async function handleSubmit(e?: any) {
    if (e) e.preventDefault();
    if (!pageLink?.trim() || pageLink === "/")
      return dispatch(openSnackbar({ message: "Please input an URL.", severity: "warning" }));
    else if (!combinedHtmlContent?.trim())
      return dispatch(openSnackbar({ message: "Please input the HTML content.", severity: "warning" }));
    try {
      let updatedMetadataFields: any = {};
      for (const key in metadata) {
        if (metadata[key] !== originMetadata[key]) {
          if (key === "time") {
            updatedMetadataFields[key] = metadata[key].sort((a: any, b: any) => {
              if (!a.start || !b.start) return 0;
              return new Date(a.start).getTime() - new Date(b.start).getTime();
            });
          }
          updatedMetadataFields[key] = metadata[key];
        }
      }
      const result = await builderApi.insertContent({
        type: "blocks",
        htmlContent: combinedHtmlContent,
        pageLink,
        blocks: curPage.blocks || [],
        backgroundImage: curPage.backgroundImage,
        backgroundColor: curPage.backgroundColor,
        metadata: updatedMetadataFields,
      });
      if (selectedIndex === "new") {
        setPageDetails((prevDetails) => [...prevDetails, result.data]);
        setSelectedIndex(pageDetails.length.toString());
        setCurPage(result.data);
      } else {
        setPageDetails((prevDetails) => prevDetails.map((page) => (page._id === result.data._id ? result.data : page)));
        setCurPage(result.data);
      }
      setPageLink(result.data.pageLink);
      dispatch(openSnackbar({ message: "The page saved successfully." }));
      //   setUnsavedChanges(false);
      if (result.inserted) setShowDeadlineDialog(true);
    } catch (error: any) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
      console.error(error);
    }
  }

  function createSubBlock(type: string, blockIndex: number) {
    setCurPage((prevPage: any) => {
      const newBlocks = [...prevPage.blocks];
      if (!newBlocks[blockIndex]) {
        newBlocks[blockIndex] = [];
      }
      newBlocks[blockIndex].push({ type, htmlContent: "" });
      return { ...prevPage, blocks: newBlocks };
    });
  }

  function handleUrlChange(e: any) {
    setPageLink(e.target.value);
  }

  function handlePageBackground(event: any) {
    setCurPage({ ...curPage, [event.target.name]: event.target.value });
  }

  const handleSelection = useCallback(
    (e: any) => {
      if (e.target.value === "new") {
        setSelectedIndex("new");
        setPageLink("/");
        setCurPage({ blocks: [] });
        setMetadata({});
        setOriginMetadata({});
        return;
      }
      const index = parseInt(e.target.value);
      setSelectedIndex(e.target.value);
      const selectedPage = pageDetails[index];
      if (selectedPage) {
        setPageLink(selectedPage.pageLink);
        setCurPage({
          ...selectedPage,
          blocks: selectedPage.blocks,
        });
        setMetadata(pageDetails[index]?.metadata);
        setOriginMetadata(pageDetails[index]?.metadata);
      }
    },
    [pageDetails]
  );

  // async function handleDateSubmit(event: any) {
  //   event.preventDefault();
  //   try {
  //     const dueDate = event.target.dueDate.value;
  //     if (dueDate) {
  //       setShowDeadlineDialog(false);
  //       const triggerName = "page-creation";
  //       const defaultTasks = await taskApi.createDefaultTask(triggerName, {
  //         link: pageLink,
  //         dueDate: dueDate,
  //       });
  //       if (defaultTasks.length) dispatch(openSnackbar({ message: "The default tasks created successfully." }));
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  function handleDateChange(index: number, dateStr: string, startOrEnd: string) {
    setMetadata((prevMetadata: any) => {
      const updatedTime = prevMetadata.time.map((date: any, i: number) => {
        if (i === index) {
          const [year, month, day] = dateStr.split("-");
          const newDate = new Date(date[startOrEnd]);
          newDate.setFullYear(parseInt(year));
          newDate.setMonth(parseInt(month) - 1);
          newDate.setDate(parseInt(day));
          return { ...date, [startOrEnd]: newDate.toISOString() };
        }
        return date;
      });
      return { ...prevMetadata, time: updatedTime };
    });
  }

  function handleTimeChange(index: number, newTime: string, startOrEnd: string) {
    setMetadata((prevMetadata: any) => {
      const updatedTime = prevMetadata.time.map((date: any, i: number) => {
        if (i === index) {
          const [hours, minutes] = newTime.split(":");
          const newDate = new Date(date[startOrEnd]);
          newDate.setHours(parseInt(hours));
          newDate.setMinutes(parseInt(minutes));
          return { ...date, [startOrEnd]: newDate.toISOString() };
        }
        return date;
      });
      return { ...prevMetadata, time: updatedTime };
    });
  }

  function handleAddTimeEntry() {
    setMetadata((prevMetadata: any) => {
      const updatedTime = prevMetadata.time || [];
      let newEntry;
      if (!updatedTime.length) {
        const now = new Date();
        newEntry = {
          start: now.toISOString(),
          end: new Date(now.getTime() + 60 * 60 * 1000).toISOString(),
        };
      } else {
        const lastEntry = updatedTime[updatedTime.length - 1];
        newEntry = {
          start: lastEntry.start,
          end: lastEntry.end,
        };
      }
      return { ...prevMetadata, time: [...updatedTime, newEntry] };
    });
  }

  function handleDeleteTimeEntry(index: number) {
    const updatedTime = metadata.time.filter((date: any, i: number) => i !== index);
    setMetadata({ ...metadata, time: updatedTime });
  }

  function handleCloseMetadata() {
    setShowMetadata(false);
  }

  // function handleSearchChange(e: any) {
  //   const input = e.target.value;
  //   if (!input) return setSearchResults([]);
  //   if (Object.keys(assetFolderApi.assets).length === 0) assetFolderApi.fetchSubElements("root");
  //   const storedAssets: any = Object.keys(assetFolderApi.assets).map((key) => assetFolderApi.assets[key]);
  //   const tempAssets = storedAssets.filter((asset: any) => {
  //     if (asset.status === "archived") return false;
  //     const lowerCaseInput = input.toLowerCase();
  //     const lowerCaseName = asset.name.toLowerCase();
  //     const nameMatch = lowerCaseName.includes(lowerCaseInput);
  //     const tagsMatch = asset.tag?.some((eachTag: string) => eachTag.toLowerCase().includes(lowerCaseInput));
  //     return nameMatch || tagsMatch;
  //   });
  //   setSearchResults(tempAssets);
  // }

  function copyURL(name: string, url: string) {
    handleCopy(name, url);
    setSearchResults([]);
  }

  async function handleCopyPageSubmit() {
    if (!migrateFrom || !migrateTo)
      return dispatch(openSnackbar({ message: "Please select both pages.", severity: "warning" }));
    try {
      const res = await builderApi.copySourceToTarget(migrateFrom, migrateTo);
      if (res) {
        dispatch(openSnackbar({ message: "Page copied successfully.", severity: "success" }));
        window.location.reload();
      }
    } catch (error: any) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
      console.error(error);
    }
  }

  useEffect(() => {
    setPageDetails([]);
    setIsLoading(true);
    builderApi
      .getAllPages()
      .then((res) => {
        if (!res) throw new Error("No data found");
        setPageDetails(res || []);
      })
      .catch((error) => {
        dispatch(openSnackbar({ message: error.message, severity: "error" }));
        setPageDetails([]);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    function handleLinks() {
      if (link && pageDetails.length) {
        const taskLink = `/${link}`;
        const page = pageDetails.find((pageContent: PageContent) => pageContent.pageLink === taskLink);
        if (!page) {
          dispatch(openSnackbar({ message: "Page not found", severity: "error" }));
          navigate("/page-editor");
        } else handleSelection({ target: { value: page._id } });
      }
    }
    handleLinks();
  }, [link, pageDetails, navigate, handleSelection, dispatch]);

  useEffect(() => {
    async function updateBlocksIfEmpty() {
      if ((!curPage.blocks || !curPage.blocks.length) && curPage.htmlContent) {
        const newBlocks = await htmlToBlocks(curPage.htmlContent);
        setCurPage((prevPage: any) => ({
          ...prevPage,
          blocks: newBlocks,
        }));
      }
    }
    updateBlocksIfEmpty();
    const html = mergeEditorsIntoHtmlString(curPage.blocks, curPage?.backgroundImage, curPage?.backgroundColor);
    setCombinedHtmlContent(html);
  }, [curPage]);

  useEffect(() => {
    if (link && pageDetails.length) {
      const taskLink = `/${link}`;
      const index = pageDetails.findIndex((pageContent: PageContent) => pageContent.pageLink === taskLink);
      if (index !== -1) {
        handleSelection({ target: { value: index.toString() } });
      } else {
        dispatch(openSnackbar({ message: "Page not found", severity: "error" }));
      }
    }
  }, [link, pageDetails, handleSelection, dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Header />
      <div style={{ height: "88vh", overflow: "hidden", marginTop: "0.5rem" }}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", height: "100%" }}>
          <div
            style={{
              padding: "0.5rem",
              overflowY: "auto",
              width: "65%",
              height: "100%",
            }}
          >
            <PreviewSite
              blocks={curPage.blocks}
              type={previewType}
              divStyles={{
                backgroundImage: curPage?.backgroundImage ? `url(${curPage?.backgroundImage})` : undefined,
                backgroundColor: curPage?.backgroundImage ? undefined : curPage?.backgroundColor,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            />{" "}
          </div>

          <div
            style={{
              padding: "0.5rem",
              overflow: "hidden",
              position: "relative",
              width: "35%",
              height: "100%",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                p: 2,
                mb: 2,
                borderRadius: 2,
                background: (theme) =>
                  `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
                borderBottom: "2px solid",
                borderColor: "primary.main",
              }}
            >
              <Stack spacing={3}>
                {/* Device Preview Controls */}
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mr: 1 }}>
                    Preview Mode:
                  </Typography>
                  <ButtonGroup variant="outlined" size="small">
                    <Button
                      onClick={() => setPreviewType("pc")}
                      startIcon={<DesktopMac />}
                      sx={{
                        bgcolor: previewType === "pc" ? "primary.light" : "transparent",
                        color: previewType === "pc" ? "primary.main" : "text.secondary",
                        borderColor: previewType === "pc" ? "primary.main" : "divider",
                        "&:hover": {
                          bgcolor: "primary.lighter",
                        },
                      }}
                    >
                      Desktop
                    </Button>
                    <Button
                      onClick={() => setPreviewType("mobile")}
                      startIcon={<PhoneIphone />}
                      sx={{
                        bgcolor: previewType === "mobile" ? "primary.light" : "transparent",
                        color: previewType === "mobile" ? "primary.main" : "text.secondary",
                        borderColor: previewType === "mobile" ? "primary.main" : "divider",
                        "&:hover": {
                          bgcolor: "primary.lighter",
                        },
                      }}
                    >
                      Mobile
                    </Button>
                  </ButtonGroup>

                  <Box sx={{ ml: "auto" }}>
                    <Button
                      variant="contained"
                      onClick={handleClick}
                      startIcon={<Add />}
                      sx={{
                        bgcolor: "secondary.main",
                        "&:hover": {
                          bgcolor: "secondary.dark",
                        },
                      }}
                    >
                      Add Block
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 3,
                        sx: {
                          mt: 1,
                          minWidth: 180,
                          borderRadius: 2,
                          "& .MuiMenuItem-root": {
                            py: 1,
                            px: 2,
                            gap: 1.5,
                            borderRadius: 1,
                            mx: 1,
                            my: 0.5,
                            "&:hover": {
                              bgcolor: "primary.lighter",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          createBlock("text");
                          handleClose();
                        }}
                      >
                        <TextFields color="primary" /> Text Block
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          createBlock("image");
                          handleClose();
                        }}
                      >
                        <ImageIcon color="primary" /> Image Block
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          createBlock("input");
                          handleClose();
                        }}
                      >
                        <InputIcon color="primary" /> Input Block
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          createBlock("carousel");
                          handleClose();
                        }}
                      >
                        <ViewCarousel color="primary" /> Carousel Block
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>

                {/* Background Controls */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    p: 2,
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "primary.light",
                    boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.primary.light}`,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Background Image
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Enter image URL"
                      value={curPage?.backgroundImage || ""}
                      onChange={handlePageBackground}
                      name="backgroundImage"
                      InputProps={{
                        startAdornment: <ImageIcon color="primary" sx={{ mr: 1 }} />,
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Background Color
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <TextField
                        size="small"
                        type="color"
                        name="backgroundColor"
                        value={curPage?.backgroundColor || "#ffffff"}
                        onChange={handlePageBackground}
                        sx={{
                          width: 100,
                          "& .MuiInputBase-input": {
                            p: 1,
                            height: 23,
                          },
                        }}
                      />
                      <TextField
                        size="small"
                        value={curPage?.backgroundColor || "#ffffff"}
                        onChange={handlePageBackground}
                        name="backgroundColor"
                        sx={{ flex: 1 }}
                        InputProps={{
                          startAdornment: <PaletteIcon color="primary" sx={{ mr: 1 }} />,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Paper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                height: "80%",
                overflowY: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Typography variant="subtitle1" color="text.secondary" sx={{ px: 2 }}>
                Total Blocks: {curPage.blocks?.length || 0}
              </Typography>
              {curPage.blocks &&
                curPage.blocks.map((block: any, index: number) => (
                  <div
                    key={index}
                    draggable
                    onDragStart={() => onDragStart(index, "block")}
                    onDragOver={onDragOver}
                    onDrop={(event) => {
                      event.preventDefault();
                      onDrop(index, "block");
                    }}
                    onDragEnd={resetDragState}
                    style={{
                      border: "1px solid #ccc",
                      position: "relative",
                      marginBottom: "1rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: "background.paper",
                        p: 1,
                        borderTopLeftRadius: "1rem",
                        borderTopRightRadius: "1rem",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        Block {index + 1} ({block.length} sub-blocks)
                      </Typography>
                    </Box>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, display: "flex", flexWrap: "nowrap", gap: "0.1rem", width: "93%" }}>
                        {block.map((subBlock: any, subIndex: number) => (
                          <SubBlock
                            key={subIndex}
                            index={index}
                            subIndex={subIndex}
                            subBlock={subBlock}
                            totalSubBlocks={block.length}
                            handleHtmlContentChange={handleHtmlContentChange}
                            handleBlockNameChange={handleBlockNameChange}
                            handleDeleteBlock={handleDeleteBlock}
                          />
                        ))}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "7%" }}>
                        <Tooltip title="Drag and Drop" arrow placement="bottom">
                          <IconButton size="small">
                            <DragIndicatorIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Sub Text Editor" arrow placement="bottom">
                          <IconButton size="small" onClick={() => createSubBlock("text", index)}>
                            <NoteAddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Sub Image Editor" arrow placement="bottom">
                          <IconButton size="small" onClick={() => createSubBlock("image", index)}>
                            <AddAPhoto />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <AppBar
          position="fixed"
          color="default"
          sx={{
            top: "auto",
            bottom: 0,
            bgcolor: "background.paper",
            borderTop: "1px solid",
            borderColor: "divider",
            backdropFilter: "blur(8px)",
            zIndex: 2,
          }}
        >
          <Toolbar sx={{ gap: 2, justifyContent: "space-between" }}>
            {/* Page Controls */}
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                label="Page Link"
                value={pageLink}
                onChange={handleUrlChange}
                disabled={selectedIndex !== "new"}
                size="small"
                sx={{ minWidth: 200 }}
              />
              {selectedIndex === "new" && (
                <Select
                  size="small"
                  defaultValue="/"
                  onChange={(e: any) => handleUrlChange(e)}
                  sx={{
                    minWidth: 120,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 1,
                    },
                  }}
                >
                  <MenuItem value="/">/</MenuItem>
                  <MenuItem value="/aboutus">/aboutus</MenuItem>
                  <MenuItem value="/faq">/faq</MenuItem>
                  <MenuItem value="/help">/help</MenuItem>
                  <MenuItem value="/termsofsale">/termsofsale</MenuItem>
                </Select>
              )}
              <Select
                disabled={link !== undefined}
                value={selectedIndex}
                onChange={(e: any) => handleSelection(e)}
                size="small"
                sx={{ minWidth: 150 }}
              >
                <MenuItem value="new">New Page</MenuItem>
                {pageDetails.map((page, index) => (
                  <MenuItem key={index} value={index.toString()}>
                    {page.pageLink}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="secondary"
                sx={{
                  px: 3,
                  boxShadow: 2,
                  "&:hover": { boxShadow: 4 },
                }}
              >
                {selectedIndex === "new" ? "Create" : "Update"}
              </Button>
            </Stack>

            {/* Media Controls */}
            <Stack direction="row" spacing={2} alignItems="center" sx={{ px: 2 }}>
              <Tooltip title="Upload Image">
                <Button
                  size="small"
                  startIcon={<UploadIcon />}
                  onClick={() => setShowUploadArea(true)}
                  variant="outlined"
                  color="primary"
                >
                  Upload
                </Button>
              </Tooltip>
              <Tooltip title="Search Image">
                <Button
                  size="small"
                  startIcon={<SearchIcon />}
                  onClick={() => setShowSearchDialog(true)}
                  variant="outlined"
                  color="primary"
                >
                  Search
                </Button>
              </Tooltip>
            </Stack>

            {/* Page Actions */}
            <Stack direction="row" spacing={2} alignItems="center">
              <ButtonGroup variant="outlined" size="small">
                <Button onClick={handleViewPage} startIcon={<Visibility />}>
                  View
                </Button>
                <Button
                  onClick={() => setShowMetadata(true)}
                  disabled={curPage?.pageLink === "/"}
                  startIcon={<Settings />}
                >
                  Metadata
                </Button>
              </ButtonGroup>

              {/* Status Indicators */}
              <Stack direction="row" spacing={2} alignItems="center">
                <Tooltip title={metadata.time?.length ? "Time entries available" : "No time entries"}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <AccessTime color={metadata.time?.length ? "success" : "error"} fontSize="small" />
                    <Typography variant="body2" color="text.secondary">
                      Time
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={metadata.address ? "Address available" : "No address"}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <LocationOn color={metadata.address ? "success" : "error"} fontSize="small" />
                    <Typography variant="body2" color="text.secondary">
                      Address
                    </Typography>
                    {metadata.address && (
                      <IconButton
                        size="small"
                        onClick={() => handleCopy("Address", metadata.address)}
                        sx={{ ml: -0.5 }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </Tooltip>
              </Stack>

              {/* Duplicate Action */}
              <Button
                variant="contained"
                onClick={() => setShowDuplicateDialog(true)}
                startIcon={<FileCopy />}
                color="primary"
                sx={{
                  whiteSpace: "nowrap",
                  boxShadow: 2,
                  "&:hover": { boxShadow: 4 },
                }}
              >
                Duplicate
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>

        <CustomDialog open={showMetadata} onClose={() => handleCloseMetadata()}>
          <DialogTitle style={{ borderBottom: "1px solid #ccc", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            Update Page Data
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem", padding: "2rem" }}
          >
            <TextField
              label="Title"
              size="small"
              fullWidth
              value={metadata.title ? metadata.title : ""}
              onChange={(e) => {
                setMetadata({ ...metadata, title: e.target.value });
              }}
            />
            <div style={{ display: "flex", alignItems: "center", width: "100%", marginLeft: "2rem" }}>
              <Typography>Event Time: </Typography>
              <IconButton size="small" onClick={handleAddTimeEntry}>
                <Add />
              </IconButton>
            </div>
            {metadata?.time?.map((value: any, index: number) => (
              <div key={index} style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <input
                  type="date"
                  value={value.start.split("T")[0]}
                  onChange={(e) => handleDateChange(index, e.target.value, "start")}
                />
                <input
                  type="time"
                  value={value.start.split("T")[1].substr(0, 5)}
                  onChange={(e) => handleTimeChange(index, e.target.value, "start")}
                />
                <input
                  type="date"
                  value={value.end.split("T")[0]}
                  onChange={(e) => handleDateChange(index, e.target.value, "end")}
                />
                <input
                  type="time"
                  value={value.end.split("T")[1].substr(0, 5)}
                  onChange={(e) => handleTimeChange(index, e.target.value, "end")}
                />
                <IconButton className="p-0" onClick={() => handleDeleteTimeEntry(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <TextField
              label="Address"
              fullWidth
              multiline
              value={metadata.address || ""}
              onChange={(e) => {
                setMetadata({ ...metadata, address: e.target.value });
              }}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              value={metadata.description || ""}
              onChange={(e) => {
                setMetadata({ ...metadata, description: e.target.value });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </CustomDialog>

        <CustomDialog open={showSearchDialog} onClose={() => setShowSearchDialog(false)}>
          <DialogTitle style={{ borderBottom: "1px solid #ccc", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            Search Image
          </DialogTitle>
          <DialogContent style={{ padding: "2rem" }}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem", marginBottom: "3rem" }}>
              {searchResults.map((asset, index) => (
                <Card
                  key={index}
                  style={{ width: "8rem", height: "10rem" }}
                  onClick={() => copyURL(asset.name, getS3ImageUrl(asset.url))}
                >
                  <img src={getS3ImageUrl(asset.url)} style={{ width: "100%", height: "75%" }} alt={asset.name} />
                  <Typography>{shortenString(asset.name)}</Typography>
                </Card>
              ))}
            </div>
            {/* <TextField onChange={handleSearchChange} fullWidth label="Search Image" /> */}
          </DialogContent>
        </CustomDialog>

        <CustomDialog open={showUploadArea} onClose={() => setShowUploadArea(false)} fullWidth>
          <DialogTitle style={{ borderBottom: "1px solid #ccc", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            Upload Image
          </DialogTitle>
          <DialogContent style={{ padding: "2rem" }}>
            <FileUploader onUploadSuccess={() => setShowUploadArea(false)} />
            {/* <UploadArea dirName={"root"} activeDir={"root"} isBuilderPage={true} /> */}
          </DialogContent>
        </CustomDialog>

        <CustomDialog open={showDuplicateDialog} onClose={() => setShowDuplicateDialog(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Duplicate Page</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack gap={2}>
              <Typography>
                All content on the "from" page will replace all of the current content on the "to" page.
              </Typography>
              <Stack direction="row" gap={2}>
                <TextField
                  select
                  fullWidth
                  label="From"
                  value={migrateFrom}
                  onChange={(e) => setMigrateFrom(e.target.value)}
                >
                  {pageDetails.map((page, index) => (
                    <MenuItem key={index} value={page.pageLink}>
                      {page.pageLink}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField select fullWidth label="To" value={migrateTo} onChange={(e) => setMigrateTo(e.target.value)}>
                  {pageDetails.map((page, index) => (
                    <MenuItem key={index} value={page.pageLink}>
                      {page.pageLink}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleCopyPageSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </CustomDialog>

        {/* <Loading open={isLoading} /> */}
      </div>
    </>
  );
}
