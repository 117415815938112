import ImageNotFound from "../assets/image-not-found.png";

export function getTimestampForString(timeRange: string) {
  const currentDate = new Date();
  let targetDate: Date;

  if (timeRange === "1Week") {
    targetDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
  } else {
    targetDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
  }

  targetDate.setHours(0, 0, 0, 0);

  return targetDate.toISOString();
}

export function formatDate(timestamp: string): string {
  if (!timestamp) return "Never";
  const date = new Date(timestamp);

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  return formattedDate === "Invalid Date" ? "Never" : formattedDate;
}

export function formatTime(timestamp: string): string {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return formattedTime === "Invalid Date" ? "N/A" : formattedTime;
}

export function isValidTimestamp(timestamp: string) {
  if (!timestamp) return false;
  return new Date(timestamp).toString() !== "Invalid Date";
}

export function getUnixTimestampOfDay(date: Date) {
  return new Date(date.toISOString().split("T")[0]).getTime();
}

export function getUTCStartOfDay(date: Date) {
  const startOfDay = new Date(date);
  startOfDay.setUTCHours(0, 0, 0, 0);
  return startOfDay;
}

export function getUTCEndOfDay(date: Date) {
  const endOfDay = new Date(date);
  endOfDay.setUTCHours(23, 59, 59, 999);
  return endOfDay;
}

export function createUTCDateString(date: Date) {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  return year + "-" + month + "-" + day;
}

export function timeToDateStr(dateObj: Date) {
  return dateObj.toISOString().split("T")[0];
}
