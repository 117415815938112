import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  List,
  ListItem,
  Box,
  InputAdornment,
  Paper,
  Fade,
  useTheme,
  alpha,
  Divider,
  Chip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { categoryApi } from "../../apis/CategoryApi";
import { useNavigate } from "react-router-dom";
import { getPathFromCategoryObj } from "../../utils/category";
import { CategoryPath } from "./CategoryPath";
import { itemApi } from "../../apis/ItemApi";
import { getS3ImageUrlForItem } from "../../utils/item";

function CategorySearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState<any>({});
  const [showResults, setShowResults] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function handleSearch() {
      if (searchTerm) {
        try {
          const categoriesPromise = categoryApi.getCategories({ searchTerm, populate: true });
          const itemsPromise = itemApi.getItems({
            search: searchTerm,
            project: ["title", "categoryId", "tags", "imageUrl"],
          });

          let promises = [categoriesPromise, itemsPromise];

          let [categories, items] = await Promise.all(promises);

          // Group items by title
          const groupedItems = items?.items.reduce((acc: any, item: any) => {
            const title = item.title;
            const categoryId = item.categoryId;
            if (!acc[categoryId + " " + title]) {
              acc[categoryId + " " + title] = { ...item, count: 1 };
            } else {
              acc[categoryId + " " + title].count++;
            }
            return acc;
          }, {});

          setCategories(categories || []);
          setItems(groupedItems || {});
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      } else {
        setCategories([]);
        setItems([]);
      }
    }
    handleSearch();
  }, [searchTerm]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box ref={containerRef} sx={{ position: "relative", margin: "0 auto", mb: 2, px: 2 }}>
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "28px",
          p: "0.1rem",
          backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
          backdropFilter: "blur(8px)",
          transition: "all 0.3s ease",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: (theme) => theme.shadows[6],
            backgroundColor: (theme) => alpha(theme.palette.background.paper, 1),
          },
        }}
      >
        <TextField
          fullWidth
          placeholder="Search All Categories & Items (Based on tags and title)..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowResults(true);
          }}
          onFocus={() => {
            if (searchTerm.trim()) {
              setShowResults(true);
            }
          }}
          variant="standard"
          sx={{
            "& .MuiInput-root": {
              padding: "0.5rem 1rem",
              "&:before, &:after": {
                display: "none",
              },
            },
            "& .MuiInputBase-input": {
              fontSize: "1rem",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontSize: "1.5rem",
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <ClearIcon
                  sx={{
                    cursor: "pointer",
                    color: (theme) => theme.palette.text.secondary,
                    fontSize: "1.5rem",
                    "&:hover": {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={() => {
                    setSearchTerm("");
                    setShowResults(false);
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      {showResults && searchTerm && (
        <Fade in={true}>
          <Paper
            elevation={6}
            sx={{
              overflow: "hidden",
              position: "absolute",
              top: "calc(100% + 0.3125rem)",
              left: "1rem",
              right: "1rem",
              maxHeight: "30rem",
              borderRadius: "1rem",
              backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.95),
              backdropFilter: "blur(0.5rem)",
              zIndex: 1000,
              display: "flex",
            }}
          >
            <List sx={{ py: "0.5rem", flex: 1 }}>
              {categories.length > 0 ? (
                <>
                  <ListItem>
                    <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold", mx: "0.5rem" }}>
                      Category Results
                    </Typography>
                  </ListItem>
                  {categories.map((category: any, index: number) => (
                    <>
                      <ListItem
                        key={category?.id}
                        sx={{
                          cursor: "pointer",
                          transition: "all 0.2s ease",
                          borderRadius: "8px",
                          mx: "0.5rem",
                          "&:hover": {
                            transform: "translateX(-7px)",
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                          },
                        }}
                        onClick={() => {
                          navigate(`/category-item-management/${category._id}`);
                          setCategories([]);
                          setShowResults(false);
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <CategoryPath
                              path={getPathFromCategoryObj({ categoryObject: category })}
                              handleCategoryClick={() => {}}
                              variant="form"
                              highlightTitle={category?.title?.toLowerCase().includes(searchTerm.toLowerCase())}
                            />{" "}
                            <Box sx={{ display: "flex", flexWrap: "wrap", mt: "0.0625rem" }}>
                              {category?.tags?.map((tag: any) => (
                                <Chip
                                  key={tag.id}
                                  label={
                                    <>
                                      <Typography
                                        component="span"
                                        fontWeight="bold"
                                        sx={{
                                          backgroundColor: tag?.tag?.toLowerCase().includes(searchTerm.toLowerCase())
                                            ? "yellow"
                                            : "transparent",
                                        }}
                                      >
                                        {tag?.tag}
                                      </Typography>
                                      <Typography component="span" fontWeight="bold">
                                        :{" "}
                                      </Typography>
                                      <Typography component="span" fontWeight="normal">
                                        {tag?.values?.map((value: string) => (
                                          <>
                                            <span
                                              style={{
                                                backgroundColor:
                                                  searchTerm && value.toLowerCase().includes(searchTerm.toLowerCase())
                                                    ? "yellow"
                                                    : "transparent",
                                              }}
                                            >
                                              {value}
                                            </span>{" "}
                                          </>
                                        ))}
                                      </Typography>
                                    </>
                                  }
                                  sx={{ marginRight: "1rem", marginBottom: "0.5rem" }}
                                />
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </ListItem>
                      {index !== categories.length - 1 && <Divider sx={{ mx: "0.5rem" }} />}
                    </>
                  ))}
                </>
              ) : (
                <ListItem>
                  <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>No categories found</Typography>
                </ListItem>
              )}
            </List>
            <Divider orientation="vertical" flexItem sx={{ mx: "0.5rem" }} />

            <List
              sx={{
                // py: "0.5rem",
                flex: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "0.5rem",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: (theme) => alpha(theme.palette.primary.main, 0.2),
                  borderRadius: "1rem",
                  "&:hover": {
                    background: (theme) => alpha(theme.palette.primary.main, 0.3),
                  },
                },
                overflowX: "hidden",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // height: "100%",
              }}
            >
              {Object.entries(items).length > 0 ? (
                <>
                  <ListItem>
                    <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold", mx: "0.5rem" }}>Items Results</Typography>
                  </ListItem>

                  {Object.entries(items).map(([title, item]: [string, any]) => (
                    <>
                      <ListItem
                        key={title}
                        sx={{
                          cursor: "pointer",
                          transition: "all 0.2s ease",
                          borderRadius: "8px",
                          mx: "0.5rem",
                          "&:hover": {
                            transform: "translateX(-7px)",
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                          },
                        }}
                        onClick={() => {
                          navigate(`/category-item-management/${item.categoryId}?itemSearch=${item.title}`);
                          setItems({});
                          setShowResults(false);
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                          <img
                            src={getS3ImageUrlForItem({ itemObj: item })}
                            alt={item?.title}
                            style={{ width: "3.125rem", height: "3.125rem", marginRight: "0.5rem" }}
                          />
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <Typography
                              sx={{
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              Category: {item?.categoryDetails?.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                                backgroundColor: item?.title?.toLowerCase().includes(searchTerm.toLowerCase())
                                  ? "yellow"
                                  : "transparent",
                              }}
                            >
                              {item?.title}{" "}
                              <span style={{ fontWeight: "normal", fontSize: "1rem" }}>({item?.count} items)</span>
                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", mt: "0.0625rem" }}>
                              {item?.tags?.map((tag: any) => (
                                <Chip
                                  key={tag.id}
                                  label={
                                    <>
                                      <Typography
                                        component="span"
                                        fontWeight="bold"
                                        sx={{
                                          backgroundColor: tag?.tag?.toLowerCase().includes(searchTerm.toLowerCase())
                                            ? "yellow"
                                            : "transparent",
                                        }}
                                      >
                                        {tag?.tag}
                                      </Typography>
                                      <Typography component="span" fontWeight="bold">
                                        :{" "}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        fontWeight="normal"
                                        sx={{
                                          backgroundColor: tag?.value?.toLowerCase().includes(searchTerm.toLowerCase())
                                            ? "yellow"
                                            : "transparent",
                                        }}
                                      >
                                        {tag?.value}
                                      </Typography>
                                    </>
                                  }
                                  sx={{ marginRight: "1rem", marginBottom: "0.5rem" }}
                                />
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </ListItem>
                      <Divider sx={{ mx: "0.5rem" }} />
                    </>
                  ))}
                </>
              ) : (
                <ListItem>
                  <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>No items found</Typography>
                </ListItem>
              )}
            </List>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                width: "100%",
                height: "0.15rem",
                background: (theme) => `linear-gradient(90deg, 
                  transparent 5%,
                  ${alpha(theme.palette.primary.main, 0.7)} 50%,
                  transparent 100%
                )`,
                transform: "translateX(-50%)",
                transition: "all 0.3s ease",
              }}
            />
          </Paper>
        </Fade>
      )}
    </Box>
  );
}

export default CategorySearch;
