import React, { RefObject } from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface FileUploadButtonProps extends ButtonProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  accept?: string;
  inputRef?: RefObject<HTMLInputElement>;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onFileChange,
  children = "Upload files",
  startIcon = <CloudUploadIcon />,
  error,
  helperText,
  accept,
  inputRef,
  ...buttonProps
}) => {
  return (
    <>
      <Button component="label" startIcon={startIcon} error={error} {...buttonProps}>
        {children}
        <VisuallyHiddenInput type="file" onChange={onFileChange} multiple accept={accept} ref={inputRef} />
      </Button>
      {helperText && (
        <Typography color={error ? "error" : "inherit"} variant="caption" display="block">
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default FileUploadButton;
