import { TableRow, TableCell, Typography, Box } from "@mui/material";
import { formatDate, formatTime } from "../../utils/time";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getS3ImageUrl } from "../../utils/s3Image";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";

interface SubscriptionDetailRowProps {
  tab: string;
  subscription: any;
  setSelectedSubscription: (subscription: any) => void;
}

export default function SubscriptionDetailRow({
  tab,
  subscription,
  setSelectedSubscription,
}: SubscriptionDetailRowProps) {
  const dispatch = useDispatch();
  const handleCopyToClipboard = async () => {
    if (subscription?.details?._id) {
      try {
        await navigator.clipboard.writeText(subscription?.details?._id);
        dispatch(openSnackbar({ message: "Copied to clipboard", severity: "success" }));
      } catch (error) {
        console.error("Failed to copy text:", error);
      }
    }
  };
  return (
    <TableRow
      key={subscription?.details?._id}
      onClick={() => {
        setSelectedSubscription(subscription);
      }}
      sx={{ cursor: "pointer" }}
    >
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              width: 45,
              height: 50,
              position: "relative",
              overflow: "hidden",
              boxShadow: 1,
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                width: "20%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                zIndex: 1,
              },
            }}
          >
            <Box
              component="img"
              src={
                getS3ImageUrl({
                  imageUrl: subscription?.details?.imageUrl,
                  type: "subscription",
                }) || ""
              }
              alt={subscription?.details?.name}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
              }}
            />
          </Box>
          <Box>
            <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
              {subscription?.details?.name || "No Subscription Name"}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
            >
              <ContentCopyIcon
                sx={{ mr: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" }, cursor: "pointer" }}
                onClick={handleCopyToClipboard}
              />
              {subscription?.details?._id || "No Subscription Id"}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{subscription?.details?.description || "No Description"}</TableCell>
      <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
        <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
          {formatDate(subscription?.details?.activationTime)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
        >
          {formatTime(subscription?.details?.activationTime)}
        </Typography>
      </TableCell>
      <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
        <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
          {formatDate(subscription?.details?.expirationTime)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
        >
          {formatTime(subscription?.details?.expirationTime)}
        </Typography>
      </TableCell>
      <TableCell sx={{ display: { xs: "none", sm: tab !== "all" ? "table-cell" : "none" } }}>
        {subscription?.stats?.count}
      </TableCell>
    </TableRow>
  );
}
