import React, { useState, useRef } from "react";
import { Box, Typography, IconButton, Stack, Paper, Chip, Button } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CropperDialog from "./CropperDialog";

interface ImageUploaderProps {
  onImageSelect: (file: File | null) => void;
  useCropper?: boolean;
  cropMode?: string;
  error?: boolean;
  helperText?: string;
  previewWidth?: number | string;
  previewHeight?: number | string;
  variant?: string;
  existingImageUrl?: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageSelect,
  useCropper = false,
  cropMode = "1:1",
  error,
  helperText,
  variant,
  previewWidth = 500,
  previewHeight = 500,
  existingImageUrl,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [newImagePreview, setNewImagePreview] = useState<string>("");
  const [openCropper, setOpenCropper] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [tempImageForCropper, setTempImageForCropper] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      if (useCropper) {
        const reader = new FileReader();
        reader.onload = () => {
          setTempImageForCropper(reader.result as string);
          setOpenCropper(true);
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setNewImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
        onImageSelect(file);
      }
    }
  };

  const handleCropSave = (croppedImage: File) => {
    setSelectedFile(croppedImage);
    const reader = new FileReader();
    reader.onload = () => {
      setNewImagePreview(reader.result as string);
    };
    reader.readAsDataURL(croppedImage);
    setOpenCropper(false);
    onImageSelect(croppedImage);
  };

  const handleRemoveImage = () => {
    setNewImagePreview("");
    setSelectedFile(null);
    onImageSelect(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Box>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" style={{ display: "none" }} />
      {!newImagePreview ? (
        <Paper
          elevation={0}
          sx={{
            width: variant === "small" ? "40%" : "100%",
            p: 4,
            borderRadius: 3,
            backgroundColor: "grey.50",
            border: "2px dashed",
            borderColor: error ? "error.main" : "grey.300",
            position: "relative",
            overflow: "hidden",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              borderColor: "primary.main",
              backgroundColor: "grey.100",
              "& .upload-icon": {
                transform: "scale(1.1)",
              },
            },
          }}
          onClick={() => fileInputRef.current?.click()}
        >
          <Stack spacing={2} alignItems="center">
            <AddPhotoAlternateIcon
              className="upload-icon"
              sx={{
                fontSize: 64,
                color: "primary.main",
                transition: "transform 0.2s ease-in-out",
              }}
            />
            <Box textAlign="center">
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Upload Image
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Click or drag & drop your image here
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="center" mt={2}>
                <Chip
                  size="small"
                  icon={<InfoOutlinedIcon />}
                  label="JPG, PNG, GIF"
                  variant="outlined"
                  sx={{ borderRadius: 1 }}
                />
              </Stack>
            </Box>
            {helperText && (
              <Typography
                variant="caption"
                color={error ? "error" : "textSecondary"}
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <InfoOutlinedIcon fontSize="small" />
                {helperText}
              </Typography>
            )}
          </Stack>
        </Paper>
      ) : (
        <Paper
          elevation={0}
          sx={{
            mt: 2,
            borderRadius: 3,
            overflow: "hidden",
            backgroundColor: "grey.50",
            border: "2px solid",
            borderColor: "grey.200",
            width: previewWidth,
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              "&:hover .image-actions": {
                opacity: 1,
              },
            }}
          >
            <Box
              component="img"
              src={newImagePreview}
              alt="Preview"
              sx={{
                width: "100%",
                height: previewHeight,
                maxHeight: 300,
                objectFit: "contain",
                display: "block",
                transition: "filter 0.2s ease-in-out",
                "&:hover": {
                  filter: "brightness(0.8)",
                },
              }}
            />
          </Box>
          <Box sx={{ p: 2, borderTop: 1, borderColor: "grey.200" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="caption" color="textSecondary">
                {selectedFile?.name}
                {selectedFile && (
                  <Typography component="span" variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                    ({(selectedFile.size / (1024 * 1024)).toFixed(2)} MB)
                  </Typography>
                )}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  onClick={() => fileInputRef.current?.click()}
                  startIcon={<EditIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Replace
                </Button>
                <Button
                  size="small"
                  color="error"
                  onClick={handleRemoveImage}
                  startIcon={<DeleteOutlineIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Remove
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      )}

      {useCropper && (
        <CropperDialog
          open={openCropper}
          onClose={() => setOpenCropper(false)}
          onCropSave={handleCropSave}
          imageUrl={tempImageForCropper}
          cropMode={cropMode}
        />
      )}
    </Box>
  );
};

export default ImageUploader;
