import { ChunkMetadata } from "../types/common";
import assetApi from "../apis/AssetApi";

const CHUNK_SIZE = 8 * 1024 * 1024; // 8MB chunks
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export const createChunks = (file: File): Blob[] => {
  const chunks: Blob[] = [];
  let start = 0;

  while (start < file.size) {
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const chunk = file.slice(start, end, file.type);

    chunks.push(chunk);
    start = end;
  }

  return chunks;
};

export const delay = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const retryOperation = async <T>(operation: () => Promise<T>, retryCount = 0): Promise<T> => {
  try {
    return await operation();
  } catch (error: any) {
    if (retryCount >= MAX_RETRIES) {
      throw error;
    }

    const status = error.response?.status;
    const isRetryable = [429, 503, 504, 408].includes(status) || !status;

    if (!isRetryable) {
      throw error;
    }

    const delayTime = Math.min(RETRY_DELAY * Math.pow(2, retryCount), 30000);
    const retryAfter = error.response?.headers?.["retry-after"];
    const waitTime = retryAfter ? parseInt(retryAfter) * 1000 : delayTime;

    await delay(waitTime);
    return retryOperation(operation, retryCount + 1);
  }
};

export const calculateProgress = (currentChunkIndex: number, totalChunks: number, chunkProgress: number): number => {
  const chunkContribution = chunkProgress / totalChunks;
  const previousChunksContribution = (currentChunkIndex / totalChunks) * 100;
  return Math.min(previousChunksContribution + chunkContribution, 100);
};

async function uploadAssetWithChunks(file: File, onProgress?: (progress: number) => void): Promise<any> {
  try {
    const chunks = createChunks(file);
    const totalChunks = chunks.length;
    const uploadId = crypto.randomUUID();
    let lastResult;

    for (let i = 0; i < chunks.length; i++) {
      const metadata: ChunkMetadata = {
        filename: file.name,
        index: i,
        totalChunks,
        uploadId,
      };

      const formData = new FormData();
      formData.append("file", chunks[i]);
      formData.append("metadata", JSON.stringify(metadata));

      lastResult = await assetApi.uploadChunk(formData, (chunkProgress) => {
        if (onProgress) {
          const currentProgress = calculateProgress(i, totalChunks, chunkProgress);
          onProgress(currentProgress);
        }
      });

      if (lastResult.complete) {
        return lastResult.result;
      }

      if (lastResult.missingChunk !== undefined) {
        i = lastResult.missingChunk - 1;
        continue;
      }
    }

    throw new Error("Upload failed to complete after all chunks were sent");
  } catch (error: any) {
    console.error("Error in chunked upload:", {
      error,
      response: error.response?.data,
      status: error.response?.status,
      message: error.message,
      stack: error.stack,
    });
    throw error;
  }
}

async function uploadAssets(files: File[], onProgress?: (fileName: string, progress: number) => void): Promise<any> {
  const results = [];

  for (const file of files) {
    try {
      const result = await uploadAssetWithChunks(file, (progress) => {
        onProgress?.(file.name, progress);
      });
      results.push(result);
    } catch (error) {
      console.error(`Error uploading ${file.name}:`, error);
      throw error;
    }
  }

  return results;
}
