import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Card, CardContent, CardHeader, Typography, Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { LockClockOutlined } from "@mui/icons-material";
import { adminApi } from "../apis/AdminApi";
import { openSnackbar } from "../redux/slices/snackbar";
import EditAdminForm from "../components/admin-profile/EditAdminForm";
import Header from "../components/common/Header";

export default function AdminProfile() {
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);
  const [adminData, setAdminData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState("");
  const dispatch = useDispatch();

  const fetchAdminData = useCallback(async () => {
    try {
      const result: any = await adminApi.getAdmin(adminInfo._id);

      if (!result) throw new Error("Invalid response from server");
      setAdminData({
        username: result.admin.username,
        email: result.admin.email,
        password: result.admin.password,
      });
    } catch (err: any) {
      console.error(err);
      dispatch(openSnackbar({ message: err.message, severity: "error" }));
    }
  }, [adminInfo, setAdminData, dispatch]);

  function handleEditClick(field: string) {
    setFieldToEdit(field);
    setIsEditing(true);
  }

  function handleBackClick() {
    setIsEditing(false);
  }

  useEffect(() => {
    fetchAdminData();
  }, [fetchAdminData]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },

          width: "100vw",
          height: "95vh",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <Card
          sx={{
            width: { xs: "90%", sm: "50%", md: "50%" },
            height: { md: "40%" },
            marginBottom: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
            transition: "0.3s",
            boxShadow: 3,
          }}
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "primary.main" }}>
                <LockClockOutlined />
              </Avatar>
            }
            title="Admin Profile"
            subheader="View and edit your profile information"
            titleTypographyProps={{ variant: "h4" }}
            subheaderTypographyProps={{ variant: "subtitle1" }}
          />
          <CardContent>
            {/* Username */}
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Username: {adminData.username}</Typography>
              <IconButton onClick={() => handleEditClick("username")} aria-label="edit">
                <EditIcon />
              </IconButton>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
              <Typography variant="h6">Email: {adminData.email}</Typography>
              <IconButton onClick={() => handleEditClick("email")} aria-label="edit">
                <EditIcon />
              </IconButton>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
              <Typography variant="h6">Password: {"********"}</Typography>
              <Box>
                <IconButton onClick={() => handleEditClick("password")} aria-label="edit">
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {isEditing && (
          <EditAdminForm
            fieldToEdit={fieldToEdit}
            onBack={handleBackClick}
            refetch={fetchAdminData}
            email={adminData.email}
            // onSave={handleSave}
          />
        )}
      </Box>
    </>
  );
}
