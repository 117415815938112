import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImportExportIcon from "@mui/icons-material/ImportExport";

interface ScratchCardHeaderProps {
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}

export default function ScratchCardDetailHeader({
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: ScratchCardHeaderProps) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleSort("serialNumber");
            handleSortDirection(sortDirection === "1" ? "-1" : "1");
          }}
        >
          Scratch Card Code
          {/* {sortColumn === "serialNumber" && (sortDirection === "1" ? " 🔼" : " 🔽")} */}
          {sortColumn === "serialNumber" ? (
            sortDirection === "1" ? (
              <ArrowUpwardIcon
                sx={{ fontSize: 18, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("serialNumber");
                  handleSortDirection("-1");
                }}
              />
            ) : (
              <ArrowDownwardIcon
                sx={{ fontSize: 18, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("serialNumber");
                  handleSortDirection("1");
                }}
              />
            )
          ) : (
            <ImportExportIcon
              sx={{ fontSize: 18, ml: 1, cursor: "pointer" }}
              onClick={() => {
                handleSort("serialNumber");
                handleSortDirection("-1");
              }}
            />
          )}
        </TableCell>
        <TableCell>Scratch Card Type</TableCell>
        <TableCell>Event</TableCell>
        <TableCell>Prize</TableCell>
        <TableCell>Usage Limit</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Redeem/Claim Time</TableCell>
      </TableRow>
    </TableHead>
  );
}
