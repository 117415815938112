import { combineReducers, configureStore } from "@reduxjs/toolkit";
import adminUserSlice from "./slices/adminUser";
import snackbarReducer from "./slices/snackbar";

const appReducer = combineReducers({
  admin: adminUserSlice,
  snackbar: snackbarReducer,
});

const store = configureStore({
  reducer: appReducer,
});

export default store;
