import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, useTheme, Grid2 as Grid, Divider } from "@mui/material";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { categoryApi } from "../apis/CategoryApi";
import { itemApi, FiltersWithCount, FilterCountGroupNames } from "../apis/ItemApi";
import CreateCategory from "../components/forms/CreateCategory";
import CreateItems from "../components/forms/CreateItems";
import { Category, Item, ItemTag } from "../types/common";
import AddIcon from "@mui/icons-material/Add";
import { CategoryPath } from "../components/category-item-management/CategoryPath";
import { CategoryCard } from "../components/category-item-management/CategoryCard";
import { getPathFromCategoryObj } from "../utils/category";
import CategorySkeleton from "../components/category-item-management/CategorySkeleton";
import EmptyState from "../components/category-item-management/item-section/EmptyState";
import CategorySearch from "../components/category-item-management/CategorySearch";
import UpdateItems from "../components/forms/UpdateItems";
import CurrentTitle from "../components/category-item-management/CurrentTitle";
import CategoryTagSection from "../components/category-item-management/CategoryTagSection";
import FilterSection from "../components/category-item-management/FilterSection";
import ItemSection from "../components/category-item-management/ItemSection";
import EditGroupForm from "../components/forms/EditGroupForm";
import { getItemGroupName } from "../utils/item";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import adminFilterVersionHistoryApi from "../apis/AdminFilterVersionHistoryApi";
import adminSavedFilterApi from "../apis/AdminSavedFilterApi";
import Header from "../components/common/Header";
import SubCategorySorter, { sortOptions } from "../components/category-item-management/SubCategorySorter";
import { ItemSortEnum } from "../types/category-item-enum";

interface FilterState {
  search: string;
  groups: string[];
  nonSerialisedItems: string[];
  stages: string[];
  visibility: string;
  tagValues: string[];
}

export default function CategoryItemManagement() {
  const { categoryId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [items, setItems] = useState<Item[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [openCreateCategoryForm, setOpenCreateCategoryForm] = useState<boolean>(false);
  const [groupMax, setGroupMax] = useState<Record<string, number>>({});
  const [openCreateItemForm, setOpenCreateItemForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isItemLoading, setIsItemLoading] = useState(true);
  const [editTitle, setEditTitle] = useState<string>("");
  const [openUpdateItemForm, setOpenUpdateItemForm] = useState<string | null>(searchParams.get("editItemId") || null);
  const [openEditGroupForm, setOpenEditGroupForm] = useState<string | null>(searchParams.get("editGroup") || null);
  const [path, setPath] = useState<any[]>([]);
  const [categoryDetails, setCategoryDetails] = useState<any>(null);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [displayedSubCategories, setDisplayedSubCategories] = useState<Category[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  // New state for maintaining URL history
  const [backStack, setBackStack] = useState<FilterState[]>([]);
  const [forwardStack, setForwardStack] = useState<FilterState[]>([]);

  // Centralized filter state
  const [filtersVersionHistory, setFiltersVersionHistory] = useState<any[]>([]);
  const [savedFilters, setSavedFilters] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterState>({
    search: searchParams.get("itemSearch") || "",
    groups: searchParams.get("groups")?.split(",").filter(Boolean) || [],
    nonSerialisedItems: searchParams.get("nonSerialisedItems")?.split(",").filter(Boolean) || [],
    stages: searchParams.get("stages")?.split(",").filter(Boolean) || [],
    visibility: searchParams.get("visibility") || "all",
    tagValues: searchParams.get("tagValues")?.split(",").filter(Boolean) || [],
  });

  const [filterCounts, setFilterCounts] = useState<FiltersWithCount>({
    groupNames: [],
    visibilities: [],
    tagValues: [],
    stages: [],
    nonSerialisedItems: [],
  });

  const [activeSortId, setActiveSortId] = useState<string>("recent");
  const [sortKeyItems, setSortKeyItems] = useState<ItemSortEnum>(ItemSortEnum.Title_Asc);

  const fetchFilterCounts = useCallback(async () => {
    if (!categoryId) return;

    try {
      const response = await itemApi.getFiltersWithCount(categoryId, {
        groupNames: filters.groups,
        visibility: filters.visibility,
        tagValues: filters.tagValues,
        stages: filters.stages,
        nonSerialisedItems: filters.nonSerialisedItems,
      });
      const newGroupMax = response.groupNames.reduce((map: Record<string, number>, item: FilterCountGroupNames) => {
        map[item.value] = item.maxNumberId;
        return map;
      }, {});

      setGroupMax(newGroupMax);
      setFilterCounts(response);
    } catch (error) {
      console.error("Error fetching filter counts:", error);
      setFilterCounts({
        groupNames: [],
        nonSerialisedItems: [],
        visibilities: [],
        tagValues: [],
        stages: [],
      });
    }
  }, [categoryId, filters]);

  const handleFilterChange = useCallback((type: keyof FilterState, value: string) => {
    setFilters((prev) => {
      const newFilters = { ...prev };

      if (type === "search") {
        newFilters.search = value;
      } else if (type === "visibility") {
        newFilters.visibility = value === prev.visibility ? "all" : value;
      } else {
        // Handle all array-based filters (groups, stages, tags, tagValues)
        const arr = [...(prev[type] as string[])];
        const index = arr.indexOf(value);

        if (index === -1) {
          arr.push(value);
        } else {
          arr.splice(index, 1);
        }

        newFilters[type] = arr;
      }

      if (prev) {
        // Check if the previous state is different from the last entry in backStack
        const lastEntry = backStack[backStack.length - 1];
        if (JSON.stringify(lastEntry) !== JSON.stringify(prev)) {
          setBackStack((prevBackStack) => [...prevBackStack, { ...prev }]);
        }
      }

      adminFilterVersionHistoryApi
        .updateFilterVersionHistory({
          userId: adminInfo._id,
          categoryId: categoryId || "",
          filterObjects: [newFilters],
        })
        .then((filterObjects) => {
          setFiltersVersionHistory((prev) => [...filterObjects, ...prev]);
          // dispatch(openSnackbar({ message: "Filters version history updated", severity: "success" }));
        })
        .catch((err) => {
          dispatch(openSnackbar({ message: "Error updating filters version history", severity: "error" }));
        });

      return newFilters;
    });
  }, []);

  const updateFilters = useCallback((filters: FilterState) => {
    setFilters((prev) => {
      if (prev === filters) return prev;

      if (prev) {
        // Check if the previous state is different from the last entry in backStack
        const lastEntry = backStack[backStack.length - 1];
        if (JSON.stringify(lastEntry) !== JSON.stringify(prev)) {
          setBackStack((prevBackStack) => [...prevBackStack, { ...prev }]);
        }
      }

      adminFilterVersionHistoryApi
        .updateFilterVersionHistory({
          userId: adminInfo._id,
          categoryId: categoryId || "",
          filterObjects: [filters],
        })
        .then((filterObjects) => {
          setFiltersVersionHistory((prev) => [...filterObjects, ...prev]);
          // dispatch(openSnackbar({ message: "Filters version history updated", severity: "success" }));
        })
        .catch((err) => {
          dispatch(openSnackbar({ message: "Error updating filters version history", severity: "error" }));
        });

      return filters;
    });
  }, []);

  const handleBack = () => {
    if (backStack.length > 0) {
      const previousFilter = backStack.pop();

      if (previousFilter) {
        if (filters) {
          setForwardStack([filters, ...forwardStack]);
        }
        setFilters(previousFilter);
      }
    }
  };

  const handleForward = () => {
    if (forwardStack.length > 0) {
      const nextFilter = forwardStack.shift();

      if (nextFilter) {
        if (filters) {
          setBackStack([...backStack, filters]);
        }
        setFilters(nextFilter);
      }
    }
  };

  const fetchData = useCallback(async (categoryId: string | undefined, page: number = 1) => {
    setIsLoading(true);
    try {
      const [currentCategory, subCategoriesResult, adminFiltersVersionHistory, adminSavedFilters] = await Promise.all([
        categoryApi.getCategoryWithParents({ categoryId, populateParents: true }),
        categoryApi.getSubCategories({ categoryId }),
        adminFilterVersionHistoryApi.getFilterVersionHistory({ userId: adminInfo._id, categoryId: categoryId || "" }),
        adminSavedFilterApi.getSavedFilters({ userId: adminInfo._id, categoryId: categoryId || "" }),
      ]);

      const path = getPathFromCategoryObj({ categoryObject: currentCategory });
      setPath(path);
      setCategoryDetails(currentCategory);
      setFiltersVersionHistory(adminFiltersVersionHistory);
      setSavedFilters(adminSavedFilters);
      setSubCategories(subCategoriesResult.categories);
    } catch (error) {
      console.error(error);
      setPath([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchItems = useCallback(
    async ({
      categoryId,
      page = 1,
      filters,
      itemsPerPage,
      sortKeyItems,
    }: {
      categoryId: string;
      page?: number;
      filters: FilterState;
      itemsPerPage: number;
      sortKeyItems?: ItemSortEnum;
    }) => {
      try {
        setIsItemLoading(true);
        const itemsResult = await itemApi.getItems({
          categoryId,
          page,
          limit: itemsPerPage,
          total: true,
          ...filters,
          sortKey: sortKeyItems ? sortKeyItems.split("_")[0] : undefined,
          sortDirection: sortKeyItems ? sortKeyItems.split("_")[1] : undefined,
        });

        setItems(itemsResult.items || []);
        setFilteredItems(itemsResult.items || []);
        setTotalItems(itemsResult.total || 0);
        setTotalQuantity(itemsResult.totalQuantity || 0);
        setCurrentPage(page);

        return itemsResult;
      } catch (error) {
        console.error("Error fetching items:", error);
        setItems([]);
        setFilteredItems([]);
        setTotalItems(0);
        setTotalQuantity(0);
        setCurrentPage(1);
        setGroupMax({});
      } finally {
        setIsItemLoading(false);
      }
    },
    []
  );

  const handlePageChange = (page: number) => {
    if (!categoryId || isItemLoading) return;
    fetchItems({ categoryId, page, filters, itemsPerPage, sortKeyItems: sortKeyItems });
  };

  const refetchItems = useCallback(() => {
    if (categoryId)
      fetchItems({
        categoryId,
        page: 1,
        filters,
        itemsPerPage: itemsPerPage,
        sortKeyItems: sortKeyItems,
      });
  }, [categoryId, filters, itemsPerPage, fetchItems, sortKeyItems]);

  const handleCategoryClick = (categoryId: string) => {
    const ripPackId = searchParams.get("ripPackId");
    const path = `/category-item-management/${categoryId}` + (ripPackId ? `?ripPackId=${ripPackId}` : "");
    navigate(path);
  };

  const applySorting = useCallback(
    (categories: Category[]) => {
      const currentSort = sortOptions.find((option) => option.id === activeSortId);
      if (currentSort) {
        return [...categories].sort(currentSort.sortFn);
      }
      return categories;
    },
    [activeSortId]
  );

  const handleSortChange = (sortedCategories: Category[]) => {
    setDisplayedSubCategories(sortedCategories);
  };

  useEffect(() => {
    const sortedCategories = applySorting(subCategories);
    setDisplayedSubCategories(sortedCategories);
  }, [subCategories, applySorting]);

  useEffect(() => {
    fetchData(categoryId, 1);
  }, [categoryId]);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    if (filters.search) newParams.set("itemSearch", filters.search);
    else newParams.delete("itemSearch");

    if (filters.groups.length) newParams.set("groups", filters.groups.join(","));
    else newParams.delete("groups");

    if (filters.nonSerialisedItems.length) newParams.set("nonSerialisedItems", filters.nonSerialisedItems.join(","));
    else newParams.delete("nonSerialisedItems");

    if (filters.stages.length) newParams.set("stages", filters.stages.join(","));
    else newParams.delete("stages");

    if (filters.visibility !== "all") newParams.set("visibility", filters.visibility);
    else newParams.delete("visibility");

    if (filters.tagValues.length) newParams.set("tagValues", filters.tagValues.join(","));
    else newParams.delete("tagValues");

    setSearchParams(newParams);
  }, [filters]);

  useEffect(() => {
    fetchFilterCounts();
  }, [fetchFilterCounts]);

  useEffect(() => {
    refetchItems();
  }, [refetchItems]);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    if (openEditGroupForm) {
      newParams.set("editGroup", openEditGroupForm);
    } else {
      newParams.delete("editGroup");
    }

    if (openUpdateItemForm) {
      newParams.set("editItemId", openUpdateItemForm);
    } else {
      newParams.delete("editItemId");
    }

    setSearchParams(newParams);
  }, [openEditGroupForm, openUpdateItemForm]);

  const handleSaveFilter = (filterName: string) => {
    adminSavedFilterApi
      .updateSavedFilter({
        name: filterName,
        userId: adminInfo._id,
        categoryId: categoryId || "",
        filterObjects: [filters],
      })
      .then((filterObjects) => {
        setSavedFilters([...savedFilters, ...filterObjects]);
        dispatch(openSnackbar({ message: "Filter saved", severity: "success" }));
      })
      .catch((err) => {
        dispatch(openSnackbar({ message: err.message, severity: "error" }));
      });
  };

  return (
    <>
      <Header
        headerComponent={<CategoryPath path={path} handleCategoryClick={handleCategoryClick} variant="no-background" />}
      />
      <Box sx={{ p: 3, maxWidth: "100%" }}>
        <CategorySearch />

        {categoryId && (
          <>
            <CurrentTitle
              categoryDetails={categoryDetails}
              categoryId={categoryId || ""}
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              fetchData={fetchData}
            />

            <Box sx={{ mt: -0.5, mb: 1 }}>
              <Typography variant="caption" sx={{ color: "text.primary", fontWeight: 400 }}>
                {categoryDetails?.description || "No description available"}
              </Typography>
            </Box>

            <CategoryTagSection
              categoryDetails={categoryDetails}
              categoryId={categoryId || ""}
              setCategoryDetails={setCategoryDetails}
              refetchItems={refetchItems}
              setOpenCreateCategoryForm={setOpenCreateCategoryForm}
              setOpenCreateItemForm={setOpenCreateItemForm}
            />
          </>
        )}

        {!categoryId && (
          <CategoryTagSection
            buttonOnly={true}
            categoryDetails={categoryDetails}
            categoryId={categoryId || ""}
            setCategoryDetails={setCategoryDetails}
            refetchItems={refetchItems}
            setOpenCreateCategoryForm={setOpenCreateCategoryForm}
            setOpenCreateItemForm={setOpenCreateItemForm}
          />
        )}

        {subCategories.length > 0 && (
          <>
            {/* Categories Section */}
            {categoryId && <Divider sx={{ mt: 3, mb: 2 }} />}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
              <Typography variant="h5" sx={{ color: "text.secondary", fontWeight: 500 }}>
                {categoryId ? "Sub Categories" : "All Categories"} ({subCategories.length})
              </Typography>
              <SubCategorySorter
                categories={subCategories}
                onSortChange={(sortedCategories) => setDisplayedSubCategories(sortedCategories)}
                activeSortId={activeSortId}
                onSortOptionChange={(sortId) => setActiveSortId(sortId)}
              />
            </Box>
            <Grid container spacing={2}>
              {displayedSubCategories.map((category) => (
                <CategoryCard
                  category={category}
                  onClick={handleCategoryClick}
                  onDelete={(category) => console.log("Delete item:", category)}
                />
              ))}
            </Grid>
          </>
        )}

        {/* Items Section */}

        {categoryId && (
          <>
            <Divider sx={{ mt: subCategories.length > 0 ? 5 : 2, mb: 2 }} />
            <Box sx={{ display: "flex", position: "relative", alignItems: "flex-start" }}>
              <FilterSection
                filterCounts={filterCounts}
                filters={filters}
                updateFilters={updateFilters}
                onFilterChange={handleFilterChange}
                isLoading={isLoading}
                handleBack={handleBack}
                handleForward={handleForward}
                backStack={backStack}
                forwardStack={forwardStack}
                filtersVersionHistory={filtersVersionHistory}
                handleSaveFilter={handleSaveFilter}
                savedFilters={savedFilters}
              />

              <ItemSection
                items={filteredItems}
                isLoading={isLoading}
                groupMax={groupMax}
                setOpenUpdateItemForm={setOpenUpdateItemForm}
                refetchItems={handlePageChange}
                total={totalItems}
                totalQuantity={totalQuantity}
                page={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
            </Box>
          </>
        )}

        {/* Dialogs */}
        {openCreateCategoryForm && (
          <CreateCategory
            open={openCreateCategoryForm}
            onClose={() => setOpenCreateCategoryForm(false)}
            setRefresh={() => fetchData(categoryId)}
            parentCategory={categoryDetails}
          />
        )}

        {openCreateItemForm && (
          <CreateItems
            open={openCreateItemForm}
            preselectedCategory={categoryDetails}
            onClose={() => setOpenCreateItemForm(false)}
            setRefresh={() => fetchData(categoryId, currentPage)}
          />
        )}

        {openUpdateItemForm && (
          <UpdateItems
            itemId={openUpdateItemForm}
            open={Boolean(openUpdateItemForm)}
            path={path}
            groupMax={groupMax}
            onClose={() => setOpenUpdateItemForm(null)}
            refresh={() => fetchData(categoryId, currentPage)}
          />
        )}

        {openEditGroupForm && (
          <EditGroupForm
            open={Boolean(openEditGroupForm)}
            path={path}
            onClose={() => setOpenEditGroupForm(null)}
            categoryId={categoryId || ""}
            groupName={openEditGroupForm}
            refresh={() => fetchData(categoryId, currentPage)}
          />
        )}
      </Box>
    </>
  );
}
