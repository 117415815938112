import React, { useEffect, useRef } from "react";
import { Menu, MenuItem, Typography, Stack, Avatar, Box, Divider } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getS3ImageUrl } from "../../../../utils/s3Image";

interface PackSelectionMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  ripPacks: any[];
  selectedRipPack: any;
  onPackSelect: (packId: string, pack: any) => void;
}

export default function PackSelectionMenu({
  anchorEl,
  open,
  onClose,
  ripPacks,
  selectedRipPack,
  onPackSelect,
}: PackSelectionMenuProps) {
  const selectedItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (open && selectedRipPack && selectedItemRef.current) {
      setTimeout(() => {
        selectedItemRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  }, [open, selectedRipPack]);

  const renderMenuItems = () => {
    if (!ripPacks || ripPacks.length === 0) {
      return (
        <MenuItem disabled>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ width: "100%" }}>
            <Avatar
              sx={{
                bgcolor: "action.disabledBackground",
                width: 40,
                height: 40,
              }}
            >
              <InventoryIcon color="disabled" />
            </Avatar>
            <Typography variant="body2" color="text.secondary">
              No packs available
            </Typography>
          </Stack>
        </MenuItem>
      );
    }

    return ripPacks.map((pack) => (
      <MenuItem
        ref={selectedRipPack?.ripPackId === pack?.ripPackId ? selectedItemRef : null}
        key={`pack-${pack?.ripPackId}`}
        onClick={() => onPackSelect(pack?.ripPackId, pack)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          py: 1.5,
          position: "relative",
          bgcolor: (theme) =>
            selectedRipPack?.ripPackId === pack?.ripPackId ? `${theme.palette.primary.main}30` : "transparent",
          "&:hover": {
            "& .pack-avatar": {
              transform: "scale(1.05)",
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
            },
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Avatar
            className="pack-avatar"
            alt={pack.details?.name}
            src={
              getS3ImageUrl({
                imageUrl: pack?.details?.packTypeId?.imageUrl,
                type: "pack-type",
              }) || ""
            }
            sx={{
              bgcolor: (theme) => theme.palette.primary.main,
              width: 35,
              height: 35,
              transition: "all 0.2s ease",
            }}
          />
          {selectedRipPack?.ripPackId === pack?.ripPackId && (
            <CheckCircleIcon
              sx={{
                position: "absolute",
                bottom: -4,
                right: -4,
                color: (theme) => theme.palette.primary.main,
                bgcolor: "background.paper",
                borderRadius: "50%",
                fontSize: 16,
              }}
            />
          )}
        </Box>

        <Stack spacing={0.2} sx={{ minWidth: 0, boxSizing: "border-box" }} divider={<div style={{ width: "100%" }} />}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {pack.details?.name || "Unnamed Pack"}
            {" (" + (pack.details?.packTypeId?.name || "No pack type") + ")"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {pack.details?.dropDetails?.name || "No drop details"}
          </Typography>
        </Stack>
      </MenuItem>
    ));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 3,
        sx: {
          maxHeight: 300,
          width: "280px",
          mt: 0.5,
          "& .MuiList-root": {
            py: 1,
          },
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => `0 4px 20px 0 ${theme.palette.mode === "dark" ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.1)"}`,
          "& .MuiMenuItem-root": {
            px: 2,
            py: 1,
            my: 0.5,
            mx: 1,
            borderRadius: 1,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.action.hover,
            },
            transition: "background-color 0.2s ease",
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      transitionDuration={200}
      keepMounted
    >
      {renderMenuItems()}
    </Menu>
  );
}
