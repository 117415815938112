import BaseAPI from "./BaseApi";

class DropApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/drop", cache: false });
  }
  async getPaginatedAllDrops({
    dropTime,
    type,
    name,
    limit,
    page,
    project,
    search,
    sortKey,
    sortDirection,
    total = false,
  }: {
    dropTime?: Date;
    type?: string;
    name?: string;
    limit?: number;
    page?: number;
    project?: any;
    search?: string;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getAllDrops", {
        params: { dropTime, type, name, limit, page, project, search, sortKey, sortDirection, total },
      });

      if (!result.data || !result.data?.success || !result.data?.dropsData)
        throw new Error("Invalid response from server");

      return result.data.dropsData;
    } catch (error: any) {
      throw new Error("Error in dropApi.getPaginatedAllDrops: " + error.message);
    }
  }

  async insertDrop(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/createDrop", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!result.data || !result.data?.success || !result.data?.drop) throw new Error("Invalid response from server");
      return result.data.drop;
    } catch (error: any) {
      throw new Error("Error in dropApi.insertDrop: " + error.message);
    }
  }
}

export const dropApi = new DropApi();
