import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  Divider,
  Grid,
  Paper,
  CircularProgress,
  Button,
  Tooltip,
  Checkbox,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import { Category, CategoryTag } from "../../types/common";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { categoryApi } from "../../apis/CategoryApi";
import { openSnackbar } from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CustomDialog from "../common/CustomDialog";

// create compnent for tag values
function CategoryTagValue({
  tagObject,
  value,
  valueIndex,
}: {
  tagObject: CategoryTag;
  value: string;
  valueIndex: number;
}) {
  return (
    <Box
      key={valueIndex}
      sx={{
        position: "relative",
        px: 1,
        py: 0.25,
        borderRadius: 1,

        bgcolor: tagObject.required ? "secondary.main" : "primary.main",

        color: "white",
        fontSize: "0.65rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {value}
    </Box>
  );
}

function EditTagName({
  categoryId,
  tagObject,
  updateTagsInCategoryObject,
}: {
  categoryId: string;
  tagObject: CategoryTag;
  updateTagsInCategoryObject: (updatedCategory: Category | null) => void;
}) {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [tagName, setTagName] = useState(tagObject.tag);
  const [tagRequired, setTagRequired] = useState(tagObject.required);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTagName(tagObject.tag);
    setTagRequired(tagObject.required);
  }, [tagObject.tag, tagObject.required]);

  const handleSubmit = () => {
    setIsLoading(true);
    categoryApi
      .editTagName({
        categoryId,
        oldTagName: tagObject.tag,
        newTagName: tagName,
        required: tagRequired,
      })
      .then((updatedCategory) => {
        updateTagsInCategoryObject(updatedCategory);
        setIsEditing(false);
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: `Failed to edit tag name for category ${categoryId} from ${tagObject.tag} to ${tagName}`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    categoryApi
      .deleteTag({
        categoryId,
        tagName: tagObject.tag,
      })
      .then((updatedCategory) => {
        updateTagsInCategoryObject(updatedCategory);
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: `Failed to delete tag ${tagObject.tag} for category ${categoryId}`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isEditing ? (
        <TextField
          fullWidth
          label="Tag Name"
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          sx={{ mt: 2, mb: 2, borderRadius: 1 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                <Checkbox checked={tagRequired} onChange={(e: any) => setTagRequired(e.target.checked)} />
                <IconButton onClick={() => handleDelete()} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
                <IconButton onClick={handleSubmit} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <SaveIcon />}
                </IconButton>
                <IconButton onClick={() => setIsEditing(false)} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <CloseIcon />}
                </IconButton>
              </>
            ),
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "1rem",
            justifyContent: "space-between",
          }}
        >
          <span>{tagObject.tag}</span>
          {tagObject.required && (
            <Tooltip title="If required, items must have this tag" arrow>
              <Typography variant="body2" sx={{ color: "grey.600" }}>
                Required
              </Typography>
            </Tooltip>
          )}
          <IconButton onClick={() => setIsEditing(true)} sx={{ ml: 1 }}>
            <EditIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}

function EditTagValue({
  categoryId,
  valueIndex,
  tagObject,
  updateTagsInCategoryObject,
}: {
  categoryId: string;
  valueIndex: number;
  tagObject: CategoryTag;
  updateTagsInCategoryObject: (updatedCategory: Category | null) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tagValue, setTagValue] = useState(tagObject.values[valueIndex]);
  const dispatch = useDispatch();
  useEffect(() => {
    setTagValue(tagObject.values[valueIndex]);
  }, [tagObject.values, valueIndex]);

  const handleSubmit = () => {
    setIsLoading(true);
    categoryApi
      .editTagValue({
        categoryId,
        tagName: tagObject.tag,
        oldValue: tagObject.values[valueIndex],
        newValue: tagValue,
      })
      .then((updatedCategory) => {
        updateTagsInCategoryObject(updatedCategory);
        setIsEditing(false);
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: `Failed to edit tag value for category ${categoryId} from ${tagObject.values[valueIndex]} to ${tagValue}`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    categoryApi
      .deleteTagValue({
        categoryId,
        tagName: tagObject.tag,
        value: tagObject.values[valueIndex],
      })
      .then((updatedCategory) => {
        updateTagsInCategoryObject(updatedCategory);
        setIsEditing(false);
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: `Failed to delete tag value ${tagObject.values[valueIndex]} for category ${categoryId}`,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isEditing ? (
        <TextField
          fullWidth
          key={valueIndex}
          label={`Value ${valueIndex + 1}`}
          value={tagValue}
          onChange={(e) => setTagValue(e.target.value)}
          sx={{ mb: 2, borderRadius: 1 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                <IconButton onClick={() => handleDelete()} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
                <IconButton onClick={handleSubmit} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <SaveIcon />}
                </IconButton>
                <IconButton onClick={() => setIsEditing(false)} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <CloseIcon />}
                </IconButton>
              </>
            ),
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "0.9rem",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{tagObject.values[valueIndex]}</span>
          <IconButton onClick={() => setIsEditing(true)} sx={{ ml: 1 }}>
            <EditIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}

function EditTagDialog({
  categoryId,
  openEditTagDialog,
  setOpenEditTagDialog,
  tagObject,
  updateTagsInCategoryObject,
}: {
  categoryId: string;
  openEditTagDialog: boolean;
  setOpenEditTagDialog: (value: boolean) => void;
  tagObject: CategoryTag;
  updateTagsInCategoryObject: (updatedCategory: Category | null) => void;
}) {
  const dispatch = useDispatch();

  const [newTagValue, setNewTagValue] = useState("");

  const handleAddTagValue = () => {
    categoryApi
      .addTagValue({ categoryId, tagName: tagObject.tag, value: newTagValue })
      .then((updatedCategory) => {
        updateTagsInCategoryObject(updatedCategory);
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: `Failed to add tag value ${newTagValue} for tag ${tagObject.tag} in category ${categoryId}`,
            severity: "error",
          })
        );
      });
  };

  return (
    <CustomDialog
      open={openEditTagDialog}
      onClose={() => setOpenEditTagDialog(false)}
      text="Edit Tag"
      subText="Change details of tag"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold", textAlign: "left", color: "primary.main" }}>
            Tag Name
          </Typography>
          <Paper elevation={3} sx={{ padding: 2, bgcolor: "white", borderRadius: 1, boxShadow: 3 }}>
            <EditTagName
              categoryId={categoryId}
              tagObject={tagObject}
              updateTagsInCategoryObject={updateTagsInCategoryObject}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        {
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold", textAlign: "left", color: "primary.main" }}>
              Tag Values
            </Typography>
            <Stack spacing={2}>
              {tagObject.values &&
                tagObject.values.length > 0 &&
                tagObject.values.map((value: string, valueIndex: number) => (
                  <Paper
                    key={valueIndex}
                    elevation={2}
                    sx={{ padding: 2, bgcolor: "white", borderRadius: 1, boxShadow: 2 }}
                  >
                    <EditTagValue
                      categoryId={categoryId}
                      valueIndex={valueIndex}
                      tagObject={tagObject}
                      updateTagsInCategoryObject={updateTagsInCategoryObject}
                    />
                  </Paper>
                ))}
              <TextField
                fullWidth
                label="New Tag Value"
                value={newTagValue}
                onChange={(e) => setNewTagValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && newTagValue) {
                    handleAddTagValue();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleAddTagValue} disabled={!newTagValue}>
                      <AddIcon />
                    </IconButton>
                  ),
                }}
                sx={{ mb: 2, borderRadius: 1 }}
              />
            </Stack>
          </Grid>
        }
      </Grid>
    </CustomDialog>
  );
}

export default function CategoryTagNameValues({
  categoryId,
  tagObject,
  updateTagsInCategoryObject,
}: {
  categoryId: string;
  tagObject: CategoryTag;
  updateTagsInCategoryObject: (updatedCategory: Category | null) => void;
}) {
  const [openEditTagDialog, setOpenEditTagDialog] = useState(false);

  return (
    <Box
      sx={{
        px: 1,
        py: 0.25,
        borderRadius: 1,
        gap: 0.5,
        fontSize: "0.75rem",
        fontWeight: 500,
        bgcolor: tagObject.required ? "primary.main" : "secondary.main",
        color: "white",
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {tagObject?.tag || "No Tags"}
      {tagObject.values && tagObject.values.length > 0 && (
        <Stack direction="row" spacing={0.5} sx={{ ml: 1 }}>
          {tagObject.values.map((value: string, valueIndex: number) => (
            <CategoryTagValue tagObject={tagObject} value={value} valueIndex={valueIndex} key={valueIndex} />
          ))}
        </Stack>
      )}

      <Box sx={{ display: "flex" }}>
        <IconButton sx={{ ml: 0.5, p: 0.5 }} onClick={() => setOpenEditTagDialog(true)}>
          <EditIcon sx={{ color: "white", fontSize: "1rem" }} />
        </IconButton>
      </Box>

      {/* Floating Elements */}
      <EditTagDialog
        categoryId={categoryId}
        openEditTagDialog={openEditTagDialog}
        setOpenEditTagDialog={setOpenEditTagDialog}
        tagObject={tagObject}
        updateTagsInCategoryObject={updateTagsInCategoryObject}
      />
    </Box>
  );
}
