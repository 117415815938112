import BaseAPI from "./BaseApi";

class ScratchCardApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/scratchCard", cache: false });
  }

  // Create scratch card
  async createScratchCards({
    eventId,
    rewardId,
    rewardChoices,
    amount,
    type,
    usageLimit,
  }: {
    eventId: string;
    rewardId: string;
    rewardChoices: any[];
    amount: number;
    type: string;
    usageLimit: number;
  }): Promise<any> {
    try {
      const result = await this.axios.post("/createMany", {
        eventId,
        rewardId,
        rewardChoices,
        amount,
        type,
        usageLimit,
      });
      if (!result.data || !result.data?.success || !result.data?.scratchCardDetails)
        throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in ScratchCardApi.createScratchCard: " + error.message);
    }
  }

  // Get paginated scratch cards
  async getPaginatedScratchCards({
    code,
    eventId,
    ownerUserId,
    rewardIds,
    status,
    type,
    fromTimestamp,
    toTimestamp,
    search,
    limit,
    page,
    sortKey,
    sortDirection,
    total = false,
    populate,
  }: {
    code?: string;
    eventId?: number;
    ownerUserId?: string;
    rewardIds?: string[];
    status?: string;
    type?: string;
    fromTimestamp?: string;
    toTimestamp?: string;
    search?: string;
    limit?: number;
    page?: number;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
    populate?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getScratchCards", {
        params: {
          code,
          eventId,
          ownerUserId,
          rewardIds,
          status,
          type,
          fromTimestamp,
          toTimestamp,
          search,
          limit,
          page,
          sortKey,
          sortDirection,
          total,
          populate,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.scratchCardsData)
        throw new Error("Invalid response from server");
      return result.data.scratchCardsData;
    } catch (error: any) {
      throw new Error("Error in ScratchCardApi.getPaginatedScratchCards: " + error.message);
    }
  }

  async getScratchCardByClaimTime({
    fromTimestamp,
    toTimestamp,
    limit,
    status,
    page,
    sortKey,
    sortDirection,
    total = false,
  }: {
    fromTimestamp?: string;
    toTimestamp?: string;
    limit?: number;
    status?: string;
    page?: number;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/getScratchCardByStatus", {
        params: {
          fromTimestamp,
          toTimestamp,
          limit,
          status,
          page,
          sortKey,
          sortDirection,
          total,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.scratchCardsData)
        throw new Error("Invalid response from server");
      return result.data.scratchCardsData;
    } catch (error: any) {
      throw new Error("Error in ScratchCardApi.getScratchCardByClaimTime: " + error.message);
    }
  }
}

export const scratchCardApi = new ScratchCardApi();
