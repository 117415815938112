import React from "react";
import { useRef, useState } from "react";
import Menu from "./Menu";
import { AppBar, Avatar, Box, Button, IconButton, Popover, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountBox, Logout, Settings } from "@mui/icons-material";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useDispatch } from "react-redux";
import { resetAdminInfo } from "../../redux/slices/adminUser";
import { unsetAuthCookie } from "../../utils/auth";
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn";
import { useNavigate } from "react-router-dom";
import { useAuthBasedRedirect } from "../../hooks/useAuthBasedRedirect";

export const HEADER_HEIGHT = {
  xs: "3.2rem",
  sm: "4.5rem",
};

export default function Header({
  authBasedRedirect = true,
  headerComponent,
}: {
  authBasedRedirect?: boolean;
  headerComponent?: React.ReactNode;
}) {
  useAuthBasedRedirect({ authBasedRedirect });

  const isMobile = useIsMobile();
  const isLoggedIn = useIsLoggedIn();
  const [showMenu, setShowMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const moreButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logoutUser() {
    dispatch(resetAdminInfo());
    unsetAuthCookie();
  }

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ background: "linear-gradient(45deg, #f58b0e 30%, primary.light 90%)", zIndex: 100 }}
      >
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center">
              {isLoggedIn && (
                <Button onClick={() => setShowMenu(!showMenu)} color="secondary">
                  <MenuIcon />
                </Button>
              )}
              {!isMobile && (
                <>
                  {headerComponent || (
                    <Typography variant="h6" fontFamily={"Monospace"} fontWeight="Bold" marginLeft={1}>
                      SHOCKEM-MARKET-ADMIN
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {isLoggedIn && (
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => setShowMore(true)}>
                  <Avatar />
                </IconButton>
                <IconButton onClick={() => setShowMore(true)} ref={moreButtonRef}>
                  <MoreVertIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {isLoggedIn && (
        <React.Fragment>
          <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
          <Popover
            open={showMore}
            onClose={() => setShowMore(false)}
            anchorEl={moreButtonRef.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{ marginTop: "0.5rem" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Box display="flex" flexDirection="column" alignItems="left" gap="0.5rem" p="0.5rem">
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<AccountBox />}
                onClick={() => navigate("/admin-profile")}
              >
                Profile
              </Button>
              <Button
                onClick={() => {
                  logoutUser();
                }}
                variant="outlined"
                startIcon={<Logout />}
              >
                Logout
              </Button>
            </Box>
          </Popover>
        </React.Fragment>
      )}
    </Box>
  );
}
