import { Tabs, Tab, TextField, InputAdornment, IconButton, Tooltip, MenuItem, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

interface EventToolBarProps {
  tab: string;
  total: number;
  searchQuery: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
  setCreateEvent: () => void;
}

export default function EventToolBar({
  tab,
  total,
  searchQuery,
  handleTabChange,
  setSearchQuery,
  onRefresh,
  setCreateEvent,
}: EventToolBarProps) {
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", my: 2 }}>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="user status tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ "& .MuiTab-root": { textTransform: "none" } }} // Prevent text from being capitalized
        >
          <Tab label={tab === "all" ? `All Digital Events (${total})` : `All Digital Events`} value="all" />
        </Tabs>
      </Grid>

      <Grid size={{ xs: 12, sm: 8 }} container spacing={2} justifyContent="flex-end">
        <Grid size={{ xs: tab === "all" ? 6 : 12, sm: 4 }}>
          <TextField
            variant="outlined"
            placeholder={total ? "Search events by name or description..." : "No events to search"}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>

        <Grid size={{ xs: 4, sm: 2 }} container justifyContent="flex-end">
          <Button variant="contained" onClick={setCreateEvent} color="secondary" sx={{ borderRadius: 2, fontSize: 12 }}>
            Add Event
          </Button>
        </Grid>

        <Grid size={{ xs: 2, sm: 1 }} container justifyContent="flex-end">
          <Tooltip title="Refresh">
            <IconButton
              onClick={onRefresh}
              aria-label="refresh"
              sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
