import BaseAPI from "./BaseApi";

class AdminFilterVersionHistoryApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/admin-filter-version-history", cache: false });
  }

  async getFilterVersionHistory({ userId, categoryId }: { userId: string; categoryId: string }) {
    try {
      if (!userId || !categoryId) {
        return [];
      }

      const result = await this.axios.get(`/`, { params: { userId, categoryId } });
      if (!result.data || !result.data?.success || !result.data?.adminFiltersVersionHistory)
        throw new Error("Invalid response from server");
      return result.data.adminFiltersVersionHistory;
    } catch (error: any) {
      throw new Error("Error in AdminFilterVersionHistoryApi.getFilterVersionHistory: " + error.message);
    }
  }

  async updateFilterVersionHistory({
    userId,
    categoryId,
    filterObjects,
  }: {
    userId: string;
    categoryId: string;
    filterObjects: any;
  }) {
    try {
      const result = await this.axios.post(`/`, { userId, categoryId, filterObjects });
      if (!result.data || !result.data?.success || !result.data?.filterObjects)
        throw new Error("Invalid response from server");
      return result.data.filterObjects;
    } catch (error: any) {
      throw new Error("Error in AdminFilterVersionHistoryApi.updateFilterVersionHistory: " + error.message);
    }
  }
}

const adminFilterVersionHistoryApi = new AdminFilterVersionHistoryApi();

export default adminFilterVersionHistoryApi;
