import { retryOperation } from "../utils/chunkUtils";
import BaseAPI from "./BaseApi";
import { AxiosProgressEvent } from "axios";

class AssetApi extends BaseAPI {
  constructor() {
    super({ baseURL: process.env.REACT_APP_SERVER_URL, cache: false, generalPath: "/assets" });
  }

  async uploadChunk(formData: FormData, onProgress?: (progress: number) => void): Promise<any> {
    return retryOperation(async () => {
      try {
        const response = await this.axios.post(`/upload/chunk`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total && onProgress) {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              onProgress(progress);
            }
          },
        });
        return response.data;
      } catch (error: any) {
        console.error("Chunk upload error details:", {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          headers: error.response?.headers,
        });
        throw error;
      }
    });
  }

  async getAllAssets() {
    try {
      const response = await this.axios.get("/");
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Failed to fetch assets");
    }
  }

  async deleteAsset(assetId: string) {
    try {
      const response = await this.axios.delete(`/${assetId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Failed to delete asset");
    }
  }
}

const assetApi = new AssetApi();
export default assetApi;
