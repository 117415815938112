import BaseAPI from "./BaseApi";

class BoxApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/box", cache: false });
  }

  async createBox(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!result.data || !result.data?.success || !result.data?.box) throw new Error("Invalid response from server");
      return result.data.box;
    } catch (error: any) {
      throw new Error("Error in BoxApi.createBox: " + error.message);
    }
  }

  async getPaginatedBoxes({
    limit,
    page,
    project,
    sortKey,
    sortDirection,
    total = false,
  }: {
    limit?: number;
    page?: number;
    project?: any;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
  }) {
    try {
      const result = await this.axios.get("/", {
        params: { limit, page, project, sortKey, sortDirection, total },
      });
      if (!result.data || !result.data.boxes) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in BoxApi.getPaginatedBoxes: " + error.message);
    }
  }

  async getItemDistribution({ boxId }: { boxId: string }): Promise<any> {
    try {
      if (!boxId) {
        return {};
      }

      const result = await this.axios.get(`/${boxId}/itemDistribution`);
      if (!result.data || !result.data?.success || !result.data?.itemDistribution)
        throw new Error("Invalid response from server");
      return result.data.itemDistribution;
    } catch (error: any) {
      throw new Error("Error in RipPackApi.getItemDistribution: " + error.message);
    }
  }
}

export const boxApi = new BoxApi();
