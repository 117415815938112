import BaseAPI from "./BaseApi";

class SubscriptionApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/subscription", cache: false });
  }

  async getSubscriptionDetails({
    subscriptionIds,
    fromTimestamp,
    toTimestamp,
    limit,
    page,
    project,
    sortKey,
    sortDirection,
    total = false,
    search,
  }: {
    subscriptionIds?: string[];
    fromTimestamp?: string;
    toTimestamp?: string;
    limit?: number;
    page?: number;
    project?: any;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
    search?: string;
  }): Promise<{
    subscriptions: Array<{ subscriptionId: string; details: any; stats: { count: number; events: any } }>;
    total: number;
  }> {
    try {
      const result = await this.axios.get("/", {
        params: {
          subscriptionIds,
          fromTimestamp,
          toTimestamp,
          limit,
          page,
          project,
          sortKey,
          sortDirection,
          total,
          search,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.subscriptionsData)
        throw new Error("Invalid response from server");
      return result.data.subscriptionsData;
    } catch (error: any) {
      throw new Error("Error in SubscriptionApi.getSubscriptionDetails: " + error.message);
    }
  }

  async createSubscription(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!result.data || !result.data?.success || !result.data?.subscription)
        throw new Error("Invalid response from server");
      return result.data.subscription;
    } catch (error: any) {
      throw new Error("Error in SubscriptionApi.createSubscription: " + error.message);
    }
  }

  async updateSubscription(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.put("/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!result.data || !result.data?.success || !result.data?.subscription)
        throw new Error("Invalid response from server");
      return result.data.subscription;
    } catch (error: any) {
      throw new Error("Error in SubscriptionApi.updateSubscription: " + error.message);
    }
  }
}

export const subscriptionApi = new SubscriptionApi();
