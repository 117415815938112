import {
  Box,
  Checkbox,
  Divider,
  Paper,
  Typography,
  TextField,
  IconButton,
  Chip,
  Dialog,
  InputAdornment,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Redo, Undo, History, Search, Close } from "@mui/icons-material";
import { HEADER_HEIGHT } from "../common/Header";
import { formatString } from "../../utils/string";
import { useState, useEffect } from "react";
import { formatDate, formatTime } from "../../utils/time";

interface FilterState {
  search: string;
  groups: string[];
  nonSerialisedItems: string[];
  stages: string[];
  visibility: string;
  tagValues: string[];
}

interface FilterCount {
  value: string;
  count: number;
}

interface FilterCounts {
  groupNames: FilterCount[];
  visibilities: FilterCount[];
  tagValues: FilterCount[];
  stages: FilterCount[];
  nonSerialisedItems: FilterCount[];
}

interface FilterSectionProps {
  filterCounts: FilterCounts;
  filters: FilterState;
  updateFilters: any;
  onFilterChange: (type: keyof FilterState, value: string) => void;
  isLoading?: boolean;
  handleBack: () => void;
  handleForward: () => void;
  backStack: FilterState[];
  forwardStack: FilterState[];
  filtersVersionHistory: any[];
  handleSaveFilter: (filterName: string) => void;
  savedFilters: any[];
}

export default function FilterSection({
  filterCounts,
  filters,
  updateFilters,
  onFilterChange,
  isLoading = false,
  handleBack,
  handleForward,
  backStack,
  forwardStack,
  filtersVersionHistory,
  handleSaveFilter,
  savedFilters,
}: FilterSectionProps) {
  const navigate = useNavigate();

  const [search, setSearch] = useState(filters.search);
  const [isVersionHistoryOpen, setIsVersionHistoryOpen] = useState(false);
  const [filterName, setFilterName] = useState("");

  return (
    <Stack
      component={Paper}
      sx={{
        p: 2,
        width: "20%",
        boxShadow: 2,
        position: "sticky",
        top: HEADER_HEIGHT,
        height: "90vh",
        overflowY: "auto",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ color: "text.secondary" }}>
          Filter
        </Typography>
        <Stack direction="row">
          <IconButton size="small" onClick={handleBack} disabled={backStack.length === 0}>
            {"(" + backStack.length + ")"}
            <Undo />
          </IconButton>
          <IconButton size="small" onClick={handleForward} disabled={forwardStack.length === 0}>
            <Redo />
            {"(" + forwardStack.length + ")"}
          </IconButton>

          <Tooltip title="Version History of this filter">
            <IconButton size="small" onClick={() => setIsVersionHistoryOpen(true)}>
              <History />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      {isVersionHistoryOpen && (
        <VersionHistoryDialog
          open={isVersionHistoryOpen}
          onClose={() => setIsVersionHistoryOpen(false)}
          backStack={backStack}
          forwardStack={forwardStack}
          filtersVersionHistory={filtersVersionHistory}
          updateFilters={updateFilters}
        />
      )}

      {savedFilters.length > 0 && (
        <>
          <TextField
            select
            label="Saved Filters"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            size="small"
            sx={{ mb: 2 }}
          >
            {savedFilters.map((savedFilter) => (
              <MenuItem
                key={savedFilter._id}
                value={savedFilter.name}
                onClick={() => updateFilters(savedFilter.filterObject)}
              >
                {savedFilter.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
      <TextField
        size="small"
        placeholder="Filter name..."
        value={filterName}
        onChange={(e) => setFilterName(e.target.value)}
      />
      <Button
        onClick={() => {
          handleSaveFilter(filterName);
        }}
        variant="contained"
        color="primary"
        disabled={!filterName}
      >
        Save Filter
      </Button>
      <Divider sx={{ my: 2 }} />

      <TextField
        size="small"
        placeholder="Search items..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onFilterChange("search", search);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => onFilterChange("search", search)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Stack direction="row" spacing={1} sx={{ mt: "0.5rem", mb: "0.5rem" }}>
        {filters.search && (
          <Chip key="search" label={`Search: ${filters.search}`} onDelete={() => onFilterChange("search", "")} />
        )}
      </Stack>

      <Divider sx={{ my: 2 }} />

      <FilterGroup
        title="Serialized"
        filters={filterCounts.groupNames.filter((f) => f.value)}
        selectedValues={filters.groups}
        onToggle={(value) => onFilterChange("groups", value)}
      />

      <FilterGroup
        title="Non-Serialized Unique"
        filters={filterCounts.nonSerialisedItems}
        selectedValues={filters.nonSerialisedItems}
        onToggle={(value) => onFilterChange("nonSerialisedItems", value)}
        formatLabel={formatString}
      />

      <FilterGroup
        title="Item Stages"
        filters={filterCounts.stages}
        selectedValues={filters.stages}
        onToggle={(value) => onFilterChange("stages", value)}
        formatLabel={formatString}
      />

      <FilterGroup
        title="Visibility"
        filters={filterCounts.visibilities}
        selectedValues={[filters.visibility]}
        onToggle={(value) => onFilterChange("visibility", value)}
      />

      <FilterGroup
        title={`Tags (${filterCounts.tagValues.length})`}
        filters={filterCounts.tagValues}
        selectedValues={filters.tagValues}
        onToggle={(value) => onFilterChange("tagValues", value)}
        emptyLabel="(empty)"
      />
    </Stack>
  );
}

const VersionHistoryDialog = ({
  open,
  onClose,
  backStack,
  forwardStack,
  filtersVersionHistory,
  updateFilters,
}: {
  open: boolean;
  onClose: () => void;
  backStack: FilterState[];
  forwardStack: FilterState[];
  filtersVersionHistory: FilterState[];
  updateFilters: (filters: FilterState) => void;
}) => {
  const dateGroups: Record<string, any[]> = filtersVersionHistory.reduce((acc: any, filter: any) => {
    const date = formatDate(filter.createdAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(filter);
    return acc;
  }, {});

  const filterObjToShow = (filter: any) => {
    const cleanFilterObject = Object.fromEntries(
      Object.entries(filter.filterObject).filter(
        ([_, value]) => value !== null && value !== "" && (value as any[]).length > 0
      )
    );

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: Object.entries(cleanFilterObject)
            .map(([key, value]) => `<strong>${key}:</strong> ${Array.isArray(value) ? value.join(", ") : value}`)
            .join("<br />"),
        }}
      />
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Version History
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1}>
          {Object.entries(dateGroups).map(([date, dateGroup]) => (
            <>
              <Typography sx={{ mt: 2, fontWeight: 600, fontSize: "1rem" }}>
                {dateGroup[0].createdAt && formatDate(dateGroup[0].createdAt)}
              </Typography>

              {dateGroup.map((filter: any, index: number) => (
                <>
                  <Box key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ flex: 1 }}>
                      {formatDate(filter.createdAt)} {formatTime(filter.createdAt)}
                    </Typography>
                    <Typography sx={{ flex: 2 }}>{filterObjToShow(filter)}</Typography>
                    <Button
                      onClick={() => {
                        updateFilters(filter?.filterObject);
                        onClose();
                      }}
                    >
                      Select
                    </Button>
                  </Box>
                  <Divider />
                </>
              ))}
            </>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const FilterGroup = ({
  title,
  filters,
  selectedValues,
  onToggle,
  formatLabel = (v: string) => v,
  emptyLabel,
  isSingleSelect,
}: {
  title: string;
  filters: { value: string; count: number }[];
  selectedValues: string[];
  onToggle: (value: string) => void;
  formatLabel?: (value: string) => string;
  emptyLabel?: string;
  isSingleSelect?: boolean;
}) => (
  <>
    <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
      {title}
    </Typography>
    {filters
      ?.sort((a, b) => a.value?.localeCompare(b.value))
      .map(({ value, count }) => {
        const isChecked = selectedValues.includes(value);
        return (
          <Box key={value} sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={isChecked}
              onChange={() => onToggle(value)}
              size="small"
              {...(isSingleSelect && { type: "radio" })}
            />
            <Typography>
              {value ? formatLabel(value) : emptyLabel} ({count})
            </Typography>
          </Box>
        );
      })}
    <Divider sx={{ my: 2 }} />
  </>
);
