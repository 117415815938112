import { Tabs, Tab, TextField, InputAdornment, IconButton, Tooltip, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import AddPackItems from "../forms/AddPackItems";

interface BoxItemListToolBarProps {
  tab: string;
  total: number;
  searchQuery: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
}

export default function BoxItemListToolBar({
  tab,
  total,
  searchQuery,
  handleTabChange,
  setSearchQuery,
  onRefresh,
}: BoxItemListToolBarProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", my: 2 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="boxItem status tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ "& .MuiTab-root": { textTransform: "none" } }}
          >
            <Tab label={`All BoxItems (${total})`} value="all" />
          </Tabs>
        </Grid>

        <Grid size={{ xs: 12, sm: 8 }} container spacing={2} justifyContent="flex-end">
          <Grid size={{ xs: 6, sm: 4 }}>
            <TextField
              variant="outlined"
              placeholder="Search Box Items..."
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
              fullWidth
            />
          </Grid>

          <Grid size={{ xs: 2, sm: 1 }} container justifyContent="flex-end">
            <Tooltip title="Refresh">
              <IconButton
                onClick={onRefresh}
                aria-label="refresh"
                sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* UpdateBoxItemQuantity Dialog */}
    </React.Fragment>
  );
}
