import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { adminAuthApi } from "../../apis/AdminAuthApi";
import { useDispatch, useSelector } from "react-redux";
import { validatePassword } from "../../utils/validation";
import { adminApi } from "../../apis/AdminApi";
import { openSnackbar } from "../../redux/slices/snackbar";
import PasswordRequirement from "../common/PasswordRequirement";

interface EditPasswordFormProps {
  onBack: () => void;
  onSavePassword: () => void;
}

export default function EditPasswordForm({ onBack, onSavePassword }: EditPasswordFormProps) {
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordRequirements = validatePassword(newPassword, confirmPassword).passwordRequirements;

  const dispatch = useDispatch();

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      if (!validatePassword(newPassword, confirmPassword).isPasswordValid) throw new Error("Invalid password");
      const result: any = await adminAuthApi.verifyCredentials(adminInfo.email, currentPassword);

      if (!result?.adminId) throw new Error("Invalid password");
      const updatedResult = await adminApi.updateAdmin({ id: adminInfo._id, password: newPassword });
      if (!updatedResult) throw new Error("Error in updating password");
      onBack();
      onSavePassword();
      dispatch(openSnackbar({ message: "Password updated successfully", severity: "success" }));
    } catch (err: any) {
      console.error(err);
      dispatch(openSnackbar({ message: "Failed to update password! ", severity: "error" }));
    }
  }

  return (
    <Box>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Current Password"
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Confirm New Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2">Password must include:</Typography>
        <PasswordRequirement label={"At least 8 characters"} valid={passwordRequirements.minLength} />
        <PasswordRequirement label={"At least one special character"} valid={passwordRequirements.hasSpecialChar} />
        <PasswordRequirement
          label={"Both upper and lower case letters"}
          valid={passwordRequirements.hasUpperCase && passwordRequirements.hasLowerCase}
        />
        <PasswordRequirement label={"At least one number"} valid={passwordRequirements.hasNumber} />
        <PasswordRequirement
          label={"Passwords match"}
          valid={Boolean(newPassword === confirmPassword && passwordRequirements.minLength)}
        />
      </Box>
      <Button onClick={handleSubmit} fullWidth variant="contained" sx={{ mt: 3 }}>
        Save Password
      </Button>
    </Box>
  );
}
