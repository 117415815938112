import defaultUserPic from "../assets/defaultUserPic.png";

export function getImageUrlFromProfileImageField(userIdWithVersion: string) {
  const s3Path = `users/profile-images/${userIdWithVersion}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;
  if (!userIdWithVersion || !s3Path || !cloudfrontPrefix) {
    console.error("@getImageUrlFromUserId: Invalid s3Path or cloudfrontPrefix", { s3Path, cloudfrontPrefix });
    return defaultUserPic;
  }
  return `${cloudfrontPrefix}/${s3Path}`;
}
