import BaseAPI from "./BaseApi";

class EventApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/event", cache: false });
  }

  async getEventDetails({
    eventIds,
    limit,
    page,
    project,
    sortKey,
    search,
    sortDirection,
    total = false,
  }: {
    eventIds?: string[];
    fromTimestamp?: string;
    toTimestamp?: string;
    limit?: number;
    page?: number;
    project?: any;
    sortKey?: string;
    search?: string;
    sortDirection?: string;
    total?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("", {
        params: {
          eventIds,
          limit,
          page,
          project,
          sortKey,
          search,
          sortDirection,
          total,
        },
      });
      if (!result.data || !result.data?.success || !result.data?.eventsData)
        throw new Error("Invalid response from server");
      return result.data.eventsData;
    } catch (error: any) {
      throw new Error("Error in EventApi.getEventDetails: " + error.message);
    }
  }

  async createEvent(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!result.data || !result.data?.success || !result.data?.event) throw new Error("Invalid response from server");
      return result.data.event;
    } catch (error: any) {
      throw new Error("Error in EventApi.createEvent: " + error.message);
    }
  }
}

export const eventApi = new EventApi();
