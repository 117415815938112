import { CurrencyEnum, currencies, Currency } from "../types/currency";

export function getCurrencyOptions(): CurrencyEnum[] {
  return currencies.map((currency) => currency.code);
}

export function getCurrencyProps(code: CurrencyEnum): Currency | undefined {
  return currencies.find((currency) => currency.code === code);
}

export function formatPriceFromObject(obj: any) {
  const { price, currency } = obj;

  if (!price || !currency) {
    return "";
  }

  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    }).format(price);
  } catch (error) {
    console.error("Error formatting price:", error);
    return ``;
  }
}
