import React, { useEffect, useState } from "react";
import { Button, Box, Divider, MenuItem, Typography, TextField, Chip } from "@mui/material";
import CustomDialog from "../common/CustomDialog";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { dropApi } from "../../apis/DropApi";
import FileUploadButton from "../common/FileUploadButton";
import { packTypeApi } from "../../apis/PackTypeApi";
import CreateRipPack from "./CreateRipPack";
import { CurrencyEnum } from "../../types/currency";
import { getS3ImageUrl } from "../../utils/s3Image";

export default function CreateDrop({ open, onClose, setRefresh }: any) {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [dropTime, setDropTime] = useState<string>("");
  const [tag, setTag] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [selectedPackTypes, setSelectedPackTypes] = useState<string[]>([]);
  const [packTypes, setPackTypes] = useState<any[]>([]);
  const [ripPacksData, setRipPacksData] = useState<{
    [key: string]: {
      name: string;
      quantity: number;
      maxQuantity: number;
      price: number;
      currency: CurrencyEnum;
    };
  }>({});

  async function handleCreateDrop() {
    const missingRequiredFields = [];
    if (!name) missingRequiredFields.push("Name");
    if (!dropTime) missingRequiredFields.push("Drop Time");
    if (!image) missingRequiredFields.push("Image");

    if (missingRequiredFields.length > 0) {
      dispatch(
        openSnackbar({
          message: `Please fill in the following required fields: ${missingRequiredFields.join(", ")}`,
          severity: "warning",
        })
      );
      return;
    }

    if (!image || !dropTime || !name) throw new Error("Unexpected error");

    // Validate RipPack data with specific field checks
    for (const packType of selectedPackTypes) {
      const packData = ripPacksData[packType];
      const missingFields = [];

      if (!packData?.name) missingFields.push("name");
      if (!packData?.quantity) missingFields.push("quantity");
      if (!packData?.maxQuantity) missingFields.push("max quantity");
      if (packData?.price === undefined || packData?.price === null) missingFields.push("price");

      if (missingFields.length > 0) {
        dispatch(
          openSnackbar({
            message: `Please fill in the following fields for ${packType}: ${missingFields.join(", ")}`,
            severity: "error",
          })
        );
        return;
      }
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("tags", JSON.stringify(tags));
      formData.append("packType", JSON.stringify(selectedPackTypes));
      formData.append("dropTime", new Date(dropTime).toISOString());
      formData.append("dropImage", image);

      // Add RipPack data
      const ripPacks = selectedPackTypes.map((packType) => {
        const packTypeObj = packTypes.find((pt) => pt.name === packType);
        const ripPackData = ripPacksData[packType];
        return {
          name: ripPackData.name,
          packTypeId: packTypeObj?._id,
          packQuantity: ripPackData.quantity,
          maxQuantityPerPack: ripPackData.maxQuantity,
          price: ripPackData.price,
          currency: ripPackData.currency,
        };
      });

      formData.append("ripPacks", JSON.stringify(ripPacks));

      const result = await dropApi.insertDrop(formData);

      if (!result) throw new Error(`Failed to create drop`);

      dispatch(openSnackbar({ message: "Drop created successfully!", severity: "success" }));
      setRefresh(true);
      onClose();
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Error creating drop!", severity: "error" }));
    }
  }

  const handleCreateTag = () => {
    const newTag = tag.trim();
    if (newTag) {
      setTags((prevTags: any) => [...prevTags, newTag]);
      setTag("");
    }
  };

  function handleDeleteTag(tagToDelete: any) {
    return () => {
      setTags((prevTags: any) => prevTags.filter((tag: any) => tag !== tagToDelete));
    };
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      // Create a preview URL for the image
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  function handlePackDataChange(packType: string, dataOrUpdater: any) {
    setRipPacksData((prev) => {
      const currentPackData = prev[packType] || {
        name: "",
        quantity: 1,
        maxQuantity: 1,
        price: 0,
        currency: CurrencyEnum.USD,
      };

      const newData = typeof dataOrUpdater === "function" ? dataOrUpdater(currentPackData) : dataOrUpdater;

      return {
        ...prev,
        [packType]: {
          ...currentPackData,
          ...newData,
        },
      };
    });
  }

  useEffect(() => {
    async function fetchPackTypes() {
      try {
        const response = await packTypeApi.getPackTypes({});
        setPackTypes(response.packTypes);
      } catch (error) {
        dispatch(openSnackbar({ message: "Error fetching pack types!", severity: "error" }));
      }
    }

    if (open) {
      setName("");
      setDescription("");
      setDropTime("");
      setTags([]);
      setImage(null);
      setImagePreview("");
      setSelectedPackTypes([]);
      setRipPacksData({});
    }
    fetchPackTypes();
  }, [open, dispatch]);

  useEffect(() => {
    setRipPacksData((prev) => {
      const newRipPacksData = { ...prev };

      selectedPackTypes.forEach((packType) => {
        if (!newRipPacksData[packType]) {
          newRipPacksData[packType] = {
            name: "",
            quantity: 1,
            maxQuantity: 1,
            price: 0,
            currency: CurrencyEnum.USD,
          };
        }
      });

      // Remove data for unselected pack types
      Object.keys(newRipPacksData).forEach((packType) => {
        if (!selectedPackTypes.includes(packType)) {
          delete newRipPacksData[packType];
        }
      });

      return newRipPacksData;
    });
  }, [selectedPackTypes]);

  return (
    <CustomDialog open={open} onClose={onClose} text="Create Drop" subText="Fill in the details for the new drop">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Name
          </Typography>
          <TextField fullWidth value={name} onChange={(e) => setName(e.target.value)} required />
        </Box>

        <Divider />

        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Description
          </Typography>
          <TextField fullWidth value={description} onChange={(e) => setDescription(e.target.value)} required />
        </Box>

        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Tags
          </Typography>
          <TextField
            fullWidth
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleCreateTag()}
            required
          />
          <Button variant="contained" onClick={handleCreateTag} sx={{ mt: "0.5rem" }}>
            Add Tag
          </Button>
        </Box>

        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {tags.length > 0 && tags.map((tag: any) => <Chip label={tag} onDelete={handleDeleteTag(tag)} />)}
        </Box>

        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Drop/Release Time{" "}
          </Typography>
          <TextField
            type="datetime-local"
            fullWidth
            value={dropTime}
            onChange={(e) => setDropTime(e.target.value)}
            required
          />
        </Box>

        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Image
          </Typography>
          <FileUploadButton onFileChange={handleImageChange} />
          {imagePreview && (
            <Box component="img" src={imagePreview} alt="Selected Image" sx={{ width: "100%", height: "auto" }} />
          )}
        </Box>

        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Pack Type
          </Typography>
          <TextField
            select
            fullWidth
            SelectProps={{
              multiple: true,
              renderValue: (selected: any) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }} onClick={(e) => e.stopPropagation()}>
                  {(selected as string[]).map((value) => (
                    <Chip
                      key={value}
                      label={value
                        .split("-")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")}
                      onClick={(e: any) => e.stopPropagation()}
                      size="small"
                    />
                  ))}
                </Box>
              ),
              onClick: (e: any) => e.stopPropagation(),
            }}
            value={selectedPackTypes}
            onChange={(e: any) => setSelectedPackTypes(e.target.value)}
            required
            helperText={
              selectedPackTypes.length > 0 &&
              `Selected: ${selectedPackTypes.length} pack type${selectedPackTypes.length > 1 ? "s" : ""}`
            }
          >
            {packTypes.map((packType: any) => (
              <MenuItem key={packType._id} value={packType.name}>
                {packType.name} {selectedPackTypes.includes(packType.name) && "✓"}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {selectedPackTypes.length > 0 &&
          selectedPackTypes.map((packType: string) => (
            <Box
              key={packType}
              sx={{
                mb: 2,
                borderRadius: 2,
                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  background: (theme) =>
                    `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                  color: "white",
                }}
              >
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {packType} Type
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5, opacity: 0.9 }}>
                    Configure pack details and pricing
                  </Typography>
                </Box>

                <Box
                  component="img"
                  src={
                    getS3ImageUrl({
                      imageUrl: packTypes.find((pt) => pt.name === packType)?.imageUrl,
                      type: "pack-type",
                    }) || ""
                  }
                  alt={packType}
                  sx={{
                    width: { xs: 32, sm: 70 },
                    height: { xs: 32, sm: 70 },
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    border: "2px solid black",
                  }}
                />
              </Box>
              <Box sx={{ p: 3 }}>
                <CreateRipPack
                  packTypeDetails={packTypes.find((pt) => pt.name === packType)}
                  packData={ripPacksData[packType]}
                  onPackDataChange={(data) => {
                    handlePackDataChange(packType, data);
                  }}
                />
              </Box>
            </Box>
          ))}

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handleCreateDrop}>
            Create Drop
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
}
