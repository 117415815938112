import TextEditor from "./TextEditor";
import ImageEditor from "./ImageEditor";
import InputBlock from "./InputEditor";
import Carousel from "./Carousel";

interface SubBlockProps {
  index: number;
  subIndex: number;
  subBlock: any;
  totalSubBlocks: number;
  handleHtmlContentChange: (htmlContent: string, blockIndex: any) => void;
  handleBlockNameChange: (name: string, blockIndex: any) => void;
  handleDeleteBlock: (blockIndex: any) => void;
}

export default function SubBlock({
  index,
  subIndex,
  subBlock,
  totalSubBlocks,
  handleHtmlContentChange,
  handleBlockNameChange,
  handleDeleteBlock,
}: SubBlockProps) {
  return (
    <div
      style={{
        width: `${100 / totalSubBlocks}%`,
        transition: "width 0.3s ease",
      }}
    >
      {{
        text: (
          <TextEditor
            htmlContent={subBlock.htmlContent}
            handleHtmlContentChange={(htmlContent) => handleHtmlContentChange(htmlContent, { index, subIndex })}
            blockIndex={{ index, subIndex }}
            handleDeleteBlock={() => handleDeleteBlock({ index, subIndex })}
          />
        ),
        image: (
          <ImageEditor
            blockIndex={{ index, subIndex }}
            blockName={subBlock.name}
            handleBlockNameChange={(name) => handleBlockNameChange(name, { index, subIndex })}
            handleHtmlContentChange={(htmlContent) => handleHtmlContentChange(htmlContent, { index, subIndex })}
            htmlContent={subBlock.htmlContent}
            handleDeleteBlock={() => handleDeleteBlock({ index, subIndex })}
          />
        ),
        input: (
          <InputBlock
            htmlContent={subBlock.htmlContent}
            handleHtmlContentChange={(htmlContent) => handleHtmlContentChange(htmlContent, { index, subIndex })}
            blockIndex={{ index, subIndex }}
            handleDeleteBlock={() => handleDeleteBlock({ index, subIndex })}
          />
        ),
        carousel: (
          <Carousel
            htmlContent={subBlock.htmlContent}
            handleHtmlContentChange={(htmlContent) => handleHtmlContentChange(htmlContent, { index, subIndex })}
            blockIndex={{ index, subIndex }}
            handleDeleteBlock={() => handleDeleteBlock({ index, subIndex })}
          />
        ),
      }[subBlock.type as string] || null}
    </div>
  );
}
