export function formatDistanceToNow(date: Date | string, options?: { addSuffix?: boolean }): string {
  const now = new Date();
  const targetDate = date instanceof Date ? date : new Date(date);
  const diffInSeconds = Math.floor((now.getTime() - targetDate.getTime()) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  };

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    const value = Math.floor(diffInSeconds / secondsInUnit);
    if (value >= 1) {
      const suffix = options?.addSuffix ? ' ago' : '';
      return `${value} ${unit}${value === 1 ? '' : 's'}${suffix}`;
    }
  }

  return options?.addSuffix ? 'just now' : 'now';
}
