import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import SkeletonRow from "../components/common/SkeletonRow";
import DropDetailRow from "../components/drop-management/DropDetailRow";
import DropDetailHeader from "../components/drop-management/DropDetailHeader";
import DropListToolBar from "../components/drop-management/DropListToolBar";
import CreateDrop from "../components/forms/CreateDrop";
import { dropApi } from "../apis/DropApi";
import PackItemsManagement from "./PackItemsManagement";
import CreatePackType from "../components/forms/CreatePackType";
import ItemStatsForPack from "../components/pack-item-management/ItemStatsForPack";
import Header from "../components/common/Header";

export default function DropManagement() {
  const [dropsList, setDropsList] = useState<any[]>([]);
  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("-1");
  const [createDrop, setCreateDrop] = useState(false);
  const [createPackType, setCreatePackType] = useState(false);
  const [selectedDrop, setSelectedDrop] = useState<any>(null);
  const [selectedRipPack, setSelectedRipPack] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [packSelectedForStats, setPackSelectedForStats] = useState(null);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  const fetchDrops = useCallback(async () => {
    try {
      setLoading(true);

      const params = {
        page,
        limit: rowsPerPage,
        total: true,
        sortKey: sortColumn,
        sortDirection: sortDirection === "-1" ? "-1" : "1",
        search: searchQuery,
      };

      const { drops, total } = await dropApi.getPaginatedAllDrops(params);
      setTotal(total);
      setDropsList(drops);
      if (!searchQuery) dispatch(openSnackbar({ message: "Drops fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching events:", error);
      dispatch(openSnackbar({ message: "Error fetching Drops ", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [rowsPerPage, sortDirection, sortColumn, dispatch, searchQuery, page]);

  useEffect(() => {
    fetchDrops();
  }, [sortColumn, sortDirection, tab, fetchDrops]);

  useEffect(() => {
    if (refresh) fetchDrops();
  }, [refresh, fetchDrops]);

  const packManagementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((selectedDrop || selectedRipPack) && packManagementRef.current) {
      packManagementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [selectedDrop, selectedRipPack]);

  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          width: "100%",
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Drop-Pack Management
          </Typography>
        </Box>
        <DropListToolBar
          tab={tab}
          handleTabChange={handleTabChange}
          total={total}
          searchQuery={searchQuery}
          setSearchQuery={(value: string) => {
            setSearchQuery(value);
            setPage(0);
          }}
          onRefresh={() => setRefresh(true)}
          setCreateDrop={() => setCreateDrop(true)}
          setCreatePackType={() => setCreatePackType(true)}
        />
        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
          <Table>
            <DropDetailHeader
              tab={tab}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={setSortColumn}
              handleSortDirection={setSortDirection}
            />
            <TableBody>
              {loading ? (
                <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={5} />
              ) : dropsList.length > 0 ? (
                dropsList.map((drop: any) => (
                  <DropDetailRow
                    key={drop?._id}
                    tab={tab}
                    drop={drop}
                    setSelectedDrop={setSelectedDrop}
                    setSelectedPack={setSelectedRipPack}
                    setPackSelectedForStats={setPackSelectedForStats}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Drops found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(total / rowsPerPage)}
            variant="outlined"
            color="primary"
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
        </Box>

        {/* Float elements */}
        <CreateDrop open={createDrop} onClose={() => setCreateDrop(false)} setRefresh={setRefresh} />

        <CreatePackType open={createPackType} onClose={() => setCreatePackType(false)} setRefresh={setRefresh} />
      </Box>
      <Box ref={packManagementRef} sx={{ width: "100%", height: "auto", backgroundColor: "white" }}>
        <PackItemsManagement selectedDrop={selectedDrop?._id} selectedPack={selectedRipPack?._id} />
      </Box>
      {packSelectedForStats && (
        <ItemStatsForPack onClose={() => setPackSelectedForStats(null)} pack={packSelectedForStats} />
      )}
    </React.Fragment>
  );
}
