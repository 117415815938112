import React from "react";
import { Box, Button, Divider, Drawer, List, ListItem, Paper, Typography } from "@mui/material";
import {
  Home as HomeIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Person as PersonIcon,
  Event as EventIcon,
  ReceiptLong as ReceiptLongIcon,
  Category as CategoryIcon,
  Inventory2 as Inventory2Icon,
  Redeem as RedeemIcon,
  Casino as CasinoIcon,
  Widgets as WidgetsIcon,
  Campaign as CampaignIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Image as ImageIcon,
  TextFields,
} from "@mui/icons-material";

import { Link, useLocation } from "react-router-dom";

import { useIsMobile } from "../../hooks/useIsMobile";
import { useSelector } from "react-redux";

interface MenuProps {
  showMenu: boolean;
  setShowMenu: (showMenu: boolean) => void;
}

export default function Menu({ showMenu, setShowMenu }: MenuProps) {
  const isMobile = useIsMobile();
  const location = useLocation();
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);

  const getButtonStyle = (path: string) => {
    return location.pathname === path
      ? {
          backgroundColor: "secondary.main",
          fontWeight: "bold",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }
      : {};
  };

  return (
    <Drawer
      anchor="left"
      open={showMenu}
      onClose={() => setShowMenu(false)}
      PaperProps={{ sx: { minWidth: isMobile ? "60%" : "13%" } }}
      color="secondary"
      sx={{ zIndex: 1000 }}
    >
      <Box component={Paper} height="100%">
        <Box
          display="flex"
          alignItems="center"
          gap="1rem"
          boxShadow={3}
          padding="1.5rem"
          sx={{ backgroundColor: "primary.main" }}
        >
          <AdminPanelSettingsIcon />
          <Typography variant="h6" fontFamily={"Monospace"} fontWeight="Bold">
            SHOCKEM-MARKET-ADMIN
          </Typography>
        </Box>
        <List>
          <ListItem>
            <Button
              component={Link}
              to="/"
              sx={{ justifyContent: "flex-start", ...getButtonStyle("/") }}
              startIcon={<HomeIcon />}
              fullWidth
            >
              Home
            </Button>
          </ListItem>
          <Divider variant="middle" />
          {adminInfo.roleName === "superAdmin" && (
            <React.Fragment>
              <ListItem>
                <Button
                  component={Link}
                  to="/role-management"
                  sx={{
                    justifyContent: "flex-start",
                    ...getButtonStyle("/role-management"),
                  }}
                  startIcon={<SupervisorAccountIcon />}
                  fullWidth
                >
                  Admin Role Management
                </Button>
              </ListItem>
              <Divider variant="middle" />
            </React.Fragment>
          )}
          <ListItem>
            <Button
              component={Link}
              to="/user-management"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/user-management"),
              }}
              startIcon={<PersonIcon />}
              fullWidth
            >
              User Management
            </Button>
          </ListItem>
          <Divider variant="middle" />

          <ListItem>
            <Button
              component={Link}
              to="/subscription-activity"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/subscription-activity"),
              }}
              startIcon={<ReceiptLongIcon />}
              fullWidth
            >
              Subscription Management
            </Button>
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <Button
              component={Link}
              to="/category-item-management"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/category-item-management"),
              }}
              startIcon={<CategoryIcon />}
              fullWidth
            >
              Category & Item Management
            </Button>
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <Button
              component={Link}
              to="/drop-management"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/drop-management"),
              }}
              startIcon={<CampaignIcon />}
              fullWidth
            >
              Drop Management
            </Button>
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <Button
              component={Link}
              to="/box-management"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/box-management"),
              }}
              startIcon={<WidgetsIcon />}
              fullWidth
            >
              Box Management
            </Button>
          </ListItem>
          <Divider variant="middle" />

          <ListItem>
            <Button
              component={Link}
              to="/scratch-card-management"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/scratch-card-management"),
              }}
              startIcon={<CasinoIcon />}
              fullWidth
            >
              Scratch Card Management
            </Button>
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <Button
              component={Link}
              to="/asset-management"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/asset-management"),
              }}
              startIcon={<ImageIcon />}
              fullWidth
            >
              Asset Management
            </Button>
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <Button
              component={Link}
              to="/page-editor"
              sx={{
                justifyContent: "flex-start",
                ...getButtonStyle("/page-editor"),
              }}
              startIcon={<TextFields />}
              fullWidth
            >
              Page Editor
            </Button>
          </ListItem>
          <Divider variant="middle" />
        </List>
      </Box>
    </Drawer>
  );
}
