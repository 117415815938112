import { useState, useEffect } from "react";
import { Box, Typography, TextField, IconButton, Stack, Button, Paper, Tooltip, Collapse, Card } from "@mui/material";
import { Add, Close, ViewCarousel, ExpandMore, ExpandLess, Image as ImageIcon, Height } from "@mui/icons-material";

interface CarouselProps {
  htmlContent: string;
  handleHtmlContentChange: (htmlContent: string, blockIndex: { index: number; subIndex: number }) => void;
  blockIndex: { index: number; subIndex: number };
  handleDeleteBlock: (blockIndex: { index: number; subIndex: number }) => void;
}

export default function Carousel({
  htmlContent,
  handleHtmlContentChange,
  blockIndex,
  handleDeleteBlock,
}: CarouselProps) {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [carouselHeight, setCarouselHeight] = useState<string>("10rem");
  const [expanded, setExpanded] = useState(true);
  const [previewHovered, setPreviewHovered] = useState(false);

  function updateHtmlContent(imageUrls: string[], carouselHeight: string) {
    const containerStyle = {
      display: "flex",
      width: "100%",
      overflow: "scroll",
      flexWrap: "nowrap",
      height: carouselHeight,
      scrollbarWidth: "none",
      msOverflowStyle: "none",
    };

    const containerStyleString = Object.entries(containerStyle)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";");

    const imageStyle = {
      height: "100%",
      width: "auto",
      transition: "transform 0.3s ease",
    };

    const imageStyleString = Object.entries(imageStyle)
      .map(([key, value]) => `${key}: ${value}`)
      .join(";");

    const images = imageUrls.map((url) => `<img src="${url}" style="${imageStyleString}" />`);
    const carouselHtml = `<div style="${containerStyleString}">${images.join("")}</div>`;
    handleHtmlContentChange(carouselHtml, blockIndex);
  }

  function handleImageUrlChange(newUrl: string, index: number) {
    const newImageUrls = imageUrls.map((url, i) => (i === index ? newUrl : url));
    setImageUrls(newImageUrls);
    updateHtmlContent(newImageUrls, carouselHeight);
  }

  function handleAddImage() {
    const newImageUrls = [...imageUrls, ""];
    setImageUrls(newImageUrls);
    updateHtmlContent(newImageUrls, carouselHeight);
  }

  function handleDeleteImage(index: number) {
    const newImageUrls = imageUrls.filter((_, i) => i !== index);
    setImageUrls(newImageUrls);
    updateHtmlContent(newImageUrls, carouselHeight);
  }

  useEffect(() => {
    if (htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      const images = Array.from(doc.querySelectorAll("img"));
      const urls = images.map((img) => img.getAttribute("src") || "");
      setImageUrls(urls);
    }
  }, [htmlContent]);

  return (
    <Paper
      elevation={previewHovered ? 3 : 1}
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        transition: "all 0.3s ease",
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          bgcolor: "background.default",
        }}
      >
        <ViewCarousel sx={{ color: "primary.main" }} />
        <Typography variant="subtitle1" fontWeight={600}>
          Carousel Block
        </Typography>
        <Box sx={{ ml: "auto", display: "flex", gap: 1 }}>
          <Tooltip title="Delete Carousel">
            <IconButton
              size="small"
              onClick={() => handleDeleteBlock(blockIndex)}
              sx={{
                color: "error.main",
                "&:hover": {
                  bgcolor: "error.lighter",
                },
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        {/* Height Control */}
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            size="small"
            label="Carousel Height"
            value={carouselHeight}
            onChange={(e) => {
              setCarouselHeight(e.target.value);
              updateHtmlContent(imageUrls, e.target.value);
            }}
            placeholder="e.g., 10rem, 200px"
            helperText="Enter height in rem or px"
            InputProps={{
              startAdornment: <Height sx={{ color: "text.secondary", mr: 1 }} />,
            }}
          />
        </Box>

        {/* Image URLs */}
        <Stack spacing={2}>
          {imageUrls.map((url, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{
                p: 2,
                display: "flex",
                gap: 1,
                alignItems: "center",
                "&:hover": {
                  bgcolor: "action.hover",
                },
              }}
            >
              <ImageIcon sx={{ color: "text.secondary" }} />
              <TextField
                size="small"
                fullWidth
                placeholder="Enter image URL"
                value={imageUrls[index]}
                onChange={(e) => handleImageUrlChange(e.target.value, index)}
                sx={{ flex: 1 }}
              />
              <Tooltip title="Remove Image">
                <IconButton
                  size="small"
                  onClick={() => handleDeleteImage(index)}
                  sx={{
                    color: "error.main",
                    "&:hover": {
                      bgcolor: "error.lighter",
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Card>
          ))}
        </Stack>

        {/* Add Image Button */}
        <Button
          variant="outlined"
          fullWidth
          onClick={handleAddImage}
          startIcon={<Add />}
          sx={{
            mt: 2,
            borderStyle: "dashed",
            "&:hover": {
              borderStyle: "solid",
            },
          }}
        >
          Add Image
        </Button>
      </Box>

      {/* Preview */}
    </Paper>
  );
}
