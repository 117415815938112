import ImageNotFound from "../assets/image-not-found.png";
import { Item } from "../types/common";

export function getS3ImageUrlForItem({ itemObj }: { itemObj?: Item }) {
  if (!itemObj) return ImageNotFound;
  if (itemObj.imageUrlPreview) return itemObj.imageUrlPreview;

  const s3Path = "item-images/" + itemObj?.imageUrl;
  if (!s3Path) return ImageNotFound;

  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;

  if (!s3Path || !cloudfrontPrefix) {
    // console.error(`@getS3ImageUrl: Invalid s3Path or cloudfrontPrefix for collection`, { s3Path, cloudfrontPrefix });
    return ImageNotFound;
  }

  return `${cloudfrontPrefix}/${s3Path}`;
}

export function getItemDisplayTitle({ itemObj }: { itemObj: Item }): string {
  if (!itemObj?.title) return "";

  return itemObj.title;
}

export function getItemDisplaySerialNumber({
  itemObj,
  serialNumberMax,
}: {
  itemObj: Item;
  serialNumberMax?: number;
}): string {
  if (!serialNumberMax) serialNumberMax = itemObj.numberId;

  if (!itemObj?.numberId) return "";
  const serialNumber = itemObj.numberId.toString().padStart(serialNumberMax.toString().length, "0");

  return `(${serialNumber} / ${serialNumberMax})`;
}

export function getItemGroupName({ itemObj }: { itemObj: Item }): string {
  return itemObj?.groupName || "";
}

export function getItemDisplayTitleWithSerial({
  itemObj,
  serialNumberMax,
}: {
  itemObj: Item;
  serialNumberMax?: number;
}): string {
  const title = getItemDisplayTitle({ itemObj });
  const serialNumber = getItemDisplaySerialNumber({ itemObj, serialNumberMax });
  return serialNumber ? `${title} ${serialNumber}` : title;
}
