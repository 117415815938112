import {
  Box,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Collapse,
  Typography,
  IconButton,
  Divider,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { boxApi } from "../../apis/BoxApi";

interface BoxItemFiltersProps {
  sectionOpen: boolean;
  setSectionOpen: () => void;
  boxFilter: any;
  setBoxFilter: (value: any) => void;
}

export default function BoxItemFilters({ sectionOpen, boxFilter, setSectionOpen, setBoxFilter }: BoxItemFiltersProps) {
  const [boxes, setBoxes] = useState<any[]>([]);

  useEffect(() => {
    if (sectionOpen) {
      boxApi.getPaginatedBoxes({}).then(({ boxes, totalBoxes }) => {
        setBoxes(boxes || []);
      });
    }
  }, [sectionOpen]);

  function filterChips() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: 1,
          flexWrap: "wrap",
          overflow: "auto",
        }}
      >
        {boxFilter && (
          <Chip label={`Rip Box: ${boxFilter?.name}`} onDelete={() => handleDelete("box")} color="primary" />
        )}
      </Box>
    );
  }

  function handleSelectChange(type: string, value: string) {
    if (type === "box") setBoxFilter(value);
  }

  function handleDelete(type: string) {
    if (type === "box") setBoxFilter(null);
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      mb={{ xs: 1, sm: 2 }}
      sx={{ zIndex: 100, justifyContent: "space-between", width: "100%" }}
    >
      <Grid
        boxSizing={"border-box"}
        sx={{ width: { xs: "100%", sm: "20rem" }, mt: { xs: 0, sm: 2 }, backgroundColor: "primary.secondary" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          component={Paper}
          onClick={setSectionOpen}
          boxShadow={3}
          sx={{
            borderBottomLeftRadius: sectionOpen ? 0 : "0.5rem",
            borderBottomRightRadius: sectionOpen ? 0 : "0.5rem",
            borderTopRightRadius: "0.5rem",
            borderTopLeftRadius: "0.5rem",
          }}
        >
          <Typography ml={2}>Filters</Typography>
          <IconButton aria-label="open-filter-section">
            {sectionOpen ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
          </IconButton>
        </Stack>
        <Box
          pl={2}
          pr={2}
          component={Paper}
          boxSizing={"border-box"}
          position={{ xs: "relative", sm: "absolute" }}
          boxShadow={3}
          width={"inherit"}
        >
          <Collapse in={sectionOpen} timeout="auto" unmountOnExit>
            <Typography sx={{ mt: 1 }}>Box Type</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <FormGroup>
              <TextField
                select
                id="rip-box-select-filter"
                value={boxFilter}
                label="Rip Box"
                onChange={(e) => handleSelectChange("box", e.target.value)}
                sx={{ mt: 1, mb: 1 }}
              >
                {(boxes || []).map((box: any, index: number) => {
                  return (
                    <MenuItem key={index} value={box}>
                      {box?.name || "Box"}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormGroup>
          </Collapse>
        </Box>
      </Grid>
      <Box sx={{ mt: { xs: 0, sm: 2 } }}>{filterChips()}</Box>
    </Grid>
  );
}
