import { useEffect, useState } from "react";
import { TextField, IconButton, Tooltip, Box, Paper, Stack, Typography } from "@mui/material";
import {
  Close,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  Palette,
  AspectRatio,
  Title,
  SmartButton,
} from "@mui/icons-material";
import { extractInputAttributes } from "../../utils/operate-html";

interface InputBlockProps {
  htmlContent: string;
  handleHtmlContentChange: (htmlContent: string, blockIndex: { index: number; subIndex: number }) => void;
  blockIndex: { index: number; subIndex: number };
  handleDeleteBlock: (blockIndex: { index: number; subIndex: number }) => void;
}

export default function InputBlock({
  htmlContent,
  handleHtmlContentChange,
  blockIndex,
  handleDeleteBlock,
}: InputBlockProps) {
  const [buttonText, setButtonText] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [action, setAction] = useState("");
  const [buttonColor, setButtonColor] = useState("#1976d2");
  const [alignment, setAlignment] = useState("left");
  const [width, setWidth] = useState("100");
  const [isHovered, setIsHovered] = useState(false);

  function handleInputChanges(type: string, value: string) {
    let tempButtonText = buttonText;
    let tempPlaceholder = placeholder;
    let tempAlignment = alignment;
    let tempAction = action;
    let tempButtonColor = buttonColor;
    let tempWidth = width;
    if (type === "alignment") {
      tempAlignment = value;
      setAlignment(value);
    } else if (type === "placeholder") {
      tempPlaceholder = value;
      setPlaceholder(value);
    } else if (type === "buttonText") {
      tempButtonText = value;
      setButtonText(value);
    } else if (type === "action") {
      tempAction = value;
      setAction(value);
    } else if (type === "buttonColor") {
      tempButtonColor = value;
      setButtonColor(value);
    } else if (type === "width") {
      tempWidth = value;
      setWidth(value);
    }

    let updatedHtmlContent = `
    <div style="display:flex; justify-content:${tempAlignment};" class="mp-input-editor-outter-div" >
      <div style="display:flex; width:${tempWidth}%;" class="${tempAction
      .trimEnd()
      .split(" ")
      .join("-")} mp-input-editor-inner-div">
      <input 
        name="${tempAction} Input"
        type="text" 
        placeholder="${tempPlaceholder}" 
        style="padding: 12px; font-size: 14px; border-radius: 6px; border: 1px solid #e0e0e0; flex-grow: 1; transition: all 0.2s ease-in-out;" 
      />
      <input 
        name="${tempAction}" 
        style="background-color: ${tempButtonColor}; color: white; padding: 12px 24px; border: none; border-radius: 6px; cursor: pointer; font-size: 14px; margin-left: 8px; transition: all 0.2s ease-in-out; box-shadow: 0 2px 4px rgba(0,0,0,0.1);" 
        type="button" 
        value="${tempButtonText}"
      />
      </div>
    </div>`;
    handleHtmlContentChange(updatedHtmlContent, blockIndex);
  }

  useEffect(() => {
    if (!htmlContent) return;
    const { placeholder, buttonText, action, buttonColor, width, alignment } = extractInputAttributes(htmlContent);
    const widthPercentage = width?.replace("%", "") || "100";
    setPlaceholder(placeholder || "");
    setButtonText(buttonText || "");
    setAction(action || "");
    setWidth(widthPercentage);
    setAlignment(alignment || "left");
    setButtonColor(buttonColor || "#1976d2");
  }, [htmlContent]);

  return (
    <Paper
      elevation={isHovered ? 3 : 1}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        p: 3,
        borderRadius: 2,
        position: "relative",
        transition: "all 0.2s ease-in-out",
        border: isHovered ? "2px solid #e0e0e0" : "2px solid transparent",
      }}
    >
      <Stack spacing={2.5} sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 2,
            borderBottom: "1px solid",
            borderColor: "divider",
            bgcolor: "background.default",
          }}
        >
          <AspectRatio sx={{ color: "primary.main" }} />
          <Typography variant="subtitle1" fontWeight={600}>
            Input Block
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="Placeholder"
            variant="outlined"
            value={placeholder}
            onChange={(e) => handleInputChanges("placeholder", e.target.value)}
            size="small"
            fullWidth
            InputProps={{
              startAdornment: <Title sx={{ mr: 1, color: "text.secondary", fontSize: "1.2rem" }} />,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="Button Text"
            variant="outlined"
            value={buttonText}
            onChange={(e) => handleInputChanges("buttonText", e.target.value)}
            size="small"
            fullWidth
            InputProps={{
              startAdornment: <SmartButton sx={{ mr: 1, color: "text.secondary", fontSize: "1.2rem" }} />,
            }}
          />
          <TextField
            label="Color"
            variant="outlined"
            type="color"
            value={buttonColor}
            onChange={(e) => handleInputChanges("buttonColor", e.target.value)}
            size="small"
            sx={{ width: "120px" }}
            InputProps={{
              startAdornment: <Palette sx={{ mr: 1, color: "text.secondary", fontSize: "1.2rem" }} />,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="Action/Name"
            variant="outlined"
            value={action}
            onChange={(e) => handleInputChanges("action", e.target.value)}
            size="small"
            fullWidth
            InputProps={{
              startAdornment: <Title sx={{ mr: 1, color: "text.secondary", fontSize: "1.2rem" }} />,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="Width"
            variant="outlined"
            value={width}
            onChange={(e) => handleInputChanges("width", e.target.value)}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <Box sx={{ color: "text.secondary" }}>%</Box>,
              startAdornment: <AspectRatio sx={{ mr: 1, color: "text.secondary", fontSize: "1.2rem" }} />,
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: -8,
            right: -8,
            display: "flex",
            gap: 0.5,
            backgroundColor: "background.paper",
            padding: "4px",
            borderRadius: "4px",
            opacity: isHovered ? 1 : 0.4,
            transition: "opacity 0.2s ease-in-out",
          }}
        >
          <Tooltip title="Align Left">
            <IconButton
              size="small"
              onClick={() => handleInputChanges("alignment", "left")}
              sx={{
                color: alignment === "left" ? "primary.main" : "text.secondary",
                backgroundColor: alignment === "left" ? "action.selected" : "transparent",
              }}
            >
              <FormatAlignLeft sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Center">
            <IconButton
              size="small"
              onClick={() => handleInputChanges("alignment", "center")}
              sx={{
                color: alignment === "center" ? "primary.main" : "text.secondary",
                backgroundColor: alignment === "center" ? "action.selected" : "transparent",
              }}
            >
              <FormatAlignCenter sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Right">
            <IconButton
              size="small"
              onClick={() => handleInputChanges("alignment", "right")}
              sx={{
                color: alignment === "right" ? "primary.main" : "text.secondary",
                backgroundColor: alignment === "right" ? "action.selected" : "transparent",
              }}
            >
              <FormatAlignRight sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Block">
            <IconButton
              size="small"
              onClick={() => handleDeleteBlock(blockIndex)}
              sx={{
                "&:hover": {
                  color: "error.main",
                  backgroundColor: "error.light",
                },
              }}
            >
              <Close sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </Paper>
  );
}
