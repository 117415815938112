import React from "react";
import { TableRow, TableCell, Typography, Box, Avatar, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import UpdateAdminRole from "../forms/UpdateAdminRole";
import EditIcon from "@mui/icons-material/Edit";

interface RoleManagementRowProps {
  tab: string;
  user: any;
  roles: any;
  setRefresh: any;
}

export default function RoleManagementRow({ tab, user, roles, setRefresh = () => {} }: RoleManagementRowProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleRowClick = () => {
    setDialogOpen(true);
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={handleRowClick}
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "secondary.light",
            boxShadow: 2, // Adds a slight shadow on hover
          },
        }}
      >
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                {user?.username || "No Username"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
              >
                {user.email || "No Email"}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>{user?.roleName || "adminUser"}</TableCell>
        <TableCell align="right">
          <Tooltip title="Click to Edit Role">
            <IconButton onClick={handleRowClick} aria-label="edit" size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <UpdateAdminRole
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        setRefresh={setRefresh}
        user={user}
        roles={roles}
      />
    </React.Fragment>
  );
}
