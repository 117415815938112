import BaseAPI from "./BaseApi";

class CategoryApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/category", cache: false });
  }

  public async getCategoryWithParents({
    categoryId,
    populateParents,
  }: {
    categoryId?: string | undefined;
    populateParents: boolean;
  }) {
    if (!categoryId) return null;
    try {
      const res = await this.axios.get("/getCategory", {
        params: {
          categoryId,
          populateParents,
        },
      });

      return res.data.category;
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message || "Unknown";
      throw new Error(message);
    }
  }

  public async getSubCategories({ categoryId }: { categoryId: string | undefined }) {
    try {
      const res = await this.axios.get("/getSubCategories", {
        params: {
          parentId: categoryId,
        },
      });
      return res.data.categories;
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message || "Unknown";
      throw new Error(message);
    }
  }

  public async getAllCategories() {
    try {
      const res = await this.axios.get("/getManyCategories");
      return res.data.categories;
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message || "Unknown";
      throw new Error(message);
    }
  }

  public async getCategories({ searchTerm, populate }: { searchTerm: string; populate?: boolean }) {
    try {
      const res = await this.axios.get(`/getManyCategories`, {
        params: {
          searchTerm,
          populate: populate,
        },
      });
      return res.data.categories;
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message || "Unknown";
      throw new Error(message);
    }
  }

  public async createCategory({
    parentId,
    title,
    description,
    tags,
  }: {
    parentId?: string;
    title: string;
    description?: string;
    tags?: { tag: string; required: Boolean }[];
  }) {
    const categoryData = {
      parentId,
      title,
      description,
      tags,
    };
    const res = await this.axios.post("/", categoryData);

    // Check if the response is successful
    if (res.data.success && res.status === 201 && res.data.category) {
      return res.data.category;
    } else {
      throw new Error("Failed to create category");
    }
  }

  public async editCategory({
    categoryId,
    title,
    description,

    parentId,
  }: {
    categoryId: string;
    title: string;
    description?: string;
    parentId?: string;
  }) {
    const categoryData = {
      title,
      description,
      parentId,
    };
    const res = await this.axios.put(`/editCategory/${categoryId}`, {
      categoryId,
      updates: categoryData,
    });
    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error("Failed to edit category");
    }
  }

  public async editTagName({
    categoryId,
    oldTagName,
    newTagName,
    required,
  }: {
    categoryId: string;
    oldTagName: string;
    newTagName: string;
    required?: boolean;
  }) {
    const res = await this.axios.put(`/${categoryId}/editTagName`, {
      oldTagName,
      newTagName,
      required,
    });
    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error(`Failed to edit tag name ${newTagName} for tag ${oldTagName} in category ${categoryId}`);
    }
  }

  public async editTagValue({
    categoryId,
    tagName,
    oldValue,
    newValue,
  }: {
    categoryId: string;
    tagName: string;
    oldValue: string;
    newValue: string;
  }) {
    if (oldValue === newValue) {
      return;
    }

    const res = await this.axios.put(`/${categoryId}/editTagValue`, {
      tagName,
      oldValue,
      newValue,
    });
    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error(
        `Failed to edit tag value from ${oldValue} to ${newValue} for tag ${tagName} in category ${categoryId}`
      );
    }
  }

  public async deleteTag({ categoryId, tagName }: { categoryId: string; tagName: string }) {
    const res = await this.axios.delete(`/${categoryId}/deleteTagName`, {
      data: { tagName },
    });

    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error(`Failed to delete tag ${tagName} in category ${categoryId}`);
    }
  }

  public async deleteTagValue({ categoryId, tagName, value }: { categoryId: string; tagName: string; value: string }) {
    const res = await this.axios.delete(`/${categoryId}/deleteTagValue`, {
      data: { tagName, value },
    });

    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error(`Failed to delete tag value ${value} for tag ${tagName} in category ${categoryId}`);
    }
  }
  public async addTag({ categoryId, tagName, required }: { categoryId: string; tagName: string; required?: boolean }) {
    if (!tagName) {
      return;
    }

    const res = await this.axios.post(`/${categoryId}/addTagName`, {
      tagName,
      required,
    });

    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error(`Failed to add tag ${tagName} in category ${categoryId}`);
    }
  }

  public async addTagValue({ categoryId, tagName, value }: { categoryId: string; tagName: string; value: string }) {
    const res = await this.axios.post(`/${categoryId}/addTagValue`, {
      tagName,
      value,
    });

    if (res.data.success && res.status === 200 && res.data.updatedCategory) {
      return res.data.updatedCategory;
    } else {
      throw new Error(`Failed to add tag value ${value} for tag ${tagName} in category ${categoryId}`);
    }
  }
}

export const categoryApi = new CategoryApi();
