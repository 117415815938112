export function truncateText(text: string, length: number) {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  }
  return text;
}

export function formatString(str: string): string {
  return str
    .replace(/[_-]+/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function shortenString(str: string, maxLength = 10) {
  if (!str) return "";
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
}

export function handleCopy(label: string, content: string) {
  if (content) {
    navigator.clipboard
      .writeText(content)
      .then(() => alert(`${label} copied to clipboard!`))
      .catch((err) => console.error(`Failed to copy ${label}: `, err));
  }
}
