import { TableCell, TableHead, TableRow } from "@mui/material";

interface RewardListHeaderProps {
  tab: string;
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}

export default function RewardListHeader({
  tab,
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: RewardListHeaderProps) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Prize</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>RipPack</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Prize Type</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Description</TableCell>
      </TableRow>
    </TableHead>
  );
}
