import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import SkeletonRow from "../components/common/SkeletonRow";
import BoxItemStats from "../components/box-item-management/BoxItemStats";
import { boxItemApi } from "../apis/BoxItemApi";
import BoxItemDetailHeader from "../components/box-item-management/BoxItemDetailHeader";
import BoxItemDetailRow from "../components/box-item-management/BoxItemDetailRow";
import BoxItemListToolBar from "../components/box-item-management/BoxItemListToolBar";
import BoxItemFilters from "../components/box-item-management/BoxItemFilters";
import Header from "../components/common/Header";

export default function BoxItemManagement() {
  const [boxItemsList, setBoxItemsList] = useState<any[]>([]);
  const [filteredBoxItemsList, setFilteredBoxItemsList] = useState<any[]>([]);

  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("-1");
  const [boxItemForStats, setBoxItemForStats] = useState(null);
  const [boxItemToEdit, setBoxItemToEdit] = useState(null);

  const [boxFilter, setBoxFilter] = useState<{ _id: string; title: string } | null>(null);
  const [filterSectionOpen, setFilterSectionOpen] = useState(false);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  const fetchPackItems = useCallback(async () => {
    try {
      setLoading(true);

      const params = {
        boxIds: boxFilter ? [boxFilter?._id] : undefined, // boxFilter?._id,
        total: true,
        sortKey: sortColumn,
        sortDirection: sortDirection === "-1" ? "-1" : "1",
      };

      const { boxItems, totalBoxItems } = await boxItemApi.getPaginatedAllBoxItems(params);
      setTotal(totalBoxItems);
      setBoxItemsList(boxItems);
      setFilteredBoxItemsList(boxItems);

      dispatch(openSnackbar({ message: "PackItems fetched successfully", severity: "success" }));
    } catch (error: any) {
      dispatch(openSnackbar({ message: "Error fetching Pack Items ", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [boxFilter, tab, sortDirection, sortColumn, dispatch]);

  useEffect(() => {
    fetchPackItems();
  }, [sortColumn, sortDirection, tab, fetchPackItems]);

  useEffect(() => {
    if (refresh) fetchPackItems();
  }, [refresh, fetchPackItems]);

  useEffect(() => {
    setFilteredBoxItemsList(
      boxItemsList.filter((item) => {
        return (
          item?.sequenceNumber?.toString()?.includes(searchQuery.toLowerCase()) ||
          item?.ripPack?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          item?.ripPack?.description?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
      })
    );
  }, [searchQuery]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          width: "100%",
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Box Items Management
          </Typography>
        </Box>
        <BoxItemListToolBar
          tab={tab}
          handleTabChange={handleTabChange}
          total={total}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRefresh={() => setRefresh(true)}
        />
        <BoxItemFilters
          sectionOpen={filterSectionOpen}
          setSectionOpen={() => setFilterSectionOpen((prev) => !prev)}
          boxFilter={boxFilter}
          setBoxFilter={setBoxFilter}
        />
        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
          <Table>
            <BoxItemDetailHeader
              tab={tab}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={setSortColumn}
              handleSortDirection={setSortDirection}
            />
            <TableBody>
              {loading ? (
                <SkeletonRow rowsPerPage={10} columnsPerPage={5} />
              ) : filteredBoxItemsList && filteredBoxItemsList?.length > 0 ? (
                filteredBoxItemsList?.map((boxItem: any) => (
                  <BoxItemDetailRow
                    key={boxItem?._id}
                    tab={tab}
                    boxItem={boxItem}
                    setBoxItemToEdit={setBoxItemToEdit}
                    setBoxItemForStats={setBoxItemForStats}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Box Items found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <BoxItemStats open={boxItemForStats} onClose={() => setBoxItemForStats(null)} boxItem={boxItemForStats} />
      </Box>
    </>
  );
}
