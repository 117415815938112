import BaseAPI from "./BaseApi";

class RewardApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/reward", cache: false });
  }

  async createReward(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data.rewardCreated;
    } catch (error: any) {
      throw new Error("Error in RewardApi.createReward: " + error.message);
    }
  }

  async getPaginatedRewards({
    eventId,
    type,
    limit,
    page,
    project,
    search,
    sortKey,
    sortDirection,
    total = false,
    populate = false,
  }: {
    eventId?: string;
    type?: string;
    limit?: number;
    page?: number;
    project?: any;
    search?: string;
    sortKey?: string;
    sortDirection?: string;
    total?: boolean;
    populate?: boolean;
  }): Promise<any> {
    try {
      const result = await this.axios.get("/", {
        params: { eventId, type, limit, page, project, search, sortKey, sortDirection, total, populate },
      });
      if (!result.data || !result.data?.success || !result.data?.rewardsData)
        throw new Error("Invalid response from server");
      return result.data.rewardsData;
    } catch (error: any) {
      throw new Error("Error in RewardApi.getPaginatedRewards: " + error.message);
    }
  }
}

export const rewardApi = new RewardApi();
