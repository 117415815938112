import { TableRow, TableCell, Typography, Box, Avatar } from "@mui/material";
import { formatDate, formatTime } from "../../../utils/time";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getS3ImageUrl } from "../../../utils/s3Image";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/slices/snackbar";

interface EventDetailRowProps {
  tab: string;
  event: any;
}

export default function EventDetailRow({ tab, event }: EventDetailRowProps) {
  const dispatch = useDispatch();
  const handleCopyToClipboard = async () => {
    if (event?._id) {
      try {
        await navigator.clipboard.writeText(event._id);
        dispatch(openSnackbar({ message: "Copied to clipboard", severity: "success" }));
      } catch (error) {
        console.error("Failed to copy text:", error);
      }
    }
  };
  return (
    <TableRow key={event?._id}>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            alt={event?.name}
            src={
              getS3ImageUrl({
                imageUrl: event?.imageUrl,
                type: "event",
              }) || ""
            }
            sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
          />
          <Box>
            <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
              {event?.name || "No Event Name"}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
            >
              <ContentCopyIcon
                sx={{ mr: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" }, cursor: "pointer" }}
                onClick={handleCopyToClipboard}
              />
              {event?._id || "No Event Id"}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{event?.description || "No Description"}</TableCell>
      <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
        <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
          {formatDate(event?.eventTime)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
        >
          {formatTime(event?.eventTime)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
