import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, useTheme, alpha } from "@mui/material";
import ScratchCardActivity from "../components/scratch-card-management/ScratchCardActivity";
import EventActivity from "../components/scratch-card-management/EventActivity";
import RewardActivity from "../components/scratch-card-management/RewardActivity";
import EventIcon from "@mui/icons-material/Event";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import StyleIcon from "@mui/icons-material/Style";
import { SvgIconComponent } from "@mui/icons-material";
import Header, { HEADER_HEIGHT } from "../components/common/Header";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scratch-card-tabpanel-${index}`}
      aria-labelledby={`scratch-card-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 2, md: 3 } }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `scratch-card-tab-${index}`,
    "aria-controls": `scratch-card-tabpanel-${index}`,
  };
}

interface TabItemProps {
  label: string;
  Icon: SvgIconComponent;
}

const tabItems: TabItemProps[] = [
  {
    label: "Events",
    Icon: EventIcon,
  },
  {
    label: "Prizes",
    Icon: CardGiftcardIcon,
  },
  {
    label: "Scratch Cards",
    Icon: StyleIcon,
  },
];

export default function ScratchCardManagement() {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
            backdropFilter: "blur(8px)",
            position: "sticky",
            zIndex: 1000,
            boxSizing: "border-box",
            px: { xs: 2, md: 4 },
            py: 2,
            top: HEADER_HEIGHT,
          }}
        >
          <Typography variant="h5" sx={{ mb: 3, fontWeight: 600, color: "text.primary" }}>
            Scratch Card System Management
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="scratch card management tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                fontSize: { xs: "0.875rem", md: "1rem" },
                fontWeight: 500,
                minHeight: 48,
                p: 2,
                borderRadius: "8px 8px 0 0",
                color: "text.secondary",
                "&:hover": {
                  color: "primary.main",
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                },
                "&.Mui-selected": {
                  color: "primary.main",
                  fontWeight: 600,
                },
              },
              "& .MuiTabs-indicator": {
                height: 3,
                borderRadius: "3px 3px 0 0",
              },
            }}
          >
            {tabItems.map((item, index) => (
              <Tab
                key={index}
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <item.Icon sx={{ fontSize: 20 }} />
                    <span>{item.label}</span>
                  </Box>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ mt: 2 }}>
          <TabPanel value={value} index={0}>
            <EventActivity />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RewardActivity />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ScratchCardActivity />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}
