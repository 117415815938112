import { createSlice } from "@reduxjs/toolkit";

const adminInfo = localStorage.getItem("admin-user-info");

const initialState = {
  adminInfo: adminInfo
    ? JSON.parse(adminInfo)
    : {
        id: "",
        name: "NOT SIGNED IN",
        permissions: [],
        signedIn: false,
      },
};

const agentSlice = createSlice({
  name: "adminInfo",
  initialState,
  reducers: {
    setAdminInfo: (state, action) => {
      state.adminInfo = action.payload;

      localStorage.setItem("admin-user-info", JSON.stringify(action.payload));
    },
    resetAdminInfo: (state) => {
      state.adminInfo = {
        id: "",
        name: "NOT SIGNED IN",
        permissions: [],
        signedIn: false,
      };
      localStorage.removeItem("admin-user-info");
    },
  },
});

const agentReducer = agentSlice.reducer;

export const { setAdminInfo, resetAdminInfo } = agentSlice.actions;
export default agentReducer;
