import BaseAPI from "./BaseApi";

class AdminSavedFilterApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/admin-saved-filter", cache: false });
  }

  async getSavedFilters({ userId, categoryId }: { userId: string; categoryId: string }) {
    try {
      if (!userId || !categoryId) {
        return [];
      }

      const result = await this.axios.get(`/`, { params: { userId, categoryId } });
      if (!result.data || !result.data?.success || !result.data?.adminSavedFilters)
        throw new Error("Invalid response from server");
      return result.data.adminSavedFilters;
    } catch (error: any) {
      throw new Error("Error in AdminSavedFilterApi.getSavedFilter: " + error.message);
    }
  }

  async updateSavedFilter({
    name,
    userId,
    categoryId,
    filterObjects,
  }: {
    name: string;
    userId: string;
    categoryId: string;
    filterObjects: any;
  }) {
    try {
      const result = await this.axios.post(`/`, { name, userId, categoryId, filterObjects });

      if (!result.data || !result.data?.success || !result.data?.filterObjects)
        throw new Error("Invalid response from server");

      return result.data.filterObjects;
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 409) {
        throw new Error("Filter name already exists");
      }
      throw new Error("Error in Update Saved Filter!");
    }
  }
}

const adminSavedFilterApi = new AdminSavedFilterApi();

export default adminSavedFilterApi;
