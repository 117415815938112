import { KeyboardArrowUp } from "@mui/icons-material";
import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";
import { useScroll } from "../../hooks/useScroll";

export default function ScrollTopButton() {
  const trigger = useScrollTrigger();
  const scroll = useScroll();

  return (
    <Fade in={trigger}>
      <Box position="fixed" right="1rem" bottom="1rem">
        <Fab color="primary" size="small" aria-label="scroll back to top" onClick={() => scroll(0)}>
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Fade>
  );
}
