import React, { useState, useRef } from "react";
import { Box, Button, Typography, CircularProgress, useTheme, IconButton, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material/styles";
import assetApi from "../../apis/AssetApi";
import { createChunks, calculateProgress } from "../../utils/chunkUtils";
import { ChunkMetadata } from "../../types/common";

interface FileUploaderProps {
  onUploadSuccess: () => void;
}

interface PreviewFile {
  file: File;
  preview: string;
  progress?: number;
  type: "image" | "video";
}

export default function FileUploader({ onUploadSuccess }: FileUploaderProps) {
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const createPreviewFile = (file: File): PreviewFile => ({
    file,
    preview: URL.createObjectURL(file),
    progress: 0,
    type: file.type.startsWith("video/") ? "video" : "image",
  });

  const handleFiles = (mediaFiles: File[]) => {
    if (!mediaFiles.length) {
      return;
    }

    const newPreviewFiles = mediaFiles.map(createPreviewFile);
    setPreviewFiles((prev) => [...prev, ...newPreviewFiles]);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith("image/") || file.type.startsWith("video/")
    );
    handleFiles(files);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files).filter((file) =>
        file.type.startsWith("image/") || file.type.startsWith("video/")
      );
      handleFiles(files);
    }
  };

  const removePreview = (index: number) => {
    setPreviewFiles((prev) => {
      const newPreviews = [...prev];
      URL.revokeObjectURL(newPreviews[index].preview);
      newPreviews.splice(index, 1);
      return newPreviews;
    });
  };

  const uploadFile = async (file: File, onProgress?: (progress: number) => void): Promise<any> => {
    const chunks = createChunks(file);
    const totalChunks = chunks.length;
    const uploadId = crypto.randomUUID();
    let lastResult;

    for (let i = 0; i < chunks.length; i++) {
      const metadata: ChunkMetadata = {
        filename: file.name,
        index: i,
        totalChunks,
        uploadId,
      };

      const formData = new FormData();
      formData.append("chunk", chunks[i]);
      formData.append("index", metadata.index.toString());
      formData.append("totalChunks", metadata.totalChunks.toString());
      formData.append("filename", metadata.filename);
      formData.append("uploadId", metadata.uploadId);

      lastResult = await assetApi.uploadChunk(formData, (chunkProgress) => {
        if (onProgress) {
          const currentProgress = calculateProgress(i, totalChunks, chunkProgress);
          onProgress(currentProgress);
        }
      });

      if (lastResult.complete) {
        return lastResult.result;
      }

      if (lastResult.missingChunk !== undefined) {
        i = lastResult.missingChunk - 1;
      }
    }

    throw new Error("Upload failed to complete after all chunks were sent");
  };

  const handleUpload = async () => {
    if (isUploading || !previewFiles.length) return;

    setIsUploading(true);
    const results = [];

    try {
      for (let index = 0; index < previewFiles.length; index++) {
        const previewFile = previewFiles[index];
        const result = await uploadFile(previewFile.file, (progress) => {
          setPreviewFiles((prev) => {
            const updated = [...prev];
            updated[index] = { ...updated[index], progress };
            return updated;
          });
        });
        results.push(result);
      }

      setPreviewFiles([]);
      onUploadSuccess();
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
          borderRadius: 1,
          p: 3,
          textAlign: "center",
          bgcolor: isDragging ? alpha(theme.palette.primary.main, 0.05) : "transparent",
          transition: "all 0.2s ease-in-out",
        }}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*,video/*"
          multiple
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />
        <CloudUploadIcon sx={{ fontSize: 48, color: "text.secondary", mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          Drag and drop files here
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          or
        </Typography>
        <Button variant="outlined" onClick={() => fileInputRef.current?.click()}>
          Browse Files
        </Button>
      </Box>

      {previewFiles.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {previewFiles.map((file, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }} key={index}>
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "100%",
                    borderRadius: 1,
                    overflow: "hidden",
                    boxShadow: theme.shadows[2],
                  }}
                >
                  {file.type === "image" ? (
                    <Box
                      component="img"
                      src={file.preview}
                      alt={`Preview ${index + 1}`}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Box
                      component="video"
                      src={file.preview}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      controls
                      muted
                      loop
                      onMouseOver={(e) => (e.target as HTMLVideoElement).play()}
                      onMouseOut={(e) => (e.target as HTMLVideoElement).pause()}
                    />
                  )}
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      removePreview(index);
                    }}
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      bgcolor: "background.paper",
                      "&:hover": {
                        bgcolor: "background.paper",
                      },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  {file.progress !== undefined && file.progress > 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        p: 1,
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        value={file.progress}
                        sx={{
                          height: 4,
                          borderRadius: 2,
                          bgcolor: "rgba(255, 255, 255, 0.2)",
                          "& .MuiLinearProgress-bar": {
                            bgcolor: "primary.main",
                          },
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{ color: "white", mt: 0.5, display: "block", textAlign: "center" }}
                      >
                        {Math.round(file.progress)}%
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleUpload} disabled={isUploading}>
              Upload {previewFiles.length} {previewFiles.length === 1 ? "File" : "Files"}
              {isUploading && <CircularProgress size={24} sx={{ ml: 1 }} />}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
