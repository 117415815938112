import React, { useEffect, useState } from "react";
import { Button, Box, Divider, MenuItem, Typography, InputAdornment, TextField } from "@mui/material";
import CustomDialog from "../common/CustomDialog";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { boxApi } from "../../apis/BoxApi";
import { ripPackApi } from "../../apis/RipPackApi";
import { CurrencyEnum } from "../../types/currency";
import { getCurrencyOptions, getCurrencyProps } from "../../utils/currency";
import { Label } from "@mui/icons-material";

export default function CreateBox({
  open,
  onClose,
  setRefresh,
}: {
  open: boolean;
  onClose: () => void;
  setRefresh: (value: boolean) => void;
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<String>("");
  const [type, setType] = useState<string>("");
  const [typeError, setTypeError] = useState<String>("");
  const [description, setDescription] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [price, setPrice] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyEnum>(CurrencyEnum.USD);
  const [packsAdded, setPacksAdded] = useState<{ quantity: number; pack: any }[]>([{ pack: null, quantity: 0 }]);
  const [packsAddedError, setPacksAddedError] = useState<String>("");
  const [ripPacksMap, setRipPacksMap] = useState<{ [key: string]: any }>({});
  const currencyProps = getCurrencyProps(currency);

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value as CurrencyEnum);
  };

  async function handleCreateBox() {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("type", type);
      formData.append("description", description);
      formData.append("price", price.toString());
      formData.append("currency", currency);
      if (packsAdded) {
        const packObj = packsAdded.map((packWithQuantity, index) => {
          return {
            quantity: packWithQuantity?.quantity,
            packId: packWithQuantity?.pack?._id,
          };
        });
        formData.append("packsAdded", JSON.stringify(packObj));
      }
      if (image) formData.append("boxImage", image);
      await boxApi.createBox(formData);
      dispatch(openSnackbar({ message: "Box created successfully!", severity: "success" }));
      setRefresh(true);
      onClose();
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Error creating box!", severity: "error" }));
    }
  }

  useEffect(() => {
    ripPackApi
      .getRipPackDetails({})
      .then(({ ripPacks, total }) => {
        let ripPacksMap_: {
          [key: string]: any;
        } = {};

        ripPacks.forEach(({ ripPackId, details }: any) => {
          ripPacksMap_[ripPackId] = details;
        });
        setRipPacksMap(ripPacksMap_ || {});
      })
      .catch((e) => {
        console.error(e);
        onClose();
        dispatch(openSnackbar({ message: "Error fetching rip packs!", severity: "error" }));
      });
  }, [open, dispatch, onClose]);

  useEffect(() => {
    if (name.length === 0) {
      setNameError("Name is required!");
    } else {
      setNameError("");
    }
  }, [name]);
  useEffect(() => {
    if (type.length === 0) {
      setTypeError("Type is required!");
    } else {
      setTypeError("");
    }
  }, [type]);
  useEffect(() => {
    if (packsAdded.length === 0) {
      setPacksAddedError("Packs are required!");
    } else {
      let error = "";

      packsAdded.forEach((packWithQuantity, index) => {
        if (!packWithQuantity?.pack) {
          error = "Pack is required!";
        } else if (!packWithQuantity?.quantity || packWithQuantity?.quantity <= 0) {
          error = "Pack Quantity is required!";
        }
      });
      setPacksAddedError(error);
    }
  }, [packsAdded]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      avatarSrc=""
      text="Create Box"
      subText="Fill in the details for the new box"
    >
      <Typography variant="body1">Name</Typography>
      <TextField
        fullWidth
        type="text"
        error={!!nameError}
        helperText={nameError}
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <Divider sx={{ mb: 2 }} />

      <Typography variant="body1">Type</Typography>
      <TextField
        fullWidth
        value={type}
        error={!!typeError}
        helperText={typeError}
        onChange={(e) => setType(e.target.value)}
        required
      />
      <Divider sx={{ mb: 2 }} />

      <Typography variant="body1">Description</Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      />

      {Object.keys(ripPacksMap).length > 0 && (
        <>
          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => setPacksAdded((prev) => [...prev, { pack: null, quantity: 0 }])}>
              + Add Pack
            </Button>
            {packsAddedError && <Typography color="error">{packsAddedError}</Typography>}
          </Box>

          {packsAdded.map((packWithQuantity, index) => (
            <Box key={index} sx={{ display: "flex", gap: 2, alignItems: "center", mt: 2 }}>
              <TextField
                select
                label="Select Pack"
                fullWidth
                value={packWithQuantity?.pack?._id || ""}
                onChange={(e) => {
                  setPacksAdded((prev) => {
                    const newPacksAdded = [...prev];
                    newPacksAdded[index].pack = ripPacksMap[e.target.value];
                    return newPacksAdded;
                  });
                }}
              >
                <MenuItem value="">Select Pack</MenuItem>
                {Object.entries(ripPacksMap).map(([ripPackId, ripPack]) => (
                  <MenuItem key={ripPackId} value={ripPackId}>
                    {ripPack?.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                type="number"
                label="Quantity"
                fullWidth
                value={packsAdded[index]?.quantity}
                onChange={(e) => {
                  setPacksAdded((prev) => {
                    const newPacksAdded = [...prev];
                    newPacksAdded[index].quantity = parseInt(e.target.value);
                    return newPacksAdded;
                  });
                }}
                required
              />
            </Box>
          ))}

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1">Image</Typography>
          <input type="file" accept="image/*" onChange={(e) => setImage(e.target.files?.[0] || null)} required />
          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <TextField
              type="number"
              label="Price"
              value={price}
              onChange={(e) => setPrice(parseInt(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencyProps?.symbol}</InputAdornment>,
              }}
            />
            <TextField select label="Currency" value={currency} onChange={handleCurrencyChange}>
              {getCurrencyOptions().map((currencyOption) => (
                <MenuItem key={currencyOption} value={currencyOption}>
                  {currencyOption}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleCreateBox}
              disabled={!!packsAddedError || !!typeError || !!nameError}
            >
              Create Box
            </Button>
          </Box>
        </>
      )}
    </CustomDialog>
  );
}
