import React from "react";
import { Tabs, Tab, TextField, InputAdornment, IconButton, Tooltip, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { timeToDateStr } from "../../utils/time";

interface SubscriptionToolBarProps {
  tab: string;
  fromTimestamp: Date;
  toTimestamp: Date;
  total: number;
  searchQuery: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setFromTimestamp: (value: Date) => void;
  setToTimestamp: (value: Date) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
  setCreateSubscription: () => void;
}

export default function SubscriptionToolBar({
  tab,
  fromTimestamp,
  toTimestamp,
  total,
  searchQuery,
  handleTabChange,
  setFromTimestamp,
  setToTimestamp,
  setSearchQuery,
  onRefresh,
  setCreateSubscription,
}: SubscriptionToolBarProps) {
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", my: 2 }}>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="user status tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ "& .MuiTab-root": { textTransform: "none" } }} // Prevent text from being capitalized
        >
          <Tab
            label={tab === "all" ? `All Digital Subscriptions (${total})` : `All Digital Subscriptions`}
            value="all"
          />
          <Tab
            label={
              tab === "user-subscriptions-activity"
                ? `User Subscriptions Activity (${total})`
                : `User Subscriptions Activity`
            }
            value="user-subscriptions-activity"
          />
        </Tabs>
      </Grid>

      <Grid size={{ xs: 12, sm: 8 }} container spacing={2} justifyContent="flex-end">
        <Grid size={{ xs: tab === "all" ? 6 : 12, sm: 4 }}>
          <TextField
            variant="outlined"
            placeholder="Search users..."
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>

        {tab !== "all" && (
          <React.Fragment>
            <Grid size={{ xs: 6, sm: 2 }}>
              <TextField
                type="date"
                label="From"
                value={timeToDateStr(fromTimestamp)}
                onChange={(event) => {
                  setFromTimestamp(new Date(event.target.value)); // new Date(event.target.value);
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <TextField
                type="date"
                label="To"
                value={timeToDateStr(toTimestamp)}
                onChange={(event) => setToTimestamp(new Date(event.target.value))}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
              />
            </Grid>
          </React.Fragment>
        )}

        <Grid size={{ xs: 4, sm: 2 }} container justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={setCreateSubscription}
            color="secondary"
            sx={{ borderRadius: 2, fontSize: 12 }}
          >
            Add Subscription
          </Button>
        </Grid>

        <Tooltip title="Refresh">
          <IconButton
            onClick={onRefresh}
            aria-label="refresh"
            sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
