import { Dialog, Box, IconButton, DialogTitle, DialogContent, Typography, Divider } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomDialog({ children, text, subText, showDivider = true, ...props }: any) {
  if (!props.maxWidth) props.maxWidth = "md";
  if (!props.fullWidth) props.fullWidth = true;

  return (
    <Dialog {...props}>
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div" fontWeight="bold" sx={{ pr: 6 }}>
          {text}
        </Typography>
        <IconButton
          onClick={props.onClose as any}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {subText && (
        <>
          <Box sx={{ px: 3, pb: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              {subText}
            </Typography>
          </Box>
        </>
      )}
      {showDivider && <Divider />}
      <DialogContent sx={{ pt: 2 }}>{children}</DialogContent>
    </Dialog>
  );
}
