import {
  Box,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Collapse,
  Typography,
  IconButton,
  Divider,
  Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Grid from "@mui/material/Grid2";
import { formatString } from "../../../utils/string";
import { rewardTypeEnum } from "../../../types/reward-enum";
import { format } from "path";
import { scratchCardStatusEnum, scratchCardTypeEnum } from "../../../types/scratch-card-enum";

interface ScratchCardFiltersProps {
  sectionOpen: boolean;
  setSectionOpen: () => void;
  filterRewardType: string;
  filterStatus: string;
  filterType: string;
  setFilterRewardType: (value: string) => void;
  setFilterStatus: (value: string) => void;
  setFilterType: (value: string) => void;
}

export default function ScratchCardFilters({
  sectionOpen,
  filterRewardType,
  filterStatus,
  filterType,
  setSectionOpen,
  setFilterRewardType,
  setFilterStatus,
  setFilterType,
}: ScratchCardFiltersProps) {
  function filterChips() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: 1,
          flexWrap: "wrap",
          overflow: "auto",
        }}
      >
        {filterRewardType && (
          <Chip
            label={`Prize Type: ${formatString(filterRewardType)}`}
            onDelete={() => handleDelete("rewardType")}
            color="primary"
          />
        )}
        {filterStatus && filterStatus !== "all" && (
          <Chip
            label={`Status: ${formatString(filterStatus)}`}
            onDelete={() => handleDelete("status")}
            color="primary"
          />
        )}
        {filterType && filterType !== "all" && (
          <Chip label={`Type: ${formatString(filterType)}`} onDelete={() => handleDelete("type")} color="primary" />
        )}
        {(!filterStatus || filterStatus === "all") && (!filterType || filterType === "all") && !filterRewardType && (
          <Chip label="No Filter" color="primary" />
        )}
        {filterStatus === "claimed" && <Chip label={"Timestamp on Reward Claim Time"} color="primary" />}
        {filterStatus === "redeemed" && <Chip label={"Timestamp on Redeem Time"} color="primary" />}
      </Box>
    );
  }

  function handleSelectChange(type: string, value: string) {
    if (type === "rewardType") setFilterRewardType(value);
    else if (type === "status") setFilterStatus(value);
    else if (type === "type") setFilterType(value);
  }

  function handleDelete(type: string) {
    if (type === "rewardType") setFilterRewardType("");
    else if (type === "status") setFilterStatus("all");
    else if (type === "type") setFilterType("all");
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      mb={{ xs: 1, sm: 2 }}
      sx={{ zIndex: 1, justifyContent: "space-between", width: "100%" }}
    >
      <Grid
        boxSizing={"border-box"}
        sx={{ width: { xs: "100%", sm: "20rem" }, mt: { xs: 0, sm: 2 }, backgroundColor: "primary.secondary" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          component={Paper}
          onClick={setSectionOpen}
          boxShadow={3}
          sx={{
            borderBottomLeftRadius: sectionOpen ? 0 : "0.5rem",
            borderBottomRightRadius: sectionOpen ? 0 : "0.5rem",
            borderTopRightRadius: "0.5rem",
            borderTopLeftRadius: "0.5rem",
          }}
        >
          <Typography ml={2}>Filters</Typography>
          <IconButton aria-label="open-filter-section">
            {sectionOpen ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
          </IconButton>
        </Stack>
        <Box
          pl={2}
          pr={2}
          component={Paper}
          boxSizing={"border-box"}
          position={{ xs: "relative", sm: "absolute" }}
          boxShadow={3}
          width={"inherit"}
        >
          <Collapse in={sectionOpen} timeout="auto" unmountOnExit>
            <Typography sx={{ mt: 1 }}>Scratch Card Type</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={filterType === "all"} onChange={() => handleSelectChange("type", "all")} />}
                label="All"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterType === scratchCardTypeEnum.Physical}
                    onChange={() => handleSelectChange("type", scratchCardTypeEnum.Physical)}
                  />
                }
                label={formatString(scratchCardTypeEnum.Physical)}
              />
              {/* <FormControlLabel
                control={
                  <Checkbox checked={filterType === scratchCardTypeEnum.PromoCode} onChange={() => handleSelectChange("type", scratchCardTypeEnum.PromoCode)} />
                }
                label={formatString(scratchCardTypeEnum.PromoCode)}
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterType === scratchCardTypeEnum.PromoCode}
                    onChange={() => handleSelectChange("type", scratchCardTypeEnum.PromoCode)}
                  />
                }
                label={formatString(scratchCardTypeEnum.PromoCode)}
              />
            </FormGroup>
            <Typography sx={{ mt: 1 }}>Prize Type</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterRewardType === rewardTypeEnum.AllSubscriptions}
                    onChange={() => handleSelectChange("rewardType", rewardTypeEnum.AllSubscriptions)}
                  />
                }
                label={formatString(rewardTypeEnum.AllSubscriptions)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterRewardType === rewardTypeEnum.RipPack}
                    onChange={() => handleSelectChange("rewardType", rewardTypeEnum.RipPack)}
                  />
                }
                label={formatString(rewardTypeEnum.RipPack)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterRewardType === "Merchandise"}
                    onChange={() => handleSelectChange("rewardType", "Merchandise")}
                  />
                }
                label="Merchandise"
              />
            </FormGroup>
            <Typography sx={{ mt: 1 }}>Status</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={filterStatus === "all"} onChange={() => handleSelectChange("status", "all")} />
                }
                label="All"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterStatus === scratchCardStatusEnum.Created}
                    onChange={() => handleSelectChange("status", scratchCardStatusEnum.Created)}
                  />
                }
                label={formatString(scratchCardStatusEnum.Created)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterStatus === scratchCardStatusEnum.Active}
                    onChange={() => handleSelectChange("status", scratchCardStatusEnum.Active)}
                  />
                }
                label={formatString(scratchCardStatusEnum.Active)}
              />
            </FormGroup>
          </Collapse>
        </Box>
      </Grid>
      <Box sx={{ mt: { xs: 0, sm: 2 } }}>{filterChips()}</Box>
    </Grid>
  );
}
