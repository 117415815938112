import React from "react";
import { TextField, IconButton, Tooltip, Tabs, Tab, InputAdornment, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { timeToDateStr } from "../../../utils/time";

interface ScratchCardToolBarProps {
  tab: string;
  total: number;
  searchQuery: string;
  fromTimestamp: Date;
  toTimestamp: Date;
  setFromTimestamp: (value: Date) => void;
  setToTimestamp: (value: Date) => void;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setShowCreateScratchCard: (value: boolean) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
}

export default function ScratchCardListToolBar({
  tab,
  total,
  searchQuery,
  fromTimestamp,
  toTimestamp,
  setFromTimestamp,
  setToTimestamp,
  handleTabChange,
  setSearchQuery,
  setShowCreateScratchCard,
  onRefresh,
}: ScratchCardToolBarProps) {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%", mt: 2, mb: { xs: 2, sm: 0 } }}
      >
        <Grid size={{ xs: 12, sm: 3 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="user status tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ "& .MuiTab-root": { textTransform: "none" } }}
          >
            <Tab label={tab === "all" ? `Scratch Cards (${total})` : `Scratch Cards`} value="all" />
          </Tabs>
        </Grid>

        <Grid size={{ xs: 12, sm: 9 }} container spacing={2} justifyContent="flex-end">
          <Grid size={{ xs: 12, sm: 5 }}>
            <TextField
              variant="outlined"
              placeholder={total ? "Search scratch cards..." : "No scratch cards to search"}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          <Grid size={{ xs: 6, sm: 2 }}>
            <TextField
              type="date"
              label="From"
              value={timeToDateStr(fromTimestamp)}
              onChange={(event) => setFromTimestamp(new Date(event.target.value))}
              slotProps={{ inputLabel: { shrink: true } }}
              sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <TextField
              type="date"
              label="To"
              value={timeToDateStr(toTimestamp)}
              onChange={(event) => setToTimestamp(new Date(event.target.value))}
              fullWidth
              slotProps={{ inputLabel: { shrink: true } }}
              sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
            />
          </Grid>

          <Grid size={{ xs: 10, sm: 2 }} container>
            <Button
              variant="contained"
              onClick={() => setShowCreateScratchCard(true)}
              color="secondary"
              sx={{ borderRadius: 2, fontSize: 12 }}
              fullWidth
            >
              Create Scratch Card
            </Button>
          </Grid>

          {/* <Grid size={{ xs: 10, sm: 2 }} container>
            <Button
              variant="contained"
              onClick={() => setShowCreateScratchCard(true)}
              color="secondary"
              sx={{ borderRadius: 2, fontSize: 12 }}
              fullWidth
            >
              Scratch Card Activation
            </Button>
          </Grid> */}

          <Tooltip title="Refresh">
            <IconButton
              onClick={onRefresh}
              aria-label="refresh"
              sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
              size="small"
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
