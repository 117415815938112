import React, { useEffect, useState } from "react";
import { Button, Box, Divider, TextField, Typography, Chip } from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import CustomDialog from "../common/CustomDialog";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { categoryApi } from "../../apis/CategoryApi";
import { getPathFromCategoryObj } from "../../utils/category";
import { CategoryPath } from "../category-item-management/CategoryPath";
import { Category, CategoryTag } from "../../types/common";
import { TagManager } from "./create-category/TagManager";

interface CreateCategoryProps {
  open: boolean;
  onClose: () => void;
  setRefresh: (value: boolean) => void;
  parentCategory: Category | null;
}

export default function CreateCategory({ open, onClose, setRefresh, parentCategory }: CreateCategoryProps) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [tags, setTags] = useState<CategoryTag[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [parentCategoryTags, setParentCategoryTags] = useState<CategoryTag[]>([]);

  useEffect(() => {
    if (parentCategory?.tags) {
      const initialTags = parentCategory.tags.map((categoryTag: any) => ({
        ...categoryTag,
        updatedValue: {
          ...categoryTag,
        },
      }));
      setParentCategoryTags(initialTags);
    }
  }, [parentCategory?.tags]);

  async function handleCreateCategory() {
    if (!title || tags.some((tag) => !tag)) {
      dispatch(openSnackbar({ message: "Title and Tags are required", severity: "warning" }));
      return;
    }
    try {
      const categoryData = {
        title,
        description,
        tags,
        parentId: parentCategory?._id,
      };

      const result = await categoryApi.createCategory(categoryData);
      if (!result) {
        throw new Error(`Failed to create category`);
      }

      dispatch(openSnackbar({ message: `create category successfully!`, severity: "success" }));
      setRefresh(true);
      onClose();
    } catch (e: any) {
      dispatch(openSnackbar({ message: `Error create category!`, severity: "error" }));
    }
  }

  const handleAddParentTag = (tag: CategoryTag) => {
    if (!tags.some((t) => t.tag === tag.tag)) {
      setTags([...tags, tag]);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      text={`Create Category`}
      subText="Fill in the details for the new category"
    >
      <Box sx={{ mb: 3 }}>
        <CategoryPath
          path={getPathFromCategoryObj({ categoryObject: parentCategory })}
          handleCategoryClick={() => {}}
          variant="form"
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Title
        </Typography>
        <TextField fullWidth value={title} onChange={(e) => setTitle(e.target.value)} required />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Description
        </Typography>
        <TextField fullWidth value={description} onChange={(e) => setDescription(e.target.value)} />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              color: "primary.main",
            }}
          >
            <LabelIcon sx={{ mr: 1 }} /> Parent Category Tags
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1.5,
              minHeight: "1rem",
              p: 2,
              border: "1px dashed",
              borderColor: "divider",
              borderRadius: 1,
              backgroundColor: "background.paper",
            }}
          >
            {parentCategoryTags.map((tag) => (
              <Chip
                key={tag.tag}
                label={tag.tag}
                color={tags.some((t) => t.tag === tag.tag) ? (tag.required ? "primary" : "secondary") : "default"}
                onClick={() => handleAddParentTag(tag)}
                sx={{
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    backgroundColor: tags.some((t) => t.tag === tag.tag) ? undefined : "action.hover",
                  },
                }}
              />
            ))}
          </Box>
          <Box sx={{ mt: -0.5, ml: 1, mb: 3 }}>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              Click on a tag to add it to the category.
            </Typography>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "primary.main",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LabelIcon sx={{ mr: 1 }} /> Current Tags
              </Box>
            </Typography>

            <Box
              sx={{
                p: 2,
                backgroundColor: "grey.50",
                borderRadius: 1,
                border: "1px solid",
                borderColor: "grey.200",
              }}
            >
              <TagManager tags={tags} onTagsChange={setTags} isEditing={isEditing} onEditingChange={setIsEditing} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={handleCreateCategory}>
          Create Category
        </Button>
      </Box>
    </CustomDialog>
  );
}
