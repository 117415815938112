export enum ItemStageEnum {
  Created = "created",
  Warehouse = "warehouse",
  InPackToBeAssigned = "in-pack-to-be-assigned",
  Assigned = "assigned",
}

export enum ItemTypesEnum {
  Serialised = "serialised",
  NonSerialised = "non-serialised",
  Variant = "variant",
}

export enum ItemVisibilitiesEnum {
  Public = "public",
  Private = "private",
}

export const itemGroupNameTag = "Group Name";

export let itemStages: ItemStageEnum[] = [
  ItemStageEnum.Created,
  ItemStageEnum.Warehouse,
  ItemStageEnum.InPackToBeAssigned,
  ItemStageEnum.Assigned,
];

export let itemTypes: string[] = [ItemTypesEnum.Serialised, ItemTypesEnum.NonSerialised, ItemTypesEnum.Variant];

export let itemVisibilities: string[] = [ItemVisibilitiesEnum.Public, ItemVisibilitiesEnum.Private];

export const itemStagesMap: {
  [key in ItemStageEnum]: { color: string; contrastText: string; permissions: string[] };
} = {
  [ItemStageEnum.Created]: {
    color: "#B3E5FC", // Soft sky blue for 'Created'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
  [ItemStageEnum.Warehouse]: {
    color: "#FFF9C4", // Pale yellow for 'Warehouse'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
  [ItemStageEnum.InPackToBeAssigned]: {
    color: "#D1C4E9", // Soft lavender for 'In Pack to Be Assigned'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
  [ItemStageEnum.Assigned]: {
    color: "#C8E6C9", // Light mint green for 'Assigned'
    contrastText: "#263238", // Dark text for contrast
    permissions: [],
  },
};
