import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  Avatar,
  IconButton,
  Tooltip,
  Link,
} from "@mui/material";
import { getS3ImageUrl } from "../../../utils/s3Image";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { formatPriceFromObject } from "../../../utils/currency";
import InsightsIcon from "@mui/icons-material/Insights";

interface PackDetailRowProps {
  open: boolean;
  onClose: () => void;
  setSelectedPack: (pack: any) => void;
  ripPacks: any[];
  setPackSelectedForStats?: any;
}

export default function PackDetailRow({
  open,
  onClose,
  ripPacks,
  setSelectedPack,
  setPackSelectedForStats,
}: PackDetailRowProps) {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={2}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Pack Details
            </Typography>
            <Table
              size="small"
              sx={{
                backgroundColor: "secondary.light",
                borderRadius: 1,
                boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
                "&:hover": { backgroundColor: "secondary.light", cursor: "pointer", color: "white" },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Pack Name</strong>
                  </TableCell>

                  <TableCell>
                    <strong>Type</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Pack Quantity</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Max Quantity (Per Pack)</strong>
                  </TableCell>
                  <TableCell>
                    <strong>
                      <strong>Total Items In Pack</strong>
                    </strong>
                  </TableCell>
                  <TableCell>
                    <strong>Price</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Insights</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ripPacks.length > 0 ? (
                  ripPacks.map((ripPack: any, index: number) => (
                    <TableRow
                      key={ripPack?.details?._id || index}
                      hover
                      onClick={() => setSelectedPack(ripPack?.details)}
                    >
                      <TableCell>
                        {" "}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <Avatar
                            alt={ripPack?.details?.name}
                            src={getS3ImageUrl({
                              imageUrl: ripPack?.details?.packTypeId?.imageUrl,
                              type: "pack-type",
                            })}
                            sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
                          />
                          <Box>
                            <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                              {ripPack?.details?.name || "No RipPack Name"}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: { xs: "0.75rem", sm: "0.875rem" },
                              }}
                            >
                              <ContentCopyIcon sx={{ mr: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" } }} />
                              {ripPack?.details?._id || "No RipPack Id"}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell>{ripPack?.details?.packTypeId?.name || "N/A"}</TableCell>
                      <TableCell sx={{ maxWidth: 100, overflow: "hidden", textOverflow: "ellipsis" }}>
                        {ripPack?.details?.packTypeId?.description || "N/A"}
                      </TableCell>
                      <TableCell>{ripPack?.details?.packQuantity || "N/A"}</TableCell>
                      <TableCell>{ripPack?.details?.maxQuantityPerPack || "N/A"}</TableCell>
                      <TableCell>
                        <Tooltip title="Link to Pack Manager for adding items to this pack">
                          <Link href={`/category-item-management?ripPackId=${ripPack?.details?._id}`}>
                            {ripPack?.stats?.totalItemsInPack}
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{formatPriceFromObject(ripPack?.details) || "N/A"}</TableCell>
                      <TableCell>
                        <IconButton>
                          <Tooltip title="Odds breakdown of items in this pack">
                            <InsightsIcon
                              onClick={() => {
                                setPackSelectedForStats && setPackSelectedForStats(ripPack?.details);
                              }}
                            />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No packs found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
