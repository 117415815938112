import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { eventApi } from "../../apis/EventApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import FileUploadButton from "../common/FileUploadButton";
import CustomDialog from "../common/CustomDialog";

export default function CreateEvent({ open, onClose, setRefresh }: any) {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [eventDate, setEventDate] = useState<string>("");
  const [eventTime, setEventTime] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setImageUrlPreview(URL.createObjectURL(file));
    }
  };

  async function handleCreateEvent() {
    if (!name || !selectedFile || !eventDate || !eventTime) {
      dispatch(openSnackbar({ message: "All fields are required", severity: "warning" }));
      return;
    }

    const eventDateTime = new Date(`${eventDate} ${eventTime}`).toISOString();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("eventDateTime", eventDateTime);
    formData.append("eventImage", selectedFile as Blob);

    try {
      await eventApi.createEvent(formData);
      dispatch(openSnackbar({ message: "Event created successfully", severity: "success" }));
      onClose();
      setRefresh(true);
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to create Event!", severity: "error" }));
      console.error(e);
    }
  }

  return (
    <CustomDialog open={open} onClose={onClose} text="Create Event" subText="Fill in the details for the new event">
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body1">Name</Typography>
          <TextField value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
        </Stack>

        <Stack>
          <Typography variant="body1">Description</Typography>
          <TextField
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
          />
        </Stack>

        <Stack>
          <Typography variant="body1">Event Date</Typography>
          <TextField
            type="date"
            value={eventTime}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventTime(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
          />
        </Stack>

        <Stack>
          <Typography variant="body1">Event Time</Typography>
          <TextField
            type="time"
            value={eventDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>

        <Stack>
          <Typography variant="body1">Image</Typography>
          <FileUploadButton onFileChange={handleFileChange} />
          {imageUrlPreview && <Box component="img" src={imageUrlPreview} alt="Uploaded" />}
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={handleCreateEvent}>
            Create Event
          </Button>
        </Stack>
      </Stack>
    </CustomDialog>
  );
}
