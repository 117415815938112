import { TableCell, TableHead, TableRow } from "@mui/material";

interface RoleManagementHeaderProps {
  tab: string;
}

export default function RoleManagementHeader({ tab }: RoleManagementHeaderProps) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Username</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Role</TableCell>
        <TableCell sx={{ borderBottom: "none" }}></TableCell>
      </TableRow>
    </TableHead>
  );
}
