import { Button, Divider, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import CustomDialog from "../common/CustomDialog";
import { adminApi } from "../../apis/AdminApi";

export default function UpdateAdminRole({ open, onClose, setRefresh, user, roles }: any) {
  const dispatch = useDispatch();
  const [role, setRole] = useState<string>(user.role || "");
  async function handleUpdateRole() {
    try {
      if (user.role === role) {
        onClose();
        return;
      }
      await adminApi.updateAdminRole({ id: user._id, role });
      dispatch(openSnackbar({ message: "Role Updated successfully", severity: "success" }));
      onClose();
      setRefresh(true);
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to Update Role!", severity: "error" }));
      console.error(e);
    }
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      avatarSrc=""
      text="Update Role"
      subText=" Change role of user"
      linkText=""
      linkUrl=""
      linkText2=""
      linkUrl2=""
    >
      {({ InputContainer, Label, InputFieldsContainer, StyledTextField, StyledImage }: any) => (
        <React.Fragment>
          <InputContainer>
            <Label variant="body1">Username</Label>
            <Typography variant="body2">{user.username || "No Username"}</Typography>
          </InputContainer>
          <Divider sx={{ mb: 2 }} />

          <InputContainer>
            <Label variant="body1">Email</Label>
            <Typography variant="body2">{user.email || "No Email"}</Typography>
          </InputContainer>
          <Divider sx={{ mb: 2 }} />

          <InputContainer>
            <Label variant="body1">Role</Label>
            <Select
              value={role}
              onChange={(e) => setRole(e.target.value as string)} // Use _id as the value
              fullWidth
              displayEmpty
            >
              {roles.map((roleItem: any) => (
                <MenuItem key={roleItem._id} value={roleItem._id}>
                  {roleItem.roleName}
                </MenuItem>
              ))}
            </Select>
          </InputContainer>

          <Divider sx={{ mb: 2 }} />

          <InputContainer sx={{ justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUpdateRole}>
              Confirm
            </Button>
          </InputContainer>
        </React.Fragment>
      )}
    </CustomDialog>
  );
}
