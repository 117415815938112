export enum ItemTagEnum {
  GROUP_NAME = "Group Name",
}

export enum ItemSortEnum {
  Title_Asc = "title_1",
  Title_Desc = "title_-1",
  Quantity_Asc = "quantity_1",
  Quantity_Desc = "quantity_-1",
}

export const ItemSortLabels: Record<ItemSortEnum, string> = {
  [ItemSortEnum.Title_Asc]: "Title A to Z",
  [ItemSortEnum.Title_Desc]: "Title Z to A",
  [ItemSortEnum.Quantity_Asc]: "Quantity Low to High",
  [ItemSortEnum.Quantity_Desc]: "Quantity High to Low",
};
