import BaseAPI from "./BaseApi";

class AdminApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/admin", cache: false });
  }

  async getAdmin(id: string) {
    try {
      const result = await this.axios.get("/", {
        params: { id },
      });
      if (!result.data || !result.data.admin) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in AdminApi.getAdmin: " + error.message);
    }
  }

  async registerAdmin({
    email,
    password,
    username,
  }: {
    email: string;
    password: string;
    username: string;
  }): Promise<{ admin: any }> {
    try {
      const result = await this.axios.post("/register", {
        email,
        password,
        username,
      });
      if (!result.data || !result.data.admin) throw new Error("Invalid response from server");
      const { admin } = result.data;
      return { admin };
    } catch (error: any) {
      throw new Error("Error in AdminApi.registerAdmin: " + error.message);
    }
  }

  async updateAdmin({
    id,
    email,
    password,
    username,
  }: {
    id: string;
    email?: string;
    password?: string;
    username?: string;
  }): Promise<any> {
    try {
      const result = await this.axios.put("/update", {
        id,
        email,
        password,
        username,
      });
      if (!result.data || !result.data.admin) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {}
  }

  async getAllAdmins(): Promise<any[]> {
    try {
      const result = await this.axios.get("/allAdmins");
      if (!result.data || !result.data.admins) throw new Error("Invalid response from server");
      return result.data.admins;
    } catch (error: any) {
      throw new Error("Error in AdminApi.getAllAdmins: " + error.message);
    }
  }

  async getAllRoles() {
    try {
      const result = await this.axios.get("/allRoles");
      if (!result.data || !result.data.roles) throw new Error("Invalid response from server");
      return result.data.roles;
    } catch (error: any) {
      throw new Error("Error in RoleApi.getAllRoles: " + error.message);
    }
  }
  async updateAdminRole({ id, role }: { id: string; role: string }): Promise<any> {
    try {
      const result = await this.axios.put("/updateRole", { id, role });
      if (!result.data || !result.data.admin) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in AdminApi.updateAdminRole: " + error.message);
    }
  }
}

export const adminApi = new AdminApi();
