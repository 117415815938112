import { Box, Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import FileUploadButton from "../common/FileUploadButton";
import CustomDialog from "../common/CustomDialog";
import { rewardApi } from "../../apis/RewardApi";
import { rewardTypeEnum } from "../../types/reward-enum";
import { formatString } from "../../utils/string";
import { ripPackApi } from "../../apis/RipPackApi";

export default function CreateReward({ open, onClose, setRefresh }: any) {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<rewardTypeEnum>(rewardTypeEnum.AllSubscriptions);
  const [prizeList, setPrizeList] = useState<any[]>([]);
  const [selectedPrize, setSelectedPrize] = useState<any>(null);
  const [points, setPoints] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setImageUrlPreview(URL.createObjectURL(file));
    }
  };

  async function handleCreateReward() {
    if (!name || !selectedFile || !points || !type) {
      dispatch(openSnackbar({ message: "All fields are required", severity: "warning" }));
      return;
    }

    if (type === rewardTypeEnum.RipPack && !selectedPrize) {
      dispatch(openSnackbar({ message: "Please select a prize", severity: "warning" }));
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("type", type);
    if (type === rewardTypeEnum.RipPack) formData.append("ripPackId", selectedPrize);
    formData.append("points", points.toString());
    formData.append("rewardImage", selectedFile as Blob);

    try {
      await rewardApi.createReward(formData);
      dispatch(openSnackbar({ message: "Prize created successfully", severity: "success" }));
      onClose();
      setRefresh(true);
    } catch (e: any) {
      // dispatch(openSnackbar({ message: "Failed to create Reward!", severity: "error" }));
      console.error(e);
    }
  }

  async function handleTypeChange(event: React.ChangeEvent<{ value: unknown }>) {
    const selectedValue = event.target.value as rewardTypeEnum;

    if (selectedValue === rewardTypeEnum.RipPack) {
      const rewards = await ripPackApi.getRipPackDetails({});
      setPrizeList(rewards?.ripPacks);
    }
  }

  return (
    <CustomDialog open={open} onClose={onClose} text="Create Prize" subText="Fill in the details for the new Prize">
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body1">Name</Typography>
          <TextField value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
        </Stack>

        <Stack>
          <Typography variant="body1">Description</Typography>
          <TextField
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
          />
        </Stack>

        <Stack>
          <Typography variant="body1">Type</Typography>
          <TextField
            select
            value={type}
            onChange={(e) => {
              setType(e.target.value as rewardTypeEnum);
              handleTypeChange(e);
            }}
          >
            {Object.values(rewardTypeEnum).map((rewardType: rewardTypeEnum) => (
              <MenuItem key={rewardType} value={rewardType}>
                {formatString(rewardType)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        {type === rewardTypeEnum.RipPack && (
          <Stack>
            <Typography variant="body1">Rip Packs</Typography>
            <TextField
              select
              value={selectedPrize}
              onChange={(e) => {
                setSelectedPrize(e.target.value);
              }}
            >
              {prizeList.map((prize: any) => (
                <MenuItem key={prize?.details?.id} value={prize?.details?.id}>
                  {prize?.details?.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        )}
        {/* <Stack>
          <Typography variant="body1">Points</Typography>
          <TextField
            type="number"
            value={points}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoints(Number(e.target.value))}
          />
        </Stack> */}

        <Stack>
          <Typography variant="body1">Image</Typography>
          <FileUploadButton onFileChange={handleFileChange} />
          {imageUrlPreview && <Box component="img" src={imageUrlPreview} alt="Uploaded" />}
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={handleCreateReward}>
            Create Prize
          </Button>
        </Stack>
      </Stack>
    </CustomDialog>
  );
}
