import React from "react";
import { TableRow, TableCell, Typography, Box, Avatar, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getS3ImageUrl } from "../../utils/s3Image";
import EditIcon from "@mui/icons-material/Edit";
import InsightsIcon from "@mui/icons-material/Insights";
interface BoxItemDetailRowProps {
  tab?: string;
  boxItem?: any; // Adjust the type based on your boxItem structure
  setBoxItemToEdit?: any;
  setBoxItemForStats?: any;
}

export default function BoxItemDetailRow({
  tab,
  boxItem,
  setBoxItemToEdit,
  setBoxItemForStats,
}: BoxItemDetailRowProps) {
  if (!boxItem) return null;

  return (
    <React.Fragment>
      <TableRow key={boxItem?._id} sx={{ cursor: "pointer" }}>
        <TableCell>
          {" "}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              alt={boxItem?.boxDetails?.name}
              src={getS3ImageUrl({
                imageUrl: boxItem?.boxId?.imageUrl,
                type: "box",
              })}
              sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
            />
            <Box>
              <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                {boxItem?.boxId?.name || "No Box Name"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.75rem", sm: "0.875rem" },
                }}
              >
                <ContentCopyIcon sx={{ mr: 1, fontSize: { xs: "0.7rem", sm: "0.8rem" } }} />
                {boxItem?._id || "No Box Item Id"}
              </Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell>{boxItem?.owner?.emailAddress}</TableCell>

        <TableCell>
          <IconButton>
            <InsightsIcon
              onClick={() => {
                setBoxItemForStats(boxItem);
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
