import { Item, ItemTag } from "../types/common";
import { ItemStageEnum, ItemVisibilitiesEnum } from "../types/stage";
import { getAuthCookies } from "../utils/auth";
import BaseAPI from "./BaseApi";

interface GetItemsParams {
  categoryId?: string;
  page?: number;
  limit?: number;
  total?: boolean;
  sortKey?: string;
  sortDirection?: string;
  search?: string;
  groups?: string[];
  nonSerialisedItems?: string[];
  stages?: string[];
  visibility?: string;
  tags?: ItemTag[];
  title?: string;
  project?: string[];
  tagValues?: string[];
}

interface GetItemsResponse {
  items: Item[];
  total: number;
  totalQuantity: number;
  groupMax: Record<string, number>;
  success: boolean;
}

export interface FilterCount {
  count: number;
  value: string;
}

export interface FilterCountGroupNames extends FilterCount {
  maxNumberId: number;
}

export interface FiltersWithCount {
  groupNames: FilterCountGroupNames[];
  nonSerialisedItems: FilterCount[];
  visibilities: FilterCount[];
  tagValues: FilterCount[];
  stages: FilterCount[];
}

class ItemApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/item", cache: false });
  }

  async getItem(itemId: string): Promise<any> {
    try {
      const result = await this.axios.get(`/details/${itemId}`);
      if (!result?.data || !result.data?.success || !result.data?.item) throw new Error("Invalid response from server");
      return result.data.item;
    } catch (error: any) {
      throw new Error("Error in ItemApi.getItem: " + error.message);
    }
  }

  async getItems({
    categoryId,
    page = 1,
    limit = 10,
    total = false,
    sortKey,
    sortDirection,
    groups,
    nonSerialisedItems,
    stages,
    visibility,
    tags,
    search,
    title,
    project,
    tagValues,
  }: GetItemsParams): Promise<GetItemsResponse> {
    try {
      if (!categoryId && !search) return { items: [], total: 0, totalQuantity: 0, groupMax: {}, success: true };

      const result = await this.axios.get("/getItems", {
        params: {
          categoryId,
          page,
          limit,
          total,
          search,
          groups,
          nonSerialisedItems,
          stages,
          visibility,
          tags,
          tagValues,
          sortKey,
          sortDirection,
        },
      });

      if (!result?.data || !result.data?.success || !result.data?.items) {
        throw new Error("Invalid response from server");
      }

      return result.data;
    } catch (error: any) {
      console.error(error);
      throw new Error("Error in ItemApi.getItems: " + error.message);
    }
  }

  async createItem(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/createItem", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!result?.data || !result.data?.success || !result.data?.item) throw new Error("Invalid response from server");
      return result.data.item;
    } catch (error: any) {
      throw new Error("Error in ItemApi.createItem: " + error.message);
    }
  }

  async updateItemsBatch(formData: FormData): Promise<any> {
    try {
      const result = await this.axios.post("/updateItemsBatch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: getAuthCookies(),
        },
      });
      if (!result.data || !result.data?.success) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in ItemApi.updateItemsBatch: " + error.message);
    }
  }

  async getFiltersWithCount(
    categoryId: string,
    filter: {
      groupNames?: string[];
      visibility?: string;
      tagValues?: string[];
      stages?: string[];
      nonSerialisedItems?: string[];
    }
  ): Promise<FiltersWithCount> {
    try {
      const cleanedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
        if (Array.isArray(value) && value.length === 0) return acc;
        return { ...acc, [key]: value };
      }, {});

      const result = await this.axios.get("/getFiltersWithCount", {
        params: { categoryId, ...cleanedFilter },
      });

      if (!result?.data || !result.data?.success || !result.data?.filters) {
        throw new Error("Invalid response from server");
      }

      return result?.data?.filters;
    } catch (error: any) {
      throw new Error("Error in ItemApi.getFiltersWithCount: " + error.message);
    }
  }
}

export const itemApi = new ItemApi();
