import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  IconButton,
  useTheme,
  alpha,
  Tooltip,
  useMediaQuery,
  Checkbox,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  getItemDisplaySerialNumber,
  getItemDisplayTitle,
  getItemGroupName,
  getS3ImageUrlForItem,
} from "../../../utils/item";
import { Item } from "../../../types/common";
import { itemGroupNameTag, ItemStageEnum, ItemTypesEnum, ItemVisibilitiesEnum } from "../../../types/stage";
import { formatDate } from "../../../utils/time";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";

interface ItemCardProps {
  item: Item;
  onEdit?: (item: Item) => void;
  onDelete?: (item: Item) => void;
  hoveredGroupName: string | null;
  onGroupNameHover: (groupName: string | null) => void;
  isSelected?: boolean;
  serialNumberMax?: number;
  onSelect?: () => void;
}

export default function ItemCard({
  item,
  onEdit,
  onDelete,
  hoveredGroupName,
  onGroupNameHover,
  isSelected = false,
  serialNumberMax,
  onSelect,
}: ItemCardProps) {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "200px 0px 0px 0px",
    triggerOnce: true,
  });
  if (!inView) return <Box ref={ref} sx={{ height: "15rem" }} />;

  const groupNameTagValue = getItemGroupName({ itemObj: item });
  const isHighlighted = hoveredGroupName === groupNameTagValue;

  if (!item) return null;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: 1.5, sm: 2 },
        transition: "all 0.3s ease",
        outline: isHighlighted ? `2px solid ${theme.palette.primary.main}` : "none",
        boxShadow: isHighlighted ? `0 8px 24px ${alpha(theme.palette.primary.main, 0.25)}` : theme.shadows[1],
        position: "relative",
        "&:hover": {
          boxShadow: isHighlighted ? `0 8px 24px ${alpha(theme.palette.primary.main, 0.25)}` : theme.shadows[8],
          "& .item-actions": {
            opacity: 1,
          },
        },
      }}
    >
      {searchParams.get("ripPackId") && (
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            zIndex: 2,
            opacity: isSelected ? 1 : 0,
            transition: "opacity 0.2s ease",
            ".MuiCard-root:hover &": {
              opacity: 1,
            },
          }}
        >
          {item.stage === ItemStageEnum.Assigned ? (
            <Tooltip title="Item is already in a pack">
              <Box
                sx={{
                  p: 1,
                  borderRadius: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AssignmentTurnedInIcon
                  sx={{
                    color: "success.main",
                    fontSize: "1.25rem",
                  }}
                />
              </Box>
            </Tooltip>
          ) : (
            <Checkbox
              checked={isSelected}
              onClick={(e) => {
                e.stopPropagation();
                onSelect?.();
              }}
              sx={{
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: 1,
                "&.Mui-checked": {
                  color: "primary.main",
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: isSelected ? "white" : "rgba(0, 0, 0, 0.4)",
                },
              }}
            />
          )}
        </Box>
      )}

      <Box sx={{ position: "relative", width: "100%" }}>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            loading="lazy"
            sx={{
              width: "100%",
              height: { xs: 150, sm: 180, md: 220, lg: 260 },
              objectFit: "contain",
              bgcolor: "secondary.main",
              transition: "all 0.3s ease",
            }}
            image={getS3ImageUrlForItem({ itemObj: item })}
            alt={getItemDisplayTitle({ itemObj: item }) || item?.title}
          />
          {item?.type === ItemTypesEnum.NonSerialised && item?.quantity !== undefined && (
            <Box
              sx={{
                position: "absolute",
                bottom: 8,
                right: 8,
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                color: "white",
                borderRadius: "0.25rem",
                padding: "0.25rem 0.5rem",
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
                fontSize: "0.875rem",
                fontWeight: 500,
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: "inherit",
                  fontWeight: "inherit",
                }}
              >
                Quantity: {item.quantity}
              </Typography>
            </Box>
          )}
          {item?.type === ItemTypesEnum.Serialised && groupNameTagValue && (
            <Box
              sx={{
                position: "absolute",
                top: "0.5rem",
                left: "0.5rem",
                background: isHighlighted
                  ? `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
                  : "rgba(255, 152, 0, 0.9)",
                color: "white",
                borderRadius: "0.5rem",
                padding: "0.5rem 0.75rem",
                display: "flex",
                alignItems: "center",
                gap: "0.3rem",
                fontSize: "0.875rem",
                fontWeight: 600,
                cursor: "pointer",
                boxShadow: isHighlighted
                  ? `0 4px 12px ${alpha(theme.palette.primary.main, 0.4)}`
                  : "0 2px 8px rgba(0,0,0,0.2)",
                transform: isHighlighted ? "translateY(-2px)" : "none",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.4)}`,
                },
              }}
              onMouseEnter={() => onGroupNameHover(groupNameTagValue)}
              onMouseLeave={() => onGroupNameHover(null)}
            >
              <CollectionsBookmarkIcon
                sx={{
                  fontSize: "1.1rem",
                  animation: isHighlighted ? "pulse 2s infinite" : "none",
                }}
              />
              <Typography
                component="span"
                sx={{
                  fontSize: "inherit",
                  fontWeight: "inherit",
                  textShadow: "0 1px 2px rgba(0,0,0,0.1)",
                }}
              >
                {groupNameTagValue}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0.5rem",
            left: "0.5rem",
            bgcolor: item?.visibility === ItemVisibilitiesEnum.Public ? "green" : "red",
            color: "white",
            borderRadius: "0.5rem",
            padding: "0.25rem 0.5rem",
            fontSize: "0.75rem",
            fontWeight: 600,
          }}
        >
          <Tooltip
            title={
              item?.visibility === ItemVisibilitiesEnum.Private
                ? `Item will be made public on ${formatDate(item?.publicVisibilityDate)}`
                : ""
            }
          >
            <Typography sx={{ fontSize: "0.75rem", fontWeight: 600 }}>
              {item.visibility === ItemVisibilitiesEnum.Public ? "Public" : "Private"}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          className="item-actions"
          sx={{
            position: "absolute",
            top: { xs: 4, sm: 8 },
            right: { xs: 4, sm: 8 },
            display: "flex",
            gap: { xs: 0.5, sm: 1 },
            opacity: isMobile ? 1 : 0,
            transition: "opacity 0.2s ease",
            bgcolor: "rgba(255, 255, 255, 0.9)",
            borderRadius: 1,
            padding: { xs: "0.125rem", sm: "0.25rem" },
          }}
        >
          {onEdit && (
            <Tooltip title="Edit Item">
              <IconButton
                size={isMobile ? "small" : "medium"}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(item);
                }}
                sx={{
                  color: "primary.main",
                  "&:hover": { bgcolor: alpha(theme.palette.primary.main, 0.1) },
                }}
              >
                <EditIcon fontSize={isMobile ? "small" : "medium"} />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title="Delete Item">
              <IconButton
                size={isMobile ? "small" : "medium"}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(item);
                }}
                sx={{
                  color: "error.main",
                  "&:hover": { bgcolor: alpha(theme.palette.error.main, 0.1) },
                }}
              >
                <DeleteIcon fontSize={isMobile ? "small" : "medium"} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <CardContent
        sx={{
          flexGrow: 1,
          pt: { xs: 1.5, sm: 2 },
          px: { xs: 1.5, sm: 2 },
          pb: { xs: 1.5, sm: 2 },
        }}
      >
        <Box sx={{ mb: { xs: 1, sm: 1.5 } }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              mb: 0.5,
              fontSize: { xs: "0.875rem", sm: "1rem" },
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {getItemDisplayTitle({ itemObj: item }) || item?.title}
            {item.type === ItemTypesEnum.Serialised &&
              " " + getItemDisplaySerialNumber({ itemObj: item, serialNumberMax })}
            {item.stage === ItemStageEnum.Assigned && (
              <Tooltip title="Item is in a pack">
                <AssignmentTurnedInIcon
                  sx={{
                    color: "success.main",
                    fontSize: "1rem",
                  }}
                />
              </Tooltip>
            )}
          </Typography>
        </Box>
        {item?.description && !isMobile && (
          <Typography sx={{ fontSize: "0.8rem", mb: 1, ml: 0.2, color: "text.secondary" }}>
            {item?.description}
          </Typography>
        )}
        {item?.tags && item?.tags.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: { xs: 0.5, sm: 0.75 },
              mt: "auto",
            }}
          >
            {item?.tags.map((tag: any, index: number) =>
              tag.value.trim() !== "" && tag?.tag !== itemGroupNameTag ? (
                <Tooltip
                  key={index}
                  title={tag?.tag}
                  placement="top"
                  arrow
                  enterDelay={200}
                  enterNextDelay={200}
                  TransitionProps={{
                    timeout: 300,
                  }}
                >
                  <Chip
                    label={tag?.value}
                    sx={{
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      color: "primary.main",
                      fontSize: "0.7rem",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        bgcolor: alpha(theme.palette.primary.main, 0.15),
                        boxShadow: theme.shadows[1],
                      },
                    }}
                  />
                </Tooltip>
              ) : null
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
