import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Button,
  Box,
  MenuItem,
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import CustomDialog from "../common/CustomDialog";
import ImageUploader from "../common/ImageUploader";
import { itemApi } from "../../apis/ItemApi";
import { itemGroupNameTag, itemTypes, ItemTypesEnum, itemVisibilities, ItemVisibilitiesEnum } from "../../types/stage";
import { Tag } from "../../types/common";
import { categoryApi } from "../../apis/CategoryApi";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

interface CategoryTag {
  tag: string;
  values: string[];
  required: boolean;
}

interface ItemVariant {
  id?: string;
  name: string;
  quantity: number;
  imageUrl?: string;
  file?: File;
}

export default function CreateItem({ open, onClose, setRefresh, preselectedCategory }: any) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [visibility, setVisibility] = useState<string>(ItemVisibilitiesEnum.Public);
  const [publicVisibilityDate, setPublicVisibilityDate] = useState<string>("");

  const [currentImageUrl, setCurrentImageUrl] = useState<string>("");
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [stockImage, setStockImage] = useState<File | null>(null);
  const [stockImagePreview, setStockImagePreview] = useState<string | null>(null);
  const [useStockImage, setUseStockImage] = useState<boolean>(true);
  const [variants, setVariants] = useState<ItemVariant[]>([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(preselectedCategory?._id || "");
  const [selectedCategory, setSelectedCategory] = useState<any>(preselectedCategory || null);
  const [categories, setCategories] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(1);
  const [serialRange, setSerialRange] = useState({
    from: 1,
    to: 1,
  });

  const filteredCategories = useMemo(() => {
    return categories.filter((category) => category?.title?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [categories, searchTerm]);

  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    categoryApi
      .getAllCategories()
      .then((categories) => {
        setCategories(categories);
        if (preselectedCategory) {
          setSelectedCategoryId(preselectedCategory._id);

          let categoryDetails = categories.find((category: any) => category._id === preselectedCategory._id);
          setSelectedCategory(categoryDetails);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        dispatch(openSnackbar({ message: "Error fetching categories", severity: "error" }));
      });
  }, []);

  function handleImageSelect(file: File | null) {
    if (file) {
      setSelectedFile(file);
      // Revoke the old preview URL if it exists
      if (imageUrlPreview && imageUrlPreview.startsWith("blob:")) {
        URL.revokeObjectURL(imageUrlPreview);
      }
      setImageUrlPreview(URL.createObjectURL(file));
    } else {
      // Keep the existing image
      setSelectedFile(null);
      setImageUrlPreview("");
    }
  }

  const handleStockImageChange = (file: File | null) => {
    if (file) {
      setStockImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStockImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setStockImage(null);
      setStockImagePreview(null);
    }
  };

  const handleVariantImageChange = (index: number, file: File | null) => {
    if (file) {
      setVariants((prev) => prev.map((variant, i) => (i === index ? { ...variant, file } : variant)));
    }
  };

  const addVariant = () => {
    const newVariant: ItemVariant = {
      name: "",
      quantity: 1,
    };
    setVariants((prev) => [...prev, newVariant]);
  };

  const removeVariant = (index: number) => {
    setVariants((prev) => prev.filter((_, i) => i !== index));
  };

  const updateVariant = (index: number, updates: Partial<ItemVariant>) => {
    setVariants((prev) => prev.map((variant, i) => (i === index ? { ...variant, ...updates } : variant)));
  };

  async function handleCreateItem() {
    const validationErrors = [];

    if (!title) validationErrors.push("Title is required");

    if (!type) validationErrors.push("Please select an item type");
    if (type === ItemTypesEnum.Variant) {
      validationErrors.push("Please select any other type than variant");
    }

    if ((type === ItemTypesEnum.Serialised || type === ItemTypesEnum.NonSerialised) && !selectedFile) {
      validationErrors.push("Please upload an image");
    }

    if (!selectedCategoryId) validationErrors.push("Please select a category");

    if (quantity < 1) validationErrors.push("Quantity must be greater than 0");

    if (type === ItemTypesEnum.Serialised && (!serialRange.from || !serialRange.to)) {
      validationErrors.push("Please enter both From and To values for serialization range");
    }

    if (type === ItemTypesEnum.Serialised && serialRange.from > serialRange.to) {
      validationErrors.push("From value must be less than or equal to To value");
    }

    if (type === ItemTypesEnum.Serialised) {
      const groupNameTag = tags.find((tag) => tag.tag === "Group Name");
      if (!groupNameTag?.value) {
        validationErrors.push("Group Name is required for serialised items");
      }
    }
    if (type === ItemTypesEnum.NonSerialised && quantity < 1) {
      validationErrors.push("Quantity must be greater than 0");
    }

    // Check for required tags
    const emptyRequiredTags = tags.filter((tag) => tag.isRequired && !tag.value).map((tag) => tag.tag);

    if (emptyRequiredTags.length > 0) {
      validationErrors.push(`Required tags missing values: ${emptyRequiredTags.join(", ")}`);
    }

    if (validationErrors.length > 0) {
      dispatch(
        openSnackbar({
          message: validationErrors[0],
          severity: "error",
        })
      );
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    if (description) formData.append("description", description);
    formData.append("type", type);
    formData.append("visibility", visibility);
    if (visibility === ItemVisibilitiesEnum.Private) formData.append("publicVisibilityDate", publicVisibilityDate);
    // if (stockImage) formData.append("stockImage", stockImage);
    if (selectedFile) formData.append("itemImage", selectedFile as Blob);
    formData.append("categoryId", selectedCategoryId);
    formData.append("tags", JSON.stringify(tags));
    formData.append("groupName", tags.find((tag) => tag.tag === "Group Name")?.value || "");
    if (type === ItemTypesEnum.Serialised) {
      formData.append("rangeStart", serialRange.from.toString());
      formData.append("rangeEnd", serialRange.to.toString());
    } else {
      formData.append("quantity", quantity.toString());
    }

    // if (type === ItemTypesEnum.Serialised) {
    //   formData.append(
    //     "variants",
    //     JSON.stringify(
    //       variants.map((variant) => ({ name: variant.name, quantity: variant.quantity, file: variant.file }))
    //     )
    //   );
    // } else {
    //   formData.append(
    //     "variants",
    //     JSON.stringify(
    //       variants.map((variant) => ({ name: variant.name, quantity: variant.quantity, file: variant.file }))
    //     )
    //   );
    // }

    try {
      await itemApi.createItem(formData);
      dispatch(openSnackbar({ message: "Item created successfully!", severity: "success" }));
      setRefresh();
      onClose();
    } catch (error: any) {
      dispatch(openSnackbar({ message: "Error creating item", severity: "error" }));
    }
  }

  const handleCategoryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const categoryId: string = event.target.value;
      setSelectedCategoryId(categoryId);

      const categoryDetails = categories.find((category) => category._id === categoryId);
      setSelectedCategory(categoryDetails);

      if (categoryDetails?.tags) {
        const initialTags = categoryDetails.tags.map((categoryTag: CategoryTag) => ({
          tag: categoryTag.tag,
          value: "",
          isRequired: categoryTag.required,
          availableValues: categoryTag.values || [],
          isCustom: false,
        }));

        setTags(initialTags);
        setType("");
      }
    },
    [categories]
  );

  const handleTypeChange = useCallback((newType: string) => {
    setType(newType);

    const groupNameTag = {
      tag: itemGroupNameTag,
      value: "",
      isRequired: true,
      availableValues: [],
      isCustom: false,
    };

    if (newType === ItemTypesEnum.Serialised) {
      setTags((prevTags) => {
        if (!prevTags.some((tag) => tag.tag === "Group Name")) {
          return [...prevTags, groupNameTag];
        }
        return prevTags;
      });
    } else {
      setTags((prevTags) => prevTags.filter((tag) => tag.tag !== "Group Name"));
    }
  }, []);

  useEffect(() => {
    if (preselectedCategory?._id) {
      const event = {
        target: {
          value: preselectedCategory._id,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      handleCategoryChange(event);
    }
  }, [preselectedCategory, handleCategoryChange]);

  return (
    <CustomDialog open={open} onClose={onClose} text="Create Item" subText="Fill in the details for the new item">
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body1">Select Category</Typography>
          <TextField
            select
            value={selectedCategoryId}
            onChange={handleCategoryChange}
            sx={{ width: "100%" }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
                onKeyDown: (e) => {
                  // Prevent default keyboard navigation when search is focused
                  if (document.activeElement?.tagName === "INPUT") {
                    e.stopPropagation();
                  }
                },
              },
            }}
          >
            <Box sx={{ p: 1, pb: 0 }}>
              <TextField
                size="small"
                fullWidth
                label="Search categories..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                autoComplete="off"
              />
            </Box>
            {filteredCategories?.map((category: any) => (
              <MenuItem key={category?._id} value={category?._id}>
                {category?.title || "No Category Name"}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack>
          <Typography variant="body1">Name</Typography>
          <TextField value={title} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)} />
        </Stack>

        <Stack>
          <Typography variant="body1">Description</Typography>
          <TextField
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
          />
        </Stack>

        <Stack>
          <Typography variant="body1">Type</Typography>
          <TextField select value={type} onChange={(e) => handleTypeChange(e.target.value)} fullWidth>
            <MenuItem value="" disabled>
              Select Type
            </MenuItem>
            {itemTypes.map((itemType) => (
              <MenuItem key={itemType} value={itemType}>
                {itemType}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack>
          <Typography variant="body1">Visibility</Typography>
          <Stack sx={{ gap: 2, width: "100%" }} direction="row">
            <TextField
              select
              value={visibility}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVisibility(e.target.value)}
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Visibility
              </MenuItem>
              {itemVisibilities.map((itemVisibility: any) => (
                <MenuItem key={itemVisibility} value={itemVisibility}>
                  {itemVisibility}
                </MenuItem>
              ))}
            </TextField>
            {visibility === ItemVisibilitiesEnum.Private && (
              <TextField
                type="date"
                fullWidth
                value={publicVisibilityDate}
                helperText="Item will be made public on this date"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPublicVisibilityDate(e.target.value)}
              />
            )}
          </Stack>
        </Stack>

        {selectedCategory && (
          <Box sx={{ mb: { xs: 3, sm: 4 }, mt: { xs: 2, sm: 2 } }}>
            <Typography>Category Tags</Typography>

            {tags.length > 0 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(auto-fill, minmax(280px, 1fr))",
                  },
                  gap: { xs: 1.5, sm: 2 },
                  p: { xs: 1.5, sm: 2 },
                  backgroundColor: "grey.50",
                  borderRadius: { xs: 1.5, sm: 2 },
                  border: "1px solid",
                  borderColor: "grey.200",
                }}
              >
                {tags.map((tag, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: { xs: 1.5, sm: 2 },
                      backgroundColor: "background.paper",
                      borderRadius: 1,
                      boxShadow: 1,
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        boxShadow: 2,
                        transform: { xs: "none", sm: "translateY(-2px)" },
                      },
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        mb: 1,
                        color: tag.isRequired ? "primary.main" : "secondary.main",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Box
                          component="span"
                          sx={{
                            width: { xs: 6, sm: 8 },
                            height: { xs: 6, sm: 8 },
                            borderRadius: "50%",
                            backgroundColor: tag?.isRequired ? "primary.main" : "secondary.main",
                            display: "inline-block",
                          }}
                        />
                        {tag?.tag}
                      </Box>
                      {tag.isRequired && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "grey.600",
                          }}
                        >
                          Required
                        </Typography>
                      )}
                    </Typography>

                    <TextField
                      select={tag.availableValues.length > 0}
                      value={tag.isCustom ? "custom" : tag.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newTags = [...tags];
                        if (e.target.value === "custom") {
                          newTags[index] = {
                            ...newTags[index],
                            isCustom: true,
                            value: "",
                          };
                        } else {
                          newTags[index] = {
                            ...newTags[index],
                            isCustom: false,
                            value: e.target.value,
                          };
                        }
                        setTags(newTags);
                      }}
                      fullWidth
                      size="small"
                      placeholder={`Enter ${tag.tag}`}
                    >
                      {tag.availableValues.length > 0 && [
                        ...tag.availableValues.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )),
                        <MenuItem key={"custom"} value="custom">
                          <em style={{ color: "grey" }}>Enter custom value</em>
                        </MenuItem>,
                      ]}
                    </TextField>
                    {tag.isCustom && (
                      <TextField
                        value={tag.value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newTags = [...tags];
                          newTags[index] = {
                            ...newTags[index],
                            value: e.target.value,
                          };
                          setTags(newTags);
                        }}
                        fullWidth
                        size="small"
                        placeholder={`Enter custom ${tag.tag}`}
                        sx={{ mt: 1 }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {type === ItemTypesEnum.Serialised && (
          <Stack direction="row" gap={5} alignItems="center">
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "50%" }}>
              <Typography variant="body1">From</Typography>
              <TextField
                type="number"
                value={serialRange.from}
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSerialRange((prev) => ({ ...prev, from: Number(e.target.value) }))
                }
                placeholder="Enter starting number"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "50%" }}>
              <Typography variant="body1" sx={{ marginRight: 2 }}>
                To
              </Typography>
              <TextField
                type="number"
                fullWidth
                value={serialRange.to}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSerialRange((prev) => ({ ...prev, to: Number(e.target.value) }))
                }
                placeholder="Enter ending number"
              />
            </Box>
          </Stack>
        )}

        {type === ItemTypesEnum.NonSerialised && (
          <Stack>
            <Typography variant="body1">Quantity</Typography>
            <TextField
              type="number"
              inputProps={{ min: 1 }}
              value={quantity}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
            />
          </Stack>
        )}

        {type === ItemTypesEnum.Variant && (
          <>
            <Stack spacing={1}>
              <Typography variant="body1">Item Image</Typography>
              <Stack direction="column" spacing={2} alignItems="start">
                <FormControlLabel
                  control={<Switch checked={useStockImage} onChange={(e) => setUseStockImage(e.target.checked)} />}
                  label="Use Stock Image"
                  labelPlacement="start"
                />
                {useStockImage && (
                  <Box sx={{ width: "100%" }}>
                    <ImageUploader
                      onImageSelect={handleStockImageChange}
                      useCropper={true}
                      cropMode="1:1"
                      previewWidth={300}
                      previewHeight={200}
                      variant="small"
                      existingImageUrl={stockImagePreview ? stockImagePreview : undefined}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="body1">Variants</Typography>
              <Button variant="outlined" onClick={addVariant} startIcon={<AddIcon />}>
                Add Variant
              </Button>

              {variants.map((variant, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: 1,
                  }}
                >
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Variant {index + 1}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <TextField
                        label="Variant Name"
                        value={variant.name}
                        onChange={(e) => updateVariant(index, { name: e.target.value })}
                        size="small"
                      />
                      <TextField
                        type="number"
                        label="Quantity"
                        value={variant.quantity}
                        onChange={(e) => updateVariant(index, { quantity: parseInt(e.target.value) || 0 })}
                        size="small"
                      />
                      <IconButton onClick={() => removeVariant(index)} color="error" size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Stack>

                    {!useStockImage && (
                      <Stack spacing={1}>
                        <ImageUploader
                          onImageSelect={(image) => handleVariantImageChange(index, image)}
                          useCropper={true}
                          cropMode="1:1"
                          previewWidth={900}
                          previewHeight={200}
                          existingImageUrl={variant.imageUrl}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </>
        )}

        {type !== ItemTypesEnum.Variant && (
          <Stack>
            <Typography variant="body1">Image</Typography>
            <ImageUploader
              onImageSelect={handleImageSelect}
              useCropper={true}
              cropMode="1:1"
              previewWidth={850}
              existingImageUrl={currentImageUrl}
            />
          </Stack>
        )}

        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" onClick={handleCreateItem}>
            Create Item
          </Button>
        </Stack>
      </Stack>
    </CustomDialog>
  );
}
