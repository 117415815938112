import { TableCell, TableHead, TableRow } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface DropDetailHeaderProps {
  tab: string;
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}

export default function DropDetailHeader({
  tab,
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: DropDetailHeaderProps) {
  const handleSortColumn = (column: string) => {
    if (column === sortColumn) {
      handleSortDirection(sortDirection === "1" ? "-1" : "1");
    } else {
      handleSortDirection("1");
      handleSort(column);
    }
  };
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Box Name</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Box Type</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Description</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Packs Added</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>No of Box Items</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Created Time</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Price</TableCell>
      </TableRow>
    </TableHead>
  );
}
