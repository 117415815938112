import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import SkeletonRow from "../components/common/SkeletonRow";
import PackItemDetailRow from "../components/pack-item-management/PackItemDetailRow";
import PackItemDetailHeader from "../components/pack-item-management/PackItemDetailHeader";
import PackItemListToolBar from "../components/pack-item-management/PackItemListToolBar";
// import CreatePackItem from "../components/forms/CreatePackItem";
import { packItemApi } from "../apis/PackItemApi";
import CreateRipPack from "../components/forms/CreateRipPack";
import UpdatePackItem from "../components/forms/UpdatePackItem";
import Header from "../components/common/Header";

export default function PackItemsManagement({ selectedDrop, selectedPack }: any) {
  const [packItemsList, setPackItemsList] = useState<any[]>([]);
  const [filteredPackItemsList, setFilteredPackItemsList] = useState<any[]>([]);

  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("sequenceNumber");
  const [sortDirection, setSortDirection] = useState("-1");
  const [createRipPack, setCreateRipPack] = useState(false);
  const [dropFilter, setDropFilter] = useState("");
  const [packFilter, setPackFilter] = useState("");

  const [packItemToEdit, setPackItemToEdit] = useState(null);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  useEffect(() => {
    if (selectedDrop) {
      setDropFilter(selectedDrop);
    }
    if (selectedPack) {
      setPackFilter(selectedPack);
    }
  }, [selectedDrop, selectedPack]);

  const fetchPackItems = useCallback(async () => {
    try {
      setLoading(true);

      const params = {
        total: true,
        sortKey: sortColumn,
        sortDirection: sortDirection === "-1" ? "-1" : "1",
      };

      const { ripPackItems, total } = await packItemApi.getPaginatedAllPackItems(params);
      setTotal(total);
      setPackItemsList(ripPackItems);
      setFilteredPackItemsList(ripPackItems);
      dispatch(openSnackbar({ message: "PackItems fetched successfully", severity: "success" }));
    } catch (error: any) {
      dispatch(openSnackbar({ message: "Error fetching Pack Items ", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [sortDirection, sortColumn, dispatch]);

  useEffect(() => {
    fetchPackItems();
  }, [sortColumn, sortDirection, fetchPackItems]);

  useEffect(() => {
    if (refresh) fetchPackItems();
  }, [refresh, fetchPackItems]);

  useEffect(() => {
    let filteredItems = [...packItemsList];

    if (dropFilter) {
      // Filter by dropId if dropFilter is not empty
      filteredItems = filteredItems.filter((item) => item.ripPack.dropId === dropFilter);
    }

    if (packFilter) {
      // If packFilter is applied, override dropFilter and filter by ripPack._id
      filteredItems = filteredItems.filter((item) => item.ripPack._id === packFilter);
    }

    filteredItems = filteredItems.filter((item) => {
      return (
        item?.sequenceNumber?.toString()?.includes(searchQuery.toLowerCase()) ||
        item?.ripPack?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.ripPack?.description?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
    });

    setFilteredPackItemsList(filteredItems);
    setTotal(filteredItems.length);
  }, [searchQuery, dropFilter, packFilter, packItemsList]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          width: "100%",
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Pack Items Management
          </Typography>
        </Box>
        <PackItemListToolBar
          tab={tab}
          handleTabChange={handleTabChange}
          total={total}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRefresh={() => setRefresh(true)}
          setCreateRipPack={() => setCreateRipPack(true)}
          dropFilter={dropFilter}
          setDropFilter={setDropFilter}
          packFilter={packFilter}
          setPackFilter={setPackFilter}
        />
        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
          <Table>
            <PackItemDetailHeader
              tab={tab}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={setSortColumn}
              handleSortDirection={setSortDirection}
            />
            <TableBody>
              {loading ? (
                <SkeletonRow rowsPerPage={10} columnsPerPage={5} />
              ) : filteredPackItemsList && filteredPackItemsList.length > 0 ? (
                filteredPackItemsList.map((packItem: any) => (
                  <PackItemDetailRow
                    key={packItem?._id}
                    tab={tab}
                    packItem={packItem}
                    setPackItemToEdit={setPackItemToEdit}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Pack Items found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <UpdatePackItem
          open={packItemToEdit}
          onClose={() => {
            setPackItemToEdit(null);
          }}
          packItemToEdit={packItemToEdit}
          refreshPackItems={() => {
            setRefresh(true);
          }}
        />
      </Box>
    </>
  );
}
