import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect, useState } from "react";
import CustomDialog from "../common/CustomDialog";
import { ripPackApi } from "../../apis/RipPackApi";
import { useTheme } from "@mui/material/styles";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";

export default function ItemStatsForPack({ onClose, pack }: any) {
  const [itemsInPack, setItemsInPack] = useState<any[]>([]);
  const [itemStatsForPack, setItemStatsForPack] = useState<any>([]);
  const theme = useTheme();

  const [groupByOptions, setGroupByOptions] = useState<any[]>([
    {
      column: "title",
      label: "Title",
    },
    {
      column: "groupName",
      label: "Group Name",
    },
    {
      column: "categoryDetails.title",
      label: "Category",
    },
  ]);
  const [groupBy, setGroupBy] = useState<string>("title");

  React.useEffect(() => {
    if (pack) {
      ripPackApi
        .getItemDistribution({ ripPackId: pack?._id })
        .then((response: any) => {
          if (!response) return;

          let itemsInPack = response.itemsInPack;
          setItemsInPack(itemsInPack);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [pack]);

  useEffect(() => {
    if (itemsInPack.length > 0) {
      let groupByAggregates: any = {};

      itemsInPack.forEach((item: any) => {
        let groupByValue = item[groupBy];
        if (groupBy === "categoryDetails.title") {
          groupByValue = item.categoryDetails.title;
        }

        if (groupByAggregates[groupByValue]) {
          groupByAggregates[groupByValue].count += 1;
          groupByAggregates[groupByValue].items.push(item);
        } else {
          groupByAggregates[groupByValue] = { count: 1, items: [item] };
        }
      });

      // Calculate total items for odds calculation
      const totalItems = itemsInPack.length;

      let stats = Object.keys(groupByAggregates).map((groupByValue) => {
        const groupByData = groupByAggregates[groupByValue];

        return {
          id: groupByValue,
          value: (groupByData.count / totalItems) * 100, // Calculate odds as a percentage
          label:
            (groupByValue || "Unknown Title") +
            " " +
            "(" +
            ((groupByData.count / totalItems) * 100).toFixed(2) +
            "% - " +
            groupByData.count +
            " items)",
        };
      });

      setItemStatsForPack(stats);
    }
  }, [itemsInPack, groupBy]);

  return (
    <React.Fragment>
      {
        <CustomDialog
          open={true}
          onClose={onClose}
          avatarSrc=""
          subText=""
          linkText=""
          linkUrl=""
          linkText2=""
          linkUrl2=""
          removeHeaderDivider={true}
          showDivider={false}
        >
          <Box sx={{ padding: 2, textAlign: "center" }}>
            <Typography sx={{ marginBottom: 2, fontWeight: "bold", fontSize: "2rem" }}>
              Probability Distribution of Items in this Pack
            </Typography>
            <FormControl>
              <Select value={groupBy} onChange={(event) => setGroupBy(event.target.value)} sx={{ marginBottom: 2 }}>
                {groupByOptions.map((option: any) => (
                  <MenuItem key={option.column} value={option.column}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <PieChart
              series={[
                {
                  data: itemStatsForPack,
                },
              ]}
              height={300}
              sx={{
                "& .MuiPieChart-root": {
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                },
              }}
            />
          </Box>
        </CustomDialog>
      }
    </React.Fragment>
  );
}
