export enum rewardTypeEnum {
  AllSubscriptions = "all-subscriptions",
  RipPack = "rip-pack",
}

export enum rewardStatusEnum {
  Active = "active",
  Inactive = "inactive",
  Expired = "expired",
}
