import {
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import AddPackItems from "../forms/AddPackItems";
import { dropApi } from "../../apis/DropApi";
import { ripPackApi } from "../../apis/RipPackApi";

interface PackItemListToolBarProps {
  tab: string;
  total: number;
  searchQuery: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  setSearchQuery: (value: string) => void;
  onRefresh: () => void;
  setCreateRipPack: () => void;
  dropFilter: string;
  setDropFilter: any;
  packFilter: string;
  setPackFilter: any;
}

export default function PackItemListToolBar({
  tab,
  total,
  searchQuery,
  handleTabChange,
  setSearchQuery,
  onRefresh,
  dropFilter,
  setDropFilter,
  packFilter,
  setPackFilter,
}: PackItemListToolBarProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [drops, setDrops] = useState<any[]>([]); // List of drops
  const [allPacks, setAllPacks] = useState<any[]>([]); // List of all packs initially loaded
  const [filteredPacks, setFilteredPacks] = useState<any[]>([]); // Packs based on selected drop

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    async function fetchDrops() {
      // Fetch the drop list here, replace with your API call
      const fetchedDrops = await dropApi.getPaginatedAllDrops({});
      setDrops(fetchedDrops.drops);

      const fetchedPacks = await ripPackApi.getRipPackDetails({});
      setAllPacks(fetchedPacks.ripPacks || []);
    }
    fetchDrops();
  }, []);

  useEffect(() => {
    // Filter packs based on selected drop
    if (dropFilter) {
      const filtered = allPacks.filter((pack) => pack.details.dropId === dropFilter);
      setFilteredPacks(filtered);
    } else {
      setFilteredPacks(allPacks);
    }
  }, [dropFilter, allPacks]);
  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", my: 2 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="packItem status tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ "& .MuiTab-root": { textTransform: "none" } }}
          >
            <Tab label={`All PackItems (${total})`} value="all" />
          </Tabs>
        </Grid>

        <Grid size={{ xs: 12, sm: 8 }} container spacing={2} justifyContent="flex-end">
          <Grid size={{ xs: 6, sm: 4 }}>
            <TextField
              variant="outlined"
              placeholder="Search packItems..."
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
              fullWidth
            />
          </Grid>
          {/* Drop Filter */}
          <Grid size={{ xs: 6, sm: 2.1 }}>
            <Select
              value={dropFilter}
              onChange={(e) => {
                setDropFilter(e.target.value);
                setPackFilter("");
              }}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>All Drops</em>
              </MenuItem>
              {drops.map((drop) => (
                <MenuItem key={drop._id} value={drop._id}>
                  {drop.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Pack Filter - conditional on selected drop */}
          <Grid size={{ xs: 6, sm: 2.1 }}>
            <Select
              value={packFilter}
              onChange={(e) => setPackFilter(e.target.value)}
              displayEmpty
              fullWidth
              disabled={!dropFilter} // Disable pack filter if no drop is selected
            >
              <MenuItem value="">
                <em>All Packs</em>
              </MenuItem>
              {filteredPacks.map((pack) => (
                <MenuItem key={pack.details._id} value={pack.details._id}>
                  {pack.details.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid size={{ xs: 2, sm: 1 }} container justifyContent="flex-end">
            <Tooltip title="Refresh">
              <IconButton
                onClick={onRefresh}
                aria-label="refresh"
                sx={{ backgroundColor: "primary.contrastText", borderRadius: 1 }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* UpdatePackItemQuantity Dialog */}
      <AddPackItems open={isDialogOpen} onClose={closeDialog} onRefresh={onRefresh} />
    </React.Fragment>
  );
}
