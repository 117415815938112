import React from "react";
import { Box, Skeleton, TableCell, TableRow } from "@mui/material";

interface SkeletonRowProps {
  rowsPerPage: number;
  columnsPerPage?: number;
}

export default function SkeletonRow({ rowsPerPage, columnsPerPage = 3 }: SkeletonRowProps) {
  // Ensure columnsPerPage is at least 2
  const dynamicColumns = Math.max(columnsPerPage - 2, 0); // Subtract 2 for the first two constant columns

  return (
    <React.Fragment>
      {Array.from(new Array(rowsPerPage)).map((_: any, index: number) => (
        <TableRow key={index}>
          <TableCell>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, height: "100%" }}>
              <Skeleton variant="circular" width={40} height={40} sx={{ borderRadius: 5 }} />
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 1, flexGrow: 1 }}>
                <Skeleton variant="text" width="70%" height={20} />
                <Skeleton variant="text" width="30%" height={20} />
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="50%" height={20} />
          </TableCell>
          {/* Dynamic columns based on columnsPerPage */}
          {Array.from(new Array(dynamicColumns)).map((_, colIndex: number) => (
            <TableCell key={colIndex} sx={{ display: { xs: "none", sm: "table-cell" } }}>
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="30%" height={20} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </React.Fragment>
  );
}
