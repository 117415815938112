import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { userApi } from "../apis/UserApi";
import UserDetailRow from "../components/user-activity/UserDetailRow";
import UserDetailHeader from "../components/user-activity/UserDetailHeader";
import UserActivityToolBar from "../components/user-activity/UserActivityToolBar";
import { useDispatch } from "react-redux";
import { getUTCEndOfDay } from "../utils/time";
import SkeletonRow from "../components/common/SkeletonRow";
import { openSnackbar } from "../redux/slices/snackbar";
import Header from "../components/common/Header";

export default function UserActivity() {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const oneDayAgo = useMemo(() => new Date(new Date().setDate(new Date().getDate() - 1)), []);
  const [fromTimestamp, setFromTimestamp] = useState(getUTCEndOfDay(oneDayAgo));
  const [toTimestamp, setToTimestamp] = useState(new Date());
  const rowsPerPage = 10;
  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState("all");
  const [sortColumn, setSortColumn] = useState("accountCreatedAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
    setPage(0);
  }

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const commonParams = {
        page,
        limit: rowsPerPage,
        search: searchQuery,
        total: true,
        sortKey: sortColumn,
        sortDirection: sortDirection === "-1" ? "-1" : "1",
      };

      let params: any = {};
      if (tab === "all") {
        params = { ...commonParams };
      } else {
        params = {
          ...commonParams,
          fromTimestamp: fromTimestamp,
          toTimestamp: toTimestamp,
          eventField: tab,
        };
      }
      const usersData =
        tab === "all" ? await userApi.getPaginatedAllUsers(params) : await userApi.getPaginatedUsersByEvent(params);
      const { users, total } = usersData;
      if (!users) throw new Error("users is not defined");

      setUsers(users);
      setTotal(total);
      if (!searchQuery) dispatch(openSnackbar({ message: "Users fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching users:", error);
      dispatch(openSnackbar({ message: "Error fetching users", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [tab, fromTimestamp, toTimestamp, page, sortColumn, sortDirection, searchQuery, dispatch]);

  useEffect(() => {
    fetchUsers();
  }, [page, tab, fromTimestamp, toTimestamp, sortColumn, sortDirection, searchQuery, dispatch, fetchUsers]);

  useEffect(() => {
    if (refresh) fetchUsers();
  }, [refresh, fetchUsers]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
          width: "100vw",
          minHeight: "100vh",
          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h3" gutterBottom>
            User Activity
          </Typography>
          <Typography variant="h6" gutterBottom>
            Manage user activity
          </Typography>
        </Box>
        <UserActivityToolBar
          tab={tab}
          total={total}
          searchQuery={searchQuery}
          fromTimestamp={fromTimestamp}
          toTimestamp={toTimestamp}
          setFromTimestamp={setFromTimestamp}
          setToTimestamp={setToTimestamp}
          handleTabChange={handleTabChange}
          setSearchQuery={(value: string) => {
            setSearchQuery(value);
            setPage(0);
          }}
          onRefresh={() => setRefresh(true)}
        />
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
            <Table>
              <UserDetailHeader
                tab={tab}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handleSort={setSortColumn}
                handleSortDirection={setSortDirection}
              />

              <TableBody>
                {loading ? (
                  <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={4} />
                ) : users.length > 0 ? (
                  users.map((user: any) => <UserDetailRow key={user?._id} user={user} tab={tab} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No users found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(total / rowsPerPage)}
              variant="outlined"
              color="primary"
              page={page + 1}
              onChange={(event, value) => setPage(value - 1)}
            />
          </Box>
        </Box>{" "}
      </Box>
    </>
  );
}
