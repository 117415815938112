import BaseApi from "./BaseApi";

class BuilderApi extends BaseApi {
  constructor() {
    super({ generalPath: "/builder", cache: false });
  }

  async getAllPages() {
    try {
      const result = await this.axios.get("/fetch-all-pages", {});
      return result.data;
    } catch (error: any) {
      throw new Error("Error in BuilderApi.getAllPages: " + error.message);
    }
  }

  async fetchPageContent(pageLink: string) {
    try {
      const result = await this.axios.post("/fetch", { pageLink });
      return result.data;
    } catch (error: any) {
      throw new Error("Error in BuilderApi.getPageContent: " + error.message);
    }
  }

  async insertContent(pageContent: any) {
    try {
      const result = await this.axios.post("/insert", pageContent);
      return result.data;
    } catch (error: any) {
      throw new Error("Error in BuilderApi.insertContent: " + error.message);
    }
  }

  async copySourceToTarget(sourcePageLink: string, targetPageLink: string) {
    try {
      const pageContent = await this.fetchPageContent(sourcePageLink);
      delete pageContent._id;
      const res = await this.insertContent({ ...pageContent, pageLink: targetPageLink });
      return res.data;
    } catch (error: any) {
      throw new Error("Error in BuilderApi.copySourceToTarget: " + error.message);
    }
  }
}

export const builderApi = new BuilderApi();
