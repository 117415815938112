import BaseAPI from "./BaseApi";

class AdminAuthApi extends BaseAPI {
  constructor() {
    super({ generalPath: "/adminAuth", cache: false });
  }

  async verifyCredentials(email: string, password: string): Promise<{ duoURL: string; adminId: string }> {
    try {
      const result = await this.axios.get("/verifyCredentials", {
        params: {
          email,
          password,
        },
      });
      if (!result.data || !result.data.adminId) throw new Error("Invalid response from server");
      const { duoURL, adminId } = result.data;
      return { duoURL, adminId };
    } catch (error: any) {
      throw new Error("Error in AdminAuthApi.verifyCredentials: " + error.message);
    }
  }

  async validateDuoCallback({ adminId, duoCode }: { adminId: string; duoCode: string }) {
    try {
      const result = await this.axios.get("/validateDuo", {
        params: {
          adminId,
          duoCode,
        },
      });

      if (!result.data) throw new Error("Invalid response from server, No data");
      if (!result.data.token) throw new Error("Invalid response from server, No token");
      if (!result.data.duoToken) throw new Error("Invalid response from server, No duoToken");
      if (!result.data.admin) throw new Error("Invalid response from server, No admin user data");
      const { token, duoToken, admin } = result.data;

      return { token, duoToken, admin };
    } catch (error: any) {
      throw new Error("Error in AdminAuthApi.validateDuoCallback: " + error.message);
    }
  }

  async generateOtpAndSendEmail(email: string) {
    try {
      const result = await this.axios.post("/generateOtpAndSendEmail", {
        email,
      });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in LoginApi.generateOtpAndSendEmail: " + error.message);
    }
  }
  async verifyOtp(email: string, otp: string) {
    try {
      const result = await this.axios.get("/verifyOtp", {
        params: {
          email,
          otp,
        },
      });
      if (!result.data) throw new Error("Invalid response from server");
      return result.data;
    } catch (error: any) {
      throw new Error("Error in AdminAuthApi.verifyOtp: " + error.message);
    }
  }
}

export const adminAuthApi = new AdminAuthApi();
