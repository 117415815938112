import React from "react";
import { Box, TextField, Button, Chip, Typography, IconButton, Tooltip, Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import RestoreIcon from "@mui/icons-material/Restore";
import AddIcon from "@mui/icons-material/Add";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { CategoryTag } from "../../../types/common";

interface TagManagerProps {
  tags: CategoryTag[];
  onTagsChange: (tags: CategoryTag[]) => void;
  isEditing: boolean;
  onEditingChange: (isEditing: boolean) => void;
}

interface TagValueInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  placeholder?: string;
}

const TagValueInput: React.FC<TagValueInputProps> = ({ value, onChange, onSubmit, placeholder }) => (
  <TextField
    value={value}
    onChange={(e) => onChange(e.target.value)}
    onKeyPress={(e) => {
      if (e.key === "Enter" && value.trim()) {
        onSubmit(value.trim());
      }
    }}
    placeholder={placeholder || "Add a value"}
    size="small"
  />
);

const TagChip: React.FC<{ label: string; onDelete?: () => void }> = ({ label, onDelete }) => (
  <Box sx={{ position: "relative", display: "inline-flex", alignItems: "center", margin: "0.25rem" }}>
    <Chip label={label} onDelete={onDelete} />
  </Box>
);

export const TagManager: React.FC<TagManagerProps> = ({ tags, onTagsChange, isEditing, onEditingChange }) => {
  const [newTagValues, setNewTagValues] = React.useState<string[]>([]);
  const [newTag, setNewTag] = React.useState<string>("");
  const [showAddTag, setShowAddTag] = React.useState(false);

  function handleTagValueAdd(index: number, value: string) {
    const newTags = [...tags];
    newTags[index].updatedValue = {
      ...newTags[index].updatedValue,
      values: [...newTags[index].updatedValue.values, value],
    };
    onTagsChange(newTags);

    const updatedValues = [...newTagValues];
    updatedValues[index] = "";
    setNewTagValues(updatedValues);
  }

  function handleTagDelete(index: number) {
    onTagsChange(tags.filter((_, i) => i !== index));
  }

  function handleTagUpdate(index: number, updates: Partial<CategoryTag["updatedValue"]>) {
    const newTags = [...tags];
    newTags[index].updatedValue = {
      ...newTags[index].updatedValue,
      ...updates,
    };
    onTagsChange(newTags);
  }

  function handleSaveChanges() {
    onTagsChange(
      tags.map((tag) => ({
        ...tag.updatedValue,
        updatedValue: { ...tag.updatedValue },
      }))
    );
    onEditingChange(false);
  }

  function handleResetChanges() {
    onTagsChange(
      tags.map((tag) => ({
        ...tag,
        updatedValue: { ...tag },
      }))
    );
  }

  function handleAddNewTag() {
    if (newTag?.trim()) {
      onTagsChange([
        ...tags,
        {
          tag: newTag.trim(),
          required: false,
          values: [],
          updatedValue: {
            tag: newTag.trim(),
            required: false,
            values: [],
          },
        },
      ]);
      setNewTag("");
      setShowAddTag(false);
    }
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {!showAddTag ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setShowAddTag(true)}
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                px: 2,
              }}
            >
              Add New Tag
            </Button>
          ) : (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <TextField
                size="small"
                placeholder="Enter tag name"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && newTag.trim()) {
                    handleAddNewTag();
                  }
                }}
                sx={{ minWidth: "200px" }}
                autoFocus
              />
              <Button variant="contained" onClick={handleAddNewTag} disabled={!newTag.trim()} size="small">
                Add
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowAddTag(false);
                  setNewTag("");
                }}
                size="small"
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {tags.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 2,
            px: 1,
            backgroundColor: "grey.50",
            borderRadius: 1,
            border: "2px dashed",
            borderColor: "grey.300",
          }}
        >
          <LocalOfferIcon sx={{ fontSize: 48, color: "grey.400", mb: 2 }} />
          <Typography variant="h6" sx={{ color: "grey.600", mb: 1, textAlign: "center" }}>
            No Tags Added Yet
          </Typography>
          <Typography variant="body2" sx={{ color: "grey.500", textAlign: "center", maxWidth: 400 }}>
            Tags help organize and filter items in your category. Click "Add New Tag" to get started.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(auto-fill, minmax(280px, 1fr))",
            },
            gap: { xs: 2, sm: 3 },
            mb: 2,
          }}
        >
          {tags.map((tag, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                p: { xs: "1rem", sm: "1.25rem" },
                backgroundColor: "background.paper",
                borderRadius: "0.75rem",
                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                },
              }}
            >
              {isEditing ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                    <TextField
                      value={tag.updatedValue.tag}
                      onChange={(e) => handleTagUpdate(index, { tag: e.target.value })}
                      variant="outlined"
                      size="small"
                      sx={{ flexGrow: 1 }}
                    />
                    <Tooltip title="Make this tag required or not" arrow>
                      <Checkbox
                        checked={tag.updatedValue.required}
                        onChange={() => handleTagUpdate(index, { required: !tag.updatedValue.required })}
                      />
                    </Tooltip>
                  </Box>
                  <TagValueInput
                    value={newTagValues[index] || ""}
                    onChange={(value) => {
                      const updatedValues = [...newTagValues];
                      updatedValues[index] = value;
                      setNewTagValues(updatedValues);
                    }}
                    onSubmit={(value) => handleTagValueAdd(index, value)}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.75, alignItems: "center" }}>
                    {tag.updatedValue.values.map((value) => (
                      <TagChip
                        key={value}
                        label={value}
                        onDelete={() =>
                          handleTagUpdate(index, {
                            values: tag.updatedValue.values.filter((v) => v !== value),
                          })
                        }
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: tag.required ? "primary.main" : "secondary.main",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: tag.required ? "primary.main" : "secondary.main",
                          display: "inline-block",
                          mr: 1,
                        }}
                      />
                      {tag.tag}
                    </Typography>
                    {tag.required && (
                      <Tooltip title="If required, items must have this tag" arrow>
                        <Typography variant="body2" sx={{ color: "grey.600" }}>
                          Required
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.75 }}>
                    {tag.values.map((value) => (
                      <TagChip key={value} label={value} />
                    ))}
                  </Box>
                </Box>
              )}
              <IconButton
                onClick={() => handleTagDelete(index)}
                sx={{
                  position: "absolute",
                  right: { xs: "-8px", sm: "-12px" },
                  top: { xs: "-8px", sm: "-12px" },
                  backgroundColor: "error.main",
                  color: "white",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "error.dark",
                  },
                  "& svg": {
                    fontSize: "1rem",
                  },
                }}
              >
                <Tooltip title="Delete tag" arrow>
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      {isEditing && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <IconButton onClick={handleResetChanges}>
            <Tooltip title="Reset to original values" arrow>
              <RestoreIcon />
            </Tooltip>
          </IconButton>
        </Box>
      )}

      <IconButton
        onClick={() => onEditingChange(!isEditing)}
        sx={{
          position: "absolute",
          right: { xs: "-0.5rem", sm: "-2rem" },
          top: { xs: "-0.5rem", sm: "-2rem" },
          backgroundColor: "primary.main",
          color: "white",
          padding: "4px",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
          "& svg": {
            fontSize: "1rem",
          },
        }}
      >
        <Tooltip title={isEditing ? "Cancel editing" : "Edit tags"} arrow>
          {isEditing ? <RemoveIcon /> : <EditIcon />}
        </Tooltip>
      </IconButton>
    </Box>
  );
};
