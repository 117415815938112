import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { statsApi } from "../apis/StatsApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import Header from "../components/common/Header";

export default function StatsActivity() {
  const [stats, setStats] = useState<{
    subscriptions: { total: number; uniqueUsers: number };
    scratchCards: { total: number; uniqueUsers: number };
    timeRangeInHours: number;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [timeRange, setTimeRange] = useState(24); // Default 24 hours

  const dispatch = useDispatch();

  const fetchStats = useCallback(async () => {
    try {
      setLoading(true);
      const statsData = await statsApi.getRecentStats(timeRange);
      setStats(statsData);
      dispatch(openSnackbar({ message: "Stats fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching stats:", error);
      dispatch(openSnackbar({ message: "Error fetching stats", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [timeRange, dispatch]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const StatCard = ({ title, total, uniqueUsers }: { title: string; total: number; uniqueUsers: number }) => (
    <Card
      sx={{
        height: "100%",
        background: "linear-gradient(135deg, #fff 0%, #fff5eb 100%)",
        boxShadow: "0 8px 32px rgba(240, 87, 37, 0.1)",
        borderRadius: "16px",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
        },
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: "#f05725", fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="h4" sx={{ my: 2, color: "#f0a725", fontWeight: 700 }}>
          {total}
        </Typography>
        <Typography variant="body1" sx={{ color: "#f07825" }}>
          {uniqueUsers} Unique Users
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "1rem", sm: "2rem", md: "3rem", lg: "5rem" },
          width: "100%",
          minHeight: "100vh",
          background: "linear-gradient(180deg, rgba(240, 167, 37, 0.9) 0%, rgba(240, 87, 37, 0.85) 100%)",
        }}
      >
        <Box sx={{ mb: { xs: 2, sm: 4 }, textAlign: "center" }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              color: "#fff",
              fontWeight: 700,
              textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            }}
          >
            Usage Statistics
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: "#fff", fontSize: { xs: "1rem", sm: "1.25rem" } }}>
            Recent activity overview
          </Typography>
        </Box>

        <Box sx={{ mb: { xs: 2, sm: 4 }, width: { xs: "100%", sm: "200px" } }}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                color: "#fff",
                backgroundColor: "rgba(240, 87, 37, 0.8)",
                padding: "0 8px",
                borderRadius: "4px",
              }}
            >
              Time Range
            </InputLabel>
            <Select
              value={timeRange}
              label="Time Range"
              onChange={(e) => setTimeRange(Number(e.target.value))}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                borderRadius: "8px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
              }}
            >
              <MenuItem value={1}>Last Hour</MenuItem>
              <MenuItem value={24}>Last 24 Hours</MenuItem>
              <MenuItem value={48}>Last 48 Hours</MenuItem>
              <MenuItem value={72}>Last 72 Hours</MenuItem>
              <MenuItem value={168}>Last Week</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {loading ? (
          <CircularProgress sx={{ color: "#fff" }} />
        ) : (
          stats && (
            <Grid container spacing={{ xs: 2, md: 4 }} sx={{ maxWidth: 1200, margin: "0 auto" }}>
              <Grid item xs={12} md={6}>
                <StatCard
                  title="Subscription Activity"
                  total={stats.subscriptions.total}
                  uniqueUsers={stats.subscriptions.uniqueUsers}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StatCard
                  title="Scratch Card Activity"
                  total={stats.scratchCards.total}
                  uniqueUsers={stats.scratchCards.uniqueUsers}
                />
              </Grid>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
