import { TableCell, TableHead, TableRow } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface EventListHeaderProps {
  tab: string;
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}

export default function EventListHeader({
  tab,
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: EventListHeaderProps) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Event</TableCell>
        <TableCell sx={{ borderBottom: "none" }}>Description</TableCell>
        <TableCell sx={{ borderBottom: "none", display: { xs: "none", sm: "table-cell" } }}>
          Event Time
          {sortColumn === "eventTime" && sortDirection === "1" ? (
            <ArrowUpwardIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => {
                // handleSort("eventTime");
                handleSortDirection("-1");
              }}
            />
          ) : (
            <ArrowDownwardIcon
              sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
              onClick={() => {
                // handleSort("eventTime");
                handleSortDirection("1");
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
