import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

interface TinyMceProps {
  hideMenu?: boolean;
  onEditorChange: (content: string) => void;
  content: string;
  viewHeight: string;
  viewWidth: string;
}

export default function TinyMce({ hideMenu, onEditorChange, content, viewHeight, viewWidth }: TinyMceProps) {
  const googleMapApiKey = process.env.REACT_APP_GOOGLEMAP_API_KEY;

  const menu = {
    file: {
      title: "File",
      items: "newdocument restoredraft | preview | importword exportpdf exportword | print | deleteallconversations",
    },
    edit: {
      title: "Edit",
      items: "undo redo | cut copy paste pastetext | selectall | searchreplace",
    },
    view: {
      title: "View",
      items:
        "code revisionhistory | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments",
    },
    insert: {
      title: "Insert",
      items:
        "image link media addcomment pageembed codesample inserttable | math | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
    },
    format: {
      title: "Format",
      items:
        "bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat",
    },
    tools: {
      title: "Tools",
      items: "spellchecker spellcheckerlanguage | a11ycheck code wordcount",
    },
    table: {
      title: "Table",
      items: "inserttable | cell row column | advtablesort | tableprops deletetable ",
    },
    help: { title: "Help", items: "help" },
  };

  const plugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "preview",
    "help",
    "wordcount",
    "importcss",
    "emoticons",
    "pagebreak",
    // "quickbars",
  ];

  const toolbar =
    "undo redo |" +
    "blocks fontfamily fontsize createContainer modifyContainer " +
    "bold italic forecolor backcolor | " +
    "alignleft aligncenter alignright alignjustify | " +
    "numlist bullist link image media | " +
    "lineheight outdent indent preview | " +
    "googlemaps addPadding marginsButton addBackgroundImage areaWithBGColor | " +
    "removeformat | charmap emoticons table | " +
    "code save print | pagebreak anchor | ltr rtl | ";

  const fontFamilyFormats =
    "bangers=bangers; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif;" +
    "Arial Black=arial black,avant garde; helvetica=helvetica; Book Antiqua=book antiqua,palatino; " +
    "Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; " +
    "Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; " +
    "Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; " +
    "Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; " +
    "Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; " +
    "MS Sans Serif=ms sans serif; MS Serif=ms serif;";

  return (
    <Editor
      onEditorChange={onEditorChange}
      tinymceScriptSrc={"/tinymce/tinymce.min.js"}
      licenseKey="gpl"
      init={{
        height: viewHeight,
        width: viewWidth,
        resize: "both",
        font_family_formats: fontFamilyFormats,
        menu,
        menubar: !hideMenu,
        plugins,
        toolbar,
        sandbox_iframes: false,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin: 0; padding: 0; } p { margin: 0; padding: 0; }",
        font_size_formats:
          "0.2rem 0.3rem 0.4rem 0.5rem 0.6rem 0.7rem 0.8rem 0.9rem 1rem 1.1rem 1.2rem 1.3rem 1.4rem 1.5rem 1.6rem 1.7rem 1.8rem 1.9rem 2rem 2.1rem 2.2rem 2.3rem 2.4rem 2.5rem 2.6rem 2.7rem 2.8rem 2.9rem 3rem 3.1rem 3.2rem 3.3rem 3.4rem 3.5rem 3.6rem 3.7rem 3.8rem 3.9rem 4rem 4.1rem 4.2rem 4.3rem 4.4rem 4.5rem 4.6rem 4.7rem 4.8rem 4.9rem 5rem",
        setup: (editor: TinyMCEEditor) => {
          editor.on("init", () => {
            editor.getBody().style.fontSize = "1rem";
          });
          editor.ui.registry.addButton("googlemaps", {
            text: "Map",
            onAction: function () {
              editor.windowManager.open({
                title: "Insert Google Map",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "address",
                      label: "Address",
                    },
                    {
                      type: "input",
                      name: "width",
                      label: "Width",
                    },
                    {
                      type: "input",
                      name: "height",
                      label: "Height",
                    },
                  ],
                },
                buttons: [
                  {
                    type: "cancel",
                    text: "Close",
                  },
                  {
                    type: "submit",
                    text: "Insert",
                    primary: true,
                  },
                ],
                onSubmit: function (api: any) {
                  const data = api.getData();
                  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&q=${encodeURIComponent(
                    data.address
                  )}&zoom=15`;
                  const iframe = `<iframe width="${data.width}" height="${data.height}" frameborder="0" style="border:0" src="${mapUrl}" allowfullscreen></iframe>`;
                  editor.insertContent(iframe);
                  api.close();
                },
              });
            },
          });
          editor.ui.registry.addButton("marginsButton", {
            text: "Margin",
            onAction: function () {
              editor.windowManager.open({
                title: "Set Margins",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "margin",
                    },
                  ],
                },
                buttons: [
                  {
                    type: "cancel",
                    text: "Close",
                  },
                  {
                    type: "submit",
                    text: "Insert",
                    primary: true,
                  },
                ],
                onSubmit: function (api: any) {
                  const data = api.getData();
                  const margins = `margin: ${data.margin}rem;`;
                  editor.insertContent(`<div style="${margins}">Your content here</div>`);
                  api.close();
                },
              });
            },
          });
          editor.ui.registry.addButton("addPadding", {
            text: "Padding",
            onAction: function () {
              editor.windowManager.open({
                title: "Set Padding",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "paddingTop",
                      label: "Top (rem)",
                    },
                    {
                      type: "input",
                      name: "paddingRight",
                      label: "Right (rem)",
                    },
                    {
                      type: "input",
                      name: "paddingBottom",
                      label: "Bottom (rem)",
                    },
                    {
                      type: "input",
                      name: "paddingLeft",
                      label: "Left (rem)",
                    },
                  ],
                },
                buttons: [
                  {
                    type: "cancel",
                    text: "Close",
                  },
                  {
                    type: "submit",
                    text: "Apply",
                    primary: true,
                  },
                ],
                onSubmit: function (api) {
                  const data = api.getData();
                  const parentTag = ((element: any) => {
                    while (element && element.nodeName !== "DIV" && element.nodeName !== "P") {
                      element = element.parentNode;
                    }
                    return element;
                  })(editor.selection.getNode());

                  if (parentTag) {
                    parentTag.style.paddingTop = `${data.paddingTop}rem`;
                    parentTag.style.paddingRight = `${data.paddingRight}rem`;
                    parentTag.style.paddingBottom = `${data.paddingBottom}rem`;
                    parentTag.style.paddingLeft = `${data.paddingLeft}rem`;
                    editor.dom.setStyles(parentTag, {
                      paddingTop: `${data.paddingTop}rem`,
                      paddingRight: `${data.paddingRight}rem`,
                      paddingBottom: `${data.paddingBottom}rem`,
                      paddingLeft: `${data.paddingLeft}rem`,
                    });
                  } else {
                    console.log("No parent div or p found");
                  }
                  api.close();
                },
              });
            },
          });
          editor.ui.registry.addButton("addBackgroundImage", {
            text: "BG Image",
            onAction: function () {
              editor.windowManager.open({
                title: "Add Background Img",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "url",
                      label: "URL",
                    },
                    {
                      type: "input",
                      name: "width",
                      label: "fill in 100% if you want full width",
                    },
                    {
                      type: "input",
                      name: "height",
                      label: "fill in '<number>rem' to set height. e.g. 10rem",
                    },
                  ],
                },
                buttons: [
                  {
                    type: "cancel",
                    text: "Close",
                  },
                  {
                    type: "submit",
                    text: "Insert",
                    primary: true,
                  },
                ],
                onSubmit: function (api: any) {
                  const data = api.getData();
                  const style = `background-image: url(${data.url}); background-size: cover; width: ${data.width}; height: ${data.height}; background-repeat: no-repeat;`;
                  editor.insertContent(`<div style="${style}">Your content here</div>`);
                  api.close();
                },
              });
            },
          });
          editor.ui.registry.addButton("createContainer", {
            text: "Container",
            onAction: function () {
              editor.windowManager.open({
                title: "Create Container (Leave blank if not needed)",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "width",
                      label: "Width (<1-100>% / <number>rem /blank for fit-content)",
                    },
                    {
                      type: "input",
                      name: "height",
                      label: "Height (<1-100>% / <number>rem / blank for fit-content)",
                    },
                    {
                      type: "input",
                      name: "bgColor",
                      label: "Background Color (hex / rgb / rgba)",
                    },
                    {
                      type: "input",
                      name: "bgImage",
                      label: "Background Image (URL)",
                    },
                    {
                      type: "input",
                      name: "padding",
                      label: "Padding (e.g '1rem 2rem', first value is top/bottom, second is left/right)",
                    },
                    {
                      type: "input",
                      name: "margin",
                      label: "Margin (e.g '1rem 2rem', first value is top/bottom, second is left/right)",
                    },
                    {
                      type: "input",
                      name: "borderWidth",
                      label: "BorderWidth (e.g '1px')",
                    },
                    {
                      type: "input",
                      name: "borderColor",
                      label: "BorderColor (hex / rgb / rgba)",
                    },
                    {
                      type: "input",
                      name: "borderRadius",
                      label: "BorderRadius (e.g '0.5rem')",
                    },
                  ],
                },
                buttons: [
                  {
                    type: "cancel",
                    text: "Close",
                  },
                  {
                    type: "submit",
                    text: "Insert",
                    primary: true,
                  },
                ],
                onSubmit: function (api: any) {
                  const data = api.getData();
                  const container = `
                    <div classname="custom-container" style="display: flex; flex-direction: column; align-items: center;">
                      <div
                        style="
                          width: ${data.width || "100%"};
                          height: ${data.height || "fit-content"};
                          ${data.bgColor ? `background-color: ${data.bgColor};` : ""}
                          background-image: url(${data.bgImage || ""});
                          ${data.bgImage ? "background-size: 100% 100%;" : ""}
                          padding: ${data.padding || "0"};
                          margin: ${data.margin || "0"};
                          border: ${data.borderWidth || "0"} solid ${data.borderColor || "transparent"};
                        "
                      >
                        Container Content
                      </div>
                    </div>
                  `;
                  editor.insertContent(container);
                  api.close();
                },
              });
            },
          });
          editor.ui.registry.addButton("modifyContainer", {
            text: "Modify Container",
            onAction: function () {
              let prevData: any = [];
              const selectedElement = ((element: any) => {
                while (element && element.nodeName !== "DIV" && !element.classList.contains("custom-container")) {
                  element = element.parentNode;
                }
                return element;
              })(editor.selection.getNode());
              if (selectedElement) {
                prevData.width = selectedElement.style.width || "";
                prevData.height = selectedElement.style.height || "";
                prevData.bgColor = selectedElement.style.backgroundColor || "";
                prevData.bgImage = selectedElement.style.backgroundImage
                  ? selectedElement.style.backgroundImage.slice(4, -1).replace(/"/g, "")
                  : "";
                prevData.padding = selectedElement.style.padding || "";
                prevData.margin = selectedElement.style.margin || "";
                prevData.borderWidth = selectedElement.style.borderWidth || "";
                prevData.borderColor = selectedElement.style.borderColor || "";
                prevData.borderRadius = selectedElement.style.borderRadius || "";
                editor.windowManager.open({
                  title: "Modify Container (Leave blank if not needed)",
                  body: {
                    type: "panel",
                    items: [
                      {
                        type: "input",
                        name: "width",
                        label: "Width (<1-100>% / <number>rem / blank for fit-content)",
                      },
                      {
                        type: "input",
                        name: "height",
                        label: "Height (<1-100>% / <number>rem / blank for fit-content)",
                      },
                      {
                        type: "input",
                        name: "bgColor",
                        label: "Background Color (hex / rgb / rgba)",
                      },
                      {
                        type: "input",
                        name: "bgImage",
                        label: "Background Image (URL)",
                      },
                      {
                        type: "input",
                        name: "padding",
                        label: "Padding (e.g '1rem 2rem', first value is top/bottom, second is left/right)",
                      },
                      {
                        type: "input",
                        name: "margin",
                        label: "Margin (e.g '1rem 2rem', first value is top/bottom, second is left/right)",
                      },
                      {
                        type: "input",
                        name: "borderWidth",
                        label: "BorderWidth (e.g '1px')",
                      },
                      {
                        type: "input",
                        name: "borderColor",
                        label: "BorderColor (hex / rgb / rgba)",
                      },
                      {
                        type: "input",
                        name: "borderRadius",
                        label: "BorderRadius (e.g '0.5rem')",
                      },
                    ],
                  },
                  initialData: {
                    width: prevData.width,
                    height: prevData.height,
                    bgColor: prevData.bgColor,
                    bgImage: prevData.bgImage,
                    padding: prevData.padding,
                    margin: prevData.margin,
                    borderWidth: prevData.borderWidth,
                    borderColor: prevData.borderColor,
                    borderRadius: prevData.borderRadius,
                  },
                  buttons: [
                    {
                      type: "cancel",
                      text: "Close",
                    },
                    {
                      type: "submit",
                      text: "Apply",
                      primary: true,
                    },
                  ],
                  onSubmit: function (api: any) {
                    const data = api.getData();
                    editor.dom.setStyles(selectedElement, {
                      width: data.width || selectedElement.style.width,
                      height: data.height || selectedElement.style.height,
                      backgroundColor: data.bgColor || selectedElement.style.backgroundColor,
                      backgroundImage: data.bgImage ? `url(${data.bgImage})` : selectedElement.style.backgroundImage,
                      padding: data.padding || selectedElement.style.padding,
                      margin: data.margin || selectedElement.style.margin,
                      borderWidth: data.borderWidth || selectedElement.style.borderWidth,
                      borderColor: data.borderColor || selectedElement.style.borderColor,
                      borderRadius: data.borderRadius || selectedElement.style.borderRadius,
                    });
                    api.close();
                  },
                });
              } else {
                editor.windowManager.alert("Please select a valid container to modify.");
              }
            },
          });

          // editor.ui.registry.addButton("mapApi", {
          //   text: "Map-test",
          //   onAction: function () {
          //     editor.windowManager.open({
          //       title: "Insert Google Map",
          //       body: {
          //         type: "panel",
          //         items: [
          //           {
          //             type: "input",
          //             name: "address",
          //             label: "Address",
          //           },
          //           {
          //             type: "input",
          //             name: "width",
          //             label: "Width",
          //           },
          //           {
          //             type: "input",
          //             name: "height",
          //             label: "Height",
          //           },
          //         ],
          //       },
          //       buttons: [
          //         {
          //           type: "cancel",
          //           text: "Close",
          //         },
          //         {
          //           type: "submit",
          //           text: "Insert",
          //           primary: true,
          //         },
          //       ],
          //       onSubmit: async function (api: any) {
          //         const data = api.getData();
          //         const res = await accountApi.getAllPermissions();
          //         console.log(res);
          //         const temp = res[0];
          //         editor.insertContent(`<div>${temp}</div>`);
          //         api.close();
          //       },
          //     });
          //   },
          // });
        },
      }}
      value={content}
    />
  );
}
