import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link to={"/"} color="inherit">
        Shockem Comics
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
