import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../redux/slices/snackbar";
import SkeletonRow from "../components/common/SkeletonRow";
import BoxDetailRow from "../components/box-management/BoxDetailRow";
import BoxDetailHeader from "../components/box-management/BoxDetailHeader";
import BoxListToolBar from "../components/box-management/BoxListToolBar";
import CreateBox from "../components/forms/CreateBox";
import { boxApi } from "../apis/BoxApi";
import Header from "../components/common/Header";

export default function BoxManagement() {
  const [boxesList, setBoxesList] = useState<any[]>([]);
  const [tab, setTab] = useState("all");
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("-1");
  const [createBox, setCreateBox] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const dispatch = useDispatch();

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: string) {
    setTab(newValue);
  }

  const fetchBoxes = useCallback(async () => {
    try {
      setLoading(true);

      const params = {
        page,
        limit: rowsPerPage,
        sortKey: sortColumn,
        sortDirection: sortDirection === "-1" ? "-1" : "1",
        search: searchQuery,
        total: page === 0 ? true : false,
      };

      const { boxes, totalBoxes } = await boxApi.getPaginatedBoxes(params);
      if (totalBoxes) setTotal(totalBoxes);
      setBoxesList(boxes);
      dispatch(openSnackbar({ message: "Boxes fetched successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error fetching events:", error);
      dispatch(openSnackbar({ message: "Error fetching Boxes ", severity: "error" }));
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [sortDirection, sortColumn, dispatch, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    fetchBoxes();
  }, [sortColumn, sortDirection, tab, fetchBoxes]);

  useEffect(() => {
    if (refresh) {
      fetchBoxes();
      setSearchQuery("");
    }
  }, [refresh, fetchBoxes]);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
          width: "100vw",

          height: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ mb: 3, width: "100%" }}>
          <Typography variant="h3" gutterBottom>
            Box Management
          </Typography>
          <Typography variant="h6" gutterBottom>
            Manage Boxes
          </Typography>
        </Box>
        <BoxListToolBar
          tab={tab}
          handleTabChange={handleTabChange}
          total={total}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRefresh={() => setRefresh(true)}
          setCreateBox={() => setCreateBox(true)}
        />

        <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "auto" }}>
          <Table>
            <BoxDetailHeader
              tab={tab}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={setSortColumn}
              handleSortDirection={setSortDirection}
            />
            <TableBody>
              {loading ? (
                <SkeletonRow rowsPerPage={rowsPerPage} columnsPerPage={5} />
              ) : boxesList?.length > 0 ? (
                boxesList?.map((box: any) => <BoxDetailRow key={box?._id} tab={tab} box={box} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No boxes found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(total / rowsPerPage)}
            variant="outlined"
            color="primary"
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
        </Box>

        {/* Float elements */}
        <CreateBox open={createBox} onClose={useCallback(() => setCreateBox(false), [])} setRefresh={setRefresh} />
      </Box>
    </>
  );
}
