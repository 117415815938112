import Cookies from "js-cookie";

export function getAuthCookies() {
  const token = Cookies.get("Authorization");
  return `Bearer ${token}`;
}

export function setAuthCookies(token: any) {
  Cookies.set("Authorization", token, { expires: 7 });
}

export function unsetAuthCookie() {
  Cookies.remove("Authorization");
}

export function checkIfUserIsSignedIn(): boolean {
  const token = Cookies.get("Authorization");

  if (!token) {
    console.error("Token not found");
    return false;
  }

  try {
    // Split the JWT and decode the payload (second part)
    const payload = JSON.parse(atob(token.split(".")[1]));

    // Extract the expiration time (in seconds) from the payload
    const exp = payload.exp;

    // If the expiration time is not valid, return false
    if (!exp) {
      console.error("Invalid token: expiration time not found");
      return false;
    }

    // Check if the token is still valid
    return Date.now() < exp * 1000;
  } catch (error: any) {
    console.error("Error parsing token:", error);
    return false;
  }
}
