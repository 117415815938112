import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import store from "./redux/store";
import DuoCallback from "./pages/DuoCallback";
import SignUp from "./pages/SignUp";
import Header from "./components/common/Header";
import UserActivity from "./pages/UserActivity";
import SnackbarProvider from "./components/provider/SnackbarProvider";
import AdminProfile from "./pages/AdminProfile";
import ScratchCardActivity from "./components/scratch-card-management/ScratchCardActivity";
import RewardActivity from "./components/scratch-card-management/RewardActivity";
import SubscriptionActivity from "./pages/SubscriptionActivity";
import EventActivity from "./components/scratch-card-management/EventActivity";
import DropManagement from "./pages/DropManagement";
import RoleManagement from "./pages/RoleManagement";
import { Box } from "@mui/material";
import BoxManagement from "./pages/BoxManagement";
import BoxItemManagement from "./pages/BoxItemManagement";
import StatsActivity from "./pages/StatsActivity";
import CategoryItemManagement from "./pages/CategoryItemManagement";
import AssetsManagement from "./pages/AssetManagement";
import PageEditor from "./pages/PageEditor";
import PromotionPage from "./pages/PromotionPage";
import ScrollTopButton from "./components/common/ScrollTopButton";
import ScratchCardManagement from "./pages/ScratchCardManagement";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f58b0e",
      light: "#ffb64a",
      dark: "#b55d00",
      contrastText: "#fff",
    },
    secondary: {
      main: "#424242",
      light: "#f5f5f5",
      dark: "#1b1b1b",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#ffa000",
    },
    info: {
      main: "#1976d2",
    },
    success: {
      main: "#388e3c",
    },
    background: {
      default: "secondary.light",
    },
    text: {
      primary: "#2a2a2a",
      secondary: "#616161",
    },
  },
});

function CommonLayout({ children }: any) {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <ScrollTopButton />
    </ThemeProvider>
  );
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <CommonLayout>
        <Login />
      </CommonLayout>
    ),
  },
  {
    path: "/",
    element: (
      <CommonLayout>
        <Home />
      </CommonLayout>
    ),
  },
  {
    path: "duo-callback",
    element: (
      <CommonLayout>
        <DuoCallback />
      </CommonLayout>
    ),
  },
  {
    path: "/sign-up",
    element: (
      <CommonLayout>
        <SignUp />
      </CommonLayout>
    ),
  },
  {
    path: "/user-management",
    element: (
      <CommonLayout>
        <UserActivity />
      </CommonLayout>
    ),
  },
  {
    path: "/admin-profile",
    element: (
      <CommonLayout>
        <AdminProfile />
      </CommonLayout>
    ),
  },
  {
    path: "/scratch-card-management",
    element: (
      <CommonLayout>
        <ScratchCardManagement />
      </CommonLayout>
    ),
  },
  {
    path: "/reward-activity",
    element: (
      <CommonLayout>
        <RewardActivity />
      </CommonLayout>
    ),
  },
  {
    path: "/subscription-activity",
    element: (
      <CommonLayout>
        <SubscriptionActivity />
      </CommonLayout>
    ),
  },
  {
    path: "/event-activity",
    element: (
      <CommonLayout>
        <EventActivity />
      </CommonLayout>
    ),
  },
  {
    path: "/category-item-management",
    element: (
      <CommonLayout>
        <CategoryItemManagement />
      </CommonLayout>
    ),
  },
  {
    path: "/category-item-management/:categoryId",
    element: (
      <CommonLayout>
        <CategoryItemManagement />
      </CommonLayout>
    ),
  },
  {
    path: "/drop-management",
    element: (
      <CommonLayout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
            width: "100vw",
            height: "auto",
            backgroundColor: "white",
          }}
        >
          {" "}
          <DropManagement />
        </Box>
      </CommonLayout>
    ),
  },
  {
    path: "/box-management",
    element: (
      <CommonLayout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: "2rem", sm: "5rem", md: "5rem", lg: "5rem" },
            width: "100vw",
            height: "auto",
            backgroundColor: "white",
          }}
        >
          <BoxManagement />
          <BoxItemManagement />
        </Box>
      </CommonLayout>
    ),
  },
  {
    path: "/role-management",
    element: (
      <CommonLayout>
        <RoleManagement />
      </CommonLayout>
    ),
  },
  {
    path: "/subscription-stats",
    element: (
      <ThemeProvider theme={theme}>
        <StatsActivity />
      </ThemeProvider>
    ),
  },
  {
    path: "/asset-management",
    element: (
      <CommonLayout>
        <AssetsManagement />
      </CommonLayout>
    ),
  },

  {
    path: "/page-editor",
    element: (
      <CommonLayout>
        <PageEditor />
      </CommonLayout>
    ),
  },
  {
    path: "/promotion-page/:link",
    element: (
      <CommonLayout>
        <PromotionPage />
      </CommonLayout>
    ),
  },
]);

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}
