import { Grid2 as Grid, Skeleton } from "@mui/material";

export default function ItemSkeleton() {
  return (
    <Grid container spacing={3}>
      {Array.from(new Array(4)).map((_, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
          <Skeleton variant="rectangular" height={240} sx={{ borderRadius: 2 }} />
        </Grid>
      ))}
    </Grid>
  );
}
