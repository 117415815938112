import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { Box, Typography } from "@mui/material";
import Header from "../components/common/Header";

const Home = () => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <Header />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to the Admin Dashboard
        </Typography>
      </Box>
    </>
  );
};

export default Home;
