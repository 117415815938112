import ImageNotFound from "../assets/image-not-found.png";
import { AssetType } from "../types/common";

export function getS3ImageUrlFromAsset({ asset }: { asset?: AssetType }) {
  if (!asset) return ImageNotFound;

  const s3Path = `admin-assets/${asset?.s3Key}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;

  if (!s3Path || !cloudfrontPrefix) {
    console.error(`@getS3ImageUrl: Invalid s3Path or cloudfrontPrefix for asset`, { s3Path, cloudfrontPrefix });
    return ImageNotFound;
  }

  return `${cloudfrontPrefix}/${s3Path}`;
}
