export function getPathFromCategoryObj({ categoryObject }: any) {
  let path = [];
  let current = categoryObject;

  while (current) {
    path.unshift(current); // Assuming 'name' is the property you want to include in the path
    current = current.parentDetails; // Move to the parent
  }

  return [...path]; // Join the path with a separator
}
