import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Box,
  Chip,
  Stack,
  Divider,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { formatDate } from "../../utils/time";
import { ExpandMore, Inventory2Outlined, LayersOutlined } from "@mui/icons-material";
import { truncateText } from "../../utils/string";
import { formatPriceFromObject } from "../../utils/currency";

interface BoxDetailRowProps {
  tab?: string;
  box?: any; // Adjust the type based on your box structure
}

export default function BoxDetailRow({ tab, box }: BoxDetailRowProps) {
  if (!box) return null;

  return (
    <React.Fragment>
      <TableRow key={box?._id} sx={{ cursor: "pointer" }}>
        <TableCell>
          <Typography variant="body1">{box?.name || "No Box Name"}</Typography>
        </TableCell>
        <TableCell>{box?.type || "No Details"}</TableCell>
        <TableCell>{box?.description || "No Description"}</TableCell>
        <TableCell sx={{ width: "60%" }}>
          {box?.packsAdded?.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {box?.packsAdded?.map((detail: any, index: number) => (
                <Accordion
                  key={index}
                  sx={{
                    borderRadius: 2,
                    boxShadow: 2,
                    marginBottom: 1,
                    flexShrink: 1,
                    flexGrow: 1,
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "100%" }}>
                      <Inventory2Outlined fontSize="small" color="primary" />
                      <Typography variant="subtitle2" fontWeight="bold">
                        Pack Name:
                      </Typography>
                      <Chip label={detail.packId?.name || "N/A"} size="small" variant="outlined" color="primary" />

                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <LayersOutlined fontSize="small" color="action" />
                        <Typography variant="body2" color="textSecondary">
                          {detail.quantity} items
                        </Typography>
                      </Stack>

                      <Chip
                        label={
                          detail.quantity <= (detail.packId?.availableQuantity || 0) ? "In Stock" : "Insufficient Stock"
                        }
                        size="small"
                        color={detail.quantity <= (detail.packId?.availableQuantity || 0) ? "success" : "error"}
                        sx={{ fontWeight: "bold" }}
                      />
                    </Stack>
                  </AccordionSummary>
                  <Divider variant="middle" />
                  <AccordionDetails
                    sx={{
                      padding: "0.5rem 1rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction="column" spacing={0.5} sx={{ minWidth: "50%" }}>
                      <Typography variant="caption" color="textSecondary">
                        Type:
                      </Typography>
                      <Typography variant="body2">{detail.packId?.type || "N/A"}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        Description:
                      </Typography>
                      <Typography variant="body2">{detail.packId?.description || "N/A"}</Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5} sx={{ minWidth: "50%" }}>
                      <Typography variant="caption" color="textSecondary">
                        Drop ID:
                      </Typography>
                      <Tooltip title={detail.packId?.dropId || "N/A"}>
                        <Typography variant="body2">{truncateText(detail.packId?.dropId || "N/A", 10)}</Typography>
                      </Tooltip>
                      <Typography variant="caption" color="textSecondary">
                        Available Quantity:
                      </Typography>
                      <Typography variant="body2">{detail.packId?.availableQuantity || "N/A"}</Typography>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Packs
            </Typography>
          )}
        </TableCell>
        <TableCell>{box?.boxItemsCount || 0}</TableCell>
        <TableCell>
          <Typography variant="body1">{formatDate(box?.createdAt)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{formatPriceFromObject(box) || "N/A"}</Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
