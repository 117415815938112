import ImageNotFound from "../assets/image-not-found.png";

export function getS3ImageUrl({
  imageUrl,
  _id,
  type,
}: {
  imageUrl?: string;
  _id?: string;
  type: "subscription" | "reward" | "event" | "ripPack" | "box" | "pack-type";
}) {
  if (!imageUrl && !_id) return ImageNotFound;

  const s3Path = `${type}-images/${imageUrl || _id}`;
  const cloudfrontPrefix = process.env.REACT_APP_IMAGE_CF_URL;

  if (!s3Path || !cloudfrontPrefix) {
    console.error(`@getS3ImageUrl: Invalid s3Path or cloudfrontPrefix for ${type}`, { s3Path, cloudfrontPrefix });
    return ImageNotFound;
  }

  return `${cloudfrontPrefix}/${s3Path}`;
}
