import React, { useRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Slider,
  Stack,
  IconButton,
  Typography,
  Tooltip,
  styled,
} from "@mui/material";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import FlipIcon from "@mui/icons-material/Flip";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CropIcon from "@mui/icons-material/Crop";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

interface CropperDialogProps {
  open: boolean;
  onClose: () => void;
  onCropSave: (croppedImage: File) => void;
  imageUrl: string;
  cropMode?: string;
  aspectRatio?: number;
  minCropBoxWidth?: number;
  minCropBoxHeight?: number;
  maxFileSize?: number;
  quality?: number;
  format?: string;
  title?: string;
  cancelButtonText?: string;
  saveButtonText?: string;
  showControls?: {
    rotation?: boolean;
    flip?: boolean;
    zoom?: boolean;
    reset?: boolean;
    aspectRatio?: boolean;
    cropBox?: boolean;
  };
  customAspectRatios?: Array<{
    value: number;
    label: string;
  }>;
  cropBoxMovable?: boolean;
  cropBoxResizable?: boolean;
  guides?: boolean;
  centerIndicator?: boolean;
  background?: boolean;
  autoCropArea?: number;
  responsive?: boolean;
  restore?: boolean;
  checkCrossOrigin?: boolean;
  dragMode?: "crop" | "move" | "none";
  initialAspectRatio?: number;
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  minHeight: "400px",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  "& .cropper-container": {
    width: "100% !important",
    height: "400px !important",
  },
}));

const CropperDialog: React.FC<CropperDialogProps> = ({
  open,
  onClose,
  onCropSave,
  imageUrl,
  cropMode = "1:1",
  aspectRatio,
  minCropBoxWidth = 50,
  minCropBoxHeight = 50,
  maxFileSize = 5 * 1024 * 1024, // 5MB default
  quality = 0.8,
  format = "image/jpeg",
  title = "Crop Image",
  cancelButtonText = "Cancel",
  saveButtonText = "Save",
  showControls = {
    rotation: true,
    flip: true,
    zoom: true,
    reset: true,
    aspectRatio: true,
    cropBox: true,
  },
  customAspectRatios = [
    { value: 0, label: "Free" },
    { value: 1, label: "1:1" },
    { value: 4 / 3, label: "4:3" },
    { value: 16 / 9, label: "16:9" },
    { value: 3 / 4, label: "3:4" },
    { value: 9 / 16, label: "9:16" },
  ],
  cropBoxMovable = true,
  cropBoxResizable = true,
  guides = true,
  centerIndicator = true,
  background = true,
  autoCropArea = 1,
  responsive = true,
  restore = true,
  checkCrossOrigin = true,
  dragMode = "crop",
  initialAspectRatio = 1,
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [zoom, setZoom] = useState<number>(0);
  const [currentAspectRatio, setCurrentAspectRatio] = useState<number>(
    aspectRatio || (cropMode === "1:1" ? 1 : 16 / 9)
  );

  useEffect(() => {
    if (cropperRef.current) {
      const cropper = cropperRef.current?.cropper;
      if (aspectRatio) {
        cropper.setAspectRatio(aspectRatio);
      }
    }
  }, [aspectRatio]);

  const handleRotate = (degree: number) => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(degree);
    }
  };

  const handleFlip = (type: "horizontal" | "vertical") => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      if (type === "horizontal") {
        cropper.scaleX(cropper.getData().scaleX === 1 ? -1 : 1);
      } else {
        cropper.scaleY(cropper.getData().scaleY === 1 ? -1 : 1);
      }
    }
  };

  const handleZoomChange = (_event: Event, newValue: number | number[]) => {
    if (cropperRef.current && typeof newValue === "number") {
      setZoom(newValue);
      cropperRef.current.cropper.zoomTo(newValue);
    }
  };

  const handleAspectRatioChange = (ratio: number) => {
    if (cropperRef.current) {
      setCurrentAspectRatio(ratio);
      cropperRef.current.cropper.setAspectRatio(ratio);
    }
  };

  const handleReset = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.reset();
      setZoom(0);
      setCurrentAspectRatio(initialAspectRatio);
    }
  };

  const handleSave = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      canvas.toBlob(
        (blob) => {
          if (blob) {
            if (blob.size > maxFileSize) {
              alert(`File size exceeds ${maxFileSize / (1024 * 1024)}MB limit`);
              return;
            }
            const file = new File([blob], "cropped-image." + format.split("/")[1], {
              type: format,
            });
            onCropSave(file);
          }
        },
        format,
        quality
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <StyledDialogContent>
        <Cropper
          ref={cropperRef}
          src={imageUrl}
          style={{ width: "100%", height: "400px" }}
          aspectRatio={currentAspectRatio}
          minCropBoxWidth={minCropBoxWidth}
          minCropBoxHeight={minCropBoxHeight}
          guides={guides}
          center={centerIndicator}
          background={background}
          autoCropArea={autoCropArea}
          responsive={responsive}
          restore={restore}
          checkCrossOrigin={checkCrossOrigin}
          dragMode={dragMode}
          cropBoxMovable={cropBoxMovable}
          cropBoxResizable={cropBoxResizable}
          viewMode={1}
        />

        <Box sx={{ px: 3 }}>
          {showControls.rotation && (
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
              <Typography variant="body2">Rotation:</Typography>
              <Tooltip title="Rotate Left">
                <IconButton onClick={() => handleRotate(-90)} size="small">
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rotate Right">
                <IconButton onClick={() => handleRotate(90)} size="small">
                  <RotateRightIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}

          {showControls.flip && (
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
              <Typography variant="body2">Flip:</Typography>
              <Tooltip title="Flip Horizontal">
                <IconButton onClick={() => handleFlip("horizontal")} size="small">
                  <FlipIcon sx={{ transform: "rotate(90deg)" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Flip Vertical">
                <IconButton onClick={() => handleFlip("vertical")} size="small">
                  <FlipIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}

          {showControls.zoom && (
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
              <ZoomOutIcon />
              <Slider
                value={zoom}
                onChange={handleZoomChange}
                min={0}
                max={3}
                step={0.1}
                aria-labelledby="zoom-slider"
              />
              <ZoomInIcon />
            </Stack>
          )}

          {showControls.aspectRatio && (
            <Stack direction="row" spacing={1} mb={2} gap={1} flexWrap="wrap">
              <Typography variant="body2" sx={{ width: "100%", mb: 1 }}>
                Aspect Ratio:
              </Typography>
              {customAspectRatios.map((ratio) => (
                <Button
                  key={ratio.value}
                  variant={currentAspectRatio === ratio.value ? "contained" : "outlined"}
                  size="small"
                  onClick={() => handleAspectRatioChange(ratio.value)}
                  startIcon={<AspectRatioIcon />}
                >
                  {ratio.label}
                </Button>
              ))}
            </Stack>
          )}

          {showControls.reset && (
            <Button startIcon={<RestartAltIcon />} onClick={handleReset} variant="outlined" fullWidth>
              Reset
            </Button>
          )}
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {cancelButtonText}
        </Button>
        <Button onClick={handleSave} variant="contained" startIcon={<CropIcon />}>
          {saveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropperDialog;
