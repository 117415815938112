import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import TinyMce from "../common/TinyMce";
import { Close, Fullscreen, FullscreenExit, TextFields } from "@mui/icons-material";
import { useState } from "react";

interface TextBlockProps {
  htmlContent: string;
  handleHtmlContentChange: (htmlContent: string, blockIndex: number, side?: "left" | "right") => void;
  blockIndex: any;
  handleDeleteBlock: (blockIndex: number) => void;
}

export default function TextBlock({
  htmlContent,
  handleHtmlContentChange,
  blockIndex,
  handleDeleteBlock,
}: TextBlockProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  function handleEditorChange(content: string) {
    handleHtmlContentChange(content, blockIndex);
  }

  return (
    <div
      style={{
        position: isFullscreen ? "fixed" : "relative",
        top: isFullscreen ? 70 : "auto",
        left: isFullscreen ? 0 : "auto",
        width: isFullscreen ? "80%" : "100%",
        height: isFullscreen ? "80%" : "20rem",
        zIndex: isFullscreen ? 1000 : "200",
        backgroundColor: isFullscreen ? "#fff" : "transparent",
      }}
    >
      <Stack
        spacing={2.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ position: "relative", mb: 1, ml: 2, mt: 1 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <TextFields sx={{ fontSize: "1.2rem", color: "primary.main" }} />
          <Box sx={{ fontWeight: 600, color: "text.primary" }}>Text Block</Box>
        </Box>
        <Box>
          <Tooltip title={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}>
            <IconButton onClick={handleFullscreenToggle}>
              {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Editor">
            <IconButton onClick={() => handleDeleteBlock(blockIndex)}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Box sx={{ height: "100%", ml: 2, mb: 2 }}>
        <TinyMce
          viewHeight="83%"
          viewWidth="100%"
          content={htmlContent}
          onEditorChange={handleEditorChange}
          hideMenu={true}
        />
      </Box>
    </div>
  );
}
