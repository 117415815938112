import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect, useState } from "react";
import CustomDialog from "../common/CustomDialog";
import { boxApi } from "../../apis/BoxApi";

export default function BoxItemStats({ open, onClose, boxItem }: any) {
  const [boxItemStats, setBoxItemStats] = useState<any>([]);

  useEffect(() => {
    if (boxItem) {
      boxApi
        .getItemDistribution({ boxId: boxItem?.boxId?._id })
        .then((itemDistribution: any) => {
          if (!itemDistribution) return;
          let stats = itemDistribution.map((categoryItemDistribution: any) => {
            return {
              id: categoryItemDistribution._id,
              value: categoryItemDistribution.quantityPercentage,
              label: categoryItemDistribution?.packDetails?.name || "No Pack Name",
            };
          });

          setBoxItemStats(stats);
        })
        .catch((error: any) => {
          console.error(error);
        });
      setBoxItemStats(boxItem);
    }
  }, [boxItem]);

  return (
    <React.Fragment>
      {boxItemStats && boxItemStats.length > 0 && (
        <CustomDialog
          open={open}
          onClose={onClose}
          avatarSrc=""
          text="Probability Distribution of Items in this Pack"
          subText=""
          linkText=""
          linkUrl=""
          linkText2=""
          linkUrl2=""
          removeHeaderDivider={true}
        >
          <PieChart
            series={[
              {
                data: boxItemStats,
              },
            ]}
            width={400}
            height={200}
          />
        </CustomDialog>
      )}
    </React.Fragment>
  );
}
