import { useNavigate, useParams } from "react-router-dom";
// import useIsMobile from "../hooks/useIsMobile";
import { useCallback, useEffect, useState } from "react";
import { userApi } from "../apis/UserApi";
import { useIsMobile } from "../hooks/useIsMobile";
import { builderApi } from "../apis/BuilderPageApi"; // Add this line
import Header from "../components/common/Header";
// import { isValidEmail } from "../utils/validation";

export default function PromotionPage() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { link } = useParams();

  const [data, setData] = useState<any>(null);
  const welcomeNewsletter = "66d765d1edb18db19d090776";

  function handleLinkClick(event: React.MouseEvent) {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === "A") {
      const isInternalLink = target.href.includes(window.location.origin);
      if (isInternalLink) navigate(target.pathname);
      else {
        event.preventDefault();
        window.open(target.href, "_blank");
      }
    }
  }

  const attachClickEvents = useCallback(() => {
    const sendEmailButton = document.getElementsByName("SendEmail")[0] as HTMLButtonElement;
    const sendEmailInput = document.getElementsByName("SendEmail Input")[0] as HTMLInputElement;

    if (!sendEmailButton || !sendEmailInput) return;

    async function handleClick() {
      try {
        // if (!isValidEmail(sendEmailInput.value)) throw new Error("Invalid Email");
        // // const res = await userApi.(
        // //   { email: sendEmailInput.value, tags: ["shockem-homepage"] },
        // //   welcomeNewsletter,
        // // );
        // if (res.status === 200) alert("Thank you for joining the Shock’em Army!");
      } catch (error: any) {
        console.error(error);
        alert(error.message || "An error occurred");
      }
    }
    sendEmailButton.removeEventListener("click", handleClick);
    sendEmailButton.addEventListener("click", handleClick);

    return () => {
      sendEmailButton.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (data?.blocks) attachClickEvents();
  }, [data, attachClickEvents]);

  useEffect(() => {
    async function loadPageContent() {
      try {
        const data: any = await builderApi.fetchPageContent(`/${link}`);
        setData(data);
      } catch (error: any) {
        console.error("Error fetching page content: " + error.message);
      }
    }
    if (!link) return;
    loadPageContent();
  }, [link]);

  if (!data) return null;

  if (!isMobile) {
    return (
      <>
        <Header />
        <div>
          {data?.type && data?.type === "htmlContent" ? (
            <div>
              <div dangerouslySetInnerHTML={{ __html: data.htmlContent }} onClick={handleLinkClick} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url(${data.backgroundImage})`,
                backgroundSize: "100% 100%",
                backgroundColor: data.backgroundColor,
              }}
            >
              {data?.blocks?.map((block: any, blockIndex: number) => (
                <div key={blockIndex} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                  {block.map((item: any, subIndex: number) => (
                    <div
                      key={subIndex}
                      style={{ width: `${100 / block.length}%`, overflow: "hidden" }}
                      onClick={handleLinkClick}
                      dangerouslySetInnerHTML={{ __html: item.htmlContent }}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header />
        <div>
          {data?.type && data?.type === "htmlContent" ? (
            <div dangerouslySetInnerHTML={{ __html: data.htmlContent }} onClick={handleLinkClick} />
          ) : (
            <div
              style={{
                backgroundImage: `url(${data.backgroundImage})`,
                backgroundSize: "100% 100%",
                backgroundColor: data.backgroundColor,
              }}
            >
              {data?.blocks?.map((block: any) => {
                return block.map((item: any) => {
                  return (
                    <div
                      style={{ overflow: "hidden" }}
                      dangerouslySetInnerHTML={{ __html: item.htmlContent }}
                      onClick={handleLinkClick}
                    />
                  );
                });
              })}
            </div>
          )}
        </div>
      </>
    );
  }
}
