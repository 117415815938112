import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { Box, Button, Checkbox, Tooltip, Typography, useTheme, Avatar, Stack } from "@mui/material";
import { ripPackApi } from "../../../apis/RipPackApi";
import { packItemApi } from "../../../apis/PackItemApi";
import { HEADER_HEIGHT } from "../../common/Header";
import { openSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { ItemStageEnum } from "../../../types/stage";
import PackSelectionMenu from "./pack-selection-manager/PackSelectionMenu";
import InsightsIcon from "@mui/icons-material/Insights";
import ItemStatsForPack from "../../pack-item-management/ItemStatsForPack";
import { useSearchParams } from "react-router-dom";
import { getS3ImageUrl } from "../../../utils/s3Image";

interface PackSelectionManagerProps {
  visibleItems: any[];
  selectedItems: string[];
  onSelectedItemsChange: (items: string[]) => void;
  selectedRipPack: any;
  setSelectedRipPack: (pack: any | null) => void;
  remainingSlots: number | null;
  setRemainingSlots: (slots: number | null) => void;
  refetchItems: (page: number) => void;
}

export default function PackSelectionManager({
  visibleItems,
  selectedItems,
  selectedRipPack,
  setSelectedRipPack,
  remainingSlots,
  setRemainingSlots,
  onSelectedItemsChange,
  refetchItems,
}: PackSelectionManagerProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const dispatch = useDispatch();

  const [showPackStats, setShowPackStats] = useState(false);

  const [ripPacks, setRipPacks] = useState<any[]>([]);
  const [packStats, setPackStats] = useState<any>(null);
  const [packMenuAnchorEl, setPackMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [refetchRipPackStats, setRefetchRipPackStats] = useState<boolean>(false);

  const selectedItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (Boolean(packMenuAnchorEl) && selectedRipPack && selectedItemRef.current) {
      setTimeout(() => {
        selectedItemRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  }, [packMenuAnchorEl, selectedRipPack]);

  const selectedRipPackDetails = useMemo(() => {
    if (!selectedRipPack) return null;
    return ripPacks.find((pack) => pack._id === selectedRipPack._id)?.details;
  }, [selectedRipPack, ripPacks]);

  function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
    // Filter out items that are already assigned
    const selectableItems = visibleItems.filter((item) => item.stage !== ItemStageEnum.Assigned);
    const maxSelectableItems = remainingSlots ?? selectableItems.length;
    const visibleItemIds = new Set(visibleItems.map((item) => item._id));
    
    // Get currently selected visible items
    const selectedVisibleItems = selectedItems.filter(id => visibleItemIds.has(id));
    
    if (selectedVisibleItems.length === Math.min(selectableItems.length, maxSelectableItems)) {
      // If all visible items are selected, deselect them
      const remainingSelected = selectedItems.filter((id) => !visibleItemIds.has(id));
      onSelectedItemsChange(remainingSelected);
    } else {
      // Otherwise, select all visible items up to the maximum allowed
      const itemsToSelect = selectableItems.slice(0, maxSelectableItems).map((item) => item._id);
      const nonVisibleSelected = selectedItems.filter((id) => !visibleItemIds.has(id));
      onSelectedItemsChange([...nonVisibleSelected, ...itemsToSelect]);
    }
  }

  const handlePackMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setPackMenuAnchorEl(event.currentTarget);
  };

  const handlePackMenuClose = useCallback(() => {
    setPackMenuAnchorEl(null);
  }, []);

  const handlePackSelect = async (packId: string, pack: any) => {
    try {
      setSelectedRipPack(pack);
      setSearchParams({ ripPackId: pack?.details?._id });
      onSelectedItemsChange([]); // Clear selection when changing packs
      handlePackMenuClose();
    } catch (error) {
      console.error("Error moving items to pack:", error);
    }
  };

  async function handleMoveItems() {
    try {
      const result = await packItemApi.insertPackItem({
        ripPackId: selectedRipPack?.ripPackId,
        itemIds: selectedItems,
      });
      if (!result.length) throw new Error("Error moving items to pack");
      refetchItems(1);
      onSelectedItemsChange([]);
      setSelectedRipPack(null);
      setRefetchRipPackStats(true);
      dispatch(openSnackbar({ message: "Items moved successfully", severity: "success" }));
    } catch (error: any) {
      console.error("Error moving items to pack:", error);
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  }

  useEffect(() => {
    async function fetchRipPacks() {
      try {
        const response = await ripPackApi.getRipPackDetails({});
        if (response?.ripPacks) {
          const validPacks = response.ripPacks.filter((pack) => pack?.ripPackId && pack?.details?.name);
          setRipPacks(validPacks);

          const ripPackId = searchParams.get("ripPackId");
          if (ripPackId) {
            const selectedPack = validPacks.find((pack) => pack.details._id === ripPackId);
            if (selectedPack) setSelectedRipPack(selectedPack);
          }
        }
      } catch (error) {
        console.error("Error fetching rip packs:", error);
      }
    }

    fetchRipPacks();
  }, []);

  useEffect(() => {
    async function fetchRipPackItemStats() {
      try {
        if (!selectedRipPack) return;
        const stats = await packItemApi.getRipPackItemStats({ ripPackId: selectedRipPack.ripPackId });
        setPackStats(stats);
      } catch (error) {
        console.error("Error fetching rip pack item stats:", error);
      } finally {
        setRefetchRipPackStats(false);
      }
    }
    if (selectedRipPack || refetchRipPackStats) fetchRipPackItemStats();
  }, [selectedRipPack, refetchRipPackStats]);

  useEffect(() => {
    if (selectedRipPack && packStats) {
      const maxCapacity = selectedRipPack.details.maxQuantityPerPack * selectedRipPack.details.packQuantity;
      const currentItems = packStats?.totalItemsInPack || 0;
      const remaining = maxCapacity - currentItems;
      setRemainingSlots(Math.max(0, remaining));
    } else {
      setRemainingSlots(null);
    }
  }, [selectedRipPack, packStats, setRemainingSlots]);

  if (visibleItems.length === 0) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mb: 3,
        position: "sticky",
        top: HEADER_HEIGHT,
        zIndex: 10,
      }}
    >
      {/* Pack Selection Controls */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Button
          variant="contained"
          onClick={handlePackMenuClick}
          sx={{
            borderRadius: 2,
            textTransform: "none",
            bgcolor: "primary.main",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 15px rgba(25, 118, 210, 0.2)",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            transition: "all 0.3s ease",
            "&:hover": {
              bgcolor: "primary.dark",
              boxShadow: "0 4px 20px rgba(25, 118, 210, 0.3)",
              transform: "translateY(-1px)",
            },
          }}
        >
          {selectedRipPack ? "Change Pack" : "Select Pack"}
        </Button>
      </Box>

      {/* Selection Bar */}
      <Box
        sx={{
          position: "sticky",
          top: HEADER_HEIGHT,
          display: "flex",
          alignItems: "center",
          gap: 2,
          p: 2,
          borderRadius: 1,
          bgcolor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
          borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
          borderRight: "1px solid rgba(255, 255, 255, 0.3)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          zIndex: 10,
          transition: "all 0.3s ease",
          "&:hover": {
            bgcolor: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0 4px 35px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, flex: 1 }}>
          {selectedRipPack && (
            <Checkbox
              checked={selectedItems.length === Math.min(visibleItems.length, remainingSlots ?? visibleItems.length)}
              indeterminate={
                selectedItems.length > 0 &&
                selectedItems.length < Math.min(visibleItems.length, remainingSlots ?? visibleItems.length)
              }
              onChange={handleSelectAll}
              disabled={!selectedRipPack}
              sx={{
                color: "rgba(0, 0, 0, 0.3)",
                "&.Mui-checked": {
                  color: "primary.main",
                },
                "&:hover": {
                  bgcolor: "rgba(25, 118, 210, 0.04)",
                },
              }}
            />
          )}
          <Typography variant="body2" color="text.secondary">
            {!selectedRipPack
              ? "Select a pack first"
              : selectedItems.length === 0
              ? "Select items"
              : `${selectedItems.length} items selected`}
          </Typography>
          {selectedRipPack && remainingSlots !== null && (
            <Typography variant="body2" color="primary" sx={{ ml: 2 }}>
              {remainingSlots === 0 ? "Pack is full" : `${remainingSlots} slots available`}
            </Typography>
          )}
          {selectedRipPack && (
            <Tooltip title="Odds breakdown of items in this pack">
              <InsightsIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPackStats(true);
                }}
              />
            </Tooltip>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {selectedRipPack && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography sx={{ fontWeight: 600, fontSize: "1rem" }} color="text.secondary">
                  Selected Pack:
                </Typography>
                <Avatar
                  src={
                    getS3ImageUrl({
                      imageUrl: selectedRipPack?.details?.packTypeId?.imageUrl,
                      type: "pack-type",
                    }) || ""
                  }
                  alt={selectedRipPack.details?.name}
                />
                <Stack spacing={0.2} direction="column">
                  <Typography variant="body2" color="primary" fontWeight="medium">
                    {selectedRipPack.details?.name}
                    {packStats && selectedRipPackDetails && (
                      <Typography component="span" variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                        ({packStats?.totalItemsInPack || 0}/
                        {selectedRipPack?.details?.maxQuantityPerPack * selectedRipPack?.details?.packQuantity} items)
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {selectedRipPack.details?.dropDetails?.name || "No drop details"}
                  </Typography>
                </Stack>
              </Box>
              {selectedItems.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMoveItems}
                  sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    bgcolor: "primary.main",
                    backdropFilter: "blur(10px)",
                    boxShadow: theme.shadows[2],
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: theme.shadows[4],
                      transform: "translateY(-1px)",
                    },
                  }}
                >
                  Move Items
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>

      {/* Pack Selection Menu */}
      <Button data-testid="pack-selection-trigger" sx={{ display: "none" }} onClick={handlePackMenuClick} />
      <Box
        sx={{
          position: "fixed",
          top: HEADER_HEIGHT,
          right: 0,
          p: 2,
          zIndex: 10,
        }}
      >
        <PackSelectionMenu
          anchorEl={packMenuAnchorEl}
          open={Boolean(packMenuAnchorEl)}
          onClose={handlePackMenuClose}
          ripPacks={ripPacks}
          selectedRipPack={selectedRipPack}
          onPackSelect={handlePackSelect}
        />
      </Box>
      {showPackStats && selectedRipPack && (
        <ItemStatsForPack onClose={() => setShowPackStats(false)} pack={selectedRipPack?.details} />
      )}
    </Box>
  );
}
