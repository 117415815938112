import { Button, MenuItem, Stack, Divider, TextField, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomDialog from "../common/CustomDialog";
import { rewardApi } from "../../apis/RewardApi";
import { eventApi } from "../../apis/EventApi";
import { scratchCardApi } from "../../apis/ScratchCardApi";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import { rewardTypeEnum } from "../../types/reward-enum";
import { scratchCardTypeEnum } from "../../types/scratch-card-enum";
import { packItemApi } from "../../apis/PackItemApi";
import PackDetailRow from "../drop-management/pack-management/PackDetailRow";

export default function CreateScratchCard({ open, onClose }: any) {
  const [rewards, setRewards] = useState<any[]>([]);
  const [filteredRewards, setFilteredRewards] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [reward, setReward] = useState<any>(null);
  const [ripPackItemScratchCardStats, setRipPackItemScratchCardStats] = useState<any>(null);
  const [event, setEvent] = useState("");
  const [type, setType] = useState("physical");
  const [rewardType, setRewardType] = useState(null);
  const [amount, setAmount] = useState(1);
  const [usageLimit, setUsageLimit] = useState(1);
  const dispatch = useDispatch();

  async function handleFormSubmit(e: any) {
    e.preventDefault();
    // Validate required fields
    if (!event) {
      dispatch(openSnackbar({ message: "Please select an event", severity: "error" }));
      return;
    }
    if (!rewardType) {
      dispatch(openSnackbar({ message: "Please select a prize type", severity: "error" }));
      return;
    }
    if (!reward) {
      dispatch(openSnackbar({ message: "Please select a prize", severity: "error" }));
      return;
    }

    // Parse amount and usageLimit to numbers
    const parsedAmount = Number(amount);
    const parsedUsageLimit = Number(usageLimit);

    // Check if they are valid numbers
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      dispatch(openSnackbar({ message: "Please enter a valid amount greater than 0", severity: "error" }));
      return;
    }
    if (isNaN(parsedUsageLimit) || parsedUsageLimit <= 0) {
      dispatch(openSnackbar({ message: "Please enter a valid usage limit greater than 0", severity: "error" }));
      return;
    }

    try {
      let response = await scratchCardApi.createScratchCards({
        rewardId: reward?._id,
        eventId: event,
        type: type,
        usageLimit: parsedUsageLimit,
        amount: parsedAmount,
        rewardChoices: [],
      });
      if (response.success) {
        onClose();
        dispatch(openSnackbar({ message: "Scratch Card created successfully", severity: "success" }));
        setReward(null);
        setEvent("");
        setAmount(0);
      } else {
        dispatch(openSnackbar({ message: response.message, severity: "error" }));
      }
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to create scratch card", severity: "error" }));
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const rewardsPromise = rewardApi.getPaginatedRewards({ limit: 100, populate: true });
        const eventsPromise = eventApi.getEventDetails({});
        const [rewardsResponse, eventsResponse] = await Promise.all([rewardsPromise, eventsPromise]);
        setRewards(rewardsResponse.rewards);
        setEvents(eventsResponse.events);
      } catch (e: any) {
        console.error(e);
      }
    })();
  }, [open]);

  useEffect(() => {
    if (type === scratchCardTypeEnum.Physical || type === scratchCardTypeEnum.PhysicalTest) {
      setUsageLimit(1);
    } else if (type === scratchCardTypeEnum.PromoCode) {
      setUsageLimit(Infinity); // Represent unlimited usage
    }
  }, [type]);

  useEffect(() => {
    if (rewardType === rewardTypeEnum.AllSubscriptions) {
      setFilteredRewards(rewards.filter((reward) => reward?.type === rewardType));
    } else if (rewardType === rewardTypeEnum.RipPack) {
      setFilteredRewards(rewards.filter((reward) => reward?.type === rewardType));
    }
  }, [rewardType, rewards]);

  useEffect(() => {
    if (reward && type === scratchCardTypeEnum.Physical && rewardType === rewardTypeEnum.RipPack && reward?.ripPackId) {
      packItemApi
        .getRipPackItemScratchCardStats({ ripPackId: reward?.ripPackId })
        .then((stats) => {
          setRipPackItemScratchCardStats(stats);
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      setRipPackItemScratchCardStats(null);
    }
  }, [type, rewardType, reward]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      text="Create Scratch Card From"
      subText="Fill in the details for the new scratch card(s)"
    >
      <Stack component="form" gap={1} onSubmit={handleFormSubmit}>
        <TextField label="Event" select fullWidth value={event} onChange={(e: any) => setEvent(e.target.value)}>
          {events.map((event, index) => (
            <MenuItem key={index} value={event._id}>
              {event.name}
            </MenuItem>
          ))}
        </TextField>

        <Divider sx={{ mb: 2 }} />

        <TextField
          label="Type"
          select
          fullWidth
          value={type}
          onChange={(e: any) => {
            setType(e.target.value);
            setUsageLimit(1);
          }}
        >
          <MenuItem value="physical">Physical Card</MenuItem>
          <MenuItem value="promo-code">Promo Code</MenuItem>
        </TextField>
        <Divider sx={{ mb: 2 }} />

        <TextField
          label="Prize Type"
          select
          fullWidth
          value={rewardType}
          onChange={(e: any) => {
            setRewardType(e.target.value);
            setReward(null);
          }}
        >
          {type === scratchCardTypeEnum.PromoCode && (
            <MenuItem value={rewardTypeEnum.AllSubscriptions}>All Subscription</MenuItem>
          )}
          {type === scratchCardTypeEnum.Physical && <MenuItem value={rewardTypeEnum.RipPack}>RIP Pack</MenuItem>}
        </TextField>
        {rewardType && (
          <TextField
            select
            fullWidth
            label={filteredRewards.length === 0 ? "No Prizes" : "Prize"}
            value={reward}
            onChange={(e: any) => setReward(e.target.value)}
            disabled={filteredRewards.length === 0}
          >
            {filteredRewards.map((reward, index) => (
              <MenuItem key={index} value={reward}>
                {reward.name + " ( Pack: " + reward?.ripPackDetails?.name + ")"}
              </MenuItem>
            ))}
          </TextField>
        )}

        {ripPackItemScratchCardStats && (
          <Stack>
            <Typography
              sx={{ color: ripPackItemScratchCardStats?.totalRipPackItemsInPack ? "black" : "red", fontSize: "1rem" }}
              variant="body2"
            >
              Total Pack Items: {ripPackItemScratchCardStats?.totalRipPackItemsInPack || 0}
            </Typography>
            <Typography
              sx={{
                color: ripPackItemScratchCardStats?.totalRipPackItemsAvailable ? "black" : "red",
                fontSize: "1rem",
              }}
            >
              Available Pack Items: {ripPackItemScratchCardStats?.totalRipPackItemsAvailable || 0}
            </Typography>
          </Stack>
        )}
        <Divider sx={{ mb: 2 }} />

        <TextField
          fullWidth
          label="Usage Limit"
          value={type === "promo-code" ? "Infinity" : usageLimit}
          onChange={(e: any) => setUsageLimit(parseInt(e.target.value))}
          disabled={type === "physical" || type === "promo-code"}
        />
        <Divider sx={{ mb: 2 }} />

        <TextField
          fullWidth
          helperText={
            ripPackItemScratchCardStats
              ? `You can create ${ripPackItemScratchCardStats?.totalRipPackItemsAvailable || 0} scratch cards.`
              : ""
          }
          label="Amount"
          value={amount}
          onChange={(e: any) => setAmount(Number(e.target.value))}
          type="number"
          inputProps={{
            max: ripPackItemScratchCardStats ? ripPackItemScratchCardStats.totalRipPackItemsAvailable : undefined,
          }}
        />
        <Divider sx={{ mb: 2 }} />

        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" type="submit">
            Create
          </Button>
        </Stack>
      </Stack>
    </CustomDialog>
  );
}
