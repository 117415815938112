export enum CurrencyEnum {
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR",
  GBP = "GBP",
}

export interface Currency {
  code: CurrencyEnum;
  symbol: string;
  displayName: string;
}

export const currencies: Currency[] = [
  {
    code: CurrencyEnum.USD,
    symbol: "$",
    displayName: "US Dollar",
  },
  {
    code: CurrencyEnum.CAD,
    symbol: "CA$",
    displayName: "Canadian Dollar",
  },
  {
    code: CurrencyEnum.EUR,
    symbol: "€",
    displayName: "Euro",
  },
  {
    code: CurrencyEnum.GBP,
    symbol: "£",
    displayName: "British Pound",
  },
];
