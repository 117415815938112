import { Button, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Category } from "../../types/common";
import { categoryApi } from "../../apis/CategoryApi";

interface CurrentTitleProps {
  categoryDetails: Category | null;
  categoryId: string;
  editTitle: string;
  setEditTitle: (title: string) => void;
  fetchData: (categoryId: string) => void;
}

export default function CurrentTitle({
  categoryDetails,
  categoryId,
  editTitle,
  setEditTitle,
  fetchData,
}: CurrentTitleProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  async function handleSubmit() {
    await categoryApi.editCategory({ categoryId: categoryId || "", title: editTitle });
    setEditTitle("");
    setIsDialogOpen(false);
    fetchData(categoryId);
  }

  const handleClose = () => {
    setIsDialogOpen(false);
    setEditTitle("");
  };

  const handleOpen = () => {
    setEditTitle(categoryDetails?.title || "");
    setIsDialogOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        height: "3rem",
        backgroundColor: "background.paper",
        borderRadius: "0.5rem",

        pr: 1,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          backgroundColor: "background.default",
          "& .edit-icon": {
            opacity: 1,
            transform: "translateX(0)",
          },
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h5"
          sx={{
            color: "text.primary",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            "&::before": {
              content: '""',
              width: "0.25rem",
              height: "1.5rem",
              backgroundColor: "primary.main",
              marginRight: "1rem",
              borderRadius: "0.125rem",
              transition: "height 0.2s ease",
            },
            "&:hover::before": {
              height: "2rem",
            },
          }}
        >
          {categoryDetails?.title || "All Categories"}
        </Typography>
      </Box>

      {categoryDetails && (
        <IconButton
          onClick={handleOpen}
          className="edit-icon"
          sx={{
            color: "primary.main",
            opacity: 0.7,
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              backgroundColor: "primary.light",
              transform: "translateY(-0.5rem) scale(1.1)",
            },
          }}
        >
          <EditIcon sx={{ fontSize: "1.125rem" }} />
        </IconButton>
      )}

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Category Title</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
