import { Box, Button, IconButton, Stack, TextField, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Category } from "../../types/common";
import CategoryTagNameValues from "./CategoryTagNameValues";
import { categoryApi } from "../../apis/CategoryApi";
import CloseIcon from "@mui/icons-material/Close";
import { openSnackbar } from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

export default function CategoryTagSection({
  buttonOnly,
  categoryDetails,
  categoryId,
  setCategoryDetails,
  refetchItems,
  setOpenCreateCategoryForm,
  setOpenCreateItemForm,
}: any) {
  const theme = useTheme();
  const [newTagValue, setNewTagValue] = useState("");
  const [showAddTagValueInput, setShowAddTagValueInput] = useState(false);

  const dispatch = useDispatch();

  async function handleAddTag() {
    if (!newTagValue || !categoryId) return;

    try {
      const updatedCategory = await categoryApi.addTag({
        categoryId,
        tagName: newTagValue,
        required: false,
      });

      setCategoryDetails((prev: any) => ({ ...prev, tags: updatedCategory.tags }));
      setNewTagValue("");
    } catch (error) {
      dispatch(
        openSnackbar({ message: `Failed to add tag ${newTagValue} in category ${categoryId}`, severity: "error" })
      );
    }
  }

  return (
    <Box sx={{}}>
      <Stack direction="row" gap={0.5} sx={{ justifyContent: buttonOnly ? "flex-end" : "auto" }}>
        {!buttonOnly && (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" sx={{ fontWeight: 600, color: "text.primary", mr: 1 }}>
                Tags
              </Typography>
            </Box>
            {categoryDetails &&
              categoryId &&
              categoryDetails?.tags &&
              categoryDetails?.tags?.map((tagObject: any, index: any) => (
                <CategoryTagNameValues
                  key={index}
                  categoryId={categoryId}
                  tagObject={tagObject}
                  updateTagsInCategoryObject={(updatedCategory: Category | null) => {
                    if (updatedCategory) {
                      setCategoryDetails({ ...categoryDetails, tags: updatedCategory.tags });
                      refetchItems();
                    }
                  }}
                />
              ))}

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {showAddTagValueInput && (
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "2rem",
                      fontSize: "0.875rem",
                    },
                    width: "12.5rem",
                  }}
                  placeholder="Add tag"
                  value={newTagValue}
                  onChange={(e) => setNewTagValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleAddTag();
                  }}
                />
              )}
            </Box>

            {showAddTagValueInput ? (
              <Button
                variant="contained"
                onClick={() => setShowAddTagValueInput(false)}
                sx={{
                  borderRadius: "50%",
                  minWidth: "2rem",
                  height: "2rem",
                  p: 0,
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.dark",
                  },
                }}
              >
                <CloseIcon sx={{ color: "white", fontSize: "0.8rem" }} />
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => setShowAddTagValueInput((prev: boolean) => !prev)}
                sx={{
                  borderRadius: "0.25rem",
                  minWidth: 0,
                  p: "0.5rem",
                  backgroundColor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "secondary.dark",
                  },
                }}
              >
                <AddIcon sx={{ color: "white", fontSize: "0.8rem" }} />
              </Button>
            )}
          </>
        )}

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateCategoryForm(true)}
          sx={{
            ml: buttonOnly ? 0 : "auto",
            borderRadius: 2,
            textTransform: "none",
            boxShadow: theme.shadows[2],
            bgcolor: "secondary.main",
            "&:hover": {
              bgcolor: "secondary.dark",
              boxShadow: theme.shadows[4],
              transform: "translateY(-0.0625rem)",
            },
            transition: "all 0.2s ease",
          }}
        >
          Add Category
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateItemForm(true)}
          sx={{
            borderRadius: 2,
            textTransform: "none",
            boxShadow: theme.shadows[2],
            "&:hover": {
              boxShadow: theme.shadows[4],
              transform: "translateY(-0.0625rem)",
            },
            transition: "all 0.2s ease",
          }}
        >
          Add Item
        </Button>
      </Stack>
    </Box>
  );
}
