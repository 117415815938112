import { TableCell, TableHead, TableRow } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImportExportIcon from "@mui/icons-material/ImportExport";

interface UserDetailHeaderProps {
  tab: string;
  sortColumn: string;
  sortDirection: string;
  handleSort: (column: string) => void;
  handleSortDirection: (direction: string) => void;
}
export default function UserDetailHeader({
  tab,
  sortColumn,
  sortDirection,
  handleSort,
  handleSortDirection,
}: UserDetailHeaderProps) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "secondary.light" }}>
        <TableCell sx={{ borderBottom: "none" }}>Username</TableCell>
        <TableCell sx={{ borderBottom: "none", display: { xs: "none", sm: "table-cell" } }}>Interest</TableCell>
        <TableCell
          sx={{
            borderBottom: "none",
            display: { xs: tab === "lastActiveAt" ? "table-cell" : "none", sm: "table-cell" },
          }}
        >
          Last Active
          {tab !== "accountCreatedAt" &&
            (sortColumn === "lastActiveAt" ? (
              sortDirection === "1" ? (
                <ArrowUpwardIcon
                  sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                  onClick={() => {
                    handleSort("lastActiveAt");
                    handleSortDirection("-1");
                  }}
                />
              ) : (
                <ArrowDownwardIcon
                  sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                  onClick={() => {
                    handleSort("lastActiveAt");
                    handleSortDirection("1");
                  }}
                />
              )
            ) : (
              <ImportExportIcon
                sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("lastActiveAt");
                  handleSortDirection("-1");
                }}
              />
            ))}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "none",
            display: { xs: tab !== "lastActiveAt" ? "table-cell" : "none", sm: "table-cell" },
          }}
        >
          Created At
          {tab !== "lastActiveAt" &&
            (sortColumn === "accountCreatedAt" ? (
              sortDirection === "1" ? (
                <ArrowUpwardIcon
                  sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                  onClick={() => {
                    handleSort("accountCreatedAt");
                    handleSortDirection("-1");
                  }}
                />
              ) : (
                <ArrowDownwardIcon
                  sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                  onClick={() => {
                    handleSort("accountCreatedAt");
                    handleSortDirection("1");
                  }}
                />
              )
            ) : (
              <ImportExportIcon
                sx={{ fontSize: 16, ml: 1, cursor: "pointer" }}
                onClick={() => {
                  handleSort("accountCreatedAt");
                  handleSortDirection("-1");
                }}
              />
            ))}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
