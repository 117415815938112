import React, { useState } from "react";
import { Box, Button, TextField, Card, CardContent, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditEmailForm from "./EditEmailForm";
import EditPasswordForm from "./EditPasswordForm";
import { adminApi } from "../../apis/AdminApi";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";

interface EditAdminFormProps {
  fieldToEdit: string;
  onBack: () => void;

  email: string;
  refetch: () => void;
}

export default function EditAdminForm({ fieldToEdit, onBack, email, refetch }: EditAdminFormProps) {
  const [username, setUsername] = useState("");
  const adminInfo = useSelector((state: any) => state.admin.adminInfo);

  const dispatch = useDispatch();
  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      const updatedResult: any = await adminApi.updateAdmin({
        id: adminInfo._id,
        [fieldToEdit]: fieldToEdit === "username" ? username : email,
      });

      if (!updatedResult) throw new Error("Error in updating username");
      refetch();
      onBack();
      dispatch(openSnackbar({ message: "Username updated successfully", severity: "success" }));
    } catch (error: any) {
      console.error(error);
      dispatch(openSnackbar({ message: "Error in updating username", severity: "error" }));
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
        bgcolor: "background.paper",
        boxShadow: 3,
        transition: "transform 0.3s ease",
        zIndex: 10,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <IconButton onClick={onBack} sx={{ display: "flex", gap: 2, top: 16, left: 16 }}>
        <ArrowBackIcon />
        {`Edit ${fieldToEdit.charAt(0).toUpperCase() + fieldToEdit.slice(1).toLowerCase()}`}
      </IconButton>
      <Card>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit}>
            {
              {
                username: (
                  <React.Fragment>
                    {" "}
                    <TextField
                      margin="normal"
                      required={fieldToEdit === "username"}
                      fullWidth
                      label="Username"
                      value={username}
                      helperText={!username && "Username cannot be empty"}
                      onChange={(event) => setUsername(event.target.value)}
                      autoFocus
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
                      Save Changes
                    </Button>
                  </React.Fragment>
                ),
                email: <EditEmailForm currentEmail={email} onBack={onBack} onSaveNewEmail={refetch} />,
                password: <EditPasswordForm onBack={onBack} onSavePassword={refetch} />,
              }[fieldToEdit]
            }
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
