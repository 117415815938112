import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { useState } from "react";
import { Category } from "../../types/common";
import { CategorySortEnum, CategorySortLabels } from "../../types/category";

export interface SortOption {
  id: CategorySortEnum;
  label: string;
  sortFn: (a: Category, b: Category) => number;
}

export const sortOptions: SortOption[] = [
  {
    id: CategorySortEnum.AtoZ,
    label: CategorySortLabels[CategorySortEnum.AtoZ],
    sortFn: (a, b) => a.title.localeCompare(b.title),
  },
  {
    id: CategorySortEnum.ZtoA,
    label: CategorySortLabels[CategorySortEnum.ZtoA],
    sortFn: (a, b) => b.title.localeCompare(a.title),
  },
  {
    id: CategorySortEnum.Recent,
    label: CategorySortLabels[CategorySortEnum.Recent],
    sortFn: (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  },
  {
    id: CategorySortEnum.QtyOfItems,
    label: CategorySortLabels[CategorySortEnum.QtyOfItems],
    sortFn: (a, b) => (b?.countOfItems ?? 0) - (a.countOfItems ?? 0),
  },
];

interface SubCategorySorterProps {
  categories: Category[];
  onSortChange: (sortedCategories: Category[]) => void;
  activeSortId: string;
  onSortOptionChange: (sortId: string) => void;
}

export default function SubCategorySorter({
  categories,
  onSortChange,
  activeSortId,
  onSortOptionChange,
}: SubCategorySorterProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (option: SortOption) => {
    onSortOptionChange(option.id);
    const sortedCategories = [...categories].sort(option.sortFn);
    onSortChange(sortedCategories);
    handleClose();
  };

  const activeSort = sortOptions.find((option) => option.id === activeSortId);

  return (
    <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
      <Tooltip title="Sort categories" arrow>
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: "background.paper",
            borderRadius: "0.5rem",
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
        >
          <SortIcon
            sx={{
              fontSize: "1.25rem",
              color: Boolean(anchorEl) ? "primary.main" : "text.secondary",
              transition: "color 0.2s ease",
            }}
          />
        </IconButton>
      </Tooltip>

      <Typography
        variant="caption"
        sx={{
          ml: 1,
          color: "text.secondary",
          fontWeight: 500,
        }}
      >
        {activeSort && CategorySortLabels[activeSort.id]}
      </Typography>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            minWidth: "8rem",
            boxShadow: 2,
            "& .MuiList-root": {
              p: 1,
            },
          },
        }}
      >
        {sortOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleSortChange(option)}
            selected={option.id === activeSortId}
            sx={{
              borderRadius: "0.25rem",
              my: 0.5,
              fontSize: "0.875rem",
              fontWeight: option.id === activeSortId ? 600 : 400,
              color: option.id === activeSortId ? "primary.main" : "text.primary",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.main",
              },
            }}
          >
            {CategorySortLabels[option.id]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
