import { useEffect, useState } from "react";
import { Button, Stack, TextField, Typography, Box, Link, InputAdornment, MenuItem, IconButton } from "@mui/material";
import { Category, CategoryTag, Item, Tag } from "../../types/common";
import { itemApi } from "../../apis/ItemApi";
import FileUploadButton from "../common/FileUploadButton";
import ItemCard from "../category-item-management/item-section/ItemCard";
import CustomDialog from "../common/CustomDialog";
import { getItemDisplaySerialNumber, getItemDisplayTitle, getItemDisplayTitleWithSerial } from "../../utils/item";
import { getItemGroupName } from "../../utils/item";
import { ItemTypesEnum, itemVisibilities } from "../../types/stage";
import { categoryApi } from "../../apis/CategoryApi";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface UpdateItemsProps {
  itemId: string;
  open: boolean;
  path: Category[];
  groupMax: Record<string, number>;
  onClose: () => void;
  refresh: () => void;
}

export default function UpdateItems({ itemId, open, path, groupMax, onClose, refresh }: UpdateItemsProps) {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const pathString = path.map((p: any) => p.title).join(" > ");

  const [visibility, setVisibility] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrlPreview, setImageUrlPreview] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("0");
  const [editReason, setEditReason] = useState<string>("");
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (itemId) {
      itemApi
        .getItem(itemId)
        .then((item) => {
          categoryApi
            .getCategoryWithParents({ categoryId: item.categoryId, populateParents: true })
            .then((categoryDetails) => {
              if (categoryDetails?.tags) {
                const initialTags = categoryDetails.tags.map((categoryTag: CategoryTag) => ({
                  tag: categoryTag.tag,
                  value: item.tags?.find((tagObj: any) => tagObj.tag === categoryTag.tag)?.value || "",
                  isRequired: categoryTag.required,
                  availableValues: categoryTag.values || [],
                  isCustom: false,
                }));

                if (item.type === ItemTypesEnum.Serialised) {
                  setTags([
                    ...initialTags,
                    {
                      tag: "Group Name",
                      value: getItemGroupName({ itemObj: item }),
                      isRequired: true,
                      availableValues: [],
                      isCustom: false,
                    },
                  ]);
                } else {
                  setTags(initialTags);
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching category:", error);
            });

          setVisibility(item.visibility);
          setTitle(item.title);
          setDescription(item.description);
          setQuantity(item.quantity || "0");
          setSelectedItem(item);
        })
        .catch((error) => {
          console.error("Error fetching item:", error);
        });
    }
  }, [itemId]);

  async function handleSave() {
    const formData = new FormData();

    if (selectedFile) {
      formData.append("itemImage", selectedFile, selectedFile.name);
    }

    const updateObject: any = {
      visibility,
      title,
      description,
      quantity: parseInt(quantity),
      tags: tags,
    };

    formData.append("updateObject", JSON.stringify(updateObject));
    formData.append("ids", JSON.stringify([selectedItem?._id]));

    await itemApi.updateItemsBatch(formData);
    refresh();
    onClose();
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const confirmUpdate = window.confirm("Are you sure you want to update the image?");
      if (confirmUpdate) {
        setSelectedFile(file);
        setImageUrlPreview(URL.createObjectURL(file));
      } else {
        // Optionally reset the file input if the user cancels
        event.target.value = "";
      }
    }
  }

  function handleQuantityChange(change: number) {
    const currentQuantity = parseInt(quantity);
    const newQuantity = Math.max(0, currentQuantity + change).toString();
    setQuantity(newQuantity);
  }

  return !selectedItem ? (
    <></>
  ) : (
    <CustomDialog
      open={open}
      onClose={onClose}
      text={
        selectedItem.type === ItemTypesEnum.Serialised
          ? getItemDisplayTitleWithSerial({
              itemObj: selectedItem,
              serialNumberMax: groupMax[getItemGroupName({ itemObj: selectedItem })],
            })
          : getItemDisplayTitle({ itemObj: selectedItem })
      }
      subText="Change details of item"
    >
      <Stack sx={{ gap: 3, mt: 1 }}>
        <Stack sx={{ gap: 2 }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography>Category: </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{pathString}</Typography>
          </Box>
          {selectedItem.type === ItemTypesEnum.Serialised ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography>Group Name: </Typography>
                <Link
                  href={`/category-item-management/${selectedItem.categoryId}?editGroup=${getItemGroupName({
                    itemObj: selectedItem,
                  })}`}
                >
                  {getItemGroupName({ itemObj: selectedItem })}
                </Link>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography>Serial Number: </Typography>
                <Typography sx={{ fontWeight: "bold" }}>{selectedItem.numberId}</Typography>
              </Box>
            </Box>
          ) : null}
          {selectedItem.type === ItemTypesEnum.Serialised ? null : (
            <TextField label="Unique Item Number" value={selectedItem._id} disabled />
          )}

          {/* {selectedItem.type === ItemTypesEnum.Serialised && (
            <TextField label="Serial Number" value={selectedItem.numberId} disabled />
          )} */}
          <TextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
          <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
          <TextField label="Type" value={selectedItem.type} disabled />
          <TextField
            label="Visibility"
            select
            value={visibility}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVisibility(e.target.value)}
            fullWidth
          >
            <MenuItem value="" disabled>
              Select Visibility
            </MenuItem>
            {itemVisibilities.map((itemVisibility: any) => (
              <MenuItem key={itemVisibility} value={itemVisibility}>
                {itemVisibility}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={{ mb: { xs: 3, sm: 4 } }}>
            <Typography>Category Tags</Typography>

            {tags.length > 0 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(auto-fill, minmax(280px, 1fr))",
                  },
                  gap: { xs: 1.5, sm: 2 },
                  p: { xs: 1.5, sm: 2 },
                  backgroundColor: "grey.50",
                  borderRadius: { xs: 1.5, sm: 2 },
                  border: "1px solid",
                  borderColor: "grey.200",
                }}
              >
                {tags.map((tag, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: { xs: 1.5, sm: 2 },
                      backgroundColor: "background.paper",
                      borderRadius: 1,
                      boxShadow: 1,
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        boxShadow: 2,
                        transform: { xs: "none", sm: "translateY(-2px)" },
                      },
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        mb: 1,
                        color: tag.isRequired ? "primary.main" : "secondary.main",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Box
                          component="span"
                          sx={{
                            width: { xs: 6, sm: 8 },
                            height: { xs: 6, sm: 8 },
                            borderRadius: "50%",
                            backgroundColor: tag?.isRequired ? "primary.main" : "secondary.main",
                            display: "inline-block",
                          }}
                        />
                        {tag?.tag}
                      </Box>
                      {tag.isRequired && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "grey.600",
                          }}
                        >
                          Required
                        </Typography>
                      )}
                    </Typography>

                    <TextField
                      select={tag.availableValues.length > 0}
                      disabled={tag.tag === "Group Name"}
                      value={tag.isCustom ? "custom" : tag.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newTags = [...tags];
                        if (e.target.value === "custom") {
                          newTags[index] = {
                            ...newTags[index],
                            isCustom: true,
                            value: "",
                          };
                        } else {
                          newTags[index] = {
                            ...newTags[index],
                            isCustom: false,
                            value: e.target.value,
                          };
                        }
                        setTags(newTags);
                      }}
                      fullWidth
                      size="small"
                      placeholder={`Enter ${tag.tag}`}
                    >
                      {tag.availableValues.length > 0 && [
                        ...tag.availableValues.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )),
                        <MenuItem key={"custom"} value="custom">
                          <em style={{ color: "grey" }}>Enter custom value</em>
                        </MenuItem>,
                      ]}
                    </TextField>
                    {tag.isCustom && (
                      <TextField
                        value={tag.value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newTags = [...tags];
                          newTags[index] = {
                            ...newTags[index],
                            value: e.target.value,
                          };
                          setTags(newTags);
                        }}
                        fullWidth
                        size="small"
                        placeholder={`Enter custom ${tag.tag}`}
                        sx={{ mt: 1 }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          {selectedItem.type === ItemTypesEnum.NonSerialised && (
            <>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  label="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  type="number"
                  inputProps={{ min: 1 }}
                  disabled
                  sx={{ flex: 1 }}
                />
                <Button variant="contained" onClick={() => handleQuantityChange(1)}>
                  <AddIcon />
                </Button>
                <Button variant="contained" onClick={() => handleQuantityChange(-1)} disabled={parseInt(quantity) <= 1}>
                  <RemoveIcon />
                </Button>
              </Stack>
              {quantity.toString() !== selectedItem?.quantity?.toString() && (
                <TextField
                  label="Reason for updating quantity"
                  value={editReason}
                  onChange={(e) => setEditReason(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={() => {
                            /* Add your button action here */
                          }}
                        >
                          Confirm
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </>
          )}
        </Stack>

        <FileUploadButton onFileChange={handleFileChange}>
          <Typography>Update Item Image</Typography>
        </FileUploadButton>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ width: "20rem", height: "auto" }}>
            {((selectedItem.type === ItemTypesEnum.Serialised && groupMax && Object.keys(groupMax).length > 0) ||
              selectedItem.type === ItemTypesEnum.NonSerialised) && (
              <ItemCard
                item={{ ...selectedItem }}
                serialNumberMax={groupMax[getItemGroupName({ itemObj: selectedItem })]}
                hoveredGroupName={getItemGroupName({ itemObj: selectedItem })}
                onGroupNameHover={() => {}}
              />
            )}
          </Box>
          {imageUrlPreview && (
            <>
              <ArrowForwardIcon sx={{ mx: 1 }} />
              <Box sx={{ width: "20rem", height: "auto" }}>
                {imageUrlPreview &&
                  ((selectedItem.type === ItemTypesEnum.Serialised && groupMax && Object.keys(groupMax).length > 0) ||
                    selectedItem.type === ItemTypesEnum.NonSerialised) && (
                    <ItemCard
                      item={{ ...selectedItem, imageUrlPreview }}
                      serialNumberMax={groupMax[getItemGroupName({ itemObj: selectedItem })]}
                      hoveredGroupName={getItemGroupName({ itemObj: selectedItem })}
                      onGroupNameHover={() => {}}
                    />
                  )}
              </Box>
            </>
          )}
        </Box>
        {imageUrlPreview && (
          <Button variant="outlined" onClick={() => setImageUrlPreview("")}>
            Cancel Image Update
          </Button>
        )}

        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </CustomDialog>
  );
}
