import { Avatar, Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/slices/snackbar";
import CustomDialog from "../common/CustomDialog";
import { packItemApi } from "../../apis/PackItemApi";
import { getS3ImageUrl } from "../../utils/s3Image";

export default function UpdatePackItem({ open, onClose, packItemToEdit, refreshPackItems }: any) {
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState<string>("0");

  useEffect(() => {
    setQuantity(packItemToEdit?.itemQuantity);
  }, [packItemToEdit]);

  const handleUpdatePackItem = async () => {
    try {
      if (!quantity || typeof parseInt(quantity) !== "number" || parseInt(quantity) <= 0) return;
      // Use selectedDropId as needed for update
      await packItemApi.editPackItem({ packItemId: packItemToEdit?._id, updatedItemQuantity: parseInt(quantity) });
      dispatch(openSnackbar({ message: "Quantity updated successfully", severity: "success" }));
      refreshPackItems();
      onClose();
      setQuantity("0");
      // setRefresh(true);
    } catch (e: any) {
      dispatch(openSnackbar({ message: "Failed to update pack item!", severity: "error" }));
      console.error(e);
    }
  };
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      avatarSrc=""
      text="Edit Pack Item"
      subText="Update details for this pack item"
      linkText=""
      linkUrl=""
      linkText2=""
      linkUrl2=""
      removeHeaderDivider={true}
    >
      {({ InputContainer, Label, InputFieldsContainer, StyledTextField, StyledImage }: any) => (
        <React.Fragment>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              alt={packItemToEdit?.ripPack?.name}
              src={getS3ImageUrl({
                imageUrl: packItemToEdit?.ripPack?.imageUrl,
                type: "ripPack",
              })}
              sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
            />
            <Box>
              <Typography variant="body1" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                {packItemToEdit?.ripPack?.name || "No RipPack Name"}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.75rem", sm: "0.875rem" },
                }}
              >
                {packItemToEdit?.sequenceNumber}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <InputContainer>
            <Label variant="body1">Select Quantity</Label>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </InputContainer>

          <Divider sx={{ mb: 2 }} />

          <InputContainer sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={handleUpdatePackItem}
              disabled={!quantity || typeof parseInt(quantity) !== "number" || parseInt(quantity) <= 0}
            >
              Confirm
            </Button>
          </InputContainer>
        </React.Fragment>
      )}
    </CustomDialog>
  );
}
