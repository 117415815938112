import React, { useState, useRef } from "react";
import {
  Card,
  CardMedia,
  IconButton,
  Tooltip,
  Box,
  useTheme,
  Typography,
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { alpha } from "@mui/material/styles";
import { formatDistanceToNow } from "../../utils/dateUtils";
import CustomDialog from "../common/CustomDialog";

interface AssetCardProps {
  imageUrl: string;
  onSelect?: (imageUrl: string) => void;
  onDelete?: () => void;
  asset?: {
    originalName: string;
    size: number;
    mimeType: string;
    createdAt: string | Date;
  };
}

export default function AssetCard({ imageUrl, onSelect, onDelete, asset }: AssetCardProps) {
  const theme = useTheme();
  const [showInfo, setShowInfo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showRename, setShowRename] = useState(false);
  const [newName, setNewName] = useState(asset?.originalName || "");
  const cardRef = useRef<HTMLDivElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const isVideo = asset?.mimeType.startsWith("video/");

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(imageUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy URL:", err);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: asset?.originalName || "Shared Image",
          text: "Check out this image",
          url: imageUrl,
        });
      } catch (error) {
        // Ignore AbortError when user cancels sharing
        if (error instanceof Error && error.name !== "AbortError") {
          console.error("Failed to share:", error);
        }
      }
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <Card
      ref={cardRef}
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
        transform: isHovered ? "translateY(-8px) scale(1.02)" : "none",
        boxShadow: isHovered
          ? `0 20px 25px -5px ${alpha(theme.palette.primary.main, 0.1)}, 0 10px 10px -5px ${alpha(
              theme.palette.primary.main,
              0.04
            )}`
          : theme.shadows[1],
        borderRadius: 2,
        overflow: "hidden",
        "&:hover": !showInfo
          ? {
              "& .image-overlay": {
                opacity: 1,
              },
              "& .image-actions": {
                transform: "translateY(0)",
              },
            }
          : {},
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ position: "relative", paddingTop: "100%", flex: 1 }}>
        <CardMedia
          component={isVideo ? "video" : "img"}
          image={imageUrl}
          alt={asset?.originalName || "Uploaded image"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
            transition: "all 0.3s ease",
            filter: showInfo ? "blur(8px)" : "none",
            transform: isHovered ? "scale(1.05)" : "scale(1)",
          }}
          onClick={() => onSelect?.(imageUrl)}
          {...(isVideo && {
            controls: true,
            muted: true,
            loop: true,
            onMouseEnter: () => setIsVideoPlaying(true),
            onMouseLeave: () => setIsVideoPlaying(false),
          })}
        />

        {/* Info Panel */}
        {showInfo && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: "transparent",
              transition: "all 0.3s ease-in-out",
              transform: showInfo ? "translateY(0)" : "translateY(100%)",
              pointerEvents: showInfo ? "auto" : "none",
            }}
          >
            {/* Glass Background */}
            <Box
              sx={{
                position: "absolute",
                inset: 0,
                bgcolor: alpha(theme.palette.background.paper, 0.8),
                backdropFilter: "blur(10px)",
                borderTop: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
              }}
            />

            {/* Content */}
            <Box sx={{ position: "relative", p: 2 }}>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  minHeight: 40,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    minWidth: 0, // This is important for text truncation to work
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {asset?.originalName}
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={toggleInfo}
                  sx={{
                    ml: 1,
                    mr: -1,
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>

              {/* Details */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(2, 1fr)",
                    sm: "repeat(3, 1fr)",
                  },
                  gap: { xs: 1, sm: 2 },
                  mb: 2,
                }}
              >
                <Box>
                  <Typography variant="caption" color="text.secondary" display="block">
                    Type
                  </Typography>
                  <Typography variant="body2" noWrap sx={{ fontWeight: 500 }}>
                    {asset?.mimeType.split("/")[1].toUpperCase()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary" display="block">
                    Size
                  </Typography>
                  <Typography variant="body2" noWrap sx={{ fontWeight: 500 }}>
                    {formatFileSize(asset?.size || 0)}
                  </Typography>
                </Box>
                <Box sx={{ gridColumn: { xs: "1 / -1", sm: "auto" } }}>
                  <Typography variant="caption" color="text.secondary" display="block">
                    Uploaded
                  </Typography>
                  <Typography variant="body2" noWrap sx={{ fontWeight: 500 }}>
                    {formatDistanceToNow(new Date(asset?.createdAt || ""), { addSuffix: true })}
                  </Typography>
                </Box>
              </Box>

              {/* Actions */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
                  gap: 1,
                }}
              >
                <Button
                  startIcon={copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
                  onClick={handleCopyUrl}
                  variant="text"
                  size="small"
                  fullWidth
                  sx={{
                    color: copied ? "success.main" : "text.primary",
                    transition: "color 0.2s ease-in-out",
                  }}
                >
                  {copied ? "Copied!" : "Copy URL"}
                </Button>
                <Button
                  startIcon={<ShareIcon />}
                  onClick={handleShare}
                  variant="text"
                  size="small"
                  fullWidth
                  sx={{ color: "text.primary" }}
                >
                  Share
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {/* Overlay with gradient */}
        <Box
          className="image-overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(to top, ${alpha(theme.palette.common.black, 0.8)} 0%, ${alpha(
              theme.palette.common.black,
              0.2
            )} 50%, transparent 100%)`,
            opacity: 0,
            transition: "opacity 0.3s ease",
            pointerEvents: showInfo || showRename ? "none" : "auto",
          }}
        />

        {/* Quick actions */}
        <Box
          className="image-actions"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            transform: "translateY(100%)",
            transition: "transform 0.3s ease",
            pointerEvents: showInfo || showRename ? "none" : "auto",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={copied ? "Copied!" : "Copy URL"}>
              <IconButton
                size="small"
                onClick={handleCopyUrl}
                sx={{
                  bgcolor: "background.paper",
                  "&:hover": { bgcolor: "background.default" },
                }}
              >
                {copied ? <CheckCircleIcon sx={{ color: "success.main" }} /> : <ContentCopyIcon />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Share">
              <IconButton
                size="small"
                onClick={handleShare}
                sx={{
                  bgcolor: "background.paper",
                  "&:hover": { bgcolor: "background.default" },
                }}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>

            {!showInfo && (
              <Tooltip title={"Show Info"}>
                <IconButton
                  size="small"
                  onClick={toggleInfo}
                  sx={{
                    bgcolor: "background.paper",
                    "&:hover": { bgcolor: "background.default" },
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Box>
            <IconButton
              onClick={() => {
                handleMenuClose();
                onDelete?.();
              }}
              sx={{ color: "error.main" }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        {/* File type badge */}
        {!showInfo && (
          <Chip
            label={asset?.mimeType.split("/")[1].toUpperCase()}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              bgcolor: alpha(theme.palette.background.paper, 0.9),
              backdropFilter: "blur(4px)",
              pointerEvents: showInfo ? "none" : "auto",
            }}
          />
        )}
      </Box>

      {/* Context Menu */}

      {/* Rename Dialog */}
      <CustomDialog open={showRename} onClose={() => setShowRename(false)}>
        <DialogTitle>Rename Image</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            size="small"
            autoFocus
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRename(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              // TODO: Implement rename functionality
              setShowRename(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </CustomDialog>
    </Card>
  );
}
